import AudioService from '../../../../Services/AudioService';
import { useFineTuningContext } from '../../FineTuningContext';

export type FineTuningBeforeResetPageHandler = {
    startReset: () => Promise<void>;
    goBack: () => void;
};

const useFineTuningBeforeResetPageHandler =
    (): FineTuningBeforeResetPageHandler => {
        const { send } = useFineTuningContext();
        const startReset = async () => {
            await AudioService.serviceInstance.initAudioContext();
            send('startReset');
        };
        const goBack = () => {
            send('goToStart');
        };

        return {
            startReset,
            goBack,
        };
    };

export default useFineTuningBeforeResetPageHandler;
