import { useContext } from 'react';
import { VoidReturn } from '../../../../Utils/PageUtils';
import { MobileDeviceUnmutedCheckContext } from '../MobileDeviceUnmutedCheckContext';
import { MobileDeviceUnmutedCheckState } from '../MobileDeviceUnmutedCheckModel';

export type MobileDeviceUnmutedCheckConfirmationPageHandler = {
    primaryAction: () => VoidReturn;
    secondaryAction: () => VoidReturn;
};

export default function useMobileDeviceUnmutedCheckConfirmationPageHandler(): MobileDeviceUnmutedCheckConfirmationPageHandler {
    const { setCurrentMobileDeviceUnmutedCheckState } = useContext(
        MobileDeviceUnmutedCheckContext
    );

    const primaryAction = () => {
        setCurrentMobileDeviceUnmutedCheckState(
            MobileDeviceUnmutedCheckState.Done
        );
    };

    const secondaryAction = () => {
        setCurrentMobileDeviceUnmutedCheckState(
            MobileDeviceUnmutedCheckState.Troubleshooting
        );
    };

    return {
        primaryAction,
        secondaryAction,
    };
}
