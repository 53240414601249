import React, { useContext } from 'react';
import { HeaderActionType } from './HeaderActionType';
import CustomIconButton from '../Button/CustomIconButton';
import FlexContainer from '../Container/FlexContainer';
import CustomImg from '../CustomImage/CustomImg';
import MenuDialog from '../Dialog/Menu/MenuDialog';
import CustomTypography, {
    TypographyType,
} from '../Typography/CustomTypography';
import useHeaderHandler from './HeaderHandlerHook';
import './HeaderStyle.scss';
import { HeaderResource, VoidReturn } from '../../Utils/PageUtils';
import { MainViewContext } from '../../Pages/MainComponents/MainView/MainViewContext';

export interface IHeaderProps {
    headerContent: HeaderResource;
    disabled?: boolean;
    onClick?: () => void | Promise<void>;
    onCustomBackButtonClick?: () => VoidReturn;
    textStyle?: string;
}

function Header({
    headerContent,
    disabled = false,
    onClick,
    onCustomBackButtonClick,
    textStyle = '',
}: IHeaderProps): JSX.Element {
    const { showMenu, handleCloseMenu, handleOnClick } = useHeaderHandler(
        onClick,
        headerContent.type
    );

    const { backButtonTransition } = useContext(MainViewContext);

    return (
        <FlexContainer flexRow className={'headerArea'}>
            {showMenu && (
                <MenuDialog displayed={showMenu} onClose={handleCloseMenu} />
            )}
            {headerContent.iconImgSrc && (
                <CustomImg
                    imgType="icon"
                    src={headerContent.iconImgSrc}
                    alt={`icon-${headerContent.type}`}
                    style={{ margin: 'auto auto auto 0' }}
                />
            )}
            {headerContent.backButton && (
                <CustomIconButton
                    id="btn-back"
                    imgSrc={`/resources/sharedAcrossWorkflows/Back.svg`}
                    iconSize="iconMediumSmall"
                    onClick={() => {
                        backButtonTransition(onCustomBackButtonClick);
                    }}
                ></CustomIconButton>
            )}
            <CustomTypography
                id={headerContent.id}
                hidden={!headerContent.text}
                type={TypographyType.comfortaaHeaderHeading}
                text={`${
                    headerContent.prefix ? `\`${headerContent.prefix}\`` : ''
                } ${headerContent.text}`}
                textAlign="center"
                className={`${textStyle}
                            ${headerContent.backButton ? '' : 'leftPadding'}
                            ${
                                headerContent.type &&
                                headerContent.type !== HeaderActionType.NoAction
                                    ? ''
                                    : 'rightPadding'
                            }
                }`}
            />
            {headerContent.type &&
                headerContent.type !== HeaderActionType.NoAction && (
                    <CustomIconButton
                        imgSrc={`/resources/sharedAcrossWorkflows/${headerContent.type.toString()}.svg`}
                        disabled={disabled}
                        className={disabled ? 'disabled' : ''}
                        onClick={handleOnClick}
                        id={`btn-${headerContent.type.toString()}`}
                    />
                )}
        </FlexContainer>
    );
}

export default Header;
