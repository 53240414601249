import React, { useContext, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { HLAAState } from '../../Models/HLAA/HLAAState';
import { RootState } from '../../Redux/Reducers/RootReducer';
import ArcToneService from '../../Services/ArcToneService';
import AudioService from '../../Services/AudioService';
import LocalStorageService from '../../Services/LocalStorageService';
import LoggingService from '../../Services/LoggingService';
import { withTracking } from '../../Services/Monitoring/ApplicationInsights';
import { getUserDeviceModel } from '../../Services/ResourceService';
import Polling from '../../Utils/Polling';
import HearingAssessmentBeepConfirmation from './BeepConfirmation/HearingAssessmentBeepConfirmation';
import HearingAssessmentConfirmation from './Confirmation/HearingAssessmentConfirmation';
import {
    HearingAssessmentState,
    HLAAContext,
    IHearingAssessmentContext,
    infoType,
} from './HLAAContext';
import HearingAssessmentInfoPage from './Info/HearingAssessmentInfoPage';
import HearingAssessmentOneSide from './OneSide/HearingAssessmentOneSide';
import HearingAssessmentOneSideDone from './OneSideDone/HearingAssessmentOneSideDone';
import HearingAssessmentOneSideSetUp from './OneSideSetup/HearingAssessmentOneSideSetUp';
import HearingAssessmentPlay from './Play/HearingAssessmentPlay';
import { MainViewContext } from '../MainComponents/MainView/MainViewContext';
import { TransitionAction } from '../MainComponents/MainView/MainViewModel';
import HearingAssessmentTroubleShooting from './TroubleShooting/HearingAssessmentTroubleShooting';
import HearingAssessmentStart from './Start/HearingAssessmentStart';
import HearingAssessmentInstruction from './Instruction/HearingAssessmentInstruction';
import HearingAssessmentFrequencyResource from './HearingAssessmentFrequencyResource.json';

function HearingAssessmentPage() {
    const count = useRef(0);
    const { notification: notificationReduxState } = useSelector(
        (state: RootState) => state
    );
    const [hearingAssessmentState, setHearingAssessmentState] =
        useState<HearingAssessmentState>(HearingAssessmentState.Start);
    const viewContext = useContext(MainViewContext);

    const exitHearingAssessment = async () => {
        switch (hearingAssessmentState) {
            case HearingAssessmentState.Constant0ToneHeard:
                viewContext.doTransition(TransitionAction.AssessmentZeroTone);
                break;
            case HearingAssessmentState.Confirmation:
            case HearingAssessmentState.OneSideDone:
                viewContext.doTransition(TransitionAction.AssessmentContinue);
                break;
            default:
                throw 'Fatal exit transition at HearingAssessmentPage';
        }
    };

    const createContextInfo = (): infoType => {
        const totalFrequency =
            HearingAssessmentFrequencyResource[getUserDeviceModel()];

        return {
            activeSide: 'left',
            left: {
                hlaaDone: false,
            },
            right: {
                hlaaDone: false,
            },
            totalPageCount: 0,
            hlaaState: new HLAAState('', '', -1, -1),
            startKeepAlive: false,
            totalPagesForProgress: totalFrequency * 2,
            freqStartTime: new Date().getTime(),
            repeatDeviceSetup: false,
            hlaaTestId: '',
            loading: false,
            retryBeepConfirmationCount: 0,
            retryBeepConfirmationCountThreshold: 3,
        };
    };

    const info = useRef<infoType>(createContextInfo());

    const initialValue: IHearingAssessmentContext = {
        info: info,
        hearingAssessmentState: hearingAssessmentState,
        setHearingAssessmentState: setHearingAssessmentState,
        exitHearingAssessment: exitHearingAssessment,
    };

    Polling(async () => {
        count.current = count.current + 1;
        if (
            info.current.startKeepAlive === true &&
            notificationReduxState.alertMessage.isDisplayed === false
        ) {
            const brandId =
                LocalStorageService.serviceInstance.getDeviceBrandId(
                    info.current.activeSide
                );
            const pairingAddress =
                LocalStorageService.serviceInstance.getPairingAddress(
                    info.current.activeSide
                );
            await AudioService.serviceInstance.prepAudio(
                ArcToneService.getKeepHLAAModeAlive(brandId, pairingAddress),
                'KeepHLAAModeAlive'
            );
            setDeviceIsKeptAlive();
        }
    }, 30000);

    const setDeviceIsKeptAlive = async () => {
        LoggingService.info({
            componentName: setDeviceIsKeptAlive.name,
            args: [`Device ${info.current.activeSide} is kept alive`],
        });
    };

    const renderSwitch = (state: HearingAssessmentState) => {
        window.scrollTo(0, 0);
        switch (state) {
            case HearingAssessmentState.RedoHLAA:
            case HearingAssessmentState.Start: {
                return <HearingAssessmentStart />;
            }
            case HearingAssessmentState.Instruction: {
                return <HearingAssessmentInstruction />;
            }
            case HearingAssessmentState.SetUp: {
                return <HearingAssessmentOneSideSetUp />;
            }
            case HearingAssessmentState.BeepConfirmation: {
                return <HearingAssessmentBeepConfirmation />;
            }
            case HearingAssessmentState.AssessmentPlay: {
                return <HearingAssessmentPlay />;
            }
            case HearingAssessmentState.AssessmentOneSide: {
                return <HearingAssessmentOneSide />;
            }
            case HearingAssessmentState.Confirmation: {
                return <HearingAssessmentConfirmation />;
            }
            case HearingAssessmentState.OneSideDone: {
                return <HearingAssessmentOneSideDone />;
            }
            case HearingAssessmentState.Reinstruct:
            case HearingAssessmentState.AbortMaxPresentedToneReach:
            case HearingAssessmentState.Constant0ToneHeard:
                return <HearingAssessmentInfoPage />;
            case HearingAssessmentState.TroubleShooting:
                return <HearingAssessmentTroubleShooting />;
            default: {
                return <div></div>;
            }
        }
    };

    return (
        <HLAAContext.Provider value={initialValue}>
            {renderSwitch(hearingAssessmentState)}
        </HLAAContext.Provider>
    );
}

export default withTracking(HearingAssessmentPage);
