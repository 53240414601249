import React from 'react';
import { withTracking } from '../../../../Services/Monitoring/ApplicationInsights';
import CustomTypography, {
    TypographyType,
} from '../../../Typography/CustomTypography';
import useMobileDeviceUnmutedCheckDonePageResource from './MobileDeviceUnmutedCheckDonePageResourceHook';
import useMobileDeviceUnmutedCheckDonePageHandler from './MobileDeviceUnmutedCheckDonePageHandlerHook';
import BodyContainer from '../../../Container/BodyContainer';
import CustomImg from '../../../CustomImage/CustomImg';
import CustomButton from '../../../Button/CustomButton';
import { ButtonType } from '../../../Button/ButtonType';
import './MobileDeviceUnmutedCheckDonePage.scss';

function MobileDeviceUnmutedCheckDonePage() {
    const { infoText, image, button } =
        useMobileDeviceUnmutedCheckDonePageResource();

    const { doneAction } = useMobileDeviceUnmutedCheckDonePageHandler();

    return (
        <>
            <BodyContainer>
                <CustomTypography
                    id={infoText.id}
                    type={TypographyType.notoSansBody16px}
                    text={infoText.text}
                />
                <CustomImg
                    id={image.id}
                    src={image.src}
                    alt={image.alt}
                    imgType="iconLarge"
                    className={'MobileDeviceUnmutedCheckDonePageItemArea'}
                />
            </BodyContainer>
            <CustomButton
                id={button.id}
                buttonType={ButtonType.Primary}
                onClick={doneAction}
            >
                {button.label}
            </CustomButton>
        </>
    );
}

export default withTracking(MobileDeviceUnmutedCheckDonePage);
