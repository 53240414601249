import { ButtonProps } from '@material-ui/core';
import React from 'react';
import SecondaryButton from './SecondaryButton';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import '../ButtonStyle.scss';

function SecondaryButtonWithMenuArrow(
    props: Omit<ButtonProps, 'variant' | 'endIcon'>
): React.ReactElement {
    return (
        <SecondaryButton
            {...props}
            classes={{
                endIcon: 'buttonArrowRightAlign',
            }}
            endIcon={
                <ArrowForwardIosIcon
                    className={
                        props.disabled ? `forwardIcon disabled` : 'forwardIcon'
                    }
                />
            }
        />
    );
}

export default SecondaryButtonWithMenuArrow;
