import { IconButton, IconButtonProps } from '@material-ui/core';
import React from 'react';
import CustomImg, { CustomImgType } from '../CustomImage/CustomImg';
import './ButtonStyle.scss';

export interface ICustomIconButton extends IconButtonProps {
    imgSrc: string;
    imgClassName?: string;
    imgId?: string;
    iconSize?: CustomImgType;
}

function CustomIconButton({
    imgSrc,
    imgClassName,
    imgId,
    iconSize = 'icon',
    ...props
}: ICustomIconButton): React.ReactElement {
    return (
        <IconButton {...props}>
            <CustomImg
                imgType={iconSize}
                id={imgId}
                src={imgSrc}
                className={imgClassName}
                alt=""
            />
        </IconButton>
    );
}

export default CustomIconButton;
