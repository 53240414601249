import React from 'react';
import { ButtonType } from '../../../SharedComponents/Button/ButtonType';
import CustomButton from '../../../SharedComponents/Button/CustomButton';
import AppContainer from '../../../SharedComponents/Container/AppContainer';
import BodyContainer from '../../../SharedComponents/Container/BodyContainer';
import CustomImg from '../../../SharedComponents/CustomImage/CustomImg';
import Header from '../../../SharedComponents/Header/HeaderRefactor';
import CustomTypography, {
    TypographyType,
} from '../../../SharedComponents/Typography/CustomTypography';
import usePairingPrepareHandler from './PairingPrepareHandlerHook';
import usePairingPrepareResource from './PairingPrepareResourceHook';

export default function PairingPrepare(): JSX.Element {
    const { header, instructionImage, instruction, prepareCompleteButton } =
        usePairingPrepareResource();

    const { onPrepareComplete } = usePairingPrepareHandler();

    return (
        <AppContainer>
            <Header headerContent={header} />
            <BodyContainer>
                <CustomImg
                    id={instructionImage.id}
                    src={instructionImage.src}
                    alt=""
                    imgType="large"
                    style={{ marginTop: '5vh' }}
                />
                <CustomTypography
                    id={instruction.id}
                    type={TypographyType.notoSansBody16px}
                    text={instruction.text}
                    style={{ marginBottom: 0 }}
                />
            </BodyContainer>
            <CustomButton
                id={prepareCompleteButton.id}
                buttonType={ButtonType.PrimaryLoading}
                onClick={onPrepareComplete}
            >
                {prepareCompleteButton.label}
            </CustomButton>
        </AppContainer>
    );
}
