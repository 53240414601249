import React from 'react';
import { getMasterGainStepSize } from '../../../Services/ResourceService';
import { IVolumeSlider } from '../SliderFactory';
import VolumeSlider from './SliderWithControlButton';

function SliderWithControlButtonSideLeft(
    others: IVolumeSlider
): React.ReactElement {
    return (
        <VolumeSlider
            thumbImgSrc={`/resources/sharedAcrossWorkflows/slider-left.svg`}
            stepAdjustValue={getMasterGainStepSize()}
            {...others}
        />
    );
}

export default SliderWithControlButtonSideLeft;
