import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { GainUIMapping } from '../../../Models/HearingProfile/GainUIMapping';
import IDeviceState from '../../../Redux/Models/Device/IDeviceState';
import { RootState } from '../../../Redux/Reducers/RootReducer';
import { withTracking } from '../../../Services/Monitoring/ApplicationInsights';
import AudioRoutingPage from './AudioRouting/AudioRoutingPage';
import SpeechComfortTestDetailPage from './Detail/SpeechComfortTestDetailPage';
import useRedoLoudnessPageHandler from './RedoLoudnessPageHandlerHook';
import {
    ISpeechComfortTestContext,
    ISpeechComfortTestInfo,
    SpeechComfortTestContext,
} from './SpeechComfortTestContext';
import { SpeechComfortTestState } from './SpeechComfortTestModels';

function RedoLoudness() {
    const deviceState = useSelector(
        (state: RootState) => state.device
    ) as IDeviceState;

    const [info, setInfo] = useState<ISpeechComfortTestInfo>({
        masterGain: new GainUIMapping([]),
        deviceState: deviceState,
        speechComfortTestState: SpeechComfortTestState.AudioRouting,
        previousLeftValue: 0,
        previousRightValue: 0,
        latestLeftValue: 0,
        latestRightValue: 0,
        loading: false,
    });
    const { exitSpeechComfort } = useRedoLoudnessPageHandler();

    const initialValue: ISpeechComfortTestContext = {
        info: info,
        setSpeechComfortTestInfo: setInfo,
        exitSpeechComfort: exitSpeechComfort,
    };

    const renderSwitch = (state: SpeechComfortTestState) => {
        window.scrollTo(0, 0);
        switch (state) {
            case SpeechComfortTestState.AudioRouting: {
                return <AudioRoutingPage />;
            }
            case SpeechComfortTestState.Detail: {
                return <SpeechComfortTestDetailPage />;
            }
            default: {
                return <></>;
            }
        }
    };

    return (
        <SpeechComfortTestContext.Provider value={initialValue}>
            {renderSwitch(info.speechComfortTestState)}
        </SpeechComfortTestContext.Provider>
    );
}

export default withTracking(RedoLoudness);
