import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { getUserDeviceModel } from '../../../Services/ResourceService';
import { HeaderActionType } from '../../../SharedComponents/Header/HeaderActionType';
import {
    HeaderResource,
    ImageResource,
    switchBasedOnDevice,
    TextResource,
} from '../../../Utils/PageUtils';
import { HLAAContext } from '../HLAAContext';

type HearingAssessmentConfirmationResource = {
    header: HeaderResource;
    sideText: TextResource;
    evaluationImage: ImageResource;
    iconButton: {
        id: string;
    };
    choiceImage: ImageResource;
    confirmInstruction: TextResource;
    replayInstruction: TextResource;
};

export default function useHearingAssementConfirmationResource(): HearingAssessmentConfirmationResource {
    const { t } = useTranslation();
    const { info } = useContext(HLAAContext);

    const side =
        info.current.activeSide.toLowerCase() === 'left' ? 'Left' : 'Right';
    const header = {
        id: 'hlaa-oneSide-header',
        text: t('common:Common_Feature:HearingProfileCheck'),
        type: HeaderActionType.Menu,
    };

    const sideText = {
        id: 'txt-content1',
        text: t(`pairing:Pairing_Feature:${side}Title`),
    };

    const evaluationImage = {
        id: `img-${info.current.activeSide.toLowerCase()}`,
        src: switchBasedOnDevice(getUserDeviceModel(), {
            OrionAir: `/resources/OrionAir/evaluation_${info.current.activeSide.toLowerCase()}_orionAir.svg`,
            OrionC20: `/resources/OrionC20/evaluation_${info.current.activeSide.toLowerCase()}_orionC20.svg`,
            OrionGo: `/resources/OrionGo/evaluation_${info.current.activeSide.toLowerCase()}_orionGo.svg`,
        }),
        alt: `${info.current.activeSide.toLowerCase()}`,
    };

    const iconButton = {
        id: 'fcl-',
    };

    const choiceImage = {
        id: 'img-ans-',
        src: '/resources/fittingWorkflow/hlaa_ans_',
        alt: 'next',
    };

    const confirmInstruction = {
        id: 'txt-confirm',
        text: t('hlaa:Hlaa_Feature:Confirmation:Text1'),
    };

    const replayInstruction = {
        id: 'txt-instruction',
        text: t('hlaa:Hlaa_Feature:Confirmation:Text2'),
    };

    return {
        header,
        sideText,
        evaluationImage,
        iconButton,
        choiceImage,
        confirmInstruction,
        replayInstruction,
    };
}
