import { PrivacyPolicyAndTermsAndConditionsLink } from '../../../../../CommonAssets/Configuration/PrivacyPolicyAndTermsAndConditions';
import { VoidReturn } from '../../../../../Utils/PageUtils';
import useMenuDialogHandler from '../../MenuDialogHandler';
import { itemHandler } from '../../MenuUtils';

export type LegalInfoMenuHandler = {
    openLink: itemHandler;
};

const useLegalInfoMenuHandler = (
    onClose?: () => VoidReturn
): LegalInfoMenuHandler => {
    const { onClickExternalLinkMenu } = useMenuDialogHandler();

    const openLink = async () => {
        onClickExternalLinkMenu(PrivacyPolicyAndTermsAndConditionsLink);
        if (onClose) onClose();
    };

    return {
        openLink,
    };
};

export default useLegalInfoMenuHandler;
