import React from 'react';
import CustomerServiceHintPage from '../../../../Pages/MainComponents/TroubleShooting/CustomerServiceHint/CustomerServiceHintPage';
import TroubleShooting from '../../../../Pages/MainComponents/TroubleShooting/TroubleShooting';
import { withTracking } from '../../../../Services/Monitoring/ApplicationInsights';
import useRedoHLAATroubleShootingHandler from './RedoHLAATroubleShootingHandlerHook';
import useRedoHLAATroubleShootingResource from './RedoHLAATroubleShootingResourceHook';

function RedoHLAATroubleShooting() {
    const { onRetry, retryBeepConfirmationThresholdReached } =
        useRedoHLAATroubleShootingHandler();
    return retryBeepConfirmationThresholdReached &&
        retryBeepConfirmationThresholdReached() ? (
        <CustomerServiceHintPage tryAgainAction={async () => onRetry()} />
    ) : (
        <TroubleShooting
            useHandler={useRedoHLAATroubleShootingHandler}
            useResource={useRedoHLAATroubleShootingResource}
        />
    );
}

export default withTracking(RedoHLAATroubleShooting);
