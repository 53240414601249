import { useContext } from 'react';
import LoggingService from '../../../../Services/LoggingService';
import { VoidReturn } from '../../../../Utils/PageUtils';
import { HIAssemblyContext } from '../HIAssemblyContext';
import { HIAssemblyState } from '../HIAssemblyModel';

export type HIAssemblyBatteryPageHandler = {
    onContinueAction: () => VoidReturn;
    logVideoLoadingError: (componentName: string) => void;
};

export default function useHIAssemblyBatteryPageHandler(): HIAssemblyBatteryPageHandler {
    const { setHIAssemblyState } = useContext(HIAssemblyContext);

    async function onContinueAction() {
        setHIAssemblyState(HIAssemblyState.ClickSleeveSelection);
    }

    function logVideoLoadingError(componentName: string) {
        LoggingService.warn({
            componentName: componentName,
            args: ['Video loading error'],
        });
    }

    return {
        onContinueAction,
        logVideoLoadingError,
    };
}
