import { Side } from '../../../Models/Side';
import DeviceManagementService from '../../../Services/DeviceManagementService';
import LocalStorageService from '../../../Services/LocalStorageService';
import { BeepConfirmationHandle } from '../../MainComponents/BeepConfirmation/BeepConfirmation';
import { usePairingContext } from '../PairingContext';

export default function usePairingConfirmationHandler(): BeepConfirmationHandle {
    const {
        info,
        state: { context },
        send,
    } = usePairingContext();

    const handlePairConfirmYesAction = async () => {
        await DeviceManagementService.assignPairedDevice(
            LocalStorageService.serviceInstance.getDeviceId(context.activeSide),
            info.current[context.activeSide as Side]
        );
        LocalStorageService.serviceInstance.setPairingAddress(
            context.activeSide,
            info.current[context.activeSide as Side]
        );
        send('confimred');
    };

    const handlePairConfirmNoAction = () => {
        send('rejected');
    };

    return {
        primaryAction: handlePairConfirmYesAction,
        secondaryAction: handlePairConfirmNoAction,
    };
}
