import { useContext } from 'react';
import { CustomerServiceContactService } from '../../../Services/Configuration/CustomerServiceContactService';
import { goToUrl } from '../../../Utils/NavigationUtils';
import { linkCallBacksResource, VoidReturn } from '../../../Utils/PageUtils';
import { PairingContext } from '../PairingContext';
import { MainViewContext } from '../../MainComponents/MainView/MainViewContext';
import { TransitionAction } from '../../MainComponents/MainView/MainViewModel';

export type TechnicalSetupHandler = {
    handleStartPairingAction: () => VoidReturn;
    linkCallBacks: linkCallBacksResource;
};

function useTechnicalSetupHandler(): TechnicalSetupHandler {
    const { send } = useContext(PairingContext);

    const viewContext = useContext(MainViewContext);

    const handleStartPairingAction = async () => {
        send('completeTechnicalSetup');
    };

    const startMobileDeviceUnmutedCheck = () => {
        viewContext.doTransition(
            TransitionAction.DoMobileDeviceUnmutedCheckDialogOpen
        );
    };

    const openHyperLink = () => {
        // TelemetryService.serviceInstance.TrackAnonymous(
        //     'Orion-FeatureUsage',
        //     {
        //         Feature: 'NeedHelpInPairing',
        //     }
        // );
        goToUrl(
            CustomerServiceContactService.getCountrySpecificOrionConnectionCheckAddress(
                false
            ),
            true
        );
    };

    const linkCallBacks = {
        customerSupport: openHyperLink,
        startMobileDeviceUnmutedCheck: startMobileDeviceUnmutedCheck,
    };

    return {
        handleStartPairingAction,
        linkCallBacks,
    };
}

export default useTechnicalSetupHandler;
