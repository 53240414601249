import { useContext } from 'react';
import TelemetryService from '../../../Services/Monitoring/TelemetryService';
import { getUserDeviceModel } from '../../../Services/ResourceService';
import { VoidReturn } from '../../../Utils/PageUtils';
import { PairingContext } from '../PairingContext';

export type PairingSuccessHandler = {
    handlePrimaryAction: () => VoidReturn;
};

export default function usePairingSuccessHandler(): PairingSuccessHandler {
    const {
        state: { context },
        send,
    } = useContext(PairingContext);

    const handlePrimaryAction = async () => {
        TelemetryService.serviceInstance.TrackAnonymous(
            'Orion-CompletedWorkflow',
            {
                Workflow:
                    context.activeSide === 'left'
                        ? 'DevicePairingLeft'
                        : 'DevicePairingRight',
                Model: getUserDeviceModel(),
            }
        );
        send('successed');
    };
    return {
        handlePrimaryAction,
    };
}
