import React from 'react';
import { withTracking } from '../../../../Services/Monitoring/ApplicationInsights';
import { IDialogContentProps } from '../../DialogUtils';
import useRedoEasyFitDialogResource from './RedoEasyFitDialogResourceHook';
import WarningDialog from '../../Warning/WarningDialog';
import useRedoEasyFitDialogHandler from './RedoEasyfitDialogHandlerHook';

function RedoEasyFitDialog({ onClose }: IDialogContentProps) {
    const useHandler = () => useRedoEasyFitDialogHandler();

    return (
        <WarningDialog
            onClose={onClose}
            useHandler={useHandler}
            useResource={useRedoEasyFitDialogResource}
        />
    );
}

export default withTracking(RedoEasyFitDialog);
