import React from 'react';
import { withTracking } from '../../../Services/Monitoring/ApplicationInsights';
import { ButtonType } from '../../../SharedComponents/Button/ButtonType';
import CustomButton from '../../../SharedComponents/Button/CustomButton';
import AppContainer from '../../../SharedComponents/Container/AppContainer';
import { WelcomeView } from './WelcomeView';
import useWelcomeHandler from './WelcomeHandlerHook';
import useWelcomeResource from './WelcomeResourceHook';

const WelcomePage = () => {
    const { header, illustrationImage, title, subtitle, continueButton } =
        useWelcomeResource();

    const { onContinue } = useWelcomeHandler();

    return (
        <>
            <AppContainer>
                <WelcomeView
                    header={header}
                    illustrationImage={illustrationImage}
                    title={title}
                    subtitle={subtitle}
                />
                <CustomButton
                    buttonType={ButtonType.PrimaryLoading}
                    id={continueButton.id}
                    onClick={onContinue}
                >
                    {continueButton.label}
                </CustomButton>
            </AppContainer>
        </>
    );
};

export default withTracking(WelcomePage);
