import { useTranslation } from 'react-i18next';
import { CustomerServiceContactService } from '../../../Services/Configuration/CustomerServiceContactService';
import { getUserDeviceModel } from '../../../Services/ResourceService';
import { HeaderActionType } from '../../../SharedComponents/Header/HeaderActionType';
import { LinkCallBacks } from '../../../SharedComponents/Typography/CustomTypography';
import { goToUrl } from '../../../Utils/NavigationUtils';
import {
    ButtonResource,
    HeaderResource,
    ImageResource,
    switchBasedOnDevice,
    TextResource,
} from '../../../Utils/PageUtils';

export type SevereHearingLossPageResource = {
    header: HeaderResource;
    instructionImage: ImageResource;
    customerServiceImage: ImageResource;
    customerServiceLink: TextResource;
    instructionHeader: TextResource;
    instruction1: TextResource;
    instruction2: TextResource;
    buttons: {
        primary: ButtonResource;
        secondary: ButtonResource;
    };
    linkCallBacks: LinkCallBacks;
};

export default function useSevereHearingLossPageResource(): SevereHearingLossPageResource {
    const { t } = useTranslation();

    const header = {
        id: 'servere-hearingloss-header',
        text: t(
            `initialSettings:InitialSettings_Feature:SevereHearingLoss:Header`
        ),
        type: HeaderActionType.Menu,
    };

    const instructionImage = {
        id: 'img-severe-hearing-loss',
        src: switchBasedOnDevice(getUserDeviceModel(), {
            OrionAir: `/resources/OrionAir/severe-hearing-loss-orionAir.svg`,
            OrionC20: `/resources/OrionC20/severe-hearing-loss-orionC20.svg`,
            OrionGo: `/resources/OrionGo/severe-hearing-loss-orionGo.svg`,
        }),
        alt: 'initialSettings:severeHearingLoss:header',
    };

    const customerServiceImage = {
        id: 'img-customer-service-webSupport',
        src: `/resources/sharedAcrossWorkflows/customer-care-webSupport.svg`,
        alt: 'img-customer-service-phone',
    };

    const linkCallBacks = {
        supportLink: () => {
            {
                goToUrl(
                    CustomerServiceContactService.getCountrySpecificOrionCustomerServiceContact(
                        false
                    ),
                    true
                );
            }
        },
    };

    const customerServiceLink = {
        id: 'txt-customer-service-webSupport',
        text: t(
            'initialSettings:InitialSettings_Feature:SevereHearingLoss:SupportLink'
        ),
    };

    const instructionHeader = {
        id: 'severeHearingLoss-customerServiceHeader',
        text: t(
            `initialSettings:InitialSettings_Feature:SevereHearingLoss:Text3`
        ),
    };
    const instruction1 = {
        id: 'severeHearingLoss-instruction1',
        text: t(
            `initialSettings:InitialSettings_Feature:SevereHearingLoss:Text1`
        ),
    };
    const instruction2 = {
        id: 'severeHearingLoss-instruction2',
        text: t(
            `initialSettings:InitialSettings_Feature:SevereHearingLoss:Text2`
        ),
    };
    const buttons = {
        primary: {
            label: t(`common:Common_Feature:Exit`),
            id: 'btn-exit',
        },
        secondary: {
            label: t(`common:Common_Feature:TryAgain`),
            id: 'btn-tryAgain',
        },
    };

    return {
        header,
        instructionImage,
        customerServiceImage,
        customerServiceLink,
        instructionHeader,
        instruction1,
        instruction2,
        buttons,
        linkCallBacks,
    };
}
