import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderActionType } from '../../../SharedComponents/Header/HeaderActionType';
import {
    ButtonResource,
    HeaderResource,
    ImageResource,
    TextResource,
} from '../../../Utils/PageUtils';
import { PairingContext } from '../PairingContext';
import { replaceSideWithText } from '../PairingUtils';

export type PairingSuccessResource = {
    header: HeaderResource;
    instruction: TextResource;
    instructionImage: ImageResource;
    button: ButtonResource;
};
export default function usePairingSuccessResource(): PairingSuccessResource {
    const { t } = useTranslation();
    const {
        state: { context },
    } = useContext(PairingContext);

    const header = {
        id: 'header-pairing-success',
        type: HeaderActionType.Menu,
        text: t(
            `pairing:Pairing_Feature:Success:Header`,
            replaceSideWithText(t, context.activeSide)
        ),
    };
    const instruction = {
        id: 'txt-instruction',
        text:
            context.activeSide === 'left'
                ? t(`common:Common_Feature:ConnectionSuccessfulLeft`)
                : t(`common:Common_Feature:ConnectionSuccessfulRight`),
    };
    const instructionImage = {
        id: 'img-instruction',
        src: `/resources/sharedAcrossWorkflows/successful_tick-mark.svg`,
    };
    const button = {
        id: 'btn-continue',
        label: t('common:Common_Feature:Continue'),
    };
    return {
        header,
        instruction,
        instructionImage,
        button,
    };
}
