import { ImageResource } from '../../../../../Utils/PageUtils';
import { t } from '../../../../../Services/LocalizationService';
import { CustomerServiceContactService } from '../../../../../Services/Configuration/CustomerServiceContactService';
import { LinkCallBacks } from '../../../../../SharedComponents/Typography/CustomTypography';
import { goToUrl } from '../../../../../Utils/NavigationUtils';

type FineTuningCustomerServicePageResource = {
    makeCallText: {
        id: string;
        text: string;
    };
    image: ImageResource;
    customerServiceLink: {
        id: string;
        text: string;
    };
    linkCallBacks: LinkCallBacks;
};

export default function useFineTuningCustomerServicePageResource(
    handleCustomerService: () => void
): FineTuningCustomerServicePageResource {
    const makeCallText = {
        id: 'txt-makecall',
        text: t('fineTuning:FineTuning_Feature:Solution:Text5'),
    };

    const image = {
        id: 'img-customer-service',
        src: `/resources/sharedAcrossWorkflows/customer-care-webSupport.svg`,
        alt: 'customer-service',
    };

    const openHyperLink = () => {
        goToUrl(
            CustomerServiceContactService.getCountrySpecificOrionCustomerServiceContact(
                false
            ),
            true
        );
    };

    const linkCallBacks = {
        supportLink: () => {
            handleCustomerService();
            openHyperLink();
        },
    };

    const customerServiceLink = {
        id: 'txt-contact-customer-service',
        text: t('common:Common_Feature:SupportLink'),
    };

    return {
        makeCallText,
        image,
        customerServiceLink,
        linkCallBacks,
    };
}
