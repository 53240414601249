import axios from 'axios';
import { sleep } from './ResourcesUtils';

export enum imageFolderEnum {
    sharedAcrossWorkflows = 'sharedAcrossWorkflows',
    fittingWorkflow = 'fittingWorkflow',
    fineTuningWorkflow = 'fineTuningWorkflow',
    OrionAir = 'OrionAir',
    OrionGo = 'OrionGo',
    OrionC20 = 'OrionC20',
}

export const preloadImages = async (
    folderName: imageFolderEnum
): Promise<void> => {
    let imageList: string[] = [];
    if (process.env.REACT_APP_MODE == 'LOCAL') return;
    const cdnEndpoint = process.env.REACT_APP_CDN_ENDPOINT as string;
    const preloadImage = async (url: string) => {
        await sleep(150);
        axios.get(`${cdnEndpoint}${url}`);
    };
    axios
        .get(`${cdnEndpoint}resources/${folderName}/imageList.json`)
        .then(async (imageListJson) => {
            imageList = imageListJson.data['imagePaths'];
            for (let i = 0; i < imageList.length; i++) {
                await preloadImage(imageList[i]);
            }
        });
};

export function getDeviceFolderNameFromModelId(
    modelId: string
): imageFolderEnum {
    if (modelId == '6144007') {
        return imageFolderEnum.OrionAir;
    } else if (modelId == '72780372515670') {
        return imageFolderEnum.OrionC20;
    } else if (modelId == '540938247') {
        return imageFolderEnum.OrionGo;
    }
    return imageFolderEnum.OrionAir; //default
}
