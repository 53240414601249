import { useTranslation } from 'react-i18next';
import { CustomerServiceContactService } from '../../../../Services/Configuration/CustomerServiceContactService';
import { HeaderActionType } from '../../../../SharedComponents/Header/HeaderActionType';
import { LinkCallBacks } from '../../../../SharedComponents/Typography/CustomTypography';
import { goToUrl } from '../../../../Utils/NavigationUtils';
import {
    ButtonResource,
    HeaderResource,
    ImageResource,
    TextResource,
} from '../../../../Utils/PageUtils';

export type CustomerServiceHintPageResource = {
    header: HeaderResource;
    customerServiceImage: ImageResource;
    customerServiceLink: TextResource;
    instructionHeader: TextResource;
    instruction1: TextResource;
    instruction2: TextResource;
    tryAgainButton: ButtonResource;
    exitButton: ButtonResource;
    linkCallBacks: LinkCallBacks;
};

export default function useCustomerServiceHintPageResource(): CustomerServiceHintPageResource {
    const { t } = useTranslation();

    const header = {
        id: 'customer-service-hint-header',
        text: t(`common:Common_Feature:CustomerServiceHint:Header`),
        type: HeaderActionType.Menu,
    };

    const customerServiceImage = {
        id: 'img-customer-service-support-link',
        src: `/resources/sharedAcrossWorkflows/customer-care-webSupport.svg`,
        alt: 'img-customer-service-support-link',
    };

    const linkCallBacks = {
        supportLink: () => {
            {
                goToUrl(
                    CustomerServiceContactService.getCountrySpecificOrionCustomerServiceContact(
                        false
                    ),
                    true
                );
            }
        },
    };

    const customerServiceLink = {
        id: 'txt-customer-service-hint-supportlink',
        text: t('common:Common_Feature:CustomerServiceHint:SupportLink'),
    };

    const instructionHeader = {
        id: 'txt-customer-service-hint-title',
        text: t('common:Common_Feature:CustomerServiceHint:Text2'),
    };
    const instruction1 = {
        id: 'txt-customer-service-hint-instruction1',
        text: t('common:Common_Feature:CustomerServiceHint:Text1'),
    };
    const instruction2 = {
        id: 'txt-customer-service-hint-instruction2',
        text: t('common:Common_Feature:CustomerServiceHint:Text3'),
    };
    const tryAgainButton = {
        label: t('common:Common_Feature:TryAgain'),
        id: 'btn-try-again',
    };
    const exitButton = {
        label: t(`common:Common_Feature:Exit`),
        id: 'btn-exit',
    };

    return {
        header,
        customerServiceImage,
        customerServiceLink,
        instructionHeader,
        instruction1,
        instruction2,
        tryAgainButton,
        exitButton,
        linkCallBacks,
    };
}
