import { useTranslation } from 'react-i18next';
import { HeaderActionType } from '../../Header/HeaderActionType';
import { WarningDialogResource } from '../Warning/WarningDialog';
import deviceInfoCheckDialogResource from './DeviceInfoCheckDialogResource.json';

function useDeviceInfoCheckDialogResource(): WarningDialogResource {
    const { t } = useTranslation();

    const {
        headerContent,
        imgContent,
        warningTitleContent,
        warningMessageContent,
        warningInfoContent,
        yesButton,
        noButton,
    } = deviceInfoCheckDialogResource;

    const header = {
        id: headerContent.id,
        text: t(`common${headerContent.translationKey}`),
        type: HeaderActionType.NoAction,
    };

    const image = imgContent;

    const warningTitle = {
        id: warningTitleContent.id,
        text: t(`orionGo${warningTitleContent.translationKey}`),
    };

    const warningMessage = {
        id: warningMessageContent.id,
        text: t(`orionGo${warningMessageContent.translationKey}`),
    };

    const warningInfo = {
        id: warningInfoContent.id,
        text: t(`common${warningInfoContent.translationKey}`),
    };

    const buttonLabels = {
        primaryLabel: {
            id: yesButton.id,
            label: t(yesButton.translationKey),
        },
        secondaryLabel: {
            id: noButton.id,
            label: t(noButton.translationKey),
        },
    };

    return {
        header,
        image,
        warningTitle,
        warningMessage,
        warningInfo,
        buttonLabels,
    };
}

export default useDeviceInfoCheckDialogResource;
