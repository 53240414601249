import { useContext } from 'react';
import { CustomerServiceContactService } from '../../../Services/Configuration/CustomerServiceContactService';
import TelemetryService from '../../../Services/Monitoring/TelemetryService';
import { getUserDeviceModel } from '../../../Services/ResourceService';
import { goToUrl } from '../../../Utils/NavigationUtils';
import { linkCallBacksResource, VoidReturn } from '../../../Utils/PageUtils';
import { PairingContext } from '../PairingContext';
import { MainViewContext } from '../../MainComponents/MainView/MainViewContext';
import { TransitionAction } from '../../MainComponents/MainView/MainViewModel';

export type PairingTroubleShootingHandler = {
    onClickContinue: () => VoidReturn;
    retryBeepConfirmationCountReached: () => boolean;
    linkCallBacks: linkCallBacksResource;
};

export default function usePairingTroubleShootingHandler(): PairingTroubleShootingHandler {
    const { send, state } = useContext(PairingContext);

    const viewContext = useContext(MainViewContext);

    const onClickContinue = () => {
        TelemetryService.serviceInstance.TrackAnonymous('Orion-FeatureUsage', {
            Feature: 'TryPairingAgain',
            Model: getUserDeviceModel(),
        });
        send('backToPairing');
    };

    const retryBeepConfirmationCountReached = () => {
        return (
            state.context.retryBeepConfirmationCount >=
            state.context.retryBeepConfirmationCountThreshold
        );
    };

    const openCustomerServiceContactHyperLink = () => {
        goToUrl(
            CustomerServiceContactService.getCountrySpecificOrionCustomerServiceContact(
                false
            ),
            true
        );
    };

    const openConnectionSupportLink = () => {
        goToUrl(
            CustomerServiceContactService.getCountrySpecificOrionConnectionCheckAddress(
                false
            ),
            true
        );
    };

    const startMobileDeviceUnmutedCheck = () => {
        viewContext.doTransition(
            TransitionAction.DoMobileDeviceUnmutedCheckDialogOpen
        );
    };

    const linkCallBacks = {
        customerSupport: openCustomerServiceContactHyperLink,
        connectionCheckSupport: openConnectionSupportLink,
        startMobileDeviceUnmutedCheck: startMobileDeviceUnmutedCheck,
    };

    return {
        onClickContinue,
        retryBeepConfirmationCountReached,
        linkCallBacks,
    };
}
