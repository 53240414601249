import { VoidReturn } from '../../../Utils/PageUtils';
import { MenuItem } from '../../List/MenuList';
import { ListItemType, SubMenuItem } from '../../List/SubMenuList';

export const createMenuItem = (
    iconImgSrc: string,
    displayText: string,
    onClickHandler: (item: ListItemType) => VoidReturn,
    id?: string,
    hasSubMenu?: boolean,
    disabled?: boolean
): MenuItem => {
    return {
        id,
        iconImgSrc,
        displayText,
        hasSubMenu,
        disabled,
        onClickHandler,
    };
};

export const createSubMenuItem = (
    id: string,
    displayText: string,
    onClickHandler: (item: ListItemType) => VoidReturn,
    textColor?: string
): SubMenuItem => {
    return {
        id,
        onClickHandler: onClickHandler,
        displayText,
        textColor,
    };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type itemHandler = (item?: any) => VoidReturn;
