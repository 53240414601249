import { Button, ButtonProps } from '@material-ui/core';
import React from 'react';
import { joinMultipleStyle } from '../../../Utils/ScssUtils';
import '../ButtonStyle.scss';

function SecondaryButton({
    className,
    ...others
}: Omit<ButtonProps, 'variant'>): React.ReactElement {
    return (
        <Button
            className={joinMultipleStyle('buttonNotFilled', className)}
            variant="outlined"
            {...others}
        />
    );
}

export default SecondaryButton;
