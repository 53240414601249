import { VoidReturn } from '../../../Utils/PageUtils';
import { ProgressBarCalculateCurrentProgress } from '../../../Utils/ProgressBarService';
import { HearingAssessmentState, HLAAContext } from '../HLAAContext';
import { useContext } from 'react';
import TelemetryService from '../../../Services/Monitoring/TelemetryService';
import AudioService from '../../../Services/AudioService';
import { getUserDeviceModel } from '../../../Services/ResourceService';
import { isBluetoothDeviceByModel } from '../../../Utils/BluetoothDeviceUtils';

type HearingAssessmentBeepConfirmationHandler = {
    onBeepConfirmationYesAction: () => VoidReturn;
    onBeepConfirmationNoAction: () => VoidReturn;
    getTopProgress: () => number;
};

export default function useHearingAssessmentBeepConfirmationHandler(): HearingAssessmentBeepConfirmationHandler {
    const { info, setHearingAssessmentState } = useContext(HLAAContext);
    const userDeviceModel = getUserDeviceModel();
    const getTopProgress = (): number => {
        return ProgressBarCalculateCurrentProgress(
            info.current.totalPageCount,
            info.current.totalPagesForProgress
        );
    };

    const retryBeepConfirmationThresholdReached = () => {
        return (
            info.current.retryBeepConfirmationCount >=
            info.current.retryBeepConfirmationCountThreshold
        );
    };
    const onBeepConfirmationYesAction = () => {
        info.current.startKeepAlive = true;
        setHearingAssessmentState(HearingAssessmentState.AssessmentPlay);
    };

    const onBeepConfirmationNoActionNonBle = async () => {
        TelemetryService.serviceInstance.TrackAnonymous('Orion-UserIssue', {
            Model: userDeviceModel,
            Issue: 'HLAASetupConfirmationBeepNotHeard',
        });
        updateRetryBeepConfirmationCount();
        if (retryBeepConfirmationThresholdReached()) {
            setHearingAssessmentState(HearingAssessmentState.TroubleShooting);
        } else {
            onStartHLAA();
        }
    };

    const onBeepConfirmationNoActionBle = () => {
        TelemetryService.serviceInstance.TrackAnonymous('Orion-UserIssue', {
            Model: userDeviceModel,
            Issue: 'HLAASetupConfirmationBeepNotHeard',
        });
        updateRetryBeepConfirmationCount();
        setHearingAssessmentState(HearingAssessmentState.TroubleShooting);
    };

    const updateRetryBeepConfirmationCount = () => {
        if (!retryBeepConfirmationThresholdReached()) {
            info.current.retryBeepConfirmationCount++;
        }
    };

    const onStartHLAA = async () => {
        await AudioService.serviceInstance.initAudioContext();

        info.current.startKeepAlive = false;
        info.current.repeatDeviceSetup = true;
        info.current.freqStartTime = new Date().getTime();

        setHearingAssessmentState(HearingAssessmentState.SetUp);
    };

    const onBeepConfirmationNoAction = isBluetoothDeviceByModel(userDeviceModel)
        ? onBeepConfirmationNoActionBle
        : onBeepConfirmationNoActionNonBle;

    return {
        onBeepConfirmationYesAction,
        onBeepConfirmationNoAction,
        getTopProgress,
    };
}
