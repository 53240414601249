export class MaintenanceInfo {
    public IsAppInMaintenanceMode: boolean;
    public MaintenancePeriodEndTime: number;

    constructor(
        IsAppInMaintenanceMode: boolean,
        MaintenancePeriodEndTime: number
    ) {
        this.IsAppInMaintenanceMode = IsAppInMaintenanceMode;
        this.MaintenancePeriodEndTime = MaintenancePeriodEndTime;
    }
}
