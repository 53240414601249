import React, { useContext } from 'react';
import { MainViewContext } from '../../../Pages/MainComponents/MainView/MainViewContext';
import { MainViewWorkflow } from '../../../Pages/MainComponents/MainView/MainViewModel';
import { getUserDeviceModel } from '../../../Services/ResourceService';
import { isBluetoothDeviceByModel } from '../../../Utils/BluetoothDeviceUtils';
import CustomDialog from '../CustomDialog';
import DataCollection from '../DataCollection/DataCollection';
import { IDialogProps } from '../DialogUtils';
import LegalDialog from '../Legal/LegalDialog';
import { LegalInfoType } from '../Legal/LegalDialogModel';
import RedoHLAADialog from '../RedoHLAA/Dialog/RedoHLAADialog';
import RedoHLAAPage from '../RedoHLAA/RedoHLAAPage';
import { MenuContext } from './MenuContext';
import useMenuDialogHandler from './MenuDialogHandler';
import { MenuDialogType } from './MenuDialogType';
import { useInstructionsMenuResource } from './MenuPages/InstructionsMenu/InstructionsMenuResourceHook';
import useLegalInformationMenuResource from './MenuPages/LegalInfo/LegalInfoMenuResourceHook';
import MainMenu from './MenuPages/MainMenu/MainMenu';
import useAccountSettingsResource from './MenuPages/Settings/AccountSettingsMenuResourceHook';
import SubMenuDialog from './SubMenuDialog';
import GlobalDialog from '../GlobalDialog/UseGlobalDialog';

function MenuDialog({ displayed, onClose }: IDialogProps): React.ReactElement {
    const { popMenu, peekMenu, pushMenu } = useMenuDialogHandler();

    const viewContext = useContext(MainViewContext);

    const menuView: { [key: string]: React.ReactElement } = {
        main: <MainMenu onClose={onClose} key={'main'} />,
        [MenuDialogType.ReturnToTimeline]: <GlobalDialog />,
        [MenuDialogType.RedoHLAA]:
            viewContext.workflowType === MainViewWorkflow.AppFitting &&
            isBluetoothDeviceByModel(getUserDeviceModel()) ? (
                <RedoHLAAPage onClose={onClose} />
            ) : (
                <RedoHLAADialog
                    onClose={onClose}
                    key={MenuDialogType.RedoHLAA}
                />
            ),
        [MenuDialogType.Instructions]: (
            <SubMenuDialog
                useResource={useInstructionsMenuResource}
                onClose={onClose}
                key={MenuDialogType.Instructions}
            />
        ),
        [MenuDialogType.AccountSettings]: (
            <SubMenuDialog
                key={MenuDialogType.AccountSettings}
                useResource={useAccountSettingsResource}
                onClose={onClose}
            />
        ),
        [MenuDialogType.RevokeAnalyticConsent]: (
            <DataCollection
                key={MenuDialogType.RevokeAnalyticConsent}
                onClose={onClose}
            />
        ),
        [MenuDialogType.LegalInformation]: (
            <SubMenuDialog
                useResource={useLegalInformationMenuResource}
                onClose={onClose}
                key={MenuDialogType.LegalInformation}
            />
        ),
        [MenuDialogType.AppInfo]: (
            <LegalDialog
                type={LegalInfoType.AppInfo}
                onClose={popMenu}
                key={MenuDialogType.AppInfo}
            />
        ),
        [MenuDialogType.LegalInfo]: (
            <LegalDialog
                type={LegalInfoType.LegalInfo}
                onClose={popMenu}
                key={MenuDialogType.LegalInfo}
            />
        ),
        [MenuDialogType.CookiePolicy]: (
            <LegalDialog
                type={LegalInfoType.CookiePolicy}
                onClose={popMenu}
                key={MenuDialogType.CookiePolicy}
            />
        ),
    };

    return (
        <MenuContext.Provider value={{ pushMenu, popMenu, onClose }}>
            <CustomDialog open={displayed} onClose={onClose}>
                {menuView[peekMenu()]}
            </CustomDialog>
        </MenuContext.Provider>
    );
}
export default React.memo(MenuDialog);
