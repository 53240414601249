import {
    Step,
    StepConnector,
    StepContent,
    StepLabel,
    Stepper,
} from '@material-ui/core';
import React from 'react';
import { ButtonType } from '../../Button/ButtonType';
import CustomButton from '../../Button/CustomButton';
import ButtonContainer from '../../Container/ButtonContainer';
import FlexContainer from '../../Container/FlexContainer';
import CustomImg from '../../CustomImage/CustomImg';
import CustomTypography, {
    TypographyType,
} from '../../Typography/CustomTypography';
import { VerticalStepperProps } from '../StepperModel';
import '../StepperStyle.scss';

function ButtonStepper({
    activeStep,
    stepContents,
}: VerticalStepperProps): React.ReactElement {
    return (
        <Stepper
            id="stepper-workflow"
            className={'stepperWorkflow'}
            activeStep={activeStep}
            orientation="vertical"
            connector={
                <StepConnector
                    classes={{
                        line: 'connectorLine',
                        vertical: 'connectorVertical',
                    }}
                />
            }
        >
            {stepContents.map((stepContent, index) => (
                <Step
                    key={index}
                    expanded={true}
                    className={stepContent.disabled ? 'disabled' : ''}
                >
                    <StepLabel
                        icon={
                            <CustomImg
                                id={`img-stepper-${stepContent.stepLabel}`}
                                src={stepContent.stepIcon}
                                alt={`img-stepper-${stepContent.stepLabel}`}
                            />
                        }
                    >
                        <FlexContainer flexRow className={'stepperLabelArea'}>
                            <CustomTypography
                                id={`txt-stepper-label-${stepContent.stepLabel}`}
                                type={TypographyType.notoSansBody16px}
                                text={stepContent.stepLabelContent}
                                textAlign={'left'}
                                className={'stepperLabelText'}
                            />
                        </FlexContainer>
                    </StepLabel>
                    <StepContent
                        id={`txt-stepper-content-${stepContent.stepLabel}`}
                        classes={{
                            root:
                                stepContent.index === stepContents.length - 1
                                    ? ''
                                    : 'stepperContent',
                        }}
                    >
                        <ButtonContainer className={'buttonStepContent'}>
                            <CustomButton
                                buttonType={ButtonType.SubMenuButton}
                                withDefaultContainer={false}
                                onClick={stepContent.onClickHandler}
                                className={stepContent.className}
                                disabled={stepContent.disabled}
                                id={stepContent.id}
                            >
                                {stepContent.stepTextContent}
                            </CustomButton>
                        </ButtonContainer>
                    </StepContent>
                </Step>
            ))}
        </Stepper>
    );
}

export default ButtonStepper;
