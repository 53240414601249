import { useContext } from 'react';
import LoggingService from '../../../../Services/LoggingService';
import PatientManagementService from '../../../../Services/PatientManagementService';
import { Workflow } from '../../../../Utils/CompletedWorkflow';
import { VoidReturn } from '../../../../Utils/PageUtils';
import { MainViewContext } from '../../MainView/MainViewContext';
import { TransitionAction } from '../../MainView/MainViewModel';
import { HIAssemblyContext } from '../HIAssemblyContext';
import { HIAssemblyState } from '../HIAssemblyModel';

export type HIAssemblyClickSleevePageHandler = {
    onContinueAction: () => VoidReturn;
    logVideoLoadingError: (componentName: string) => void;
    onBackButtonAction: () => VoidReturn;
};

export default function useHIAssemblyClickSleevePageHandler(): HIAssemblyClickSleevePageHandler {
    const viewContext = useContext(MainViewContext);
    const { setHIAssemblyState } = useContext(HIAssemblyContext);

    async function onContinueAction() {
        await PatientManagementService.endWorkflow(Workflow.HIAssemble);
        viewContext.doTransition(TransitionAction.HiAssembleContinue);
    }

    function onBackButtonAction() {
        setHIAssemblyState(HIAssemblyState.BatteryInsertion);
    }

    function logVideoLoadingError(componentName: string) {
        LoggingService.warn({
            componentName: componentName,
            args: ['Video loading error'],
        });
    }

    return {
        onContinueAction,
        logVideoLoadingError,
        onBackButtonAction,
    };
}
