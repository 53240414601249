import { GainUIMapping } from '../../../Models/HearingProfile/GainUIMapping';
import IDeviceState from '../../../Redux/Models/Device/IDeviceState';

export interface SpeechComfortTestStateContext {
    masterGain: GainUIMapping;
    deviceState: IDeviceState;
    speechComfortTestState: SpeechComfortTestState;
    latestLeftValue: number;
    latestRightValue: number;
    loading: boolean;
    view: () => JSX.Element;
}

export enum SpeechComfortTestState {
    AudioRouting,
    Start,
    Detail,
}

export type SpeechComfortTestEvent = 'start' | 'detail';
