import { IconButton, IconButtonProps } from '@material-ui/core';
import React from 'react';
import { TextResource } from '../../Utils/PageUtils';
import FlexContainer from '../Container/FlexContainer';
import CustomImg, { CustomImgType } from '../CustomImage/CustomImg';
import CustomTypography, {
    TypographyType,
} from '../Typography/CustomTypography';
import './ButtonStyle.scss';

export interface ICustomIconButtonWithText extends IconButtonProps {
    imgSrc: string;
    imgClassName?: string;
    iconSize?: CustomImgType;
    textSource: TextResource;
}

function CustomIconButtonWithText({
    imgSrc,
    imgClassName,
    iconSize = 'icon',
    textSource,
    ...props
}: ICustomIconButtonWithText): JSX.Element {
    return (
        <FlexContainer>
            <IconButton {...props}>
                <CustomImg
                    imgType={iconSize}
                    src={imgSrc}
                    className={imgClassName}
                    alt=""
                />
            </IconButton>
            <CustomTypography
                id={textSource.id}
                text={textSource?.text}
                type={TypographyType.comfortaaSubHeading}
            />
        </FlexContainer>
    );
}

export default CustomIconButtonWithText;
