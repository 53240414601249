import { useTranslation } from 'react-i18next';
import { HeaderActionType } from '../../../SharedComponents/Header/HeaderActionType';
import {
    ButtonResource,
    HeaderResource,
    ImageResource,
    TextResource,
} from '../../../Utils/PageUtils';

type WelcomeResource = {
    header: HeaderResource;
    illustrationImage: ImageResource;
    title: TextResource;
    subtitle: TextResource;
    continueButton: ButtonResource;
};

function useWelcomeResource(): WelcomeResource {
    const { t } = useTranslation();
    const header = {
        id: 'header-welcome',
        type: HeaderActionType.Menu,
        text: t('welcome:Welcome_Feature:Header'),
        backButton: true,
    };

    const illustrationImage = {
        id: 'img-easyfitillustration',
        src: '/resources/sharedAcrossWorkflows/easyfit-illustration.png',
        alt: t('welcome:Welcome_Feature:TitleText'),
    };

    const title = {
        id: 'txt-title',
        text: t('welcome:Welcome_Feature:TitleText'),
    };

    const subtitle = {
        id: 'txt-subtitle',
        text: t('welcome:Welcome_Feature:Text'),
    };

    const continueButton = {
        id: 'btn-continue',
        label: t('welcome:Welcome_Feature:NextButton'),
    };

    return {
        header,
        illustrationImage,
        title,
        subtitle,
        continueButton,
    };
}

export default useWelcomeResource;
