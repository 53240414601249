import React from 'react';
import { ButtonType } from '../../../SharedComponents/Button/ButtonType';
import CustomButton from '../../../SharedComponents/Button/CustomButton';
import AppContainer from '../../../SharedComponents/Container/AppContainer';
import BodyContainer from '../../../SharedComponents/Container/BodyContainer';
import ButtonStickyContainer from '../../../SharedComponents/Container/ButtonStickyContainer';
import CustomImg from '../../../SharedComponents/CustomImage/CustomImg';
import Header from '../../../SharedComponents/Header/HeaderRefactor';
import CustomTypography, {
    TypographyType,
} from '../../../SharedComponents/Typography/CustomTypography';
import CustomerServiceHintPage from '../../MainComponents/TroubleShooting/CustomerServiceHint/CustomerServiceHintPage';
import usePairingTroubleShootingHandler from './PairingTroubleShootingHandlerHook';
import usePairingTroubleShootingResource from './PairingTroubleShootingResourceHook';
import './PairingTroubleShootingStyle.scss';

export default function PairingTroubleshooting(): JSX.Element {
    const {
        onClickContinue,
        retryBeepConfirmationCountReached,
        linkCallBacks,
    } = usePairingTroubleShootingHandler();

    const {
        header,
        subTitle,
        instruction,
        instructionNote,
        instructionImage,
        button,
        help,
        additionalDetails,
    } = usePairingTroubleShootingResource();

    return retryBeepConfirmationCountReached() ? (
        <CustomerServiceHintPage
            tryAgainAction={async () => onClickContinue()}
        />
    ) : (
        <AppContainer>
            <Header headerContent={header} />
            <BodyContainer style={{ marginBottom: '0', marginTop: '10px' }}>
                <CustomImg
                    id={instructionImage.id}
                    src={instructionImage.src}
                    alt=""
                    imgType="mediumSmall"
                />
                <CustomTypography
                    type={TypographyType.notoSansBody14px}
                    className="pairingTechnicalIssueTitleText"
                    text={subTitle.text}
                    id={subTitle.id}
                />
                <CustomTypography
                    id={instruction.id}
                    type={TypographyType.notoSansBody14px}
                    text={instruction.text}
                    textAlign={'left'}
                    className="pairingTechnicalIssueInstructionText"
                    linkCallBacks={linkCallBacks}
                />
                <CustomTypography
                    id={instructionNote.id}
                    type={TypographyType.notoSansBody12px}
                    text={instructionNote.text}
                    textAlign={'left'}
                    className="pairingTechnicalIssueImportantNoteText"
                />
            </BodyContainer>
            <ButtonStickyContainer style={{ flexDirection: 'column' }}>
                <CustomButton
                    id={button.id}
                    buttonType={ButtonType.Primary}
                    onClick={onClickContinue}
                    withDefaultContainer={false}
                >
                    {button.label}
                </CustomButton>
                <CustomTypography
                    id={additionalDetails.id}
                    type={TypographyType.notoSansBody14px}
                    text={additionalDetails.text}
                    linkCallBacks={linkCallBacks}
                />
            </ButtonStickyContainer>
            <BodyContainer className="pairingTechnicalIssueBottomHelpContainer">
                <CustomTypography
                    id={help.id}
                    type={TypographyType.notoSansBody14px}
                    text={help.text}
                    linkCallBacks={linkCallBacks}
                />
            </BodyContainer>
        </AppContainer>
    );
}
