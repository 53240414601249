import React from 'react';

export type infoType = {
    activeSide: string;
    totalPageCount: number;
    totalPagesForProgress: number;
    retryBeepConfirmationCount: number;
    retryBeepConfirmationCountThreshold: number;
};

export enum RedoHLAAState {
    Start,
    TechnicalSetup,
    OneSideSetUp,
    BeepConfirmation,
    TroubleShooting,
    SleeveSelection,
}

export interface IRedoHLAAContext {
    info: React.MutableRefObject<infoType>;
    redoHLAAState: RedoHLAAState;
    setRedoHLAAState: React.Dispatch<RedoHLAAState>;
}

export const RedoHLAAContext = React.createContext({} as IRedoHLAAContext);
