import React, { useContext, useRef, useState } from 'react';
import { MainViewContext } from '../../../Pages/MainComponents/MainView/MainViewContext';
import { TransitionAction } from '../../../Pages/MainComponents/MainView/MainViewModel';
import LocalStorageService from '../../../Services/LocalStorageService';
import { withTracking } from '../../../Services/Monitoring/ApplicationInsights';
import CompletedWorkflowService from '../../../Utils/CompletedWorkflow';
import { IDialogContentProps } from '../DialogUtils';
import { MenuDialogType } from '../Menu/MenuDialogType';
import RedoHLAABeepConfirmation from './BeepConfirmation/RedoHLAABeepConfirmation';
import RedoHLAADialog from './Dialog/RedoHLAADialog';
import RedoHLAAOneSideSetUp from './OneSideSetUp/RedoHLAAOneSideSetUp';
import {
    infoType,
    IRedoHLAAContext,
    RedoHLAAContext,
    RedoHLAAState,
} from './RedoHLAAContext';
import SleeveSelection from '../SleeveSelection/SleeveSelection';
import RedoHLAATechnicalSetup from './TechnicalSetup/RedoHLAATechnicalSetup';
import RedoHLAATroubleShooting from './TroubleShooting/RedoHLAATroubleShooting';
import { getUserDeviceModel } from '../../../Services/ResourceService';
import { isBluetoothDeviceByModel } from '../../../Utils/BluetoothDeviceUtils';

function RedoHLAAPage({ onClose }: IDialogContentProps) {
    const [redoHLAAState, setRedoHLAAState] = useState<RedoHLAAState>(
        RedoHLAAState.Start
    );
    const viewContext = useContext(MainViewContext);
    const workflow = LocalStorageService.serviceInstance.getCompletedWorkflow();

    const createContextInfo = (): infoType => {
        return {
            activeSide: 'left',
            totalPageCount: 0,
            totalPagesForProgress: 4,
            retryBeepConfirmationCount: 0,
            retryBeepConfirmationCountThreshold: 3,
        };
    };

    const info = useRef<infoType>(createContextInfo());

    const initialValue: IRedoHLAAContext = {
        info: info,
        redoHLAAState: redoHLAAState,
        setRedoHLAAState: setRedoHLAAState,
    };
    const renderSwitch = (state: RedoHLAAState) => {
        window.scrollTo(0, 0);
        switch (state) {
            case RedoHLAAState.Start: {
                return (
                    <RedoHLAADialog
                        onClose={
                            // if completed workflow and no onClose passed in, will be alert dialog from app
                            // if completed workflow and onClose passed in, will be from menu selection
                            CompletedWorkflowService.isCompletedMandatoryWorkflow(
                                workflow
                            ) && onClose == undefined
                                ? () =>
                                      viewContext.doTransition(
                                          TransitionAction.SkipRedoHLAA
                                      )
                                : onClose
                        }
                        key={MenuDialogType.RedoHLAA}
                        onPrimaryAction={() =>
                            isBluetoothDeviceByModel(getUserDeviceModel())
                                ? setRedoHLAAState(RedoHLAAState.TechnicalSetup)
                                : viewContext.doTransition(
                                      TransitionAction.DoRedoHLAA
                                  )
                        }
                    />
                );
            }
            case RedoHLAAState.TechnicalSetup: {
                return <RedoHLAATechnicalSetup />;
            }
            case RedoHLAAState.OneSideSetUp: {
                return <RedoHLAAOneSideSetUp />;
            }
            case RedoHLAAState.BeepConfirmation: {
                return <RedoHLAABeepConfirmation onClose={onClose} />;
            }
            case RedoHLAAState.SleeveSelection: {
                return <SleeveSelection onClose={onClose} />;
            }
            case RedoHLAAState.TroubleShooting: {
                return <RedoHLAATroubleShooting />;
            }
        }
    };

    return (
        <RedoHLAAContext.Provider value={initialValue}>
            {renderSwitch(redoHLAAState)}
        </RedoHLAAContext.Provider>
    );
}

export default withTracking(RedoHLAAPage);
