/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import AudioService from '../../../Services/AudioService';
import DeviceManagementService from '../../../Services/DeviceManagementService';
import LocalStorageService from '../../../Services/LocalStorageService';
import { Volume } from '../../../Models/HearingProfile/Volume';
import { VolumeUIMapping } from '../../../Models/HearingProfile/VolumeUIMapping';
import ArcToneService from '../../../Services/ArcToneService';
import { useHistory } from 'react-router-dom';
import { VoidReturn } from '../../../Utils/PageUtils';

export type SliderState = {
    initialPosition: number;
    posMin: number;
    posMax: number;
};

export type SmartRemotePageHandler = {
    loading: boolean;
    error: string;
    sliderState: SliderState;
    onChangeCommittedSlider: (value: number | number[]) => VoidReturn;
    onChangeSlider: (value: number) => VoidReturn;
    handleSliderStart: (event: React.TouchEvent<HTMLElement>) => void;
};

export default function useSmartRemotePageHandler(): SmartRemotePageHandler {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const volumeGain = useRef<VolumeUIMapping>(new VolumeUIMapping([]));
    const [sliderState, setSliderState] = useState({
        initialPosition: 0,
        posMin: -8,
        posMax: 7,
    });
    const [_Ignore, setPosVolume] = useState(sliderState.initialPosition);
    const isComponentMounted = useRef(true);
    const history = useHistory();

    useEffect(() => {
        const doAction = async () => {
            const activeDeviceId = getDeviceIdBySide('left');
            const value = await DeviceManagementService.getDeviceVolumeGain(
                activeDeviceId
            );
            const volumeMapping = new VolumeUIMapping([
                new Volume('left', value, 15, 0),
                new Volume('right', value, 15, 0),
            ]);

            volumeGain.current = volumeMapping;
            if (isComponentMounted.current) {
                setSliderState({
                    initialPosition:
                        volumeMapping.display['left'].startingPosition,
                    posMin: volumeMapping.display['left'].min,
                    posMax: volumeMapping.display['left'].max,
                });
                setPosVolume(volumeMapping.display['left'].startingPosition);
                setLoading(false);
            }
        };
        (async () => {
            if (isComponentMounted.current) {
                setLoading(true);
                await doAction();
            }
        })();
        return () => {
            isComponentMounted.current = false;
        };
    }, [history]);

    const onChangeCommittedSlider = async (value: number | number[]) => {
        await changeVolumeGainValue(Number(value));
    };

    const onChangeSlider = (value: number) => {
        setPosVolume(value);
    };

    const getDeviceIdBySide = (side: string): string => {
        return LocalStorageService.serviceInstance.getDeviceId(side);
    };

    const getBrandIdBySide = (side: string): number => {
        return LocalStorageService.serviceInstance.getDeviceBrandId(side);
    };

    const getPairingAddressBySide = (side: string): number => {
        return LocalStorageService.serviceInstance.getPairingAddress(side);
    };

    const changeVolumeGainPerSide = async (
        side: string,
        volumeGainValue: number
    ) => {
        await AudioService.serviceInstance.prepAudio(
            ArcToneService.getVolumeGain(
                getBrandIdBySide(side),
                getPairingAddressBySide(side),
                volumeGain.current.uiToValueMap.get(side)!.get(volumeGainValue)!
            ),
            `${side} side set volume to ${volumeGain.current.uiToValueMap
                .get(side)!
                .get(volumeGainValue)!}`
        );

        await DeviceManagementService.adjustVolumeGain(
            LocalStorageService.serviceInstance.getPatientId(),
            getDeviceIdBySide(side),
            volumeGain.current.uiToValueMap.get(side)!.get(volumeGainValue)!
        );
    };
    const changeVolumeGainValue = async (value: number) => {
        try {
            setLoading(true);
            setError('');
            const uiVolumeGainValue = Number.parseInt(value.toString());
            await AudioService.serviceInstance.initAudioContext();
            await changeVolumeGainPerSide('left', uiVolumeGainValue);
            await changeVolumeGainPerSide('right', uiVolumeGainValue);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setError(`Error when changing volume to ${value}.`);
            throw error;
        }
    };

    const handleSliderStart = (event: React.TouchEvent<HTMLElement>): void => {
        AudioService.serviceInstance.initAudioContext();
        event.preventDefault();
    };

    return {
        loading,
        error,
        sliderState,
        onChangeCommittedSlider,
        onChangeSlider,
        handleSliderStart,
    };
}
