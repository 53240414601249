import React from 'react';
import { ButtonType } from '../../SharedComponents/Button/ButtonType';
import CustomButton from '../../SharedComponents/Button/CustomButton';
import Carousel from '../../SharedComponents/Carousel/Carousel';
import { CarouselFooterContent } from '../../SharedComponents/Carousel/CarouselFooter';
import AppContainer from '../../SharedComponents/Container/AppContainer';
import BodyContainer from '../../SharedComponents/Container/BodyContainer';
import CustomImg from '../../SharedComponents/CustomImage/CustomImg';
import Header from '../../SharedComponents/Header/HeaderRefactor';
import CustomTypography, {
    TypographyType,
} from '../../SharedComponents/Typography/CustomTypography';
import {
    ButtonResource,
    HeaderResource,
    TextResource,
    VoidReturn,
} from '../../Utils/PageUtils';

export interface IPairingCarouselPage {
    useResource: (step: number) => PairingCarouselResource;
    useHandler: () => PairingCarouselHandler;
}

export type PairingCarouselHandler = {
    disabled: boolean;
    step: number;
    setStep: (newStep: number) => VoidReturn;
    buttonOnClick: () => VoidReturn;
};

export type PairingCarouselResource = {
    header: HeaderResource;
    imgSrcList: string[];
    instructionList: CarouselFooterContent[];
    button: ButtonResource;
    title: TextResource;
};

export default function PairingCarousel({
    useResource,
    useHandler,
}: IPairingCarouselPage): JSX.Element {
    const { step, setStep, disabled, buttonOnClick } = useHandler();

    const { header, imgSrcList, instructionList, button, title } =
        useResource(step);

    const carouselContents = imgSrcList.map((imgSrc, index) => {
        return (
            <CustomImg
                key={`carousel-img-${index}`}
                imgType={'medium'}
                src={imgSrc}
                alt={`img-carousel-${step}`}
            />
        );
    });

    return (
        <AppContainer>
            <Header headerContent={header} disabled={disabled} />
            <BodyContainer flexStart noRowMargin>
                <CustomTypography
                    type={TypographyType.notoSansBody18px}
                    noMargin
                    text={title.text}
                    id={title.id}
                />
                <Carousel
                    carouselContents={carouselContents}
                    instructionContents={instructionList}
                    step={step}
                    setStep={setStep}
                    carouselContainerClassName={
                        'carouselContainerTechnicalSetup'
                    }
                />
            </BodyContainer>
            {step === carouselContents.length - 1 && (
                <CustomButton
                    id={button.id}
                    buttonType={ButtonType.PrimaryLoading}
                    onClick={buttonOnClick}
                >
                    {button.label}
                </CustomButton>
            )}
        </AppContainer>
    );
}
