import React from 'react';
import { withTracking } from '../../../Services/Monitoring/ApplicationInsights';
import { ButtonType } from '../../../SharedComponents/Button/ButtonType';
import CustomButton from '../../../SharedComponents/Button/CustomButton';
import AppContainer from '../../../SharedComponents/Container/AppContainer';
import BodyContainer from '../../../SharedComponents/Container/BodyContainer';
import CustomImg from '../../../SharedComponents/CustomImage/CustomImg';
import Header from '../../../SharedComponents/Header/HeaderRefactor';
import CustomTypography, {
    TypographyType,
} from '../../../SharedComponents/Typography/CustomTypography';
import { getUserDeviceModel } from '../../../Services/ResourceService';
import { deviceModel } from '../../../Models/SupportedDeviceModel';
import { isBluetoothDeviceByModel } from '../../../Utils/BluetoothDeviceUtils';
import './CompleteRecommendationPage.scss';
import useCompleteRecommendationHandler from './CompleteRecommendationPageHandlerHook';
import useCompleteRecommendationResource from './CompleteRecommendationPageResourceHook';

function CompleteRecommendationPage(): React.ReactElement {
    const { isButtonDisabled, handleCompleteRecommendationContinue } =
        useCompleteRecommendationHandler();

    const { header, instructionsComplete, finishImage, exitButton, title } =
        useCompleteRecommendationResource();

    const renderInstructionText = (model: deviceModel) => {
        return isBluetoothDeviceByModel(model) ? (
            <CustomTypography
                id={instructionsComplete.id}
                type={TypographyType.notoSansBody16px}
                text={instructionsComplete.text}
            />
        ) : (
            <></>
        );
    };

    return (
        <>
            <AppContainer>
                <Header headerContent={header} />
                <CustomImg
                    id={finishImage.id}
                    imgType={'fullwidth'}
                    src={finishImage.src}
                    alt={finishImage.alt}
                    className={'completeImageCompleteRecommendation'}
                />
                <BodyContainer>
                    <CustomTypography
                        id={title.id}
                        type={TypographyType.notoSansBody20px}
                        text={title.text}
                    />
                    <>{renderInstructionText(getUserDeviceModel())}</>
                </BodyContainer>
                <CustomButton
                    buttonType={ButtonType.PrimaryLoading}
                    id={exitButton.id}
                    onClick={handleCompleteRecommendationContinue}
                    disabled={isButtonDisabled}
                >
                    {exitButton.label}
                </CustomButton>
            </AppContainer>
        </>
    );
}

export default withTracking(CompleteRecommendationPage);
