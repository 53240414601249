import { AnyEventObject, assign, createMachine } from 'xstate';
import { getUserDeviceModel } from '../../Services/ResourceService';
import { isBluetoothDeviceByModel } from '../../Utils/BluetoothDeviceUtils';
import { PairingStateContext, pairingStateMachine } from './PairingModels';
import usePairingStateMachineHandler from './PairingStateMachineHandler';

export default function PairingStateMachine(): pairingStateMachine {
    const {
        exitWorkflowHandler,
        updateRetryBeepConfirmationCount,
        updatePairingContext,
        switchSide,
    } = usePairingStateMachineHandler();
    const arcPairingMachine = {
        initial: 'PreparePairing',
        states: {
            PreparePairing: {
                on: {
                    completePrepare: 'PairOneSide',
                },
            },
            PairOneSide: {
                on: {
                    completeOneSide: 'Confirmation',
                },
            },
            Confirmation: {
                on: {
                    confimred: 'Success',
                    rejected: {
                        target: 'TroubleShooting',
                        actions: updateRetryBeepConfirmationCount,
                    },
                },
            },
            TroubleShooting: {
                on: {
                    backToPairing: 'PreparePairing',
                },
            },
            Success: {
                on: {
                    successed: [
                        {
                            target: 'PairOneSide',
                            cond: (context: PairingStateContext) =>
                                Boolean(switchSide(context)),
                            actions: updatePairingContext,
                        },
                        {
                            actions: exitWorkflowHandler,
                        },
                    ],
                },
            },
        },
    };

    const stateMachine = createMachine(
        {
            id: 'pairing-state-machine',
            initial: 'TechnicalSetup',
            context: {
                activeSide: 'left',
                left: false,
                right: false,
                retryBeepConfirmationCount: 0,
                retryBeepConfirmationCountThreshold: 3,
            } as PairingStateContext,
            states: {
                TechnicalSetup: {
                    on: {
                        completeTechnicalSetup: [
                            {
                                target: 'BLEPairing',
                                cond: 'isBluetoothWorkflow',
                            },
                            {
                                target: 'arcPairing',
                            },
                        ],
                    },
                },
                arcPairing: {
                    ...arcPairingMachine,
                },
                BLEPairing: {
                    on: {
                        completeBLEParing: {
                            actions: exitWorkflowHandler,
                        },
                        switchSide: {
                            actions: assign(
                                (
                                    context: PairingStateContext,
                                    event: AnyEventObject
                                ) => {
                                    return {
                                        activeSide: event.data,
                                    };
                                }
                            ),
                        },
                    },
                },
            },
        },
        {
            guards: {
                isBluetoothWorkflow: () =>
                    isBluetoothDeviceByModel(getUserDeviceModel()),
            },
        }
    );
    return stateMachine;
}
