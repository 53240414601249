import React from 'react';
import { withTracking } from '../../../../Services/Monitoring/ApplicationInsights';
import { ButtonType } from '../../../../SharedComponents/Button/ButtonType';
import CustomButton from '../../../../SharedComponents/Button/CustomButton';
import AppContainer from '../../../../SharedComponents/Container/AppContainer';
import BodyContainer from '../../../../SharedComponents/Container/BodyContainer';
import Header from '../../../../SharedComponents/Header/HeaderRefactor';
import CustomTypography, {
    TypographyType,
} from '../../../../SharedComponents/Typography/CustomTypography';
import VideoPlayer from '../../../../SharedComponents/VideoPlayer/VideoPlayer';
import useHIAssemblyClickSleevePageHandler from './HIAssemblyClickSleevePageHandlerHook';
import useHIAssemblyClickSleevePageResource from './HIAssemblyClickSleevePageResourceHook';
import '.././HIAssemblyPageStyle.scss';
import BulletList from '../../../../SharedComponents/List/BulletList';

export interface IHIAssemblyPageProps {
    workflow: string;
}

function HIAssemblyClickSleevePage() {
    const { onContinueAction, logVideoLoadingError, onBackButtonAction } =
        useHIAssemblyClickSleevePageHandler();

    const {
        header,
        instructionsText,
        videoTitle,
        smallerSizeTitle,
        smallerSizePoints,
        largerSizeTitle,
        largerSizePoints,
        video,
        continueButton,
    } = useHIAssemblyClickSleevePageResource();

    return (
        <AppContainer>
            <Header
                headerContent={header}
                onCustomBackButtonClick={onBackButtonAction}
            />
            <BodyContainer>
                <CustomTypography
                    id={instructionsText.id}
                    type={TypographyType.notoSansBody16px}
                    text={instructionsText.text}
                    textAlign={'left'}
                    className={'hiAssemblyInstructions'}
                />
                <CustomTypography
                    id={videoTitle.id}
                    type={TypographyType.notoSansBody16px}
                    text={videoTitle.text}
                    textAlign={'left'}
                    className={'hiAssemblyClickSleeveTitleBold'}
                />
                <VideoPlayer
                    id={video.id}
                    url={video.url}
                    videoType={video.videoType}
                    previewImageSrc={video.previewImageSrc}
                    onError={() =>
                        logVideoLoadingError(HIAssemblyClickSleevePage.name)
                    }
                />
                <CustomTypography
                    id={smallerSizeTitle.id}
                    type={TypographyType.notoSansBody16px}
                    text={smallerSizeTitle.text}
                    textAlign={'left'}
                    className={'hiAssemblyClickSleeveTitleBold'}
                />
                <BulletList
                    id={smallerSizePoints.id}
                    bulletItems={smallerSizePoints.bulletItems}
                    className={'itemLeftAlign'}
                />
                <CustomTypography
                    id={largerSizeTitle.id}
                    type={TypographyType.notoSansBody16px}
                    text={largerSizeTitle.text}
                    textAlign={'left'}
                    className={'hiAssemblyClickSleeveTitleBold'}
                />
                <BulletList
                    id={largerSizePoints.id}
                    bulletItems={largerSizePoints.bulletItems}
                    className={'itemLeftAlign'}
                />
            </BodyContainer>
            <CustomButton
                id={continueButton.id}
                buttonType={ButtonType.PrimaryLoading}
                onClick={async () => {
                    await onContinueAction();
                }}
            >
                {continueButton.label}
            </CustomButton>
        </AppContainer>
    );
}

export default withTracking(HIAssemblyClickSleevePage);
