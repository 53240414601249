import axios from 'axios';
import { useCallback, useEffect } from 'react';
import { Patient } from '../../../Models/Patient/Patient';
import ExitRouteService, {
    ExitStatus,
} from '../../../Services/ExitRouteService';
import LocalStorageService from '../../../Services/LocalStorageService';
import LoggingService from '../../../Services/LoggingService';
import PatientManagementService from '../../../Services/PatientManagementService';
import { checkPairingStatus } from '../../../Services/ResourceService';
import CompletedWorkflowService from '../../../Utils/CompletedWorkflow';
import { setCouplingIfRequired } from '../../../Utils/CouplingUtils';
import {
    getDeviceFolderNameFromModelId,
    preloadImages,
    imageFolderEnum,
} from '../../../Utils/PreloadImageUtils';
import {
    DialogState,
    ITransitionHandler,
    MainViewWorkflow,
    PageState,
    TransitionAction,
    ViewState,
} from './MainViewModel';
import {
    checkAndGetNotCompletedWorkflowNextPage,
    handleActionError,
    handleInvalidUserAndToken,
    supportedPageStateQueryParamsFineTuning,
    validatePreviousState,
} from './TransitionHandlerUtils';

function useAppFineTuningTransitionHandler(): ITransitionHandler {
    const axiosTokenInterceptor = useCallback(() => {
        axios.interceptors.response.use(async (response) => {
            return handleInvalidUserAndToken(
                response,
                MainViewWorkflow.AppFineTuning
            );
        });
    }, []);

    useEffect(() => {
        preloadImages(imageFolderEnum.fineTuningWorkflow);
        preloadImages(
            getDeviceFolderNameFromModelId(
                LocalStorageService.serviceInstance
                    .getDeviceModelId('left')
                    .toString()
            )
        );
        axiosTokenInterceptor();
    }, [axiosTokenInterceptor]);

    const doTransition = async (
        currentView: ViewState,
        action: TransitionAction
    ): Promise<ViewState> => {
        const currentPage = currentView.page;
        let nextPage = PageState.None;
        let nextDialog = currentView.dialog;

        switch (action) {
            case TransitionAction.GoToFineTuningLandingPage:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.FineTuning
                );
                nextPage = PageState.Landing;
                break;
            case TransitionAction.FineTuningComplete:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.FineTuning
                );
                nextPage = PageState.FineTuningComplete;
                break;
            case TransitionAction.FineTuningWorkflowError:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.FineTuningWorkflowError
                );
                ExitRouteService.exit(ExitStatus.GenericErrorFail);
                nextPage = currentPage;
                break;
            case TransitionAction.GoFineTuning:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.Landing,
                    PageState.FineTuningComplete
                );
                nextPage = PageState.FineTuning;
                break;
            case TransitionAction.ExitInAppBrowser:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.Landing,
                    PageState.FineTuningComplete
                );
                ExitRouteService.exit(ExitStatus.CompleteSuccess);
                nextPage = currentPage;
                break;
            case TransitionAction.RedoLoudnessContinue:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.RedoLoudness
                );
                ExitRouteService.exit(ExitStatus.RedoLoudnessSuccess);
                nextPage = currentPage;
                break;
            case TransitionAction.ChangeSleeveCustomerServiceContinue:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.FineTuning
                );
                ExitRouteService.exit(ExitStatus.CompleteSuccess);
                nextPage = currentPage;
                break;
            case TransitionAction.UpdateAppDialog:
                nextDialog = DialogState.UpdateApp;
                nextPage = PageState.SplashScreen;
                break;
            case TransitionAction.UpdateAppDialogExit:
                ExitRouteService.exit(ExitStatus.InvalidTokenFail);
                nextPage = currentPage;
                break;
            case TransitionAction.GoSplashScreen:
                nextDialog = DialogState.None;
                nextPage = PageState.SplashScreen;
                break;
            case TransitionAction.DoLeaveApp:
                nextDialog = DialogState.None;
                nextPage = PageState.RedoLoudness;
                break;
            case TransitionAction.DoLeaveAppDialogOpen:
                nextDialog = DialogState.LeaveApp;
                nextPage = currentPage;
                break;
            case TransitionAction.DoLeaveAppDialogClose:
                nextDialog = DialogState.None;
                nextPage = currentPage;
                break;
            case TransitionAction.DoMobileDeviceUnmutedCheckDialogOpen:
                nextDialog = DialogState.MobileDeviceUnmutedCheck;
                nextPage = currentPage;
                break;
            case TransitionAction.DoReturnToPageDialogClose:
                nextDialog = DialogState.None;
                nextPage = currentPage;
                break;
            // eslint-disable-next-line no-fallthrough
            case TransitionAction.WelcomeContinue:
            case TransitionAction.JourneyResume:
            case TransitionAction.HiAssembleContinue:
            case TransitionAction.ConsentContinue:
            case TransitionAction.AnalyticsConsentContinue:
            case TransitionAction.ContraindicationContinue:
            case TransitionAction.PairingContinue:
            case TransitionAction.AssessmentContinue:
            case TransitionAction.AssessmentZeroTone:
            case TransitionAction.InitialSettingsContinue:
            case TransitionAction.InitialSettingsSevereLoss:
            case TransitionAction.InitialSettingsMismatchedLoss:
            case TransitionAction.SpeechComfortContinue:
            case TransitionAction.CompleteRecommendationContinue:
            case TransitionAction.GoRedoLoudness:
            case TransitionAction.DoRedoHLAA:
            case TransitionAction.DoRepeatPairing: // fall through
            default:
                nextPage = handleActionError(currentPage, action);
                break;
        }

        LoggingService.info({
            componentName: 'AppFineTuningTransitionHandlerHook.doTransition',
            args: [
                `Transitioning from ${PageState[currentView.page]} (action: ${
                    TransitionAction[action]
                }) to ${PageState[nextPage]} with dialog ${
                    DialogState[nextDialog]
                }`,
            ],
        });
        return { dialog: nextDialog, page: nextPage } as ViewState;
    };

    const goToInitialState = async (
        patient: Patient | undefined,
        debugRoute?: PageState,
        initial?: PageState
    ): Promise<ViewState> => {
        let nextPage = PageState.None;
        const nextDialog = DialogState.None;

        if (debugRoute) {
            nextPage = debugRoute;
        } else {
            if (!patient) {
                // No patient record found
                nextPage = PageState.FineTuningWorkflowError;
            } else {
                setCouplingIfRequired();

                if (
                    initial &&
                    supportedPageStateQueryParamsFineTuning.includes(initial)
                ) {
                    nextPage = checkAndGetNotCompletedWorkflowNextPage(
                        patient.easyFitWorkflows,
                        initial,
                        PageState.FineTuningWorkflowError
                    );

                    if (!checkPairingStatus()) {
                        nextPage = PageState.FineTuningWorkflowError;
                    }
                } else {
                    if (
                        CompletedWorkflowService.isCompletedMandatoryWorkflow(
                            patient.easyFitWorkflows
                        )
                    ) {
                        nextPage = PageState.Landing;
                    } else {
                        nextPage = PageState.FineTuningWorkflowError;
                    }
                }
            }
        }
        const supportedHis = await PatientManagementService.getSupporedHis();
        LocalStorageService.serviceInstance.setSupportedHis(supportedHis);

        LoggingService.info({
            componentName:
                'AppFineTuningTransitionHandlerHook.goToInitialState',
            args: [
                `Initial state: ${PageState[nextPage]} with dialog ${DialogState[nextDialog]}`,
            ],
        });
        return { dialog: nextDialog, page: nextPage } as ViewState;
    };

    return {
        doTransition,
        goToInitialState,
    };
}

export default useAppFineTuningTransitionHandler;
