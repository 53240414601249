import React from 'react';
import Header from '../../../SharedComponents/Header/HeaderRefactor';
import useFineTuningStartPageHandler from './FineTuningStartPageHandlerHook';
import useFineTuningStartPageResource from './FineTuningStartPageResourceHook';
import AppContainer from '../../../SharedComponents/Container/AppContainer';
import BodyContainer from '../../../SharedComponents/Container/BodyContainer';
import VerticalStepper from '../../../SharedComponents/Stepper/VerticalStepper';
import { useFineTuningContext } from '../FineTuningContext';
import './FineTuningStartPageStyle.scss';
import FlexContainer from '../../../SharedComponents/Container/FlexContainer';
import CustomTypography, {
    TypographyType,
} from '../../../SharedComponents/Typography/CustomTypography';
import CustomIconButton from '../../../SharedComponents/Button/CustomIconButton';
import CustomButton from '../../../SharedComponents/Button/CustomButton';
import { ButtonType } from '../../../SharedComponents/Button/ButtonType';
import { HeaderActionType } from '../../../SharedComponents/Header/HeaderActionType';
import { StepContentType } from '../../../SharedComponents/Stepper/StepperModel';

export default function FineTuningStartPage(): JSX.Element {
    const { info } = useFineTuningContext();
    const {
        infoCallback,
        activeStep,
        solutionButtonDisabled,
        goHome,
        goSelection,
        goSolution,
        goReset,
    } = useFineTuningStartPageHandler();
    const {
        stepContents,
        headerText,
        instructionText,
        resetText,
        buttonText,
        resetImgSrc,
    } = useFineTuningStartPageResource({
        activeStep,
        onClick: goSelection,
    });
    const resetInfoText = info.current.isResetDisabled
        ? 'resetInfoTextDisabled'
        : 'resetInfoTextEnabled';

    return (
        <AppContainer>
            <Header
                headerContent={{
                    id: 'header-fine-tuning',
                    type: HeaderActionType.Home,
                    text: headerText,
                }}
                onClick={goHome}
            />
            <BodyContainer flexStart>
                <CustomTypography
                    noMargin
                    id={'txt-fine-tuning-info'}
                    linkCallBacks={infoCallback}
                    className={'instructionStartText'}
                    textAlign={'left'}
                    type={TypographyType.notoSansBody16px}
                    text={instructionText}
                />
                <VerticalStepper
                    activeStep={activeStep}
                    stepContentType={StepContentType.ButtonStepper}
                    stepContents={stepContents}
                    showActiveStepText={false}
                    showCompletedStepIcon={true}
                />
            </BodyContainer>
            <FlexContainer flexRow className={'resetInfoArea'}>
                <div className={'resetInfoTextArea'}>
                    <CustomTypography
                        id={'txt-fine-tuning-reset'}
                        className={resetInfoText}
                        text={resetText}
                        type={TypographyType.notoSansBody16px}
                    />
                </div>
                <div className={'arrowRight'}></div>
                <CustomIconButton
                    imgSrc={resetImgSrc}
                    disabled={info.current.isResetDisabled}
                    id={'btn-reset'}
                    onClick={goReset}
                />
            </FlexContainer>
            <CustomButton
                id={'btn-get-solution'}
                onClick={goSolution}
                disabled={solutionButtonDisabled}
                buttonType={ButtonType.PrimaryLoading}
            >
                {buttonText}
            </CustomButton>
        </AppContainer>
    );
}
