import React from 'react';
import { FineTuningSolution } from '../../../../../Models/FineTuning/FineTuningSolution';
import { ButtonType } from '../../../../../SharedComponents/Button/ButtonType';
import CustomButton from '../../../../../SharedComponents/Button/CustomButton';
import FlexContainer from '../../../../../SharedComponents/Container/FlexContainer';
import CustomImg from '../../../../../SharedComponents/CustomImage/CustomImg';
import CustomTypography, {
    TypographyType,
} from '../../../../../SharedComponents/Typography/CustomTypography';
import useFineTuningSolutionPageHandler from '../../FineTuningSolutionPageHandlerHook';
import useFineTuningBeforeApplyPageResource from './FineTuningBeforeApplyPageResource';
import '../../FineTuningSolutionPage.scss';
import useFineTuningBeforeApplyPageHandler from './FineTuningBeforeApplyPageHandler';

export interface IFineTuningBeforeApplyPage {
    solutionNumber: number;
    currentSolution: FineTuningSolution;
}

export default function FineTuningBeforeApplyPage(
    props: IFineTuningBeforeApplyPage
): React.ReactElement {
    const { solutionText, image, button } =
        useFineTuningBeforeApplyPageResource(props);
    const { isContentEnabled } = useFineTuningSolutionPageHandler();
    const { handleApply } = useFineTuningBeforeApplyPageHandler();
    return (
        <FlexContainer
            className={
                isContentEnabled() ? 'solutionCard' : 'solutionCard disabled'
            }
        >
            <CustomTypography
                id={solutionText.id}
                text={solutionText.text}
                type={TypographyType.notoSansSubHeading}
            />
            <CustomImg
                id={image.id}
                imgType={'iconLarge'}
                src={image.src}
                className={'imgSettings'}
                alt={image.alt}
            />
            <CustomButton
                id={button.id}
                onClick={async () => {
                    await handleApply(
                        props.currentSolution,
                        props.solutionNumber
                    );
                }}
                disabled={!isContentEnabled()}
                withDefaultContainer={false}
                className={'beforeApplyButton'}
                buttonType={ButtonType.Primary}
            >
                {button.text}
            </CustomButton>
        </FlexContainer>
    );
}
