import { useContext } from 'react';
import { VoidReturn } from '../../../../Utils/PageUtils';
import { ProgressBarCalculateCurrentProgress } from '../../../../Utils/ProgressBarService';
import { InitialSettingContext } from '../InitialSettingContext';
import { InitialSettingApplicationState } from '../InitialSettingModels';
import useInitialSettingSideHandler from '../Side/InitialSettingSideHandlerHook';

export type ApplyInitialSettingHandle = {
    onClickApply: () => VoidReturn;
    getTopProgress: () => number;
};

export default function useInitialSettingApplyHandler(): ApplyInitialSettingHandle {
    const { info, setInitialSettingInfo, exitInitialSetting } = useContext(
        InitialSettingContext
    );

    const { initializeClusterSetting, programInitialSetting } =
        useInitialSettingSideHandler();

    const getTopProgress = (): number => {
        return ProgressBarCalculateCurrentProgress(
            info.totalPageCount,
            info.totalPagesForProgress
        );
    };

    const onClickApply = async () => {
        const currentSide = info.activeSide;
        const currentSideState = info.sideStates.find((item) => {
            return item.side === currentSide;
        });

        if (currentSideState?.isDone === true) {
            exitInitialSetting('continue');
            return;
        }

        info.beepConfirmationCount = 0;

        setInitialSettingInfo({
            ...info,
            initialSettingStep: InitialSettingApplicationState.Writing,
            loading: true,
        });

        if (!info.initialized) {
            await initializeClusterSetting();
        } else {
            await programInitialSetting();
        }
    };

    return {
        onClickApply: onClickApply,
        getTopProgress: getTopProgress,
    };
}
