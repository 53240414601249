import { CheckboxProps } from '@material-ui/core';
import { Checkbox } from '@material-ui/core';
import React from 'react';
import { joinMultipleStyle } from '../../Utils/ScssUtils';
import FlexContainer from '../Container/FlexContainer';
import CustomTypography, {
    TypographyType,
} from '../Typography/CustomTypography';
import './CheckBoxStyle.scss';

export interface ICustomCheckBoxProps extends CheckboxProps {
    displayText: string;
    containerClassName?: string;
}

function CustomCheckBox({
    displayText,
    containerClassName,
    ...props
}: ICustomCheckBoxProps): React.ReactElement {
    return (
        <FlexContainer
            flexRow
            className={joinMultipleStyle('checkBoxArea', containerClassName)}
        >
            <Checkbox
                classes={{
                    root: 'checkbox',
                }}
                {...props}
            />
            <CustomTypography
                textAlign="left"
                type={TypographyType.notoSansBody16px}
                text={displayText}
            />
        </FlexContainer>
    );
}

export default CustomCheckBox;
