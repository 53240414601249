import { useContext } from 'react';
import { InitialSettings } from '../../../Models/HearingProfile/InitialSettings';
import DeviceManagementService from '../../../Services/DeviceManagementService';
import LocalStorageService from '../../../Services/LocalStorageService';
import TelemetryService from '../../../Services/Monitoring/TelemetryService';
import PatientManagementService from '../../../Services/PatientManagementService';
import { getUserDeviceModel } from '../../../Services/ResourceService';
import { MainViewContext } from '../../MainComponents/MainView/MainViewContext';
import { TransitionAction } from '../../MainComponents/MainView/MainViewModel';
import { ISpeechComfortTestInfo } from './SpeechComfortTestContext';
import { getMasterGainIdFromObject } from './SpeechComfortTestUtils';

export type SpeechComfortTestPageHandler = {
    exitSpeechComfort: (value: ISpeechComfortTestInfo) => Promise<void>;
};

export default function useSpeechComfortTestPageHandler(): SpeechComfortTestPageHandler {
    const viewContext = useContext(MainViewContext);

    const exitSpeechComfort = async (info: ISpeechComfortTestInfo) => {
        const patientId = LocalStorageService.serviceInstance.getPatientId();
        const leftDeviceId =
            LocalStorageService.serviceInstance.getDeviceId('Left');
        const rightDeviceId =
            LocalStorageService.serviceInstance.getDeviceId('Right');

        const speechComfort: InitialSettings = {
            patientId: patientId,
            settings: {
                [leftDeviceId]: getMasterGainIdFromObject(
                    info.masterGain.uiToValueMap,
                    'left',
                    info.latestLeftValue
                ),
                [rightDeviceId]: getMasterGainIdFromObject(
                    info.masterGain.uiToValueMap,
                    'right',
                    info.latestRightValue
                ),
            },
        };
        await DeviceManagementService.confirmInitialSettingsAreApplied(
            speechComfort
        );
        const activeLeftClusterId = await DeviceManagementService.getClusterId(
            leftDeviceId
        );
        const activeRightClusterId = await DeviceManagementService.getClusterId(
            rightDeviceId
        );
        const workflow = await PatientManagementService.getCompletedWorkflow();
        const userDeviceModel = getUserDeviceModel();
        LocalStorageService.serviceInstance.setCompletedWorkflow(
            Number(workflow)
        );
        TelemetryService.serviceInstance.TrackAnonymous(
            'Orion-CompletedWorkflow',
            {
                Workflow: 'SpeechComfortTest',
                Model: userDeviceModel,
            }
        );
        TelemetryService.serviceInstance.TrackAnonymous(
            'Orion-Initial-MasterGain',
            {
                Model: userDeviceModel,
                LeftCluster: activeLeftClusterId.toString(),
                PreviousLeftMasterGain: getMasterGainIdFromObject(
                    info.masterGain.uiToValueMap,
                    'left',
                    info.previousLeftValue
                ).toString(),
                CurrentLeftMasterGain: getMasterGainIdFromObject(
                    info.masterGain.uiToValueMap,
                    'left',
                    info.latestLeftValue
                ).toString(),
            }
        );

        TelemetryService.serviceInstance.TrackAnonymous(
            'Orion-Initial-MasterGain',
            {
                Model: userDeviceModel,
                RightCluster: activeRightClusterId.toString(),
                PreviousRightMasterGain: getMasterGainIdFromObject(
                    info.masterGain.uiToValueMap,
                    'right',
                    info.previousRightValue
                ).toString(),
                CurrentRightMasterGain: getMasterGainIdFromObject(
                    info.masterGain.uiToValueMap,
                    'right',
                    info.latestRightValue
                ).toString(),
            }
        );

        viewContext.doTransition(TransitionAction.SpeechComfortContinue);
    };

    return {
        exitSpeechComfort,
    };
}
