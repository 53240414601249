import React from 'react';
import ReturnToTimelineStateService, {
    ReturnToTimelineState,
} from '../../../Services/ReturnToTimelineService';
import { ButtonType } from '../../../SharedComponents/Button/ButtonType';
import CustomButton from '../../../SharedComponents/Button/CustomButton';
import AppContainer from '../../../SharedComponents/Container/AppContainer';
import BodyContainer from '../../../SharedComponents/Container/BodyContainer';
import CustomImg from '../../../SharedComponents/CustomImage/CustomImg';
import Header from '../../../SharedComponents/Header/HeaderRefactor';
import BulletList from '../../../SharedComponents/List/BulletList';
import TopProgressBar from '../../../SharedComponents/TopProgressBar/TopProgressBar';
import CustomTypography, {
    TypographyType,
} from '../../../SharedComponents/Typography/CustomTypography';
import '../HearingAssessmentStyle.scss';
import useHearingAssessmentStartHandler from './HearingAssessmentStartHandler';
import useHearingAssessmentStartResource from './HearingAssessmentStartResource';

export default function HearingAssessmentStart(): React.ReactElement {
    const { startHLAA, getTopProgress } = useHearingAssessmentStartHandler();
    const {
        header,
        instructionTitle,
        instructionImage,
        instructionGeneral,
        startButton,
    } = useHearingAssessmentStartResource();

    ReturnToTimelineStateService.setCurrentReturnToTimelineState(
        ReturnToTimelineState.HLAA
    );

    return (
        <AppContainer>
            <TopProgressBar progress={getTopProgress()} />
            <Header headerContent={header} />
            <BodyContainer className={'hlaaStartBody'}>
                <CustomTypography
                    type={TypographyType.notoSansSubHeading}
                    text={instructionTitle.text}
                    textAlign="center"
                    id={instructionTitle.id}
                    className={'hlaaTitle'}
                />
                <CustomImg
                    id={instructionImage.id}
                    src={instructionImage.src}
                    imgType="small"
                    alt={instructionImage.alt}
                    className={'hlaaImage'}
                />
                <BulletList
                    id={instructionGeneral.id}
                    bulletItems={instructionGeneral.text.split('|')}
                />
            </BodyContainer>
            <CustomButton
                id={startButton.id}
                buttonType={ButtonType.PrimaryLoading}
                onClick={startHLAA}
            >
                {startButton.text}
            </CustomButton>
        </AppContainer>
    );
}
