import { Switch, SwitchProps } from '@material-ui/core';
import React from 'react';
import FlexContainer from '../Container/FlexContainer';
import CustomTypography, {
    TypographyType,
} from '../Typography/CustomTypography';
import './SwitchStyle.scss';

export interface ICustomSwitchProps
    extends Omit<SwitchProps, 'inputProps' | 'classes'> {
    switchLabel: string;
}

function CustomSwitch({
    switchLabel,
    onChange,
    ...props
}: ICustomSwitchProps): React.ReactElement {
    return (
        <FlexContainer flexRow className="switchArea">
            <CustomTypography
                type={TypographyType.notoSansBody16px}
                text={switchLabel}
                textAlign="left"
            ></CustomTypography>
            <Switch
                className={'customSwitch'}
                classes={{
                    thumb: 'thumb',
                    switchBase: 'base',
                }}
                {...props}
            />
        </FlexContainer>
    );
}

export default CustomSwitch;
