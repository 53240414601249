import React from 'react';
import { withTracking } from '../../../Services/Monitoring/ApplicationInsights';
import CustomerServiceHintPage from '../../MainComponents/TroubleShooting/CustomerServiceHint/CustomerServiceHintPage';
import TroubleShooting from '../../MainComponents/TroubleShooting/TroubleShooting';
import useHearingAssessmentTroubleShootingHandler from './HearingAssessmentTroubleShootingHandlerHook';
import useHearingAssessmentTroubleShootingResource from './HearingAssessmentTroubleShootingResourceHook';

function HearingAssessmentTroubleShooting() {
    const { onRetry, retryBeepConfirmationThresholdReached } =
        useHearingAssessmentTroubleShootingHandler();
    return retryBeepConfirmationThresholdReached &&
        retryBeepConfirmationThresholdReached() ? (
        <CustomerServiceHintPage tryAgainAction={async () => onRetry()} />
    ) : (
        <TroubleShooting
            useHandler={useHearingAssessmentTroubleShootingHandler}
            useResource={useHearingAssessmentTroubleShootingResource}
        />
    );
}

export default withTracking(HearingAssessmentTroubleShooting);
