import React, { useContext, useEffect } from 'react';
import { MainViewContext } from '../../../Pages/MainComponents/MainView/MainViewContext';
import useGlobalDialogHandler from './GlobalDialogHandlerHook';
import useGlobalDialogRender from './GlobalDialogRenderHook';
import usePairingConnectionLostGlobalDialogResource from './UsePairingConnectionLostGlobalDialogResourceHook';
import useUpdateAppGlobalDialogResource from './UseUpdateAppGlobalDialogResourceHook';
import useReturnToTimelineResource from './UseReturnToTimelineResourceHook';
import useMobileDeviceUnmutedCheckResource from './UseMobileDeviceUnmutedCheckDialogResourceHook';
import useLeaveAppDialogResource from './useLeaveAppDialogResourceHook';

function GlobalDialog(): React.ReactElement {
    const {
        pairingConnectionLostHandler,
        deviceInfoCheckHandler,
        updateAppHandler,
        returntoTimelineHandler,
        mobileDeviceUnmutedCheckHandler,
        leaveAppHandler,
        updateDisplay,
    } = useGlobalDialogHandler();

    const usePairingConnectionLostResource =
        usePairingConnectionLostGlobalDialogResource;

    const useUpdateAppResource = useUpdateAppGlobalDialogResource;

    const viewContext = useContext(MainViewContext);

    const {
        renderPairingConnectionLostDialog,
        renderDeviceInfoCheckDialog,
        renderUpdateAppDialog,
        renderReturnToTimelineDialog,
        renderMobileDeviceUnmutedCheckDialog,
        renderLeaveAppDialog,
    } = useGlobalDialogRender({
        pairingConnectionLostHandler,
        usePairingConnectionLostResource,
        deviceInfoCheckHandler,
        updateAppHandler,
        useUpdateAppResource,
        returntoTimelineHandler,
        useReturnToTimelineResource,
        mobileDeviceUnmutedCheckHandler,
        useMobileDeviceUnmutedCheckResource,
        leaveAppHandler,
        useLeaveAppDialogResource,
    });

    useEffect(() => {
        updateDisplay(viewContext.dialogState);
    }, [updateDisplay, viewContext.dialogState]);

    return (
        <>
            {renderPairingConnectionLostDialog()}
            {renderDeviceInfoCheckDialog()}
            {renderUpdateAppDialog()}
            {renderReturnToTimelineDialog()}
            {renderMobileDeviceUnmutedCheckDialog()}
            {renderLeaveAppDialog()}
        </>
    );
}

export default GlobalDialog;
