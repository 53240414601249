import { useTranslation } from 'react-i18next';
import { HeaderActionType } from '../../../Header/HeaderActionType';
import { WarningDialogResource } from '../../Warning/WarningDialog';
import redoEasyFitDialogResource from './RedoEasyFitDialogResource.json';

function useRedoEasyFitDialogResource(): WarningDialogResource {
    const { t } = useTranslation();
    const { headerContent, buttons } = redoEasyFitDialogResource;

    const header = {
        id: headerContent.id,
        text: t(headerContent.translationKey),
        type: HeaderActionType.NoAction,
    };

    const warningMessage = {
        id: redoEasyFitDialogResource['orionApp'].warningMessageContent.id,
        text: t(
            redoEasyFitDialogResource['orionApp'].warningMessageContent
                .translationKey
        ),
    };

    const buttonLabels = {
        primaryLabel: {
            id: buttons.primary.id,
            label: t(buttons.primary.translationKey),
        },
        secondaryLabel: {
            id: buttons.secondary.id,
            label: t(buttons.secondary.translationKey),
        },
    };

    return {
        header,
        warningMessage,
        buttonLabels,
    };
}

export default useRedoEasyFitDialogResource;
