import { useState } from 'react';

export enum RunningState {
    Reset = 'Reset',
    Running = 'Running',
    Completed = 'Completed',
    Error = 'Error',
}

export const useRunning = (): [
    boolean,
    string,
    (runningState: RunningState, errorMessage?: string) => void
] => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const setRunning = (runningState: RunningState, errorMessage?: string) => {
        switch (runningState) {
            case RunningState.Running: {
                setLoading(true);
                setError('');
                break;
            }
            case RunningState.Completed: {
                setLoading(false);
                setError('');
                break;
            }
            case RunningState.Error: {
                setLoading(false);
                setError(errorMessage === undefined ? '' : errorMessage);
                break;
            }
            case RunningState.Reset:
            default: {
                setLoading(false);
                setError('');
                break;
            }
        }
    };
    return [loading, error, setRunning];
};
