import { useFineTuningContext } from '../../../FineTuningContext';

type FTFooterAppliedSuccessPageHandler = {
    goHome: () => void;
};

const useFTFooterAppliedSuccessPageHandler =
    (): FTFooterAppliedSuccessPageHandler => {
        const { send } = useFineTuningContext();
        const goHome = () => {
            send('completeFineTuning');
        };

        return { goHome };
    };

export default useFTFooterAppliedSuccessPageHandler;
