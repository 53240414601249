import { useTranslation } from 'react-i18next';
import { HeaderActionType } from '../../../../SharedComponents/Header/HeaderActionType';
import {
    ButtonResource,
    HeaderResource,
    ImageResource,
    TextResource,
} from '../../../../Utils/PageUtils';

export type SpeechComfortTestStartPageResource = {
    header: HeaderResource;
    instructionImage: ImageResource;
    instructionText: TextResource;
    button: ButtonResource;
};

export default function useSpeechComfortTestStartPageResource(): SpeechComfortTestStartPageResource {
    const { t } = useTranslation();

    return {
        header: {
            id: 'speech-comfort-header',
            text: t('common:Common_Feature.AdjustLoudness'),
            type: HeaderActionType.Menu,
        },
        instructionImage: {
            id: 'img-speechComfort',
            src: '/resources/sharedAcrossWorkflows/speech-comfort_illustration.png',
            alt: 'Speech Comfort Test',
        },
        instructionText: {
            id: 'speechComfort-instruction-text',
            text: t('speechComfort:SpeechComfort_Feature:Start:Text'),
        },
        button: {
            id: 'btn-continue-to-speechComfort-slider',
            label: t('common:Common_Feature:Continue'),
        },
    };
}
