export class HLAAState {
    public action: string;
    public side: string;
    public freqId: number;
    public seqId: number;

    constructor(action: string, side: string, freqId: number, seqId: number) {
        this.action = action;
        this.side = side;
        this.freqId = freqId;
        this.seqId = seqId;
    }
}
