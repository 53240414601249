import { AxiosRequestConfig } from 'axios';

export class ServiceConfigFactory {
    public static CreateAxiosConfigWithAuth(
        token: string,
        timeout?: number
    ): AxiosRequestConfig {
        return {
            validateStatus: (_Ignore) => true,
            timeout: timeout !== undefined ? timeout : 10000,
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
        };
    }

    public static CreateAxiosConfigWithoutAuth(): AxiosRequestConfig {
        return {
            validateStatus: (_Ignore) => true,
            timeout: 10000,
            headers: {
                'Content-Type': 'application/json',
            },
        };
    }
}
