import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BestCluster } from '../../../../Models/HearingProfile/BestCluster';
import { BestClusterAppliedSettings } from '../../../../Models/HearingProfile/BestClusterAppliedSettings';
import IDeviceState from '../../../../Redux/Models/Device/IDeviceState';
import { updateDevice } from '../../../../Redux/Reducers/DeviceSlice';
import { RootState } from '../../../../Redux/Reducers/RootReducer';
import DeviceManagementService from '../../../../Services/DeviceManagementService';
import LocalStorageService from '../../../../Services/LocalStorageService';
import TelemetryService from '../../../../Services/Monitoring/TelemetryService';
import PatientManagementService from '../../../../Services/PatientManagementService';
import { getUserDeviceModel } from '../../../../Services/ResourceService';
import { Workflow } from '../../../../Utils/CompletedWorkflow';
import { BeepConfirmationHandle } from '../../../MainComponents/BeepConfirmation/BeepConfirmation';
import { InitialSettingContext, Side } from '../InitialSettingContext';
import { InitialSettingApplicationState } from '../InitialSettingModels';
import useInitialSettingSideHandler from '../Side/InitialSettingSideHandlerHook';

export default function useInitialSettingConfirmationHandler(): BeepConfirmationHandle {
    const { info, setInitialSettingInfo } = useContext(InitialSettingContext);
    const { initializeClusterSetting, programInitialSetting } =
        useInitialSettingSideHandler();
    const dispatch = useDispatch();
    const deviceState = useSelector(
        (state: RootState) => state.device
    ) as IDeviceState;

    const getOtherSide = (side: string): string => {
        const otherSideState = info.sideStates.find((item) => {
            return item.side !== side;
        });
        if (otherSideState !== undefined) {
            return otherSideState.side;
        }
        return '';
    };

    const reachBeepConfirmationCountLimit = (): boolean => {
        return info.beepConfirmationCount >= info.beepConfirmationCountLimit;
    };

    const updateBeepConfirmationCount = () => {
        if (!reachBeepConfirmationCountLimit()) {
            info.beepConfirmationCount += 1;
        }
    };

    const storeMasterGainData = async (
        sideStates: {
            side: string;
            isDone: boolean;
            bestCluster: BestCluster;
        }[]
    ) => {
        const masterGainState = Object.entries(sideStates).map((x) => {
            return {
                side: x[1].side,
                masterGainId: x[1].bestCluster.masterGainId,
                masterGainUiMinId: x[1].bestCluster.masterGainUiMinId,
                masterGainUiMaxId: x[1].bestCluster.masterGainUiMaxId,
            };
        });
        dispatch(
            updateDevice({
                ...deviceState,
                masterGainState: masterGainState,
            })
        );
    };

    const applySetting = async () => {
        const initialSettings: {
            [deviceId: string]: BestCluster;
        } = Object.assign(
            {},
            ...info.sideStates.map(
                (curr: {
                    side: string;
                    isDone: boolean;
                    bestCluster: BestCluster;
                }) => ({
                    [LocalStorageService.serviceInstance.getDeviceId(
                        curr.side
                    )]: curr.bestCluster,
                })
            )
        );

        const patientInitialSettings: BestClusterAppliedSettings = {
            patientId: LocalStorageService.serviceInstance.getPatientId(),
            deviceInitialSettings: initialSettings,
        };
        await DeviceManagementService.confirmBestClustersAreApplied(
            patientInitialSettings
        );

        storeMasterGainData(info.sideStates);
        await PatientManagementService.endWorkflow(Workflow.Recommendation);
    };

    const handleConfirmYesAction = async () => {
        const currentSide = info.activeSide;
        const otherSideState = info.sideStates.find((item) => {
            return item.side !== currentSide;
        });

        TelemetryService.serviceInstance.TrackAnonymous(
            'Orion-CompletedWorkflow',
            {
                Workflow:
                    currentSide.toLowerCase() === 'left'
                        ? 'InitialSettingsLeft'
                        : 'InitialSettingsRight',
                Model: getUserDeviceModel(),
            }
        );

        const otherSide = getOtherSide(currentSide) as Side;

        if (otherSideState?.isDone !== true) {
            const currentStates = info.sideStates.map(
                (x: {
                    side: Side;
                    isDone: boolean;
                    bestCluster: BestCluster;
                }) => ({
                    side: x.side,
                    isDone: x.side === currentSide ? true : x.isDone,
                    bestCluster: x.bestCluster,
                })
            );
            setInitialSettingInfo({
                ...info,
                initialSettingStep: InitialSettingApplicationState.Application,
                activeSide: otherSide,
                completedSide: currentSide,
                sideStates: currentStates,
                loading: false,
            });
        } else {
            await applySetting();
            setInitialSettingInfo({
                ...info,
                initialSettingStep: InitialSettingApplicationState.Application,
                completedSide: currentSide,
                isOneSideDone: true,
                loading: false,
            });
        }
    };

    const handleConfirmNoAction = async () => {
        updateBeepConfirmationCount();
        if (reachBeepConfirmationCountLimit()) {
            setInitialSettingInfo({
                ...info,
                initialSettingStep:
                    InitialSettingApplicationState.CustomerService,
            });
        } else {
            setInitialSettingInfo({
                ...info,
                initialSettingStep: InitialSettingApplicationState.Writing,
                loading: true,
            });
            if (!info.initialized) {
                await initializeClusterSetting();
            } else {
                await programInitialSetting();
            }
        }
    };

    return {
        primaryAction: handleConfirmYesAction,
        secondaryAction: handleConfirmNoAction,
    };
}
