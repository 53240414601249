import { useContext } from 'react';
import { deviceModel } from '../../../../Models/SupportedDeviceModel';
import {
    HearingAssessmentState,
    HLAAContext,
} from '../../../../Pages/HLAA/HLAAContext';
import { MainViewContext } from '../../../../Pages/MainComponents/MainView/MainViewContext';
import { TransitionAction } from '../../../../Pages/MainComponents/MainView/MainViewModel';
import LocalStorageService from '../../../../Services/LocalStorageService';
import { getUserDeviceModel } from '../../../../Services/ResourceService';
import { VoidReturn } from '../../../../Utils/PageUtils';
import { ProgressBarCalculateCurrentProgress } from '../../../../Utils/ProgressBarService';
import { RedoHLAAContext, RedoHLAAState } from '../RedoHLAAContext';

type RedoHLAABeepConfirmationHandler = {
    onBeepConfirmationYesAction: () => VoidReturn;
    onBeepConfirmationNoAction: () => VoidReturn;
    getTopProgress: () => number;
};

export default function useRedoHLAABeepConfirmationHandler(
    onClose?: () => VoidReturn
): RedoHLAABeepConfirmationHandler {
    const { doTransition } = useContext(MainViewContext);
    const { info, setRedoHLAAState } = useContext(RedoHLAAContext);
    const { setHearingAssessmentState } = useContext(HLAAContext);
    const getTopProgress = (): number => {
        return ProgressBarCalculateCurrentProgress(
            info.current.totalPageCount,
            info.current.totalPagesForProgress
        );
    };

    const onBeepConfirmationYesAction = async () => {
        const isMandatoryWorkflowCompletedOnce =
            LocalStorageService.serviceInstance.getMandatoryWorkflowCompletedOnce();
        const currentSide = info.current.activeSide;
        const activeSide =
            info.current.activeSide === 'left' ? 'right' : 'left';

        info.current.activeSide = activeSide;

        if (currentSide === 'right') {
            info.current.totalPageCount = 0;
            if (
                isMandatoryWorkflowCompletedOnce &&
                getUserDeviceModel() === deviceModel.OrionComplete
            )
                setRedoHLAAState(RedoHLAAState.SleeveSelection);
            else {
                if (setHearingAssessmentState)
                    setHearingAssessmentState(HearingAssessmentState.RedoHLAA);
                doTransition(TransitionAction.GoToAssessment);
                if (onClose) onClose();
            }
        } else {
            info.current.retryBeepConfirmationCount = 0;
            setRedoHLAAState(RedoHLAAState.OneSideSetUp);
        }
    };

    const onBeepConfirmationNoAction = () => {
        if (
            info.current.retryBeepConfirmationCount <
            info.current.retryBeepConfirmationCountThreshold
        ) {
            info.current.retryBeepConfirmationCount++;
        }
        setRedoHLAAState(RedoHLAAState.TroubleShooting);
    };

    return {
        onBeepConfirmationYesAction,
        onBeepConfirmationNoAction,
        getTopProgress,
    };
}
