import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import LocalStorageService from '../../../Services/LocalStorageService';
import TelemetryService from '../../../Services/Monitoring/TelemetryService';
import PatientManagementService from '../../../Services/PatientManagementService';
import { getUserDeviceModel } from '../../../Services/ResourceService';
import CompletedWorkflowService, {
    Workflow,
} from '../../../Utils/CompletedWorkflow';
import { MainViewContext } from '../MainView/MainViewContext';
import { TransitionAction } from '../MainView/MainViewModel';
import { PartialDialogHandler } from '../../../SharedComponents/Dialog/PartialDialog/PartialDialog';
import ReturnToTimelineService from '../../../Services/ReturnToTimelineService';
import ReturnToTimelineStateService from '../../../Services/ReturnToTimelineService';

export type UserJourneyHandler = {
    useWarningDialogHandler: () => PartialDialogHandler;
    activeStep: number;
    activeReturnToTimelineStep: number;
    warningDisplayed: boolean;
    showStartOver: () => void;
    onResume: () => Promise<void>;
    onClose: () => void;
    onRedoStep: () => Promise<void>;
};

function useUserJourneyHandler(): UserJourneyHandler {
    const history = useHistory();
    const [activeStep, setActiveState] = useState(0);
    const [warningDisplayed, setWarningDisplayed] = useState(false);
    const viewContext = useContext(MainViewContext);

    useEffect(() => {
        const updateStepper = () => {
            const workflowStates = [
                Workflow.DevicePairingLeft | Workflow.DevicePairingRight,
                Workflow.HLAATest,
                Workflow.Recommendation,
                Workflow.SpeechComfortTest,
            ];

            const completedState =
                LocalStorageService.serviceInstance.getCompletedWorkflow();

            for (let step = 0; step < workflowStates.length; step++) {
                if (
                    !CompletedWorkflowService.isCompleted(
                        completedState,
                        workflowStates[step]
                    )
                ) {
                    if (step > 0) setActiveState(step);
                    break;
                }

                if (step === workflowStates.length - 1) {
                    setActiveState(workflowStates.length);
                }
            }
        };
        updateStepper();
    }, [history]);

    const activeReturnToTimelineStep =
        ReturnToTimelineService.getCurrentStepNumberOfState();

    const onStartOverSelected = async () => {
        let completedWorkflow = undefined;
        await PatientManagementService.resetUserJourney()
            .then(() => {
                return PatientManagementService.getCompletedWorkflow();
            })
            .then((value) => {
                completedWorkflow = value;
            });
        await PatientManagementService.getPatient();

        LocalStorageService.serviceInstance.setCompletedWorkflow(
            completedWorkflow !== undefined ? completedWorkflow : NaN
        );
        ReturnToTimelineService.resetState();
        await onResume();
    };

    const onResume = async () => {
        viewContext.doTransition(TransitionAction.JourneyResume);
    };

    const onRedoStep = async () => {
        await ReturnToTimelineStateService.resetCompletedWorkflowState();
        viewContext.doTransition(TransitionAction.RedoStep);
    };

    const showStartOver = () => {
        TelemetryService.serviceInstance.TrackAnonymous('Orion-FeatureUsage', {
            Feature: 'StartOver',
            Model: getUserDeviceModel(),
        });
        setWarningDisplayed(true);
    };

    const onClose = () => {
        setWarningDisplayed(false);
    };

    const secondaryAction = () => {
        setWarningDisplayed(false);
    };

    const useWarningDialogHandler = () => {
        return {
            display: warningDisplayed,
            primaryAction: onStartOverSelected,
            secondaryAction,
        };
    };

    return {
        useWarningDialogHandler,
        activeStep,
        activeReturnToTimelineStep,
        warningDisplayed,
        showStartOver,
        onResume,
        onClose,
        onRedoStep,
    };
}

export default useUserJourneyHandler;
