import { t } from '../../../../../Services/LocalizationService';
import { ImageResource } from '../../../../../Utils/PageUtils';
import { useFineTuningSolutionContext } from '../../FineTuningSolutionContext';

type FineTuningAppliedSuccessPageResource = {
    image: ImageResource;

    activeSolutionText: {
        id: string;
        text: string;
    };
    successText: {
        id: string;
        text: string;
    };
};

export default function useFineTuningAppliedSuccessPageResource(): FineTuningAppliedSuccessPageResource {
    const { activeSolutionStep } = useFineTuningSolutionContext();

    const image = {
        id: `img-tickMark`,
        src: `/resources/sharedAcrossWorkflows/successful_tick-mark.svg`,
        alt: 'tickMark',
    };

    const activeSolutionText = {
        id: 'txt-activeSolution',
        text: `"${t('fineTuning:FineTuning_Feature:Solution:Text12')} 0${
            activeSolutionStep + 1
        }"`,
    };

    const successText = {
        id: 'txt-success',
        text: t('fineTuning:FineTuning_Feature:Solution:Text2'),
    };

    return {
        image,
        activeSolutionText,
        successText,
    };
}
