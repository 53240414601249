import { ContactDetails } from './ContactDetails';

export class CustomerServiceData {
    public countryCode: string;
    public primaryContactType: string;
    public contacts: ContactDetails[];

    constructor(
        countryCode: string,
        primaryContactType: string,
        contacts: ContactDetails[]
    ) {
        this.countryCode = countryCode;
        this.primaryContactType = primaryContactType;
        this.contacts = contacts;
    }
}
