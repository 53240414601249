import { ConsentValue } from '../Models/Patient/PatientConsentData';
import { setDeviceListFromParams } from '../Pages/MainComponents/MainView/TransitionHandlerUtils';
import LocalStorageService from '../Services/LocalStorageService';
import { getCurrentLocale } from '../Services/LocalizationService';
import TelemetryService from '../Services/Monitoring/TelemetryService';
import PatientManagementService from '../Services/PatientManagementService';
import { getUserDeviceModel } from '../Services/ResourceService';

export async function setPatientInfo(userConsented: boolean): Promise<void> {
    const userSelectedLocale = getCurrentLocale();
    const userResidingCountry =
        LocalStorageService.serviceInstance.getSelectedCountry();
    const patientId = LocalStorageService.serviceInstance.getPatientId();
    await PatientManagementService.createPatient(patientId);
    await PatientManagementService.updateLanguage(userSelectedLocale);

    await PatientManagementService.updateCountry(userResidingCountry);

    LocalStorageService.serviceInstance.setOperationConsentState(true);
    await PatientManagementService.ConsentOperationData();

    if (userConsented) {
        LocalStorageService.serviceInstance.setDataAnalyticConsentState(
            ConsentValue.Agreed
        );
        await PatientManagementService.ConsentAnalyticData();
        TelemetryService.serviceInstance.TrackAnonymous('Orion-DataAnalytic', {
            Consent: 'Agreed',
        });
    } else {
        LocalStorageService.serviceInstance.setDataAnalyticConsentState(
            ConsentValue.Declined
        );
        await PatientManagementService.RevokeAnalyticData();
        TelemetryService.serviceInstance.TrackAnonymous('Orion-DataAnalytic', {
            Consent: 'Declined',
        });
    }

    // setup devices
    const leftModelId =
        LocalStorageService.serviceInstance.getDeviceModelId('left');
    const rightModelId =
        LocalStorageService.serviceInstance.getDeviceModelId('right');
    // only supporting binaural devices at this time
    if (leftModelId === -1 || rightModelId === -1) {
        const devices = setDeviceListFromParams();
        LocalStorageService.serviceInstance.clearQueryParams();
        if (
            devices.length === 0 ||
            devices[0].modelId == '-1' ||
            devices[1].modelId == '-1'
        ) {
            await PatientManagementService.SetOrionAirDevices();
        } else {
            await PatientManagementService.SetDevices(devices);
            await PatientManagementService.getPatient();
        }
    }

    TelemetryService.serviceInstance.TrackAnonymous('Orion-HiModel', {
        Model: getUserDeviceModel(),
    });

    LocalStorageService.serviceInstance.setSelectedCountry(null);
}
