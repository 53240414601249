import { ButtonProps } from '@material-ui/core';
import React from 'react';
import { withTracking } from '../../../Services/Monitoring/ApplicationInsights';
import {
    ButtonResource,
    HeaderResource,
    ImageResource,
    TextResource,
    VoidReturn,
} from '../../../Utils/PageUtils';
import { ButtonType } from '../../Button/ButtonType';
import CustomButton from '../../Button/CustomButton';
import PairButton from '../../Button/PairButton';
import AppContainer from '../../Container/AppContainer';
import BodyContainer from '../../Container/BodyContainer';
import CustomImg from '../../CustomImage/CustomImg';
import Header from '../../Header/HeaderRefactor';
import CustomTypography, {
    TypographyType,
} from '../../Typography/CustomTypography';
import { IDialogContentProps } from '../DialogUtils';
import './WarningDialogStyle.scss';

export enum WarningType {
    Alert = 'Alert',
    ConnectionLost = 'Paring-lost',
    Hint = 'Hint',
}
export interface IUserSelection {
    label: string;
    styleFilled: boolean;
    onClickAction: () => void;
}

export type WarningDialogHandler = {
    primaryAction: () => VoidReturn;
    secondaryAction?: () => VoidReturn;
};

export type WarningDialogResource = {
    header: HeaderResource;
    image?: ImageResource;
    warningTitle?: TextResource;
    warningMessage: TextResource;
    warningInfo?: TextResource;
    buttonLabels: {
        primaryLabel: ButtonResource;
        secondaryLabel?: ButtonResource;
    };
};
export interface IWarningDialogProps extends IDialogContentProps {
    useHandler: () => WarningDialogHandler;
    useResource: () => WarningDialogResource;
}

function WarningDialog(props: IWarningDialogProps) {
    const { onClose, useHandler, useResource } = props;

    const { primaryAction, secondaryAction } = useHandler();

    const {
        header,
        image,
        warningTitle,
        warningMessage,
        warningInfo,
        buttonLabels,
    } = useResource();

    const primButtonConfig: ButtonProps = {
        id: buttonLabels.primaryLabel.id,
        onClick: primaryAction,
        children: buttonLabels.primaryLabel.label,
    };

    return (
        <AppContainer>
            <Header headerContent={header} onClick={onClose} />
            <BodyContainer>
                {image && (
                    <CustomImg
                        imgType={image.size ? image.size : 'small'}
                        id={image.id}
                        src={image.src}
                        alt={image.alt}
                        className={'warningImageArea'}
                    />
                )}
                {warningTitle && (
                    <CustomTypography
                        id={warningTitle.id}
                        type={TypographyType.notoSansBody18px}
                        text={warningTitle.text}
                        className={'warningComponentArea'}
                    />
                )}
                <CustomTypography
                    id={warningMessage.id}
                    type={TypographyType.notoSansBody16px}
                    text={warningMessage.text}
                    className={'warningComponentArea'}
                />
                {warningInfo && warningInfo.text ? (
                    <CustomTypography
                        id={warningInfo.id}
                        type={TypographyType.notoSansBody18px}
                        text={warningInfo.text}
                        textAlign="bottom"
                    />
                ) : (
                    <></>
                )}
            </BodyContainer>
            {buttonLabels.secondaryLabel ? (
                <PairButton
                    withLoading
                    secondaryButton={{
                        id: buttonLabels.secondaryLabel.id,
                        onClick: secondaryAction,
                        children: buttonLabels.secondaryLabel.label,
                    }}
                    primaryButton={primButtonConfig}
                />
            ) : (
                <CustomButton
                    {...primButtonConfig}
                    buttonType={ButtonType.Primary}
                />
            )}
        </AppContainer>
    );
}

export default withTracking(WarningDialog);
