import { useMachine } from '@xstate/react';
import React, { useRef } from 'react';
import { withTracking } from '../../Services/Monitoring/ApplicationInsights';
import BeepConfirmation from '../MainComponents/BeepConfirmation/BeepConfirmation';
import usePairingConfirmationHandler from './Confimation/PairingConfirmationHandlerHook';
import usePairingConfirmationResource from './Confimation/PairingConfirmationResourceHook';
import usePairingOneSideHandler from './OneSide/PairingOneSideHandlerHook';
import usePairingOneSideResource from './OneSide/PairingOneSideResource';
import PairingCarousel from './PairingCarouselPage';
import {
    IPairingContext,
    IPairingInfo,
    PairingContext,
} from './PairingContext';
import { PairingState } from './PairingModels';
import PairingStateMachine from './PairingStateMachine';
import PairingPrepare from './Prepare/PairingPrepare';
import PairingSuccessPage from './Success/PairingSuccessPage';
import TechnicalSetup from './TechnicalSetup/TechnicalSetup';
import PairingTroubleshooting from './Troubleshooting/PairingTroubleshooting';
import useInsertInstructionHandler from './InsertInstruction/InsertInstructionHandlerHook';
import useInsertInstructionResource from './InsertInstruction/InsertInstructionResourceHook';
import ReturnToTimelineStateService, {
    ReturnToTimelineState,
} from '../../Services/ReturnToTimelineService';
import LocalStorageService from '../../Services/LocalStorageService';
import CompletedWorkflowService from '../../Utils/CompletedWorkflow';

function PairingPage() {
    const info = useRef<IPairingInfo>({ left: -1, right: -1 });
    const [state, sendEvent] = useMachine(PairingStateMachine());

    const initialValue: IPairingContext = {
        info: info,
        state: state,
        send: sendEvent,
    };

    const completedWorkflow =
        LocalStorageService.serviceInstance.getCompletedWorkflow();

    ReturnToTimelineStateService.setCurrentReturnToTimelineState(
        CompletedWorkflowService.isCompletedMandatoryWorkflow(completedWorkflow)
            ? ReturnToTimelineState.None
            : ReturnToTimelineState.Pairing
    );

    const renderSwitch = () => {
        const arcParingMatch = (state: PairingState) => {
            return { arcPairing: state };
        };

        window.scrollTo(0, 0);

        switch (true) {
            case state.matches('TechnicalSetup'):
                return <TechnicalSetup />;
            case state.matches(arcParingMatch('PreparePairing')):
                return <PairingPrepare />;
            case state.matches(arcParingMatch('PairOneSide')):
                return (
                    <PairingCarousel
                        useHandler={usePairingOneSideHandler}
                        useResource={usePairingOneSideResource}
                    />
                );
            case state.matches(arcParingMatch('Confirmation')):
                return (
                    <BeepConfirmation
                        useHandler={usePairingConfirmationHandler}
                        useResource={usePairingConfirmationResource}
                    />
                );
            case state.matches(arcParingMatch('Success')):
                return <PairingSuccessPage />;
            case state.matches(arcParingMatch('TroubleShooting')):
                return <PairingTroubleshooting />;
            case state.matches('BLEPairing'):
                return (
                    <PairingCarousel
                        useHandler={useInsertInstructionHandler}
                        useResource={useInsertInstructionResource}
                    />
                );
            default:
                return <></>;
        }
    };
    return (
        <PairingContext.Provider value={initialValue}>
            {renderSwitch()}
        </PairingContext.Provider>
    );
}

export default withTracking(PairingPage);
