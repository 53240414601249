import { useContext, useEffect, useState } from 'react';
import { MainViewContext } from '../../MainComponents/MainView/MainViewContext';
import { TransitionAction } from '../../MainComponents/MainView/MainViewModel';

export type CompleteRecommendationHandler = {
    isButtonDisabled: boolean;
    handleCompleteRecommendationContinue: () => void;
};

function useCompleteRecommendationHandler(): CompleteRecommendationHandler {
    const { doTransition } = useContext(MainViewContext);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    useEffect(() => {
        // Reset button state on page load
        setIsButtonDisabled(false);
    }, []);

    const handleCompleteRecommendationContinue = () => {
        setIsButtonDisabled(true);
        doTransition(TransitionAction.CompleteRecommendationContinue);
    };
    return {
        isButtonDisabled,
        handleCompleteRecommendationContinue,
    };
}

export default useCompleteRecommendationHandler;
