//Utilities function to create an experssion that help to get image/traslation and other resouces path
export function createGetPathFunction(
    path: string,
    separator: string
): Function {
    return (name: string) => {
        return `${path}${separator}${name}`;
    };
}

export interface ObjectWithAnyKey {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
}

export const sleep = (milliseconds: number): Promise<unknown> => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

export const waitUntil = (
    condition: () => boolean,
    interval: number,
    maxTries?: number
): Promise<unknown> => {
    let tryNumber = 1;
    return new Promise<void>((resolve) => {
        const wait = setInterval(() => {
            if (condition() || (maxTries && maxTries <= tryNumber)) {
                resolve();
                clearInterval(wait);
            } else tryNumber++;
        }, interval);
    });
};
