import { useContext, useRef } from 'react';
import { BestCluster } from '../../../../Models/HearingProfile/BestCluster';
import { BestClusterRecommendation } from '../../../../Models/HearingProfile/BestClusterRecommendation';
import ArcToneService from '../../../../Services/ArcToneService';
import AudioService from '../../../../Services/AudioService';
import DeviceManagementService from '../../../../Services/DeviceManagementService';
import HearingProfileService from '../../../../Services/HearingProfileService';
import HLAAService from '../../../../Services/HLAAService';
import LocalStorageService from '../../../../Services/LocalStorageService';
import LoggingService from '../../../../Services/LoggingService';
import TelemetryService from '../../../../Services/Monitoring/TelemetryService';
import PatientManagementService from '../../../../Services/PatientManagementService';
import { VoidReturn } from '../../../../Utils/PageUtils';
import { InitialSettingContext, Side } from '../InitialSettingContext';
import { InitialSettingApplicationState } from '../InitialSettingModels';
import { getUserDeviceModel } from '../../../../Services/ResourceService';

export type SideInitialSettingHandle = {
    programInitialSetting: () => VoidReturn;
    initializeClusterSetting: () => VoidReturn;
    getOtherSide: (side: string) => string;
};

export default function useInitialSettingSideHandler(): SideInitialSettingHandle {
    const { info, setInitialSettingInfo } = useContext(InitialSettingContext);
    const hlaaTestId = useRef('');

    const setHearingProfileIdIntoCookies = async () => {
        const patient = await PatientManagementService.getPatient();
        if (patient === undefined) return;
        LocalStorageService.serviceInstance.setActiveHearingProfileId(
            patient.activeHearingProfileId
        );
    };

    const getOtherSide = (side: string): string => {
        const otherSideState = info.sideStates.find((item) => {
            return item.side !== side;
        });
        if (otherSideState !== undefined) {
            return otherSideState.side;
        }
        return '';
    };

    const applyInitialSettings = async () => {
        await HLAAService.applyInitialSettings(hlaaTestId.current);
        await setHearingProfileIdIntoCookies();
        return await HearingProfileService.getBestClusterInfo(
            LocalStorageService.serviceInstance.getActiveHearingProfileId()
        );
    };

    const onOneSideAudioPlayDone = async (doneSide: string) => {
        const currentStates = info.sideStates.map(
            (x: { side: Side; isDone: boolean; bestCluster: BestCluster }) => ({
                side: x.side,
                isDone: x.side === doneSide ? true : x.isDone,
                bestCluster: x.bestCluster,
            })
        );

        info.sideStates = currentStates;
    };

    const programBestClusterSettings = async (
        side: string,
        bestCluster: BestCluster
    ) => {
        await AudioService.serviceInstance.initAudioContext();
        const activeDeviceId =
            LocalStorageService.serviceInstance.getDeviceId(side);
        const brandId =
            LocalStorageService.serviceInstance.getDeviceBrandId(side);
        const pairingAddress =
            LocalStorageService.serviceInstance.getPairingAddress(side);
        const modelId = LocalStorageService.serviceInstance
            .getDeviceModelId(side)
            .toString();
        const couplingId =
            LocalStorageService.serviceInstance.getDeviceCouplingId(side);
        await AudioService.serviceInstance.prepAudio(
            ArcToneService.resetEasyFitControls(brandId, pairingAddress),
            `resetting easyfit controls for ${side}`
        );
        setInitialSettingInfo({
            ...info,
            initialSettingStep: InitialSettingApplicationState.Writing,
            loading: true,
            progress: 20,
        });
        await DeviceManagementService.EasyFitControlsIsReset(
            LocalStorageService.serviceInstance.getPatientId(),
            activeDeviceId
        );
        setInitialSettingInfo({
            ...info,
            initialSettingStep: InitialSettingApplicationState.Writing,
            loading: true,
            progress: 50,
        });
        await AudioService.serviceInstance.prepAudio(
            ArcToneService.getClusterToneSequence(
                brandId,
                pairingAddress,
                bestCluster.clusterId
            ),
            `clusterId ${bestCluster.clusterId}`
        );
        setInitialSettingInfo({
            ...info,
            initialSettingStep: InitialSettingApplicationState.Writing,
            loading: true,
            progress: 75,
        });
        await AudioService.serviceInstance.prepAudio(
            ArcToneService.getMasterGain(
                brandId,
                pairingAddress,
                bestCluster.masterGainId,
                modelId,
                couplingId
            ),
            `masterGainId ${bestCluster.masterGainId}`
        );

        if (side.toLowerCase() === 'left') {
            TelemetryService.serviceInstance.TrackAnonymous('Orion-Cluster', {
                Model: getUserDeviceModel('left'),
                Left: bestCluster.clusterId.toString(),
            });

            TelemetryService.serviceInstance.TrackAnonymous(
                'Orion-Recommended-MasterGain',
                {
                    Model: getUserDeviceModel(),
                    LeftCluster: bestCluster.clusterId.toString(),
                    Left: bestCluster.masterGainId.toString(),
                }
            );
        } else {
            TelemetryService.serviceInstance.TrackAnonymous('Orion-Cluster', {
                Model: getUserDeviceModel('right'),
                Right: bestCluster.clusterId.toString(),
            });

            TelemetryService.serviceInstance.TrackAnonymous(
                'Orion-Recommended-MasterGain',
                {
                    Model: getUserDeviceModel(),
                    RightCluster: bestCluster.clusterId.toString(),
                    Right: bestCluster.masterGainId.toString(),
                }
            );
        }
    };

    const onWritingStart = async () => {
        setInitialSettingInfo({
            ...info,
            initialSettingStep: InitialSettingApplicationState.Writing,
            loading: true,
            progress: 0,
        });
        const bestClusterForSide = info.sideStates.find((item) => {
            return item.side === info.activeSide;
        });
        if (bestClusterForSide !== undefined)
            await programBestClusterSettings(
                info.activeSide,
                bestClusterForSide.bestCluster
            );
        onOneSideAudioPlayDone(info.activeSide);
        setInitialSettingInfo({
            ...info,
            initialSettingStep: InitialSettingApplicationState.Writing,
            loading: true,
            progress: 100,
        });
        await sleep(1000);
    };

    const programInitialSetting = async () => {
        setInitialSettingInfo({
            ...info,
            initialSettingStep: InitialSettingApplicationState.Writing,
            loading: true,
        });
        await onWritingStart();
        setInitialSettingInfo({
            ...info,
            initialSettingStep: InitialSettingApplicationState.Confirming,
            loading: false,
        });
    };

    const initializeSettingsState = async (
        bestClusters: BestClusterRecommendation
    ) => {
        const sideStates = info.sideStates.map(
            (x: { side: Side; isDone: boolean; bestCluster: BestCluster }) => ({
                side: x.side,
                isDone: false,
                bestCluster:
                    bestClusters.deviceBestClusterDtos[
                        LocalStorageService.serviceInstance.getDeviceId(x.side)
                    ],
            })
        );
        info.sideStates = sideStates;
        info.initialized = true;
    };

    const initializeClusterSetting = async () => {
        hlaaTestId.current = getHlaaTestId();

        const hearingProfileId =
            LocalStorageService.serviceInstance.getActiveHearingProfileId();

        let bestClusterInfo: BestClusterRecommendation | undefined;
        try {
            if (hearingProfileId && hearingProfileId !== '') {
                bestClusterInfo = await HearingProfileService.getBestCluster(
                    hearingProfileId,
                    hlaaTestId.current
                );
                if (bestClusterInfo === undefined) throw new Error('notfound');
            } else {
                bestClusterInfo = await applyInitialSettings();
            }
        } catch (ex) {
            if (ex.toString().toLowerCase().includes('notfound')) {
                bestClusterInfo = await applyInitialSettings();
            } else {
                throw ex;
            }
        }
        LoggingService.info({
            componentName: initializeClusterSetting.name,
            args: [bestClusterInfo],
        });
        await initializeSettingsState(bestClusterInfo);
        await programInitialSetting();
    };

    const getHlaaTestId = (): string => {
        let hlaaTestId =
            LocalStorageService.serviceInstance.getLastCompletedHlaaTestId();
        if (hlaaTestId === '') {
            if (
                LocalStorageService.serviceInstance.getActiveHlaaTestId() !== ''
            )
                hlaaTestId =
                    LocalStorageService.serviceInstance.getActiveHlaaTestId();
            else {
                throw new Error('Invalid operation - Please perform HLAA Test');
            }
        }
        return hlaaTestId;
    };

    const sleep = (milliseconds: number) => {
        return new Promise((resolve) => setTimeout(resolve, milliseconds));
    };

    return {
        programInitialSetting: programInitialSetting,
        initializeClusterSetting: initializeClusterSetting,
        getOtherSide: getOtherSide,
    };
}
