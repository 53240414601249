import i18n from 'i18next';
import Backend from 'i18next-chained-backend';
import { initReactI18next } from 'react-i18next';
import LocalStorageBackend from 'i18next-localstorage-backend'; // primary use cache
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import LocalStorageService from './LocalStorageService';

const defaultLocale = 'en';
export const locales = [
    {
        code: 'en',
        className: 'flag flag_en',
        dateFormat: 'MM/dd/yyyy',
    },
];

export const isValidLocale = (locale: string | undefined): boolean => {
    if (locale == null) {
        return false;
    }
    return locales.findIndex((l) => l.code === locale) >= 0;
};

export const getCurrentLocale = (): string => {
    let locale = LocalStorageService.serviceInstance.getLanguageCode();

    if (locale) {
        locale = locale.substring(0, 2);
        if (!isValidLocale(locale)) {
            locale = defaultLocale;
        }
    } else {
        locale = defaultLocale;
    }
    return locale;
};

i18n.use(LanguageDetector)
    // load translation using http -> see /public/locales
    .use(Backend)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    .init({
        detection: {
            // order and from where user language should be detected
            order: ['cookie', 'localStorage', 'navigator'],
            // keys or params to lookup language from
            lookupLocalStorage: 'i18nextLng',

            // cache user language on
            caches: ['localStorage'],
            //excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

            // optional expire and domain for set cookie
            cookieMinutes: 10,
            cookieDomain: 'myDomain',
        },
        backend: {
            backends: [
                LocalStorageBackend, // primary
                HttpApi, // fallback
            ],
            backendOptions: [
                {
                    prefix: 'nextgen_translation_res_',
                    expirationTime:
                        Number(
                            process.env.REACT_APP_I18_EXPIRATIONTIME_SECONDS
                        ) * 1000,
                },
                {
                    loadPath: '/locales/{{lng}}/{{ns}}.json', // xhr load path for my own fallback
                },
            ],
        },
        partialBundledLanguages: true,
        ns: [
            'common',
            'welcome',
            'consent',
            'contraindication',
            'hiAssembly',
            'hlaa',
            'pairing',
            'initialSettings',
            'speechComfort',
            'fineTuning',
            'smartRemote',
            'menu',
            'userJourney',
            'legal',
            'orionAir',
            'orionC20',
            'orionGo',
            'orionComplete',
            'splashScreen',
            'completeAndLanding',
            'orionApp',
            'webApp',
            'maintenance',
        ],
        defaultNS: 'common',
        interpolation: {
            // React already does escaping
            escapeValue: false,
        },
        react: {
            useSuspense: false,
        },
        debug: false,
        fallbackLng: defaultLocale,
        nonExplicitSupportedLngs: true,
        supportedLngs: locales.map((locale) => locale.code),
        load: 'languageOnly',
    });

export default i18n;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function t(key: string, options?: string | any): string {
    return i18n.t(key, options);
}
