import { useTranslation } from 'react-i18next';
import { HeaderActionType } from '../../../../SharedComponents/Header/HeaderActionType';
import {
    ButtonResource,
    HeaderResource,
    ImageResource,
    TextResource,
} from '../../../../Utils/PageUtils';

export type DownloadDialogResource = {
    header: HeaderResource;
    logoImage: ImageResource;
    instructionsText1: TextResource;
    instructionsText2: TextResource;
    appleImage: ImageResource;
    appleLink: TextResource;
    androidImage: ImageResource;
    androidLink: TextResource;
    primaryButton?: ButtonResource;
};

export default function useDownloadDialogResource(): DownloadDialogResource {
    const { t } = useTranslation();

    return {
        header: {
            text: t(
                'webApp:WebApp_Workflow:CompleteAndLanding_Feature:DownloadApp:Header'
            ),
            type: HeaderActionType.NoAction,
        },
        logoImage: {
            id: 'img-orion',
            alt: 'img-orion',
            src: `/resources/sharedAcrossWorkflows/App-icon_withshadow.svg`,
        },
        instructionsText1: {
            id: 'txt-instructions1',
            text: t(
                'webApp:WebApp_Workflow:CompleteAndLanding_Feature:DownloadApp:Text1'
            ),
        },
        instructionsText2: {
            id: 'txt-instructions2',
            text: t(
                'webApp:WebApp_Workflow:CompleteAndLanding_Feature:DownloadApp:Text2'
            ),
        },
        appleImage: {
            id: 'img-apple-app-store',
            alt: 'Apple App store',
            src: `/resources/sharedAcrossWorkflows/Apple.svg`,
        },
        appleLink: {
            id: 'apple-app-store-link',
            text: 'https://www.sony.net/hearing-appstore',
        },
        androidImage: {
            id: 'img-google-play-store',
            alt: 'Google Play store',
            src: `/resources/sharedAcrossWorkflows/Android.svg`,
        },
        androidLink: {
            id: 'android-app-store-link',
            text: 'https://www.sony.net/hearing-googleplay',
        },
    };
}
