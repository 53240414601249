import { useTranslation } from 'react-i18next';
import { HeaderActionType } from '../../../SharedComponents/Header/HeaderActionType';
import {
    ButtonResource,
    HeaderResource,
    TextResource,
} from '../../../Utils/PageUtils';

type ContraindicationResource = {
    header: HeaderResource;
    instruction: TextResource;
    terms: TextResource;
    termAgreementInfo: TextResource;
    termAgreementCheckBox: ButtonResource;
    continueButton: ButtonResource;
};

function useContraindicationResource(): ContraindicationResource {
    const { t } = useTranslation();

    const header = {
        text: t('common:Common_Feature:Warning'),
        type: HeaderActionType.Menu,
    };

    const instruction = {
        id: 'txt-instruction',
        text: t(`contraindication:Contraindication_Feature:Text1`),
    };

    const terms = {
        text: t('contraindication:Contraindication_Feature:Text2'),
    };

    const termAgreementInfo = {
        id: 'txt-agreeOnTerms',
        text: t(`contraindication:Contraindication_Feature:Text4`),
    };
    const termAgreementCheckBox = {
        id: 'checkbox-agreeTerms',
        label: t(`contraindication:Contraindication_Feature:Text5`),
    };
    const continueButton = {
        id: 'btn-continue',
        label: t('common:Common_Feature:Continue'),
    };

    return {
        header,
        instruction,
        terms,
        termAgreementInfo,
        termAgreementCheckBox,
        continueButton,
    };
}

export default useContraindicationResource;
