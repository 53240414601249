import { useTranslation } from 'react-i18next';

export type FineTuningDuringResetPageResource = {
    headerText: string;
    progressText: string;
};

const useFineTuningDuringResetPageResource =
    (): FineTuningDuringResetPageResource => {
        const { t } = useTranslation();

        const headerText = t('fineTuning:FineTuning_Feature:Reset:Header');

        const progressText = t('fineTuning:FineTuning_Feature:Reset:Text1');

        return {
            headerText,
            progressText,
        };
    };

export default useFineTuningDuringResetPageResource;
