import React from 'react';
import { withTracking } from '../../../Services/Monitoring/ApplicationInsights';
import { ButtonType } from '../../../SharedComponents/Button/ButtonType';
import CustomButton from '../../../SharedComponents/Button/CustomButton';
import CustomCheckBox from '../../../SharedComponents/Checkbox/CustomCheckBox';
import AppContainer from '../../../SharedComponents/Container/AppContainer';
import BodyContainer from '../../../SharedComponents/Container/BodyContainer';
import Header from '../../../SharedComponents/Header/HeaderRefactor';
import useLoading from '../../../SharedComponents/Spinner/PageLoadSpinner';
import CustomTypography, {
    TypographyType,
} from '../../../SharedComponents/Typography/CustomTypography';
import useConsentPageHandler from './ConsentPageHandlerHook';
import useConsentPageResource from './ConsentPageResourceHook';
import './ConsentPageStyle.scss';

const ConsentPage = () => {
    const { header, title, consentIntruction, checkbox, button } =
        useConsentPageResource();

    const [loadingSpinner, setLoading] = useLoading();
    const {
        showStartButton,
        handleOperationDataCheckboxChange,
        onContinue,
        linkCallBacks,
    } = useConsentPageHandler(setLoading);

    return (
        <>
            {loadingSpinner}
            <AppContainer>
                <Header headerContent={header} />
                <BodyContainer>
                    <CustomTypography
                        textAlign="left"
                        type={TypographyType.notoSansBody18px}
                        text={title.text}
                        id={title.id}
                        className={'consent-page-title'}
                    />

                    <CustomTypography
                        textAlign="left"
                        type={TypographyType.notoSansBody16px}
                        text={consentIntruction.text}
                        id={consentIntruction.id}
                        linkCallBacks={linkCallBacks}
                    />

                    <CustomCheckBox
                        onChange={handleOperationDataCheckboxChange}
                        displayText={checkbox.label}
                        id={checkbox.id}
                        containerClassName={'consent-page-checkbox'}
                        style={{ paddingLeft: 0 }}
                    />
                </BodyContainer>
                <CustomButton
                    buttonType={ButtonType.Primary}
                    id={button.id}
                    onClick={onContinue}
                    disabled={!showStartButton}
                    sticky={false}
                >
                    {button.label}
                </CustomButton>
            </AppContainer>
        </>
    );
};

export default withTracking(ConsentPage);
