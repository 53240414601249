import { useContext } from 'react';
import { VoidReturn } from '../../../../Utils/PageUtils';
import { MobileDeviceUnmutedCheckContext } from '../MobileDeviceUnmutedCheckContext';
import { MobileDeviceUnmutedCheckState } from '../MobileDeviceUnmutedCheckModel';
import MelodyAudioService from '../../../../Services/MelodyAudioService';

export type MobileDeviceUnmutedCheckStartPageHandler = {
    playMedia: () => VoidReturn;
};

export default function useMobileDeviceUnmutedCheckStartPageHandler(): MobileDeviceUnmutedCheckStartPageHandler {
    const { setCurrentMobileDeviceUnmutedCheckState } = useContext(
        MobileDeviceUnmutedCheckContext
    );

    const playMedia = async () => {
        setCurrentMobileDeviceUnmutedCheckState(
            MobileDeviceUnmutedCheckState.Play
        );
        const serviceInstance = new MelodyAudioService();
        await serviceInstance.initAudioContext();
    };

    return {
        playMedia,
    };
}
