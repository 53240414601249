import { useTranslation } from 'react-i18next';
import { HeaderActionType } from '../../../SharedComponents/Header/HeaderActionType';
import {
    ButtonResource,
    HeaderResource,
    ImageResource,
    TextResource,
} from '../../../Utils/PageUtils';

type ConsentAnalyticResource = {
    header: HeaderResource;
    consentImage: ImageResource;
    title: TextResource;
    consentAnalyticText: TextResource;
    privacyLink: TextResource;
    analyticDataConsentAgreement: TextResource;
    yesButton: ButtonResource;
    noButton: ButtonResource;
};

function useConsentAnalyticResource(): ConsentAnalyticResource {
    const { t } = useTranslation();
    const header = {
        text: t(`consent:Consent_Feature:Text11`),
        type: HeaderActionType.Menu,
    };

    const consentImage = {
        id: 'img-consent',
        src: '/resources/fittingWorkflow/consent-graphic.svg',
        alt: t('consent:header'),
    };

    const title = {
        id: 'txt-consent-analytic-title',
        text: t('consent:Consent_Feature:Text12'),
    };

    const consentAnalyticText = {
        id: 'txt-consent-analytic',
        text: t('consent:Consent_Feature:Text13'),
    };

    const privacyLink = {
        id: 'link-tap-privacy',
        text: t(`consent:Consent_Feature:Text14`),
    };

    const analyticDataConsentAgreement = {
        id: 'txt-agreeOnAnalyticDataConsent',
        text: t(`consent:Consent_Feature:Text1`),
    };

    const yesButton = {
        id: 'btn-yes',
        label: t('consent:Consent_Feature:NextButton'),
    };

    const noButton = {
        id: 'btn-no',
        label: t('consent:Consent_Feature:BackButton'),
    };

    return {
        header,
        consentImage,
        title,
        consentAnalyticText,
        privacyLink,
        analyticDataConsentAgreement,
        yesButton,
        noButton,
    };
}

export default useConsentAnalyticResource;
