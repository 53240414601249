import { useContext } from 'react';
import { MainViewContext } from '../../../../../Pages/MainComponents/MainView/MainViewContext';
import { MainViewWorkflow } from '../../../../../Pages/MainComponents/MainView/MainViewModel';
import { useTranslation } from 'react-i18next';
import { SubMenuListResource } from '../../../../../Utils/PageUtils';
import { HeaderActionType } from '../../../../Header/HeaderActionType';
import { MenuContext } from '../../MenuContext';
import { MenuDialogType } from '../../MenuDialogType';
import { createSubMenuItem } from '../../MenuUtils';

export default function useAccountSettingsResource(): SubMenuListResource {
    const { t } = useTranslation();
    const { pushMenu: renderNextMenu } = useContext(MenuContext);
    const viewContext = useContext(MainViewContext);

    const accountSettingsMenuLists = [
        createSubMenuItem(
            'item-dataAnalytic',
            t('common:Common_Feature:UsageStatistics'),
            () => renderNextMenu(MenuDialogType.RevokeAnalyticConsent)
        ),
    ];

    if (viewContext.workflowType === MainViewWorkflow.Web) {
        accountSettingsMenuLists.push(
            createSubMenuItem(
                'item-operationConsent',
                t('common:Common_Feature:DeleteAccount'),
                () => renderNextMenu(MenuDialogType.RevokeOperationConsent)
            )
        );
    }

    return {
        header: {
            id: 'header-accountSettings-menu',
            text: t('menu:Menu_Feature:Main:Text6'),
            type: HeaderActionType.Close,
        },
        menuItems: accountSettingsMenuLists,
    };
}
