import CookiesService from '../CookiesService';
import LocalStorageService from '../LocalStorageService';
import LoggingService from '../LoggingService';
import TelemetryService from '../Monitoring/TelemetryService';
import PatientManagementService from '../PatientManagementService';
import { Buffer } from 'buffer';
import { waitUntil } from '../../Utils/ResourcesUtils';
import ExitRouteService, { ExitStatus } from '../ExitRouteService';

type JwtObject = Object & { app_identity_userid: string };
export default class AuthService {
    public static adB2cInstance: AuthService = new AuthService();

    static getInstance(): AuthService {
        if (!AuthService.adB2cInstance) {
            AuthService.adB2cInstance = new AuthService();
        }
        return AuthService.adB2cInstance;
    }

    private decodeJwt(token: string): JwtObject | undefined {
        try {
            return JSON.parse(
                Buffer.from(token.split('.')[1], 'base64').toString()
            );
        } catch (error) {
            LoggingService.error({
                componentName: 'AuthService.decodeJwt',
                args: ['Error while decoding jwt: ' + error],
            });
            this.logout();
            ExitRouteService.exit(ExitStatus.InvalidTokenFail);
        }
    }

    public login(): void {
        const userId = this.getAppIdentityUserId();
        if (userId !== '') {
            LocalStorageService.serviceInstance.setPatientId(userId);
        }
    }

    private getAppIdentityUserId(): string {
        const token = this.getAccessTokenFromLocalStorage();
        const tokenObject = this.decodeJwt(token);
        return tokenObject?.['app_identity_userid'] ?? '';
    }

    public async doesAccessTokenExist(): Promise<boolean> {
        const accessTokenExists = () => {
            return this.getAccessTokenFromLocalStorage() !== '';
        };
        await waitUntil(accessTokenExists, 1000, 10);

        return accessTokenExists();
    }

    public logout(): void {
        LocalStorageService.serviceInstance.clear(true);
        sessionStorage.clear();
        CookiesService.serviceInstance.clearCookies();
        TelemetryService.serviceInstance.UnTrackPatientId();
    }

    public getAccessTokenFromLocalStorage(): string {
        const accessToken =
            LocalStorageService.serviceInstance.getAccessToken();
        return accessToken;
    }

    public acquireAccessToken(): string {
        const token = this.getAccessTokenFromLocalStorage();
        if (token) {
            return token;
        }
        LoggingService.error({
            componentName: 'AuthService.acquireAccessToken',
            args: [
                `Error occured, could not acquire access token. Logging user out.`,
            ],
        });
        this.logout();
        return '';
    }

    public async getIsAuthenticated(): Promise<boolean> {
        const userId = this.getAppIdentityUserId();

        if (userId === '') return false;
        if (
            LocalStorageService.serviceInstance.getPatientId() !== '' &&
            userId !== LocalStorageService.serviceInstance.getPatientId()
        )
            return false;

        return true;
    }

    public async refreshCookies(): Promise<void> {
        const token = this.acquireAccessToken();
        if (token !== '') {
            let patient = await PatientManagementService.getPatient();
            while (patient === undefined) {
                LoggingService.info({
                    componentName: this.refreshCookies.name,
                    args: [
                        'AuthService -> refreshCookies -> Trying to get patient',
                        patient,
                    ],
                });
                await new Promise((resolve) => setTimeout(resolve, 1000));
                try {
                    patient = await PatientManagementService.getPatient();
                } catch (ex) {
                    LoggingService.error({
                        componentName: this.refreshCookies.name,
                        args: [`Error occur when trying to get patient: ${ex}`],
                    });
                }
            }
            LoggingService.info({
                componentName: this.refreshCookies.name,
                args: [
                    'AuthService -> refreshCookies -> patient found',
                    patient,
                ],
            });
        }
        this.logout();
        ExitRouteService.exit(ExitStatus.InvalidTokenFail);
    }
}
