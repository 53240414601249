import React from 'react';
import { ButtonType } from '../../Button/ButtonType';
import CustomButton from '../../Button/CustomButton';
import CustomIconButtonWithText from '../../Button/CustomIconButtonWithText';
import AppContainer from '../../Container/AppContainer';
import BodyContainer from '../../Container/BodyContainer';
import Header from '../../Header/HeaderRefactor';
import CustomTypography, {
    TypographyType,
} from '../../Typography/CustomTypography';
import { IDialogContentProps } from '../DialogUtils';
import useSleeveSelectionHandler from './SleeveSelectionHandler';
import useSleeveSelectionResource from './SleeveSelectionResource';
import './SleeveSelection.scss';
import ActionContainer from '../../Container/ActionContainer';
import { sleeveCoupling } from '../../../Models/SupportedDeviceModel';

export default function SleeveSelection({
    onClose,
}: IDialogContentProps): React.ReactElement {
    const {
        selectedSleeve,
        onContinue,
        onSleeveChangedToClosed,
        onSleeveChangedToVented,
        openHyperLink,
    } = useSleeveSelectionHandler(onClose);
    const {
        header,
        instructionText,
        closedSleeveImage,
        ventedSleeveImage,
        continueButton,
        closedSleeveName,
        ventedSleeveName,
        linkCallBacks,
    } = useSleeveSelectionResource(openHyperLink);

    return (
        <AppContainer>
            <Header headerContent={header} />
            <BodyContainer>
                <CustomTypography
                    noMargin
                    type={TypographyType.notoSansBody18px}
                    text={instructionText.text}
                    textAlign="center"
                    id={instructionText.id}
                    linkCallBacks={linkCallBacks}
                    className={'sleeveSelectionText'}
                />
                <ActionContainer className={'button-container'}>
                    <CustomIconButtonWithText
                        id={closedSleeveName.id}
                        imgSrc={closedSleeveImage.src}
                        iconSize="small"
                        textSource={closedSleeveName}
                        imgClassName={
                            selectedSleeve === sleeveCoupling.Closed
                                ? 'sleeve-image-selected'
                                : 'sleeve-image-not-selected'
                        }
                        onClick={onSleeveChangedToClosed}
                    />
                    <CustomIconButtonWithText
                        id={ventedSleeveName.id}
                        imgSrc={ventedSleeveImage.src}
                        iconSize="small"
                        textSource={ventedSleeveName}
                        imgClassName={
                            selectedSleeve === sleeveCoupling.Vented
                                ? 'sleeve-image-selected'
                                : 'sleeve-image-not-selected'
                        }
                        onClick={onSleeveChangedToVented}
                    />
                </ActionContainer>
            </BodyContainer>
            <CustomButton
                id={continueButton.id}
                buttonType={ButtonType.PrimaryLoading}
                onClick={onContinue}
            >
                {continueButton.label}
            </CustomButton>
        </AppContainer>
    );
}
