import { Link, LinkProps } from '@material-ui/core';
import React from 'react';
import { joinMultipleStyle } from '../../Utils/ScssUtils';
import './LinkStyle.scss';

function CustomLink({ className, ...props }: LinkProps): JSX.Element {
    return (
        <Link
            underline="none"
            className={joinMultipleStyle('customLink', className)}
            {...props}
        ></Link>
    );
}

export default CustomLink;
