import { deviceModelId } from '../Models/SupportedDeviceModel';

const ValidDevices: string[] = [
    deviceModelId.OrionAir,
    deviceModelId.OrionC20,
    deviceModelId.OrionGo,
    deviceModelId.OrionComplete,
];

export function isValidDevice(modelId: string): boolean {
    return ValidDevices.includes(modelId);
}
