import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { getUserDeviceModel } from '../../../Services/ResourceService';
import { HeaderActionType } from '../../../SharedComponents/Header/HeaderActionType';
import {
    HeaderResource,
    ImageResource,
    switchBasedOnDevice,
    TextResource,
} from '../../../Utils/PageUtils';
import { HLAAContext } from '../HLAAContext';

type HearingAssessmentPlayResource = {
    header: HeaderResource;
    sideText: TextResource;
    evaluationImage: ImageResource;
    confirmInstruction: TextResource;
    progress: {
        id: string;
    };
    iconButton: {
        id: string;
    };
    playImage: ImageResource;
};

export default function useHearingAssessmentPlayResource(): HearingAssessmentPlayResource {
    const { t } = useTranslation();
    const { info } = useContext(HLAAContext);

    const side =
        info.current.activeSide.toLowerCase() === 'left' ? 'Left' : 'Right';
    const header = {
        id: 'header-hlaa-play',
        text: t('common:Common_Feature:HearingProfileCheck'),
        type: HeaderActionType.Menu,
    };

    const sideText = {
        id: 'txt-content1',
        text: t(`pairing:Pairing_Feature:${side}Title`),
    };

    const evaluationImage = {
        id: `img-${info.current.activeSide.toLowerCase()}`,
        src: switchBasedOnDevice(getUserDeviceModel(), {
            OrionAir: `/resources/OrionAir/evaluation_${info.current.activeSide.toLowerCase()}_orionAir.svg`,
            OrionC20: `/resources/OrionC20/evaluation_${info.current.activeSide.toLowerCase()}_orionC20.svg`,
            OrionGo: `/resources/OrionGo/evaluation_${info.current.activeSide.toLowerCase()}_orionGo.svg`,
        }),
        alt: `${info.current.activeSide.toLowerCase()}`,
    };

    const confirmInstruction = {
        id: 'txt-confirm',
        text: t('hlaa:Hlaa_Feature:Play:Text'),
    };

    const progress = {
        id: 'progress-loading',
    };

    const iconButton = {
        id: 'btn-play',
    };

    const playImage = {
        id: 'img-play',
        src: '/resources/sharedAcrossWorkflows/play-icon.svg',
        alt: 'play',
    };

    return {
        header,
        sideText,
        evaluationImage,
        confirmInstruction,
        progress,
        iconButton,
        playImage,
    };
}
