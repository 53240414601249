import { useTranslation } from 'react-i18next';
import { HeaderActionType } from '../../Header/HeaderActionType';
import globalDialogResource from './UpdateAppGlobalDialogResource.json';
import { DownloadDialogResource } from '../../../Pages/MainComponents/Landing/DownloadDialog/DownloadAppDialogResourceHook';

function useUpdateAppGlobalDialogResource(): DownloadDialogResource {
    const { t } = useTranslation();

    const {
        headerContent,
        imgContent,
        warningTitleContent,
        warningMessageContent,
    } = globalDialogResource;

    const header = {
        id: headerContent.id,
        text: t(headerContent.translationKey),
        type: HeaderActionType.NoAction,
    };

    const logoImage = imgContent;

    const instructionsText1 = {
        id: warningTitleContent.id,
        text: t(warningTitleContent.translationKey),
    };

    const instructionsText2 = {
        id: warningMessageContent.id,
        text: t(warningMessageContent.translationKey),
    };

    const appleImage = {
        id: 'img-apple-app-store',
        alt: 'Apple App store',
        src: `/resources/sharedAcrossWorkflows/Apple.svg`,
    };
    const appleLink = {
        id: 'apple-app-store-link',
        text: 'https://www.sony.net/hearing-appstore',
    };
    const androidImage = {
        id: 'img-google-play-store',
        alt: 'Google Play store',
        src: `/resources/sharedAcrossWorkflows/Android.svg`,
    };
    const androidLink = {
        id: 'android-app-store-link',
        text: 'https://www.sony.net/hearing-googleplay',
    };
    const primaryButton = {
        id: 'btn-confirm-yes',
        label: t('orionApp:OrionApp_Workflow:UpdateApp_Feature:NextButton'),
    };

    return {
        header,
        logoImage,
        instructionsText1,
        instructionsText2,
        appleImage,
        appleLink,
        androidImage,
        androidLink,
        primaryButton,
    };
}

export default useUpdateAppGlobalDialogResource;
