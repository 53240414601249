export class Volume {
    public side: string;
    public volumeId: number;
    public volumeUiMinId: number;
    public volumeUiMaxId: number;

    constructor(
        side: string,
        volumeId: number,
        volumeMinId: number,
        volumeUiMaxId: number
    ) {
        this.side = side;
        this.volumeId = volumeId;
        this.volumeUiMinId = volumeMinId;
        this.volumeUiMaxId = volumeUiMaxId;
    }
}
