import { SeverityLevel } from '@microsoft/applicationinsights-common';
import Telemetry from './Monitoring/Telemetry';

export enum LogLevel {
    Error = 'error',
    Warn = 'warn',
    Info = 'info',
    Log = 'log',
    Trace = 'trace',
}

export default class LoggingService {
    private static console: Console = console;
    private static readonly logConfigs: LogLevel[] = process.env
        .REACT_APP_LOG_CONFIGS
        ? (process.env.REACT_APP_LOG_CONFIGS.toLowerCase().split(
              '|'
          ) as LogLevel[])
        : [LogLevel.Warn, LogLevel.Error];

    private static getTime() {
        return new Date();
    }

    private static logDetails(level: LogLevel, componentName?: string) {
        const dateTime = LoggingService.getTime();
        componentName = componentName ? componentName : 'not specify';
        return `[${dateTime}] : [${componentName}] : [${level}] : `;
    }

    public static trace({
        componentName,
        args = [],
    }: { componentName?: string; args?: unknown[] } = {}): void {
        if (LoggingService.logConfigs.includes(LogLevel.Trace)) {
            console.trace(
                LoggingService.logDetails(LogLevel.Trace, componentName),
                ...args
            );
        }
    }

    public static log({
        componentName,
        args = [],
    }: { componentName?: string; args?: unknown[] } = {}): void {
        if (LoggingService.logConfigs.includes(LogLevel.Log)) {
            console.log(
                LoggingService.logDetails(LogLevel.Log, componentName),
                ...args
            );
        }
    }

    public static info({
        componentName,
        args = [],
    }: { componentName?: string; args?: unknown[] } = {}): void {
        if (LoggingService.logConfigs.includes(LogLevel.Info)) {
            console.info(
                LoggingService.logDetails(LogLevel.Info, componentName),
                ...args
            );
        }
    }

    public static warn({
        componentName,
        args = [],
    }: { componentName?: string; args?: unknown[] } = {}): void {
        if (LoggingService.logConfigs.includes(LogLevel.Warn)) {
            console.warn(
                LoggingService.logDetails(LogLevel.Warn, componentName),
                ...args
            );
            Telemetry.getInstance().trackTrace(
                `${componentName} - ${args.join(',')}`,
                SeverityLevel.Warning
            );
        }
    }

    public static error({
        componentName,
        args = [],
    }: { componentName?: string; args?: unknown[] } = {}): void {
        if (LoggingService.logConfigs.includes(LogLevel.Error)) {
            console.error(
                LoggingService.logDetails(LogLevel.Error, componentName),
                ...args
            );
            Telemetry.getInstance().trackTrace(
                `${componentName} - ${args.join(',')}`,
                SeverityLevel.Error
            );
        }
    }
}
