import { AudiogramResult } from './../Models/HLAA/AudiogramResult';
import { HLAAAudiogram } from './../Models/HLAA/HLAAAudiogram';
import axios from 'axios';
import { ServiceConfigFactory } from './ServiceConfigFactory';
import { HLAAState } from '../Models/HLAA/HLAAState';
import AuthService from './Authentication/AuthService';
import LoggingService from './LoggingService';
import easyFitStore from '../Redux/Store/EasyFitStore';
import { updateNotification } from '../Redux/Reducers/NotificationSlice';
import { t } from './LocalizationService';
import { NotificationCloseHandlerType } from '../Redux/Models/Notification/NotificationCloseHandlerType';
import { MainViewWorkflow } from '../Pages/MainComponents/MainView/MainViewModel';

const INVALID_USER = 'Invalid User';
export default class HLAAService {
    public static async startHLAA(
        patientId: string,
        testId: string,
        side: string,
        modelId: number
    ): Promise<void> {
        const res = await axios.post(
            process.env.REACT_APP_EASYFIT_API_URL +
                `/HLAA/StartHLAA?testId=${testId}&activeSide=${side}&modelId=${modelId}`,
            null,
            ServiceConfigFactory.CreateAxiosConfigWithAuth(
                await AuthService.adB2cInstance.acquireAccessToken()
            )
        );

        if (res.status < 300) {
            return;
        }
        return Promise.reject('Fail to start HLAA test ' + res.statusText);
    }

    public static async getHLAAState(testId: string): Promise<HLAAState> {
        const res = await axios.get(
            process.env.REACT_APP_EASYFIT_API_URL +
                `/HLAA/HLAAState?testId=${testId}`,
            ServiceConfigFactory.CreateAxiosConfigWithAuth(
                await AuthService.adB2cInstance.acquireAccessToken()
            )
        );

        if (res.status === 200 && res.data !== null) {
            return res.data;
        }
        return Promise.reject(
            'Error occurred when getHLAAState: ' + res.statusText
        );
    }

    public static async identifyThreshold(
        testId: string,
        numberOfToneHeard: number,
        is0ToneFirstRun: boolean
    ): Promise<void> {
        const res = await axios.post(
            process.env.REACT_APP_EASYFIT_API_URL +
                `/HLAA/IdentifyThreshold?testId=${testId}&numberOfToneHeard=${numberOfToneHeard}&is0ToneFirstRun=${is0ToneFirstRun}`,
            null,
            ServiceConfigFactory.CreateAxiosConfigWithAuth(
                await AuthService.adB2cInstance.acquireAccessToken()
            )
        );

        if (res.status < 300) {
            return;
        }
        return Promise.reject('Fail to identify threshold ' + res.statusText);
    }

    public static async getHLAAAudiogram(
        testId: string
    ): Promise<HLAAAudiogram[]> {
        const res = await axios.get(
            process.env.REACT_APP_EASYFIT_API_URL +
                `/HLAA/Audiogram?testId=${testId}`,
            ServiceConfigFactory.CreateAxiosConfigWithAuth(
                await AuthService.adB2cInstance.acquireAccessToken()
            )
        );

        if (res.status === 200 && res.data !== null) {
            return res.data;
        }
        return Promise.reject(
            'Error occurred when getHLAAAudiogram: ' + res.statusText
        );
    }

    public static async switchHLAASide(
        testId: string,
        side: string
    ): Promise<void> {
        const res = await axios.put(
            process.env.REACT_APP_EASYFIT_API_URL +
                `/HLAA/SwitchSide?testId=${testId}&activeSide=${side}`,
            null,
            ServiceConfigFactory.CreateAxiosConfigWithAuth(
                await AuthService.adB2cInstance.acquireAccessToken()
            )
        );

        if (res.status < 300) {
            return;
        }
        return Promise.reject('Fail to switch HLAA side ' + res.statusText);
    }

    public static async getFrequencyCount(modelId: number): Promise<number> {
        const res = await axios.get(
            `${process.env.REACT_APP_EASYFIT_API_URL}/HLAA/GetFrequencyCount?modelId=${modelId}`,
            ServiceConfigFactory.CreateAxiosConfigWithAuth(
                await AuthService.adB2cInstance.acquireAccessToken()
            )
        );

        if (res.status < 300) {
            return res.data;
        }
        return Promise.reject('Fail to get frequency count ' + res.statusText);
    }

    public static async getResult(
        testId: string
    ): Promise<AudiogramResult | undefined> {
        const res = await axios.get(
            process.env.REACT_APP_EASYFIT_API_URL +
                `/HLAA/Result?testId=${testId}`,
            ServiceConfigFactory.CreateAxiosConfigWithAuth(
                await AuthService.adB2cInstance.acquireAccessToken()
            )
        );

        if (res.status === 200 && !(res.data === INVALID_USER)) {
            if (res.data) return res.data;
            else return undefined;
        } else if (res.data === INVALID_USER) {
            LoggingService.warn({
                componentName: 'HLAAService',
                args: [
                    `API returned status code 200 with text Invalid user for invalid user response`,
                ],
            });
            easyFitStore.dispatch(
                updateNotification({
                    ...easyFitStore.getState().notification,
                    alertMessage: {
                        title: t(
                            'common:Common_Feature:ErrorHandling:Invalid_User_Title'
                        ),
                        message: [
                            t(
                                'common:Common_Feature:ErrorHandling:Invalid_User_Text'
                            ),
                        ],
                        detailMessage: '',
                        userSelections: [
                            {
                                content: t('common:Common_Feature:ok'),
                                action: NotificationCloseHandlerType.UserAccountDeletedExit,
                            },
                        ],
                        isDisplayed: true,
                        workflow: MainViewWorkflow.AppFineTuning,
                    },
                })
            );

            return;
        }

        return Promise.reject(
            'Error occurred when get hlaa result: ' + res.statusText
        );
    }

    public static async applyInitialSettings(testId: string): Promise<void> {
        const res = await axios.put<void>(
            process.env.REACT_APP_EASYFIT_API_URL +
                `/HLAA/ApplyInitialSettings?testId=${testId}`,
            null,
            ServiceConfigFactory.CreateAxiosConfigWithAuth(
                await AuthService.adB2cInstance.acquireAccessToken()
            )
        );

        if (res.status === 200) {
            return;
        }
        return Promise.reject(
            'Error occurred when get hlaa result: ' + res.statusText
        );
    }
}
