import React from 'react';
import { CustomerServiceContactService } from '../../../Services/Configuration/CustomerServiceContactService';
import { withTracking } from '../../../Services/Monitoring/ApplicationInsights';
import { ButtonType } from '../../../SharedComponents/Button/ButtonType';
import CustomButton from '../../../SharedComponents/Button/CustomButton';
import AppContainer from '../../../SharedComponents/Container/AppContainer';
import BodyContainer from '../../../SharedComponents/Container/BodyContainer';
import CustomImg from '../../../SharedComponents/CustomImage/CustomImg';
import Header from '../../../SharedComponents/Header/HeaderRefactor';
import PairButton from '../../../SharedComponents/Button/PairButton';
import CustomTypography, {
    TypographyType,
} from '../../../SharedComponents/Typography/CustomTypography';
import './SevereHearingLossPage.scss';
import useSevereHearingLossHandler from './SevereHearingLossPageHandlerHook';
import useSevereHearingLossPageResource from './SevereHearingLossPageResourceHook';

const SevereHearingLossPage = () => {
    const {
        header,
        instructionImage,
        customerServiceImage,
        customerServiceLink,
        instructionHeader,
        instruction1,
        instruction2,
        buttons,
        linkCallBacks,
    } = useSevereHearingLossPageResource();

    const { primaryAction, secondaryAction } = useSevereHearingLossHandler();

    const getCustomerServiceSupportLink = () => {
        switch (
            CustomerServiceContactService.getCountrySpecificPrimaryContactType()
        ) {
            case 'phone':
                return (
                    <CustomButton
                        id={`btn-contact-customer-service`}
                        buttonType={ButtonType.Primary}
                        withDefaultContainer={false}
                        className={'customerServiceSupportButtonSevereHearing'}
                    >
                        <CustomTypography
                            type={TypographyType.notoSansBody16px}
                            text={CustomerServiceContactService.getCountrySpecificPrimaryContact()}
                        />
                    </CustomButton>
                );
            case 'email':
                return (
                    <CustomTypography
                        type={TypographyType.notoSansBody16px}
                        text={CustomerServiceContactService.getCountrySpecificPrimaryContact()}
                        className={'customerServiceSupportLinkSevereHearing'}
                    />
                );
            case 'contactCustomerService':
                return (
                    <CustomTypography
                        type={TypographyType.notoSansBody16px}
                        id={customerServiceLink.id}
                        text={customerServiceLink.text}
                        linkCallBacks={linkCallBacks}
                        className={
                            'solutionCardLink customerServiceSupportLinkSevereHearing'
                        }
                    />
                );
        }
    };

    return (
        <AppContainer>
            <Header headerContent={header} />
            <BodyContainer>
                <CustomTypography
                    type={TypographyType.notoSansBody16px}
                    text={instruction1.text}
                    id={instruction1.id}
                    className={'severeHLinstruction'}
                />
                <CustomImg
                    id={instructionImage.id}
                    imgType={'small'}
                    src={instructionImage.src}
                    alt={instructionImage.alt}
                />
                <CustomTypography
                    type={TypographyType.notoSansBody16px}
                    text={instruction2.text}
                    id={instruction2.id}
                    className={'severeHLinstruction'}
                />
                <div
                    className={'solutionCardSevereHearing'}
                    id="customer-service-card"
                >
                    <CustomTypography
                        type={TypographyType.notoSansBody16px}
                        text={instructionHeader.text}
                        id={instructionHeader.id}
                        className={'solutionCardTitle'}
                    />
                    <CustomImg
                        id={customerServiceImage.id}
                        imgType={'icon'}
                        src={customerServiceImage.src}
                        alt={customerServiceImage.alt}
                        className={'customerServiceImageSevereHearing'}
                    />
                    {getCustomerServiceSupportLink()}
                </div>
            </BodyContainer>
            <PairButton
                withLoading
                primaryButton={{
                    children: buttons.primary.label,
                    id: buttons.primary.id,
                    onClick: primaryAction,
                }}
                secondaryButton={{
                    children: buttons.secondary.label,
                    id: buttons.secondary.id,
                    onClick: secondaryAction,
                }}
            />
        </AppContainer>
    );
};

export default withTracking(SevereHearingLossPage);
