import React from 'react';
import Header from '../../../../SharedComponents/Header/HeaderRefactor';
import { withTracking } from '../../../../Services/Monitoring/ApplicationInsights';
import AppContainer from '../../../../SharedComponents/Container/AppContainer';
import BodyContainer from '../../../../SharedComponents/Container/BodyContainer';
import { ButtonType } from '../../../../SharedComponents/Button/ButtonType';
import CustomButton from '../../../../SharedComponents/Button/CustomButton';
import CustomImg from '../../../../SharedComponents/CustomImage/CustomImg';
import CustomTypography, {
    TypographyType,
} from '../../../../SharedComponents/Typography/CustomTypography';
import useHearingProfileResultPageResource from './HearingProfileResultPageResourceHook';
import './HearingAssessmentResultPage.scss';
import useInitialSettingApplyHandler from '../SideApplication/InitialSettingApplyHandlerHook';

function HearingAssessmentResultPage() {
    const { header, title, instructionImage, instruction, continueButton } =
        useHearingProfileResultPageResource();

    const { onClickApply } = useInitialSettingApplyHandler();

    return (
        <AppContainer>
            <Header headerContent={header} />
            <BodyContainer>
                <CustomTypography
                    type={TypographyType.notoSansSubHeading}
                    text={title.text}
                    textAlign="center"
                    id={title.id}
                    className={'titleMarginHearingAssessmentResult'}
                />
                <CustomImg
                    id={instructionImage.id}
                    imgType={'small'}
                    src={instructionImage.src}
                    alt={instructionImage.alt}
                    className={'imageMarginHearingAssessmentResult'}
                />
                <CustomTypography
                    type={TypographyType.notoSansBody18px}
                    id={instruction.id}
                    text={instruction.text}
                    className={'marginBottomHearingAssessmentResult'}
                />
            </BodyContainer>
            <CustomButton
                buttonType={ButtonType.PrimaryLoading}
                id={continueButton.id}
                onClick={async () => {
                    await onClickApply();
                }}
            >
                {continueButton.label}
            </CustomButton>
        </AppContainer>
    );
}

export default withTracking(HearingAssessmentResultPage);
