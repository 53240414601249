import React from 'react';
import { HTMLAttributes } from 'react';
import { joinMultipleStyle } from '../../Utils/ScssUtils';
import TextAreaContainer from '../Container/TextAreaContainer';
import { createTypoFromStyle } from './TypographyFactory';
import './TypographyStyle.scss';

export enum TypographyType {
    comfortaaBody = 'comfortaaBody',
    comfortaaHeaderHeading = 'comfortaaHeaderHeading',
    comfortaaHeading = 'comfortaaHeading',
    comfortaaSubHeading = 'comfortaaSubHeading',
    notoSansBody20px = 'notoSansBody20px',
    notoSansBody18px = 'notoSansBody18px',
    notoSansBody16px = 'notoSansBody16px',
    notoSansBody14px = 'notoSansBody14px',
    notoSansBody12px = 'notoSansBody12px',
    notoSansHeading = 'notoSansHeading',
    notoSansSubHeading = 'notoSansSubHeading',
}

export interface ICustomTypography
    extends Omit<HTMLAttributes<HTMLDivElement>, 'children'> {
    type: TypographyType;
    text: string;
    textAlign?:
        | 'left'
        | 'right'
        | 'center'
        | 'top'
        | 'bottom'
        | 'top-left'
        | 'top-right'
        | 'bottom-left'
        | 'bottom-right';
    noMargin?: boolean;
    linkCallBacks?: LinkCallBacks;
    textWrap?: 'wrap' | 'no-wrap';
}

export type LinkCallBacks = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: (...args: any) => void | Promise<void>;
};

function CustomTypography({
    type,
    text,
    textAlign = 'center',
    textWrap = 'wrap',
    noMargin,
    linkCallBacks,
    className,
    ...others
}: ICustomTypography): JSX.Element {
    const typos = createTypoFromStyle(text, linkCallBacks);
    const textAlignCss = {
        left: 'textLeftAlign',
        right: 'textRightAlign',
        center: '',
        top: 'textRightAlign',
        bottom: 'textBottomAlign',
        'top-left': 'textTopLeftAlign',
        'top-right': 'textTopRightAlign',
        'bottom-left': 'textBottomRightAlign',
        'bottom-right': 'textBottomRightAlign',
    };
    const textWrapCss = {
        wrap: 'textWithWrap',
        'no-wrap': 'textNoWrap',
    };

    if (noMargin) {
        className = `textNoMargin ${textAlignCss[textAlign]} ${textWrapCss[textWrap]} ${className}`;
    } else {
        className = `${textAlignCss[textAlign]} ${textWrapCss[textWrap]} ${className}`;
    }
    //other property will only be injected to the container wrapper to act as a block element, as inject same call back
    //to all inner non block may cause unwanted effect, especially with event listener
    //This component focus on rendering text only, so it will ignore all other children
    //Styling is used in container level to take advantage of inheritance of text styling
    return (
        <TextAreaContainer
            className={joinMultipleStyle(type, className)}
            {...others}
        >
            {typos}
        </TextAreaContainer>
    );
}

export default CustomTypography;
