import React from 'react';
import { withTracking } from '../../../../Services/Monitoring/ApplicationInsights';
import CustomTypography, {
    TypographyType,
} from '../../../Typography/CustomTypography';
import useMobileDeviceUnmutedCheckTroubleshootingPageResource from './MobileDeviceUnmutedCheckTroubleshootingPageResourceHook';
import useMobileDeviceUnmutedCheckTroubleshootingPageHandler from './MobileDeviceUnmutedCheckTroubleshootingPageHandlerHook';
import PairButton from '../../../Button/PairButton';
import BodyContainer from '../../../Container/BodyContainer';
import './MobileDeviceUnmutedCheckTroubleshootingPage.scss';

function MobileDeviceUnmutedCheckTroubleshootingPage() {
    const { infoText, buttons } =
        useMobileDeviceUnmutedCheckTroubleshootingPageResource();

    const { primaryAction, secondaryAction } =
        useMobileDeviceUnmutedCheckTroubleshootingPageHandler();

    return (
        <>
            <BodyContainer>
                <CustomTypography
                    id={infoText.id}
                    type={TypographyType.notoSansBody16px}
                    text={infoText.text}
                />
            </BodyContainer>
            <PairButton
                primaryButton={{
                    children: buttons.primary.label,
                    id: buttons.primary.id,
                    onClick: primaryAction,
                    className:
                        'MobileDeviceUnmutedCheckTroubleshootingPagePairButton',
                }}
                secondaryButton={{
                    children: buttons.secondary.label,
                    id: buttons.secondary.id,
                    onClick: secondaryAction,
                    className:
                        'MobileDeviceUnmutedCheckTroubleshootingPagePairButton',
                }}
            />
        </>
    );
}

export default withTracking(MobileDeviceUnmutedCheckTroubleshootingPage);
