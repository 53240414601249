import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { OneSideSetUpResource } from '../../../../Pages/MainComponents/OneSideSetUp/OneSideSetUp';
import { HeaderActionType } from '../../../Header/HeaderActionType';
import { RedoEasyFitContext } from '../RedoEasyFitContext';

export default function useRedoEasyFitOneSideSetUpResource(): OneSideSetUpResource {
    const { t } = useTranslation();
    const { info } = useContext(RedoEasyFitContext);

    const header = {
        id: 'header-redo-easyFit-oneSide-setup',
        text: t(
            'orionApp:OrionApp_Workflow:RedoAssessment_Feature:OneSideSetup:Header'
        ),
        type: HeaderActionType.Menu,
    };

    const image = {
        id: `img-${info.current.activeSide.toLowerCase()}`,
        src: `/resources/sharedAcrossWorkflows/wait-asset.svg`,
        alt: `${info.current.activeSide.toLowerCase()}`,
    };

    const progress = {
        id: 'progress-loading',
    };
    const infoText = {
        id: 'txt-info',
        text: t('hlaa:Hlaa_Feature:Setup:Text'),
    };

    return {
        header,
        image,
        progress,
        infoText,
    };
}
