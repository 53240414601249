import { VoidReturn } from '../../Utils/PageUtils';

export type CarouselHandler = {
    step: number;
    handleForwardAction: () => VoidReturn;
    handleBackwardAction: () => VoidReturn;
    handleOnChangeIndex: (newStep: number) => VoidReturn;
};

function useCarouselHook(
    totalSteps: number,
    step: number,
    setStep: (newStep: number) => void
): CarouselHandler {
    function handleForwardAction() {
        const newStep = step < totalSteps - 1 ? step + 1 : totalSteps - 1;
        setStep(newStep);
    }

    function handleBackwardAction() {
        const newStep = step > 0 ? step - 1 : 0;
        setStep(newStep);
    }

    function handleOnChangeIndex(newStep: number) {
        setStep(newStep);
    }

    return {
        step,
        handleForwardAction,
        handleBackwardAction,
        handleOnChangeIndex,
    };
}

export default useCarouselHook;
