import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { deviceModel } from '../../../Models/SupportedDeviceModel';
import { getUserDeviceModel } from '../../../Services/ResourceService';
import { CarouselFooterContent } from '../../../SharedComponents/Carousel/CarouselFooter';
import { HeaderActionType } from '../../../SharedComponents/Header/HeaderActionType';
import { switchBasedOnDevice } from '../../../Utils/PageUtils';
import { PairingCarouselResource } from '../PairingCarouselPage';
import { PairingContext } from '../PairingContext';
import { replaceSideWithText } from '../PairingUtils';
import pairingOneSideConfig from './PairingOneSideResource.json';

function usePairingOneSideResource(
    currentStep: number
): PairingCarouselResource {
    const {
        state: { context },
    } = useContext(PairingContext);
    const { t } = useTranslation();

    function isOrionC20Device(): boolean {
        return getUserDeviceModel() === deviceModel.OrionC20;
    }

    const generalKey = switchBasedOnDevice(getUserDeviceModel(), {
        OrionAir: 'orionAir:OrionAir_Device:Pairing_Feature:OneSide',
        OrionC20: 'orionC20:OrionC20_Device:Pairing_Feature:OneSide',
    }) as string;

    const imgSrcList: string[] = [];
    const instructionList: CarouselFooterContent[] = [];
    //orion air header prefix config
    const getSubHeaderNumber = (step: number) => {
        switch (step) {
            case 0:
                return 1;
            case 1:
                return 2;
            case 2:
            case 3:
                return 3;
            case 4:
            case 5:
                return 4;
            default:
                return 0;
        }
    };

    const header = {
        id: 'header-pairing-one-side',
        text: t(
            `${generalKey}:Header:SubHeader${getSubHeaderNumber(
                isOrionC20Device() ? currentStep + 1 : currentStep
            )}`,
            replaceSideWithText(t, context.activeSide)
        ),
        type: HeaderActionType.Menu,
    };

    for (
        let index = 1;
        index <= pairingOneSideConfig.OrionAir.totalStep;
        index++
    ) {
        if (isOrionC20Device() && index == 1) continue;

        imgSrcList.push(
            getUserDeviceModel() === deviceModel.OrionAir
                ? `/resources/OrionAir/${context.activeSide}-paring-${index}.svg`
                : `/resources/OrionC20/${context.activeSide}-paring-${index}.svg`
        );

        // Form the dynamic key
        let dynamicKey = `${generalKey}:Text${index}`;
        if (index == 2 || index == 3) {
            dynamicKey = `${generalKey}:Text${index}${context.activeSide.toUpperCase()}`;
        }

        console.log('Dynamic Key:', dynamicKey);

        const instructionContent = t(
            dynamicKey,
            replaceSideWithText(t, context.activeSide)
        );

        console.log('Instruction Content:', instructionContent);

        instructionList.push({
            instructionContent: instructionContent,
        });
    }

    const pairingButton = {
        id: `btn-pair-${context.activeSide}`,
        label: t(`${generalKey}:NextButton`),
    };

    const title = {
        id: 'txt-title',
        text: t(
            `pairing:Pairing_Feature:${
                context.activeSide === 'left' ? 'Left' : 'Right'
            }Title`
        ),
    };

    return {
        header,
        imgSrcList,
        instructionList,
        button: pairingButton,
        title,
    };
}

export default usePairingOneSideResource;
