import React, { useContext } from 'react';
import Header from '../../../../SharedComponents/Header/HeaderRefactor';
import useSpeechComfortTestDetailsPageHandler from './SpeechComfortTestDetailPageHandlerHook';
import CustomTypography, {
    TypographyType,
} from '../../../../SharedComponents/Typography/CustomTypography';
import FlexContainer from '../../../../SharedComponents/Container/FlexContainer';
import CustomImg from '../../../../SharedComponents/CustomImage/CustomImg';
import CustomSlider, {
    SlideType,
} from '../../../../SharedComponents/Slider/CustomSlider';
import AppContainer from '../../../../SharedComponents/Container/AppContainer';
import CustomButton from '../../../../SharedComponents/Button/CustomButton';
import { ButtonType } from '../../../../SharedComponents/Button/ButtonType';
import { SpeechComfortTestContext } from '../SpeechComfortTestContext';
import useSpeechComfortTestDetailPageResource from './SpeechComfortTestDetailPageResourceHook';
import './SpeechComfortTestDetailPage.scss';
import BodyContainer from '../../../../SharedComponents/Container/BodyContainer';

export default function SpeechComfortTestDetailPage(): React.ReactElement {
    const { info } = useContext(SpeechComfortTestContext);

    const {
        goFinishAction,
        onChangeLeftSlider,
        onChangeRightSlider,
        onChangeCommittedLeftSlider,
        onChangeCommittedRightSlider,
        getSliderStateTypeForSide,
        handleSliderStart,
    } = useSpeechComfortTestDetailsPageHandler();

    const {
        header,
        instructionImage,
        instructionImageText,
        instructionText,
        button,
    } = useSpeechComfortTestDetailPageResource();

    return (
        <AppContainer>
            <Header headerContent={header} disabled={info.loading} />
            <FlexContainer flexRow={true}>
                <CustomSlider
                    id={`slider-left`}
                    type={SlideType.sideLeft}
                    sliderState={getSliderStateTypeForSide('left')}
                    handleSliderChange={onChangeLeftSlider}
                    handleSliderChangeCommitted={onChangeCommittedLeftSlider}
                    onTouchStart={handleSliderStart}
                    loadingPage={info.loading}
                />
                <FlexContainer>
                    <CustomImg
                        imgType="mediumSmall"
                        id={instructionImage.id}
                        src={instructionImage.src}
                        alt={instructionImage.alt}
                        className={'speechComfortDetailPageImage'}
                    />
                    <CustomTypography
                        type={TypographyType.notoSansSubHeading}
                        id={instructionImageText.id}
                        text={instructionImageText.text}
                        className={'speechComfortDetailPageImageText'}
                    />
                </FlexContainer>
                <CustomSlider
                    id={`slider-right`}
                    type={SlideType.sideRight}
                    sliderState={getSliderStateTypeForSide('right')}
                    handleSliderChange={onChangeRightSlider}
                    handleSliderChangeCommitted={onChangeCommittedRightSlider}
                    onTouchStart={handleSliderStart}
                    loadingPage={info.loading}
                />
            </FlexContainer>
            <BodyContainer>
                <CustomTypography
                    type={TypographyType.notoSansBody14px}
                    textAlign={'center'}
                    id={instructionText.id}
                    text={instructionText.text}
                />
            </BodyContainer>
            <CustomButton
                buttonType={ButtonType.PrimaryLoading}
                id={button.id}
                onClick={goFinishAction}
                disabled={info.loading}
            >
                {button.label}
            </CustomButton>
        </AppContainer>
    );
}
