import { t } from '../../../../../Services/LocalizationService';
import { IFineTuningAfterApplyPage } from './FineTuningAfterApplyPage';

type FineTuningAfterApplyPageResource = {
    solutionText: {
        id: string;
        text: string;
    };

    circularProgress: {
        id: string;
        text: string;
    };
};

export default function useFineTuningAfterApplyPageResource(
    props: IFineTuningAfterApplyPage
): FineTuningAfterApplyPageResource {
    const solutionText = {
        id: 'txt-solutionText',
        text: `${t('fineTuning:FineTuning_Feature:Solution:Text12')} 0${
            props.solutionNumber
        }`,
    };

    const circularProgress = {
        id: 'progress-apply-page',
        text: t('fineTuning:FineTuning_Feature:Solution:Text14'),
    };
    return {
        solutionText,
        circularProgress,
    };
}
