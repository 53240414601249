export enum FineTuningSolutionState {
    BeforeApply,
    Applying,
    AfterApply,
    AppliedSuccessfully,
    Waiting,
    ChangeSleeveRecommend,
    CustomerService,
    Warning,
    WarningCustomerService,
    ChangeSleeveCustomerService,
}
