import { useTranslation } from 'react-i18next';
import ReturnToTimelineDialogResource from './ReturnToTimelineResource.json';
import { PartialDialogResource } from '../PartialDialog/PartialDialog';

function useReturnToTimelineResource(): PartialDialogResource {
    const { t } = useTranslation();

    const { returnTitleContent, returnMessageContent, buttons } =
        ReturnToTimelineDialogResource;

    const dialogTitle = {
        id: returnTitleContent.id,
        text: t(returnTitleContent.translationKey),
    };

    const dialogMessage = {
        id: returnMessageContent.id,
        text: t(returnMessageContent.translationKey),
    };

    const buttonLabels = {
        primaryLabel: {
            id: buttons.primary.id,
            label: t(buttons.primary.translationKey),
        },
        secondaryLabel: {
            id: buttons.secondary.id,
            label: t(buttons.secondary.translationKey),
        },
    };

    return {
        dialogTitle,
        dialogMessage,
        buttonLabels,
    };
}

export default useReturnToTimelineResource;
