import { useContext } from 'react';
import { MainViewContext } from '../../../../../Pages/MainComponents/MainView/MainViewContext';
import { TransitionAction } from '../../../../../Pages/MainComponents/MainView/MainViewModel';
import { CustomerServiceContactService } from '../../../../../Services/Configuration/CustomerServiceContactService';
import { VoidReturn } from '../../../../../Utils/PageUtils';
import { MenuContext } from '../../MenuContext';
import useMenuDialogHandler from '../../MenuDialogHandler';
import { MenuDialogType } from '../../MenuDialogType';
import { itemHandler } from '../../MenuUtils';

export type MainMenuHandler = {
    fineTuning: itemHandler;
    returnToTimeline: itemHandler;
    contactCustomerService: itemHandler;
    fAQ: itemHandler;
    community: itemHandler;
    legalInformation: itemHandler;
};

export default function useMainMenuHandler(
    onClose?: () => VoidReturn
): MainMenuHandler {
    const { pushMenu: renderNextMenu } = useContext(MenuContext);
    const viewContext = useContext(MainViewContext); // todo: move somewhere else?
    const { onClickExternalLinkMenu } = useMenuDialogHandler();

    return {
        fineTuning: () => {
            if (onClose) onClose();
            viewContext.doTransition(TransitionAction.GoFineTuning);
        },
        returnToTimeline: () => {
            if (onClose) onClose();
            viewContext.doTransition(
                TransitionAction.DoReturnToTimelineDialogOpen
            );
        },
        contactCustomerService: () => {
            if (onClose) onClose();
            onClickExternalLinkMenu(
                CustomerServiceContactService.getCountrySpecificOrionCustomerServiceContact(
                    false
                )
            );
        },
        fAQ: () => {
            if (onClose) onClose();
            onClickExternalLinkMenu(
                CustomerServiceContactService.getCountrySpecificOrionFAQLink(
                    false
                )
            );
        },
        community: () => {
            if (onClose) onClose();
            onClickExternalLinkMenu(
                CustomerServiceContactService.getCountrySpecificOrionCommunityLink(
                    false
                )
            );
        },
        legalInformation: () => renderNextMenu(MenuDialogType.LegalInformation),
    };
}
