import { ListItemType } from '../../../SharedComponents/List/SubMenuList';
import { useFineTuningContext } from '../FineTuningContext';

export type FineTuningSelectionHandler = {
    goStart: () => void;
    getSelectedItems: () => string[];
    itemClickHandler: (item: ListItemType) => void;
};

const useFineTuningSelectionPageHandler = (): FineTuningSelectionHandler => {
    const { info, send } = useFineTuningContext();
    const goStart = () => {
        send('goToStart');
    };

    const getSelectedItems = (): string[] => {
        return Object.values(info.current.selected);
    };

    const newCategorySelected = (newCategoryId: string) => {
        info.current.selected = {
            category: newCategoryId,
            problem: '-1',
        };
    };

    const itemClickHandler = (item: ListItemType) => {
        const list = item.id.split('-');
        const id = list[list.length - 1];
        if (info.current.isCategory) {
            if (info.current.selected.category !== id) newCategorySelected(id);
        } else {
            info.current.selected.problem = id;
        }

        goStart();
    };

    return {
        goStart,
        getSelectedItems,
        itemClickHandler,
    };
};

export default useFineTuningSelectionPageHandler;
