import { useContext } from 'react';
import { VoidReturn } from '../../../../Utils/PageUtils';
import { MainViewContext } from '../../MainView/MainViewContext';
import { TransitionAction } from '../../MainView/MainViewModel';

export type AppLandingPageHandler = {
    onContinue: () => VoidReturn;
    onReturn: () => VoidReturn;
};

export default function useAppLandingPageHandler(): AppLandingPageHandler {
    const viewContext = useContext(MainViewContext);
    const onContinue = async () => {
        viewContext.doTransition(TransitionAction.GoFineTuning);
    };
    const onReturn = async () => {
        viewContext.doTransition(TransitionAction.ExitInAppBrowser);
    };

    return { onContinue, onReturn };
}
