import { useTranslation } from 'react-i18next';
import { TroubleShootingResource } from '../../../../Pages/MainComponents/TroubleShooting/TroubleShooting';
import { HeaderActionType } from '../../../Header/HeaderActionType';

import troubleShootingResource from './RedoEasyFitTroubleShootingResource.json';

function useRedoEasyFitTroubleShootingResource(): TroubleShootingResource {
    const { t } = useTranslation();

    const {
        headerContent,
        imgContent,
        troubleShootingMessageContent,
        buttonContent,
        customerSupportMessageContent,
        HelpContent,
    } = troubleShootingResource;

    const header = {
        id: headerContent.id,
        text: t(headerContent.translationKey),
        type: HeaderActionType.Menu,
    };

    const image = imgContent;

    const troubleShootingMessage = {
        id: troubleShootingMessageContent.id,
        text: t(troubleShootingMessageContent.translationKey),
    };

    const buttonLabel = {
        id: buttonContent.id,
        label: t(buttonContent.translationKey),
    };

    const customerSupportMessage = {
        id: customerSupportMessageContent.id,
        text: t(customerSupportMessageContent.translationKey),
    };

    const help = {
        id: HelpContent.id,
        text: `${t(HelpContent.translationKey)}`,
    };

    return {
        header,
        image,
        troubleShootingMessage,
        buttonLabel,
        customerSupportMessage,
        help,
    };
}

export default useRedoEasyFitTroubleShootingResource;
