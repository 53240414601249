import {
    deviceModel,
    deviceModelLabel,
    deviceModelName,
} from '../Models/SupportedDeviceModel';
import LoggingService from './LoggingService';
import LocalStorageService from './LocalStorageService';

export function getUserDeviceModel(side = 'left'): deviceModel {
    const modelId = LocalStorageService.serviceInstance.getDeviceModelId(side);
    if (modelId == 6144007) {
        return deviceModel.OrionAir;
    } else if (modelId == 72780372515670) {
        return deviceModel.OrionC20;
    } else if (modelId == 540938247) {
        return deviceModel.OrionGo;
    } else if (modelId == 72024937728770) {
        return deviceModel.OrionComplete;
    }
    return deviceModel.OrionAir; //default
}

export function getDeviceIdFromParams(): number {
    const params = new URLSearchParams(location.search);
    const modelParam = params.get('leftModel');
    return modelParam ? Number(modelParam) : -1;
}

export function getUserDeviceModelName(side = 'left'): deviceModelName {
    let modelId = LocalStorageService.serviceInstance.getDeviceModelId(side);
    if (modelId == -1) {
        modelId = getDeviceIdFromParams();
    }
    if (modelId == 6144007) {
        return deviceModelName.OrionAir;
    } else if (modelId == 72780372515670) {
        return deviceModelName.OrionC20;
    } else if (modelId == 540938247) {
        return deviceModelName.OrionGo;
    }
    return deviceModelName.OrionAir;
}

export function getUserDeviceModelLabel(side = 'left'): deviceModelLabel {
    let modelId = LocalStorageService.serviceInstance.getDeviceModelId(side);
    if (modelId == -1) {
        modelId = getDeviceIdFromParams();
    }
    if (modelId == 6144007) {
        return deviceModelLabel.C10;
    } else if (modelId == 72780372515670) {
        return deviceModelLabel.C20;
    } else if (modelId == 540938247) {
        return deviceModelLabel.E10;
    }
    return deviceModelLabel.C10;
}

export function checkPairingStatus(side = 'both'): boolean {
    const leftDevice =
        LocalStorageService.serviceInstance.getDeviceModelId('left');
    const rightDevice =
        LocalStorageService.serviceInstance.getDeviceModelId('right');
    switch (side) {
        case 'left':
            return leftDevice !== -1;
        case 'right':
            return rightDevice !== -1;
        default:
            return leftDevice !== -1 && rightDevice !== -1;
    }
}

export function getMasterGainStepSize(): number {
    const deviceModelId = LocalStorageService.serviceInstance
        .getDeviceModelId('left')
        .toString();
    const supportedHis =
        LocalStorageService.serviceInstance.getSupportedHis() ?? [];
    const masterGainStepSize = supportedHis.find(
        (hi) => hi.hiUri == deviceModelId
    )?.masterGainStepSize;

    return masterGainStepSize ?? 1;
}

type ResourceConfig = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
};

class ResourcerService {
    public static getResourceByDevice(resource: ResourceConfig): Object {
        const device = getUserDeviceModel();

        function getArraysIntersection(arr1: string[], arr2: string[]) {
            return arr1.filter(function (n) {
                return arr2.indexOf(n) !== -1;
            });
        }

        if (
            getArraysIntersection(
                Object.keys(resource),
                Object.keys(deviceModel)
            ).length > 0
        ) {
            switch (device) {
                case deviceModel.OrionAir:
                    return resource[deviceModel.OrionAir];
                case deviceModel.OrionC20:
                    return resource[deviceModel.OrionC20];
                case deviceModel.OrionGo:
                    return resource[deviceModel.OrionGo];
                case deviceModel.OrionComplete:
                    return resource[deviceModel.OrionComplete];
                default:
                    LoggingService.error({
                        componentName: ResourcerService.name,
                        args: ['Device model is not supported'],
                    });
                    return resource;
            }
        }
        return resource;
    }
}

export default ResourcerService;
