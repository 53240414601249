import React from 'react';
import FlexContainer from '../Container/FlexContainer';
import { createStepper } from './StepperFactory';
import { IVerticalStepper } from './StepperModel';
import './StepperStyle.scss';

function VerticalStepper({
    stepContentType,
    ...props
}: IVerticalStepper): React.ReactElement {
    return (
        <FlexContainer className={'stepperArea'}>
            {createStepper(stepContentType, props)}
        </FlexContainer>
    );
}

export default VerticalStepper;
