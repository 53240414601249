import React, { useContext } from 'react';
import { ButtonType } from '../../../SharedComponents/Button/ButtonType';
import CustomButton from '../../../SharedComponents/Button/CustomButton';
import PairButton from '../../../SharedComponents/Button/PairButton';
import AppContainer from '../../../SharedComponents/Container/AppContainer';
import BodyContainer from '../../../SharedComponents/Container/BodyContainer';
import Header from '../../../SharedComponents/Header/HeaderRefactor';
import BulletList from '../../../SharedComponents/List/BulletList';
import CustomTypography, {
    TypographyType,
} from '../../../SharedComponents/Typography/CustomTypography';
import { HearingAssessmentState, HLAAContext } from '../HLAAContext';
import useHearingAssessmentInfoPageHandler from './HearingAssessmentInfoPageHandler';
import useHearingAssementInfoPageResource from './HearingAssessmentInfoPageResource';

export default function HearingAssessmentInfoPage(): React.ReactElement {
    const { hearingAssessmentState } = useContext(HLAAContext);
    const {
        header,
        infoPageButton,
        instruction1,
        instruction2,
        supportLinkInstruction,
        pairButtons,
    } = useHearingAssementInfoPageResource();
    const {
        constant0ToneHeardReach,
        linkCallBacks,
        primaryAction,
        secondaryAction,
        error,
    } = useHearingAssessmentInfoPageHandler();

    const renderInstructionText = () => {
        if (instruction2.text.includes('|')) {
            return (
                <BulletList
                    bulletItems={instruction2.text.split('|').filter(Boolean)}
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                />
            );
        } else {
            return (
                <CustomTypography
                    type={TypographyType.notoSansBody16px}
                    text={instruction2.text}
                    textAlign="center"
                    id={instruction2.id}
                    className={'textInstruction2'}
                    noMargin
                />
            );
        }
    };

    return (
        <AppContainer>
            <Header headerContent={header} />
            <BodyContainer flexStart>
                <CustomTypography
                    type={TypographyType.notoSansBody16px}
                    noMargin
                    text={instruction1.text}
                    id={instruction1.id}
                    textAlign="left"
                />
                {renderInstructionText()}

                <CustomTypography
                    type={TypographyType.notoSansBody16px}
                    text={error}
                    id={`error`}
                    hidden={error === ''}
                />
                {hearingAssessmentState ===
                HearingAssessmentState.Reinstruct ? (
                    <CustomTypography
                        id={supportLinkInstruction.id}
                        noMargin
                        type={TypographyType.notoSansBody16px}
                        text={supportLinkInstruction.text}
                        linkCallBacks={linkCallBacks}
                        textAlign="left"
                    />
                ) : (
                    <></>
                )}
            </BodyContainer>
            {hearingAssessmentState === HearingAssessmentState.Reinstruct ||
            hearingAssessmentState ===
                HearingAssessmentState.AbortMaxPresentedToneReach ? (
                <PairButton
                    withLoading
                    primaryButton={{
                        children: pairButtons.primary.label,
                        id: pairButtons.primary.id,
                        onClick: primaryAction,
                    }}
                    secondaryButton={{
                        children: pairButtons.secondary.label,
                        id: pairButtons.secondary.id,
                        onClick: secondaryAction,
                    }}
                />
            ) : (
                <CustomButton
                    id={infoPageButton.id}
                    buttonType={ButtonType.Primary}
                    onClick={constant0ToneHeardReach}
                >
                    {infoPageButton.text}
                </CustomButton>
            )}
        </AppContainer>
    );
}
