import { Step, StepConnector, StepLabel, Stepper } from '@material-ui/core';
import React from 'react';
import FlexContainer from '../../Container/FlexContainer';
import CustomImg from '../../CustomImage/CustomImg';
import CustomTypography, {
    TypographyType,
} from '../../Typography/CustomTypography';
import { VerticalStepperProps } from '../StepperModel';
import '../StepperStyle.scss';

function IconStepper({
    activeStep,
    stepContents,
    showActiveStepText,
    showCompletedStepIcon,
}: VerticalStepperProps): React.ReactElement {
    return (
        <Stepper
            id="stepper-workflow"
            className={'stepperWorkflow'}
            activeStep={activeStep}
            orientation="vertical"
            connector={
                <StepConnector
                    classes={{
                        line: 'connectorLine connectorLineIcon',
                        vertical: 'connectorVertical connectorVerticalIcon',
                        completed: 'connectorCompleted',
                    }}
                />
            }
        >
            {stepContents.map((stepContent, index, { length }) => (
                <Step
                    key={index}
                    expanded={true}
                    className={stepContent.disabled ? 'disabled' : ''}
                >
                    <StepLabel
                        icon={
                            <CustomImg
                                id={`img-stepper-${stepContent.stepLabel}`}
                                imgType="iconMediumLarge"
                                src={
                                    stepContent.index < activeStep
                                        ? stepContent.stepIconCompleted
                                        : stepContent.stepIcon
                                }
                                alt={`img-stepper-${stepContent.stepLabel}`}
                            />
                        }
                    >
                        <FlexContainer flexRow className={'stepperLabelArea'}>
                            <CustomTypography
                                id={`txt-stepper-label-${stepContent.stepLabel}`}
                                type={TypographyType.notoSansBody16px}
                                text={stepContent.stepLabelContent}
                                className={'stepperLabelTextIcon'}
                                textAlign={'left'}
                            />
                            {showCompletedStepIcon && (
                                <CustomImg
                                    hidden={!(stepContent.index < activeStep)}
                                    style={{
                                        height: '4vh',
                                        margin: '0 auto auto 0',
                                    }}
                                    id={`img-tickMark-${stepContent.stepLabel}`}
                                    src={`/resources/sharedAcrossWorkflows/tick-mark.svg`}
                                    alt={stepContent.stepLabelContent}
                                />
                            )}
                            {showActiveStepText &&
                                (activeStep === index ||
                                    (length - 1 === index &&
                                        activeStep - 1 === index)) && (
                                    <CustomTypography
                                        id={`txt-stepper-label-${stepContent.stepLabel}`}
                                        type={TypographyType.notoSansBody16px}
                                        text={
                                            stepContent.currentStepIndicatorText ??
                                            ''
                                        }
                                        className={'activeStepText'}
                                        textAlign={'left'}
                                    />
                                )}
                        </FlexContainer>
                    </StepLabel>
                </Step>
            ))}
        </Stepper>
    );
}

export default IconStepper;
