import React, { HTMLAttributes } from 'react';
import { joinMultipleStyle } from '../../Utils/ScssUtils';
import './ContainerStyle.scss';

export interface IActionContainerProps extends HTMLAttributes<HTMLDivElement> {
    sticky?: boolean;
}

function ActionContainer({
    sticky = true,
    className,
    ...others
}: IActionContainerProps): React.ReactElement {
    className = sticky ? joinMultipleStyle('sticky', className) : className;
    return (
        <div
            className={joinMultipleStyle('actionContainer', className)}
            {...others}
        />
    );
}

export default ActionContainer;
