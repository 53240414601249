import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import LocalStorageService from '../../../Services/LocalStorageService';
import PatientManagementService from '../../../Services/PatientManagementService';
import CompletedWorkflowService, {
    Workflow,
} from '../../../Utils/CompletedWorkflow';
import { VoidReturn } from '../../../Utils/PageUtils';
import { MainViewContext } from '../MainView/MainViewContext';
import { TransitionAction } from '../MainView/MainViewModel';

type ContraindicationHandler = {
    agreeOnTerms: boolean;
    handleCheckboxChange: (
        event: React.ChangeEvent<HTMLInputElement>
    ) => VoidReturn;
    onContinue: () => VoidReturn;
};

function useContraindicationHandler(): ContraindicationHandler {
    const initializeWithTermsAgreed = useRef(false);
    const [agreeOnTerms, setAgreeOnTerms] = useState(false);
    const viewContext = useContext(MainViewContext);

    const loading = useCallback(async () => {
        const completedState =
            LocalStorageService.serviceInstance.getCompletedWorkflow();
        initializeWithTermsAgreed.current =
            CompletedWorkflowService.isCompleted(
                completedState,
                Workflow.Contraindication
            );
        setAgreeOnTerms(initializeWithTermsAgreed.current);
    }, []);

    useEffect(() => {
        loading();
    }, [loading]);

    const handleCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setAgreeOnTerms(event.target.checked);
    };

    const onContinue = async () => {
        await PatientManagementService.endWorkflow(Workflow.Contraindication);
        viewContext.doTransition(TransitionAction.ContraindicationContinue);
    };

    return {
        agreeOnTerms,
        handleCheckboxChange,
        onContinue,
    };
}

export default useContraindicationHandler;
