import { ImageResource } from '../../../../../Utils/PageUtils';
import { t } from '../../../../../Services/LocalizationService';

type FTFooterCustomerServicePageResource = {
    button: {
        id: string;
        text: string;
    };

    image: ImageResource;
};

export default function useFTFooterCustomerServicePageResource(): FTFooterCustomerServicePageResource {
    const button = {
        id: 'btn-footer-home',
        text: t('fineTuning:FineTuning_Feature:Solution:Text6'),
    };

    const image = {
        id: 'img-footer-home',
        src: '/resources/fineTuningWorkflow/home-icon-for-button.svg',
        alt: 'home',
    };

    return {
        button,
        image,
    };
}
