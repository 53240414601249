import React, { useState } from 'react';
import { withTracking } from '../../../Services/Monitoring/ApplicationInsights';
import { TextResource } from '../../../Utils/PageUtils';
import BodyContainer from '../../Container/BodyContainer';
import CustomTypography, {
    TypographyType,
} from '../../Typography/CustomTypography';
import CustomDialog from '../CustomDialog';
import { IDialogContentProps } from '../DialogUtils';
import './MobileDeviceUnmutedCheckDialog.scss';
import FlexContainer from '../../Container/FlexContainer';
import CustomIconButton from '../../Button/CustomIconButton';
import {
    MobileDeviceUnmutedCheckContext,
    IMobileDeviceUnmutedCheckContext,
} from './MobileDeviceUnmutedCheckContext';
import { MobileDeviceUnmutedCheckState } from './MobileDeviceUnmutedCheckModel';
import MobileDeviceUnmutedCheckStartPage from './Start/MobileDeviceUnmutedCheckStartPage';
import MobileDeviceUnmutedCheckPlayPage from './Play/MobileDeviceUnmutedCheckPlayPage';
import MobileDeviceUnmutedCheckConfirmationPage from './Confirmation/MobileDeviceUnmutedCheckConfirmationPage';
import MobileDeviceUnmutedCheckDonePage from './Done/MobileDeviceUnmutedCheckDonePage';
import MobileDeviceUnmutedCheckTroubleshootingPage from './Troubleshooting/MobileDeviceUnmutedCheckTroubleshootingPage';

export type MobileDeviceUnmutedCheckDialogResource = {
    dialogTitle: TextResource;
};

export interface IMobileDeviceUnmutedCheckDialogProps
    extends IDialogContentProps {
    open: boolean;
    onClose: () => void;
    useResource: () => MobileDeviceUnmutedCheckDialogResource;
}

function MobileDeviceUnmutedCheckDialog(
    props: IMobileDeviceUnmutedCheckDialogProps
) {
    const { onClose, useResource, open } = props;

    const { dialogTitle } = useResource();

    const [mobileDeviceUnmutedCheckState, setMobileDeviceUnmutedCheckState] =
        useState(MobileDeviceUnmutedCheckState.Start);

    const initialValue: IMobileDeviceUnmutedCheckContext = {
        currentMobileDeviceUnmutedCheckState: mobileDeviceUnmutedCheckState,
        setCurrentMobileDeviceUnmutedCheckState:
            setMobileDeviceUnmutedCheckState,
        onClose: onClose,
    };

    const renderSwitch = (
        mobileDeviceUnmutedCheckState: MobileDeviceUnmutedCheckState
    ) => {
        window.scrollTo(0, 0);
        switch (mobileDeviceUnmutedCheckState) {
            case MobileDeviceUnmutedCheckState.Start:
                return (
                    <MobileDeviceUnmutedCheckStartPage></MobileDeviceUnmutedCheckStartPage>
                );
            case MobileDeviceUnmutedCheckState.Play:
                return (
                    <MobileDeviceUnmutedCheckPlayPage></MobileDeviceUnmutedCheckPlayPage>
                );
            case MobileDeviceUnmutedCheckState.Confirmation:
                return (
                    <MobileDeviceUnmutedCheckConfirmationPage></MobileDeviceUnmutedCheckConfirmationPage>
                );
            case MobileDeviceUnmutedCheckState.Done:
                return (
                    <MobileDeviceUnmutedCheckDonePage></MobileDeviceUnmutedCheckDonePage>
                );
            case MobileDeviceUnmutedCheckState.Troubleshooting:
                return (
                    <MobileDeviceUnmutedCheckTroubleshootingPage></MobileDeviceUnmutedCheckTroubleshootingPage>
                );
            default:
                return <></>;
        }
    };

    return (
        <MobileDeviceUnmutedCheckContext.Provider value={initialValue}>
            <CustomDialog
                open={open}
                fullScreen={false}
                hideBackdrop={false}
                PaperProps={{
                    style: {
                        margin: '16px',
                    },
                }}
                BackdropProps={{
                    style: {
                        background: 'black',
                        opacity: 0.5,
                    },
                }}
                style={{
                    position: 'fixed',
                    zIndex: 1301,
                }}
            >
                <BodyContainer
                    className={'MobileDeviceUnmutedCheckDialogBodyContainer'}
                >
                    <FlexContainer
                        flexRow
                        className={'MobileDeviceUnmutedCheckDialogHeaderArea'}
                    >
                        <CustomTypography
                            id={dialogTitle.id}
                            type={TypographyType.notoSansSubHeading}
                            text={dialogTitle.text}
                            className={
                                'MobileDeviceUnmutedCheckDialogTitleText'
                            }
                        />
                        <CustomIconButton
                            imgSrc={
                                mobileDeviceUnmutedCheckState ===
                                MobileDeviceUnmutedCheckState.Play
                                    ? `/resources/sharedAcrossWorkflows/Close_disabled.svg`
                                    : `/resources/sharedAcrossWorkflows/Close.svg`
                            }
                            disabled={
                                mobileDeviceUnmutedCheckState ===
                                MobileDeviceUnmutedCheckState.Play
                            }
                            onClick={onClose}
                            id={`btn-mobile-device-unmuted-check-close`}
                            className={
                                'MobileDeviceUnmutedCheckDialogCloseButton'
                            }
                        />
                    </FlexContainer>
                    {renderSwitch(mobileDeviceUnmutedCheckState)}
                </BodyContainer>
            </CustomDialog>
        </MobileDeviceUnmutedCheckContext.Provider>
    );
}

export default withTracking(MobileDeviceUnmutedCheckDialog);
