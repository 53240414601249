import React, { useContext, useLayoutEffect } from 'react';
import AppContainer from '../../../SharedComponents/Container/AppContainer';
import ButtonStickyContainer from '../../../SharedComponents/Container/ButtonStickyContainer';
import Header from '../../../SharedComponents/Header/HeaderRefactor';
import CustomizedCircularProgress from '../../../SharedComponents/Spinner/CustomizedCircularProgress';
import TopProgressBar from '../../../SharedComponents/TopProgressBar/TopProgressBar';
import '../HearingAssessmentStyle.scss';
import { HLAAContext } from '../HLAAContext';
import { HLAASharedView } from '../HLAASharedView';
import useHearingAssessmentOneSideHandler from './HearingAssessmentOneSideHandler';
import useHearingAssessmentOneSideResource from './HearingAssessmentOneSideResource';
import './HearingAssessmentOneSideStyle.scss';

export default function HearingAssessmentOneSide(): React.ReactElement {
    const { info } = useContext(HLAAContext);
    const { onPlayTone, getTopProgress } = useHearingAssessmentOneSideHandler();
    const {
        header,
        headImage,
        sideTypography,
        confirmTypography,
        circularProgress,
    } = useHearingAssessmentOneSideResource(info.current.activeSide);

    useLayoutEffect(() => {
        const doAction = async () => {
            await onPlayTone();
        };
        doAction();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AppContainer>
            <TopProgressBar progress={getTopProgress()} />
            <Header headerContent={header} disabled={true} />
            <HLAASharedView
                title={sideTypography}
                instruction={confirmTypography}
                img={headImage}
                withBubble
            />
            <ButtonStickyContainer>
                <CustomizedCircularProgress
                    id={circularProgress.id}
                    size={'12vh'}
                    loadingtext={circularProgress.text}
                />
            </ButtonStickyContainer>
        </AppContainer>
    );
}
