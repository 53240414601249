import { VoidReturn } from '../../../Utils/PageUtils';
import { HearingAssessmentState, HLAAContext } from '../HLAAContext';
import { useContext } from 'react';
import { ProgressBarCalculateCurrentProgress } from '../../../Utils/ProgressBarService';

export type HearingAssessmentInstructionHandler = {
    goToHLAATest: () => VoidReturn;
    getTopProgress: () => number;
};

export default function useHearingAssessmentInstructionHandler(): HearingAssessmentInstructionHandler {
    const { info, setHearingAssessmentState } = useContext(HLAAContext);

    const getTopProgress = (): number => {
        return ProgressBarCalculateCurrentProgress(
            info.current.totalPageCount,
            info.current.totalPagesForProgress
        );
    };

    const goToHLAATest = async () => {
        setHearingAssessmentState(HearingAssessmentState.SetUp);
    };

    return {
        goToHLAATest,
        getTopProgress,
    };
}
