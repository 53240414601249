import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { MainViewContext } from '../../../Pages/MainComponents/MainView/MainViewContext';
import {
    DialogState,
    MainViewWorkflow,
    TransitionAction,
} from '../../../Pages/MainComponents/MainView/MainViewModel';
import { WarningDialogHandler } from '../Warning/WarningDialog';
import useUpdateAppGlobalDialogResource from './UseUpdateAppGlobalDialogResourceHook';
import { PartialDialogHandler } from '../PartialDialog/PartialDialog';
import ReturnToTimelineStateService from '../../../Services/ReturnToTimelineService';
import { goToUrl } from '../../../Utils/NavigationUtils';
// import { PrivacyPolicyAndTermsAndConditionsLink } from '../../../CommonAssets/Configuration/PrivacyPolicyAndTermsAndConditions';
import ILeaveAppState from '../../../Redux/Models/LeaveApp/ILeaveAppState';
import { RootState } from '../../../Redux/Reducers/RootReducer';

export type GlobalDialogState = {
    pairingConnectionLostHandler: {
        display: boolean;
        useHandler: () => WarningDialogHandler;
    };
    accountDeletedHandler: {
        display: boolean;
        onClose: () => void;
    };
    deviceInfoCheckHandler: {
        display: boolean;
        onClose: () => void;
    };
    updateAppHandler: {
        display: boolean;
        useHandler: () => WarningDialogHandler;
        onClose: () => void;
        onContinueApple: () => void;
        onContinueAndroid: () => void;
    };
    returntoTimelineHandler: {
        display: boolean;
        useHandler: () => PartialDialogHandler;
    };
    mobileDeviceUnmutedCheckHandler: {
        display: boolean;
        onClose: () => void;
    };
    leaveAppHandler: {
        display: boolean;
        useHandler: () => PartialDialogHandler;
    };
    updateDisplay: (dialog: DialogState) => void;
};

function useGlobalDialogHandler(): GlobalDialogState {
    const [pairingConnectionLostDisplay, setPairingConnectionLostDisplay] =
        useState(false);
    const [accountDeletedDisplay, setAccountDeletedDisplay] = useState(false);
    const [deviceInfoCheckDisplay, setDeviceInfoCheckDisplay] = useState(false);
    const [updateAppDisplay, setUpdateAppDisplay] = useState(false);
    const [returnToTimelineDisplay, setReturnToTimelineDisplay] =
        useState(false);
    const [
        mobileDeviceUnmutedCheckDisplay,
        setMobileDeviceUnmutedCheckDisplay,
    ] = useState(false);
    const [leaveAppDisplay, setLeaveAppDisplay] = useState(false);
    const [loading, setLoading] = useState(false);
    const viewContext = useContext(MainViewContext);
    const { appleLink, androidLink } = useUpdateAppGlobalDialogResource();
    const leaveAppState = useSelector(
        (state: RootState) => state.leaveApp
    ) as ILeaveAppState;

    const updateDisplay = (dialog: DialogState) => {
        switch (dialog) {
            case DialogState.PairingConnectionLost:
                setPairingConnectionLostDisplay(true);
                break;
            case DialogState.DeviceInfoCheck:
                setDeviceInfoCheckDisplay(true);
                break;
            case DialogState.UpdateApp:
                setUpdateAppDisplay(true);
                break;
            case DialogState.ReturnToTimeline:
                setReturnToTimelineDisplay(true);
                break;
            case DialogState.MobileDeviceUnmutedCheck:
                setMobileDeviceUnmutedCheckDisplay(true);
                break;
            case DialogState.LeaveApp:
                setLeaveAppDisplay(true);
                break;
            case DialogState.None:
                setAccountDeletedDisplay(false);
                setPairingConnectionLostDisplay(false);
                setUpdateAppDisplay(false);
                setReturnToTimelineDisplay(false);
                setMobileDeviceUnmutedCheckDisplay(false);
                setLeaveAppDisplay(false);
        }
    };
    const onClosePairingConnectionLost = () => {
        setPairingConnectionLostDisplay(false);
        viewContext.doTransition(TransitionAction.DoRepeatPairing);
    };

    const onCloseUpdateApp = () => {
        setUpdateAppDisplay(false);
        viewContext.doTransition(TransitionAction.UpdateAppDialogExit);
    };

    const pairingConnectionLostHandler = {
        display: pairingConnectionLostDisplay,
        useHandler: () => {
            return {
                primaryAction: () => {
                    setLoading(true);
                    onClosePairingConnectionLost();
                    setLoading(false);
                },
                loading,
            };
        },
    };

    const updateAppHandler = {
        display: updateAppDisplay,
        useHandler: () => {
            return {
                primaryAction: () => {
                    setLoading(true);
                    onCloseUpdateApp();
                    setLoading(false);
                },
                loading,
            };
        },
        onClose: () => onCloseUpdateApp(),
        onContinueApple: () => goToUrl(appleLink.text),
        onContinueAndroid: () => goToUrl(androidLink.text),
    };

    const accountDeletedHandler = {
        display: accountDeletedDisplay,
        onClose: () => setAccountDeletedDisplay(false),
    };

    const deviceInfoCheckHandler = {
        display: deviceInfoCheckDisplay,
        onClose: () => setDeviceInfoCheckDisplay(false),
    };

    const returntoTimelineHandler = {
        display: returnToTimelineDisplay,
        useHandler: (): PartialDialogHandler => {
            return {
                primaryAction: () => {
                    setLoading(true);
                    setReturnToTimelineDisplay(false);
                    ReturnToTimelineStateService.disableMenuOption();
                    viewContext.doTransition(TransitionAction.GoToTimeline);
                    setLoading(false);
                },
                secondaryAction: () => {
                    setLoading(true);
                    setReturnToTimelineDisplay(false);
                    viewContext.doTransition(
                        TransitionAction.DoReturnToPageDialogClose
                    );
                    setLoading(false);
                },
            };
        },
    };

    const mobileDeviceUnmutedCheckHandler = {
        display: mobileDeviceUnmutedCheckDisplay,
        onClose: () => {
            viewContext.doTransition(
                TransitionAction.DoReturnToPageDialogClose
            );
            setMobileDeviceUnmutedCheckDisplay(false);
        },
    };

    const leaveAppHandler = {
        display: leaveAppDisplay,
        useHandler: (): PartialDialogHandler => {
            return {
                primaryAction: async () => {
                    setLeaveAppDisplay(false);
                    const url = leaveAppState.leaveAppUrl;
                    goToUrl(url, true);
                    if (
                        viewContext.workflowType ===
                        MainViewWorkflow.AppFineTuning
                    ) {
                        await viewContext.doTransition(
                            TransitionAction.GoSplashScreen
                        );
                    }
                    await viewContext.doTransition(TransitionAction.DoLeaveApp);
                },
                secondaryAction: () => {
                    setLeaveAppDisplay(false);
                    viewContext.doTransition(
                        TransitionAction.DoLeaveAppDialogClose
                    );
                },
            };
        },
    };

    return {
        pairingConnectionLostHandler,
        accountDeletedHandler,
        deviceInfoCheckHandler,
        updateAppHandler,
        returntoTimelineHandler,
        mobileDeviceUnmutedCheckHandler,
        leaveAppHandler,
        updateDisplay,
    };
}

export default useGlobalDialogHandler;
