import React from 'react';
import DownloadAppDialog from '../../../Pages/MainComponents/Landing/DownloadDialog/DownloadAppDialog';
import { DownloadDialogResource } from '../../../Pages/MainComponents/Landing/DownloadDialog/DownloadAppDialogResourceHook';
import CustomDialog from '../CustomDialog';
import DeviceInfoCheckDialog from '../DeviceInfoCheck/DeviceInfoCheckDialog';
import WarningDialog, {
    WarningDialogHandler,
    WarningDialogResource,
} from '../Warning/WarningDialog';
import PartialDialog, {
    PartialDialogHandler,
    PartialDialogResource,
} from '../PartialDialog/PartialDialog';
import MobileDeviceUnmutedCheckDialog, {
    MobileDeviceUnmutedCheckDialogResource,
} from '../MobileDeviceUnmutedCheckDialog/MobileDeviceUnmutedCheckDialog';

export type GlobalDialogRender = {
    renderPairingConnectionLostDialog: () => JSX.Element;
    renderDeviceInfoCheckDialog: () => JSX.Element;
    renderUpdateAppDialog: () => JSX.Element;
    renderReturnToTimelineDialog: () => JSX.Element;
    renderMobileDeviceUnmutedCheckDialog: () => JSX.Element;
    renderLeaveAppDialog: () => JSX.Element;
};

export interface IGlobalDialogRenderProps {
    pairingConnectionLostHandler: {
        display: boolean;
        useHandler: () => WarningDialogHandler;
    };
    usePairingConnectionLostResource: () => WarningDialogResource;
    deviceInfoCheckHandler: {
        display: boolean;
        onClose: () => void;
    };
    updateAppHandler: {
        display: boolean;
        useHandler: () => WarningDialogHandler;
        onClose: () => void;
        onContinueApple: () => void;
        onContinueAndroid: () => void;
    };
    useUpdateAppResource: () => DownloadDialogResource;
    returntoTimelineHandler: {
        display: boolean;
        useHandler: () => PartialDialogHandler;
    };
    useReturnToTimelineResource: () => PartialDialogResource;
    mobileDeviceUnmutedCheckHandler: {
        display: boolean;
        onClose: () => void;
    };
    useMobileDeviceUnmutedCheckResource: () => MobileDeviceUnmutedCheckDialogResource;
    leaveAppHandler: {
        display: boolean;
        useHandler: () => PartialDialogHandler;
    };
    useLeaveAppDialogResource: () => PartialDialogResource;
}

function useGlobalDialogRender(
    props: IGlobalDialogRenderProps
): GlobalDialogRender {
    const renderPairingConnectionLostDialog = () => {
        const { display, useHandler } = props.pairingConnectionLostHandler;

        const useResource = props.usePairingConnectionLostResource;

        return (
            <CustomDialog fullScreen open={display}>
                <WarningDialog
                    useHandler={useHandler}
                    useResource={useResource}
                />
            </CustomDialog>
        );
    };

    const renderUpdateAppDialog = () => {
        const { display, onClose, onContinueApple, onContinueAndroid } =
            props.updateAppHandler;

        const useResource = props.useUpdateAppResource;

        return (
            <DownloadAppDialog
                displayed={display}
                onClose={onClose}
                useResource={useResource}
                onContinueApple={() => {
                    onContinueApple();
                }}
                onContinueAndroid={() => {
                    onContinueAndroid();
                }}
            ></DownloadAppDialog>
        );
    };

    const renderDeviceInfoCheckDialog = () => {
        const { display, onClose } = props.deviceInfoCheckHandler;
        return <DeviceInfoCheckDialog display={display} onClose={onClose} />;
    };

    const renderReturnToTimelineDialog = () => {
        return (
            <PartialDialog
                open={props.returntoTimelineHandler.display}
                useHandler={props.returntoTimelineHandler.useHandler}
                useResource={props.useReturnToTimelineResource}
            />
        );
    };

    const renderMobileDeviceUnmutedCheckDialog = () => {
        return (
            <MobileDeviceUnmutedCheckDialog
                open={props.mobileDeviceUnmutedCheckHandler.display}
                onClose={props.mobileDeviceUnmutedCheckHandler.onClose}
                useResource={props.useMobileDeviceUnmutedCheckResource}
            />
        );
    };

    const renderLeaveAppDialog = () => {
        return (
            <PartialDialog
                open={props.leaveAppHandler.display}
                useHandler={props.leaveAppHandler.useHandler}
                useResource={props.useLeaveAppDialogResource}
            />
        );
    };

    return {
        renderPairingConnectionLostDialog,
        renderDeviceInfoCheckDialog,
        renderUpdateAppDialog,
        renderReturnToTimelineDialog,
        renderMobileDeviceUnmutedCheckDialog,
        renderLeaveAppDialog,
    };
}

export default useGlobalDialogRender;
