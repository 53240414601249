import React from 'react';
import { BestCluster } from '../../../Models/HearingProfile/BestCluster';
import { InitialSettingApplicationState } from './InitialSettingModels';

export type Side = 'left' | 'right' | '';
export interface IInitialSettingInfo {
    activeSide: Side;
    completedSide: Side;
    initialSettingStep: InitialSettingApplicationState;
    initialized: boolean;
    isOneSideDone: boolean;
    loading: boolean;
    progress: number;
    totalPageCount: number;
    totalPagesForProgress: number;
    beepConfirmationCount: number;
    beepConfirmationCountLimit: number;
    sideStates: {
        side: Side;
        isDone: boolean;
        bestCluster: BestCluster;
    }[];
}

export interface IInitialSettingContext {
    info: IInitialSettingInfo;
    setInitialSettingInfo: (value: IInitialSettingInfo) => void;
    exitInitialSetting: (message: string) => void;
}

export const InitialSettingContext = React.createContext(
    {} as IInitialSettingContext
);
