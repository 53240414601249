import { useContext } from 'react';
import { CustomerServiceContactService } from '../../../../../Services/Configuration/CustomerServiceContactService';
import CompletedWorkflowService from '../../../../../Utils/CompletedWorkflow';
import { goToUrl } from '../../../../../Utils/NavigationUtils';
import { MenuContext } from '../../MenuContext';
import { itemHandler } from '../../MenuUtils';

export type instructionsHandlers = {
    video: itemHandler;
};

export default function useInstructionsMenuHandler(): instructionsHandlers {
    const { onClose } = useContext(MenuContext);
    return {
        video: () => {
            if (CompletedWorkflowService.isUnderHLAAWorkflow()) return;
            goToUrl(
                `${CustomerServiceContactService.getCountrySpecificOrionDeviceInstructionAddress(
                    false
                )}`,
                true
            );
            if (onClose) onClose();
        },
    };
}
