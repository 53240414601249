/* eslint-disable react/no-children-prop */
/* eslint-disable react/prop-types */
//disable linting due to some code belong to react markdown library
import { Typography } from '@material-ui/core';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import LoggingService from '../../Services/LoggingService';
import CustomLink from '../Link/CustomLink';
import CustomImg from '../../SharedComponents/CustomImage/CustomImg';
import CustomTypography, { LinkCallBacks } from './CustomTypography';
import './TypographyStyle.scss';

export const TypographyStyle = {
    aqua: 'aqua',
    red: 'red',
    orange: 'orange',
    black: 'black',
    link: 'link',
    lightbrown: 'lightbrown',
    redorange: 'redorange',
};

function mapStyleToCss(style: string): string {
    let cssStyle = '';
    Object.entries(TypographyStyle).forEach(([key, value]) => {
        if (key === style) {
            cssStyle = value;
        }
    });
    if (!cssStyle) {
        LoggingService.warn({
            componentName: Typography.name,
            args: ['Undefined style is found in translation file.'],
        });
    }
    return cssStyle;
}

export function createTypoFromStyle(
    text: string,
    linkCallBacks?: LinkCallBacks
): JSX.Element {
    function validURL(link: string | undefined) {
        if (link?.match(/tel:|mailto:/)) return true;
        const pattern = new RegExp(
            '^(https?:\\/\\/)?' + // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
                '(\\#[-a-z\\d_]*)?$',
            'i'
        ); // fragment locator
        return link && !!pattern.test(link);
    }

    const renderTypograpgy = (
        <ReactMarkdown
            components={{
                p: ({ node, ...props }) => <div {...props} />,
                em: ({ node, ...props }) => (
                    <em {...props} style={{ fontSize: '0.9rem' }} />
                ),
                h3: ({ node, ...props }) => (
                    <div className={'customSubHeading'} {...props} />
                ),
                a: ({ node, ref, color, href, onClick, ...props }) => (
                    <CustomLink
                        className={'linkWithinText'}
                        href={validURL(href) ? href : undefined}
                        onClick={async () => {
                            const callBackName = href?.match(
                                /callback_[a-zA-Z0-9_-]*$/i
                            )
                                ? href.substring(href.indexOf('_') + 1)
                                : props.title?.match(
                                      /callback_[a-zA-Z0-9_-]*$/i
                                  )
                                ? props.title.substring(
                                      props.title.indexOf('_') + 1
                                  )
                                : '';
                            if (
                                linkCallBacks &&
                                Object.keys(linkCallBacks).includes(
                                    callBackName
                                )
                            )
                                await linkCallBacks[callBackName]();
                            else {
                                LoggingService.error({
                                    componentName: CustomTypography.name,
                                    args: [
                                        `Invalid callback with name: ${callBackName}`,
                                    ],
                                });
                            }
                        }}
                        {...props}
                    />
                ),
                code: ({ node, ref, children, inline, ...props }) => {
                    return children.length === 1 &&
                        typeof children[0] === 'string' &&
                        children[0].match(/([^}]+)}/) ? (
                        <span
                            {...props}
                            children={children[0].substring(
                                children[0].indexOf('{') + 1,
                                children[0].indexOf('}')
                            )}
                            className={mapStyleToCss(
                                children[0].substring(
                                    0,
                                    children[0].indexOf('{')
                                )
                            )}
                        />
                    ) : (
                        <code {...props} children={children} ref={ref} />
                    );
                },
                img: ({ node, ref, children, ...props }) => {
                    return (
                        <CustomImg
                            imgType="iconSmall"
                            {...props}
                            id={props.alt}
                            className={'iconInText'}
                        />
                    );
                },
            }}
            children={text}
        />
    );
    return <>{renderTypograpgy}</>;
}
