import { useContext } from 'react';
import { VoidReturn } from '../../../Utils/PageUtils';
import { MainViewContext } from '../../MainComponents/MainView/MainViewContext';
import { TransitionAction } from '../../MainComponents/MainView/MainViewModel';

export type FineTuningCompletePageHandler = {
    onExit: () => VoidReturn;
};

export default function useFineTuningCompletePageHandler(): FineTuningCompletePageHandler {
    const viewContext = useContext(MainViewContext);
    const onExit = async () => {
        viewContext.doTransition(TransitionAction.ExitInAppBrowser);
    };

    return { onExit };
}
