import React from 'react';
import FlexContainer from '../../../../../SharedComponents/Container/FlexContainer';
import CustomizedCircularProgressPercent from '../../../../../SharedComponents/Spinner/CustomizedCircularProgressPercent';
import CustomTypography, {
    TypographyType,
} from '../../../../../SharedComponents/Typography/CustomTypography';
import useFineTuningAfterApplyPageResource from './FineTuningAfterApplyPageResource';
import '../../FineTuningSolutionPage.scss';

export interface IFineTuningAfterApplyPage {
    solutionNumber: number;
    percentage: number;
}
export default function FineTuningAfterApplyPage(
    props: IFineTuningAfterApplyPage
): React.ReactElement {
    const { solutionText, circularProgress } =
        useFineTuningAfterApplyPageResource(props);
    return (
        <FlexContainer className={'solutionCard disabled'}>
            <CustomTypography
                id={solutionText.id}
                text={solutionText.text}
                type={TypographyType.notoSansSubHeading}
            />
            <CustomizedCircularProgressPercent
                id={circularProgress.id}
                loadingPercentage={props.percentage}
                loadingText={circularProgress.text}
                spinnerContainerClassName={'spinnerContainerApplying'}
                displayTickMark={true}
            />
        </FlexContainer>
    );
}
