import { useState } from 'react';
import { ConsentValue } from '../../../Models/Patient/PatientConsentData';
import LocalStorageService from '../../../Services/LocalStorageService';
import PatientManagementService from '../../../Services/PatientManagementService';
import { VoidReturn } from '../../../Utils/PageUtils';

type DataCollectionHandler = {
    userAction: boolean;
    onCloseDataCollection: () => VoidReturn;
    onClickToggle: () => VoidReturn;
};

export default function useDataCollectionHandler(
    onClose?: () => VoidReturn
): DataCollectionHandler {
    const initialValue =
        LocalStorageService.serviceInstance.getDataAnalyticConsentState() ===
        ConsentValue.Agreed;
    const [userConsentedState, setUserConsentedState] = useState(initialValue);
    const [userAction, setUserAction] = useState(initialValue);

    const onClickToggle = () => {
        setUserAction(!userAction);
    };

    const onCloseDataCollection = async () => {
        if (userAction !== userConsentedState) {
            if (userAction) {
                LocalStorageService.serviceInstance.setDataAnalyticConsentState(
                    ConsentValue.Agreed
                );
                await PatientManagementService.ConsentAnalyticData();
            } else {
                LocalStorageService.serviceInstance.setDataAnalyticConsentState(
                    ConsentValue.Declined
                );
                await PatientManagementService.RevokeAnalyticData();
            }
            setUserConsentedState(userAction);
        }
        if (onClose) onClose();
    };
    return {
        userAction,
        onClickToggle,
        onCloseDataCollection,
    };
}
