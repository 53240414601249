import { useContext } from 'react';
import { InitialSettingContext } from '../InitialSettingContext';
import { InitialSettingApplicationState } from '../InitialSettingModels';
import useInitialSettingSideHandler from '../Side/InitialSettingSideHandlerHook';

export type InitialSettingCustomerServiceHandler = {
    retryInitialSetting: () => void;
};

function useInitialSettingCustomerServiceHandler(): InitialSettingCustomerServiceHandler {
    const { info, setInitialSettingInfo } = useContext(InitialSettingContext);
    const { initializeClusterSetting, programInitialSetting } =
        useInitialSettingSideHandler();

    const retryInitialSetting = async () => {
        setInitialSettingInfo({
            ...info,
            initialSettingStep: InitialSettingApplicationState.Writing,
            loading: true,
        });
        if (!info.initialized) {
            await initializeClusterSetting();
        } else {
            await programInitialSetting();
        }
    };
    return {
        retryInitialSetting,
    };
}

export default useInitialSettingCustomerServiceHandler;
