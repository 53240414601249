import { useTranslation } from 'react-i18next';
import { getUserDeviceModel } from '../../../Services/ResourceService';
import {
    CarouselFooterContent,
    InstructionType,
} from '../../../SharedComponents/Carousel/CarouselFooter';
import { HeaderActionType } from '../../../SharedComponents/Header/HeaderActionType';
import { isBluetoothDeviceByModel } from '../../../Utils/BluetoothDeviceUtils';
import { HeaderResource } from '../../../Utils/PageUtils';

type TechnicalSetupResource = {
    header: HeaderResource;
    imgSrcList: string[];
    instructionList: CarouselFooterContent[];
    button: {
        id: string;
        label: string;
    };
    audioRoutingHelpLink: {
        id: string;
        text: string;
    };
};

function useTechnicalSetupResource(): TechnicalSetupResource {
    const { t } = useTranslation();

    const imgSrcList: string[] = [];
    const instructionList: CarouselFooterContent[] = [];
    const lggKey = isBluetoothDeviceByModel(getUserDeviceModel())
        ? 'orionGo:OrionGo_Device:Pairing_Feature:TechnicalSetup:Text'
        : 'pairing:Pairing_Feature:InstructionForPairingSetup:Text';
    const audioRoutingHelpLink = {
        id: 'audioRouting_helplink',
        text: t(
            'orionGo:OrionGo_Device:Pairing_Feature:TechnicalSetup:TextHelp'
        ),
    };

    //orion air header prefix config

    const header = {
        id: 'header-technical-setup',
        text: t(`pairing:Pairing_Feature:InstructionForPairingSetup:Header`),
        type: HeaderActionType.Menu,
    };

    for (let index = 1; index <= 3; index++) {
        imgSrcList.push(
            `/resources/fittingWorkflow/${
                isBluetoothDeviceByModel(getUserDeviceModel()) ? 'ble-' : ''
            }technical-setup-${index}.svg`
        );
        instructionList.push({
            instructionContent: t(`${lggKey}${index}`),
            instructionType: InstructionType.TextLeftAlign,
        });
    }

    const button = {
        id: 'btn-start',
        label: t('common:Common_Feature:Continue'),
    };

    return {
        header,
        imgSrcList,
        instructionList,
        button,
        audioRoutingHelpLink,
    };
}

export default useTechnicalSetupResource;
