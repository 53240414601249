import { SupportedCountries } from '../../Models/SupportedCountries';
import Config from '../../CommonAssets/Configuration/CountryConsentConfig.json';

export type CountryConsentConfigFile = {
    [key: string]: {
        operationalDataConsent: boolean;
        analyticalDataConsent: boolean;
    };
};

export type ConsentConfig = {
    operationalDataConsent: boolean;
    analyticalDataConsent: boolean;
};

export default class CountryConsentConfigService {
    private static configs: CountryConsentConfigFile =
        Config as CountryConsentConfigFile;

    public static getCountryConfig(country: SupportedCountries): ConsentConfig {
        return this.configs[country as SupportedCountries];
    }
}
