import { useTranslation } from 'react-i18next';
import { HeaderActionType } from '../../../../SharedComponents/Header/HeaderActionType';
import {
    ButtonResource,
    HeaderResource,
    ImageResource,
    TextResource,
} from '../../../../Utils/PageUtils';

export type HearingProfileResultPageResource = {
    header: HeaderResource;
    title: TextResource;
    instructionImage: ImageResource;
    instruction: TextResource;
    continueButton: ButtonResource;
};

export default function useHearingProfileResultPageResource(): HearingProfileResultPageResource {
    const { t } = useTranslation();

    const header = {
        id: 'hearing-result-header',
        text: t(`common:Common_Feature:ApplySettings`),
        type: HeaderActionType.Menu,
    };

    const title = {
        id: 'resultPage-title',
        text: t(`initialSettings:InitialSettings_Feature:ResultPage:Title`),
    };

    const instructionImage = {
        id: 'img-apply-setting',
        src: `/resources/fittingWorkflow/illust_applyyourhearingprofile.svg`,
        alt: 'initialSettings:resultPage:header',
    };
    const instruction = {
        id: 'resultPage-instruction',
        text: t(`initialSettings:InitialSettings_Feature:ResultPage:Text`),
    };
    const continueButton = {
        label: t(
            `initialSettings:InitialSettings_Feature:ResultPage:NextButton`
        ),
        id: 'btn-apply',
    };

    return {
        header,
        title,
        instructionImage,
        instruction,
        continueButton,
    };
}
