import React, { useEffect, useRef, useState } from 'react';
import AuthService from '../../../Services/Authentication/AuthService';
import { withTracking } from '../../../Services/Monitoring/ApplicationInsights';
import PatientManagementService from '../../../Services/PatientManagementService';
import FineTuningWorkflowError from '../../../SharedComponents/Dialog/FineTuningWorkflowError/FineTuningWorkflowError';
import GlobalDialog from '../../../SharedComponents/Dialog/GlobalDialog/UseGlobalDialog';
import useLoading from '../../../SharedComponents/Spinner/PageLoadSpinner';
import FineTuningPage from '../../FineTuning/FineTuningPage';
import AudiogramScoringCheckPage from '../../HearingProfile/AudiogramScoringCheckPage';
import CompleteRecommendationPage from '../../HearingProfile/CompleteRecommendation/CompleteRecommendationPage';
import SpeechComfortTestPage from '../../HearingProfile/SpeechComfortTest/SpeechComfortTestPage';
import RedoLoudnessPage from '../../HearingProfile/SpeechComfortTest/RedoLoudnessPage';
import HearingAssessmentPage from '../../HLAA/HearingAssessmentPage';
import PairingPage from '../../Pairing/PairingPage';
import ConsentPage from '../Consent/ConsentPage';
import ConsentAnalyticPage from '../ConsentAnalytic/ConsentAnalyticPage';
import ContraindicationPage from '../Contraindication/ContraindicationPage';
import HIAssemblyPage from '../HIAssembly/HIAssemblyPage';
import LandingPage from '../Landing/LandingPage';
import SmartRemotePage from '../SmartRemote/SmartRemotePage';
import SplashScreen from '../SplashScreen/SplashScreenPage';
import UserJourneyPage from '../UserJourney/UserJourneyPage';
import WelcomePage from '../Welcome/WelcomePage';
import { IMainViewContext, MainViewContext } from './MainViewContext';
import {
    DialogState,
    IMainViewProps,
    PageState,
    TransitionAction,
    ViewState,
} from './MainViewModel';
import {
    getBackButtonViewState,
    getDebugRoute,
    getPageStateParam,
} from './TransitionHandlerUtils';
import DeviceCompatibilityCheckDialog from '../../../SharedComponents/Dialog/DeviceCompatibilityCheck/DeviceCompatibilityCheckDialog';
import AppLandingPage from '../Landing/AppLanding/AppLandingPage';
import { switchWebAppOrionApp, VoidReturn } from '../../../Utils/PageUtils';
import RedoEasyFitPage from '../../../SharedComponents/Dialog/RedoEasyFit/RedoEasyFitPage';
import RedoHLAAPage from '../../../SharedComponents/Dialog/RedoHLAA/RedoHLAAPage';
import SleeveSelection from '../../../SharedComponents/Dialog/SleeveSelection/SleeveSelection';
import UserJourneyPageReturnToTimeline from '../UserJourney/ReturnToTimeline/UserJourneyPageReturnToTimeline';
import FineTuningCompletePage from '../../FineTuning/Complete/FineTuningCompletePage';

function MainView({ workflowType, useTransitionHandler }: IMainViewProps) {
    const [loadingSpinner] = useLoading();
    const isComponentMounted = useRef(true);
    const [currentView, setView] = useState({
        dialog: DialogState.None,
        page: PageState.Loading,
    } as ViewState);

    const transitionHandler = useTransitionHandler();

    const doTransition = async (action: TransitionAction) => {
        let patient = undefined;
        if (AuthService.getInstance().acquireAccessToken())
            patient = await PatientManagementService.getPatient();
        const nextView = await transitionHandler.doTransition(
            currentView,
            action,
            patient
        );
        setView(nextView);
    };

    const backButtonTransition = (action?: () => VoidReturn) => {
        if (action) action();
        else {
            const nextView = getBackButtonViewState(currentView);
            setView(nextView);
        }
    };

    const viewContext: IMainViewContext = {
        pageState: currentView.page,
        dialogState: currentView.dialog,
        workflowType: workflowType,
        doTransition: doTransition,
        backButtonTransition: backButtonTransition,
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [currentView]);

    useEffect(() => {
        const determineInitialState = async () => {
            if (
                (await AuthService.getInstance().doesAccessTokenExist()) &&
                (await AuthService.getInstance().getIsAuthenticated())
            ) {
                AuthService.getInstance().login();
                const patient = await PatientManagementService.getPatient();

                const debugRoute = getDebugRoute();

                const initialPage = getPageStateParam();

                const nextView = await transitionHandler.goToInitialState(
                    patient,
                    debugRoute,
                    initialPage
                );

                setView(nextView);
            } else {
                setView({
                    dialog: DialogState.None,
                    page: PageState.SplashScreen,
                } as ViewState);
            }
        };

        if (isComponentMounted.current) {
            (async function init() {
                await determineInitialState();
            })();
        }
        return () => {
            isComponentMounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transitionHandler]);

    const renderView = (view: ViewState) => {
        switch (view.page) {
            case PageState.Loading:
                return loadingSpinner;
            case PageState.SplashScreen:
                return <SplashScreen />;
            case PageState.Welcome:
                return <WelcomePage />;
            case PageState.Consent:
                return <ConsentPage />;
            case PageState.ConsentAnalytic:
                return <ConsentAnalyticPage />;
            case PageState.HiAssemble:
                return <HIAssemblyPage />;
            case PageState.Contraindication:
                return <ContraindicationPage />;
            case PageState.Journey:
                return <UserJourneyPage />;
            case PageState.JourneyReturnToTimeline:
                return <UserJourneyPageReturnToTimeline />;
            case PageState.Pairing:
                return <PairingPage />;
            case PageState.RePairing:
                return <PairingPage />;
            case PageState.SleeveSelection:
                return <SleeveSelection />;
            case PageState.Assessment:
                return <HearingAssessmentPage />;
            case PageState.RedoHLAA:
                return <RedoHLAAPage />;
            case PageState.InitialSettings:
                return <AudiogramScoringCheckPage />;
            case PageState.CompleteRecommendation:
                return <CompleteRecommendationPage />;
            case PageState.SpeechComfort:
                return <SpeechComfortTestPage />;
            case PageState.RedoLoudness:
                return <RedoLoudnessPage />;
            case PageState.Landing:
                return switchWebAppOrionApp(
                    workflowType,
                    <LandingPage />,
                    <AppLandingPage />
                );
            case PageState.FineTuning:
                return <FineTuningPage />;
            case PageState.FineTuningComplete:
                return <FineTuningCompletePage />;
            case PageState.SmartRemote:
                return <SmartRemotePage />;
            case PageState.RedoAssessmentOption:
                return <RedoEasyFitPage />;
            case PageState.FineTuningWorkflowError:
                return <FineTuningWorkflowError />;
            case PageState.DeviceIncompatibleError:
                return (
                    <DeviceCompatibilityCheckDialog
                        displayed={true}
                        // to be removed in task #45821
                        onClose={() => {
                            console.log(
                                'Placeholder to pass eslint. This needs to be removed'
                            );
                        }}
                    />
                );
            default:
                return (
                    <div>
                        <p>Error: 404</p>
                    </div>
                );
        }
    };

    const renderDialogView = (dialog: DialogState) => {
        if (dialog != DialogState.None) {
            return <GlobalDialog />;
        }
    };

    return (
        <MainViewContext.Provider value={viewContext}>
            {renderView(currentView)}
            {renderDialogView(currentView.dialog)}
        </MainViewContext.Provider>
    );
}

export default withTracking(MainView);
