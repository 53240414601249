import './ContainerStyle.scss';
import React from 'react';
import { HTMLAttributes } from 'react';
import { joinMultipleStyle } from '../../Utils/ScssUtils';

function AppContainer({
    className,
    ...others
}: HTMLAttributes<HTMLDivElement>): React.ReactElement {
    return (
        <div
            className={joinMultipleStyle('baseFrameContainer', className)}
            {...others}
        />
    );
}

export default AppContainer;
