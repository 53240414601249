import { Device } from './Device';
import { Workflow } from '../../Utils/CompletedWorkflow';
import { DeviceRepairingState } from '../DeviceRepairingState';

export class Patient {
    public id: string;
    public preferredLanguage: string;
    public residingCountry: string;
    public devicePairingRequired: DeviceRepairingState;
    public easyFitWorkflows: Workflow;
    public lastCompletedHlaaTestId: string;
    public activeHlaaTestId: string;
    public activeHearingProfileId: string;
    public devices: Device[];
    public deleteOperationDataInProcess: boolean;
    public isOperationDataConsented: boolean;
    public privacyNotesVersion: string;
    public dataAnalyticConsentValue: string;
    public isMandatoryWorkflowCompletedOnce: boolean;

    constructor(
        id: string,
        preferredLanguage: string,
        residingCountry: string,
        devicePairingRequired: DeviceRepairingState,
        easyFitWorkflows: Workflow,
        lastCompletedHlaaTestId: string,
        activeHlaaTestId: string,
        activeHearingProfileId: string,
        devices: Device[],
        deleteOperationDataInProcess: boolean,
        isOperationDataConsented: boolean,
        privacyNotesVersion: string,
        dataAnalyticConsentValue: string,
        isMandatoryWorkflowCompletedOnce: boolean
    ) {
        this.id = id;
        this.preferredLanguage = preferredLanguage;
        this.residingCountry = residingCountry;
        this.lastCompletedHlaaTestId = lastCompletedHlaaTestId;
        this.devicePairingRequired = devicePairingRequired;
        this.easyFitWorkflows = easyFitWorkflows;
        this.activeHlaaTestId = activeHlaaTestId;
        this.activeHearingProfileId = activeHearingProfileId;
        this.devices = devices;
        this.deleteOperationDataInProcess = deleteOperationDataInProcess;
        this.isOperationDataConsented = isOperationDataConsented;
        this.privacyNotesVersion = privacyNotesVersion;
        this.dataAnalyticConsentValue = dataAnalyticConsentValue;
        this.isMandatoryWorkflowCompletedOnce =
            isMandatoryWorkflowCompletedOnce;
    }
}
