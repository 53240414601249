import React from 'react';
import { withTracking } from '../../../Services/Monitoring/ApplicationInsights';
import AppContainer from '../../Container/AppContainer';
import BodyContainer from '../../Container/BodyContainer';
import Header from '../../Header/HeaderRefactor';
import CustomTypography, {
    TypographyType,
} from '../../Typography/CustomTypography';
import { IDialogContentProps } from '../DialogUtils';
import useLegalHandler, { LegalHandler } from './LegalDialogHandlerHook';
import { LegalInfoType } from './LegalDialogModel';
import useLegalResource, { LegalResource } from './LegalDialogResourceHook';
import BulletList from '../../../SharedComponents/List/BulletList';
import './LegalDialogStyle.scss';
export interface ILegalProps extends IDialogContentProps {
    type: LegalInfoType;
    useResource?: (type: LegalInfoType) => LegalResource;
    useHandler?: (type: LegalInfoType) => LegalHandler;
}

function LegalDialog({
    type,
    onClose,
    useResource = useLegalResource,
    useHandler = useLegalHandler,
}: ILegalProps): JSX.Element {
    const { dialogType, linkCallBacks } = useHandler(type);
    const { header, legalContent, terms, warningContent } =
        useResource(dialogType);

    return (
        <AppContainer>
            <span id={'top-anchor'} />
            <Header headerContent={header} onClick={onClose} />
            <BodyContainer flexStart>
                {dialogType === LegalInfoType.AppInfo ? (
                    <>
                        <CustomTypography
                            id={legalContent.id}
                            data-testid={legalContent.id}
                            type={TypographyType.notoSansBody16px}
                            text={legalContent.text}
                            linkCallBacks={linkCallBacks}
                            className={'textTopLeft'}
                        />
                        <BulletList
                            bulletItems={terms.text.split('|')}
                            className={'legal-page-term'}
                        />
                        <CustomTypography
                            id={warningContent.id}
                            data-testid={warningContent.id}
                            type={TypographyType.notoSansBody16px}
                            text={warningContent.text}
                            linkCallBacks={linkCallBacks}
                            className={'textTopLeft'}
                        />
                    </>
                ) : (
                    <CustomTypography
                        id={legalContent.id}
                        data-testid={legalContent.id}
                        type={TypographyType.notoSansBody16px}
                        text={legalContent.text}
                        linkCallBacks={linkCallBacks}
                        className={'textTopLeft'}
                    />
                )}
            </BodyContainer>
        </AppContainer>
    );
}

export default withTracking(LegalDialog);
