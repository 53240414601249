import { t } from '../../../../../Services/LocalizationService';
type FTFooterAfterApplyPageResource = {
    confirmText: {
        id: string;
        text: string;
    };

    buttonTexts: {
        primary: string;
        secondary: string;
    };
};

export default function useFTFooterAfterApplyPageResource(): FTFooterAfterApplyPageResource {
    const confirmText = {
        id: 'txt-confirm',
        text: t('fineTuning:FineTuning_Feature:Solution:Text1'),
    };

    const buttonTexts = {
        primary: t('common:Common_Feature:Yes'),
        secondary: t('common:Common_Feature:No'),
    };

    return {
        confirmText,
        buttonTexts,
    };
}
