import { useTranslation } from 'react-i18next';
import { ImageResource, TextResource } from '../../../../Utils/PageUtils';

type MobileDeviceUnmutedCheckStartPageResource = {
    infoText: TextResource;
    progress: {
        id: string;
    };
    iconButton: {
        id: string;
    };
    playImage: ImageResource;
};

export default function useMobileDeviceUnmutedCheckStartPageResource(): MobileDeviceUnmutedCheckStartPageResource {
    const { t } = useTranslation();

    const infoText = {
        id: 'txt-unmuted-check-start-info',
        text: t('common:Common_Feature:MobileDeviceUnmutedCheck:Start:Text'),
    };

    const progress = {
        id: 'progress-loading-unmuted-check-start',
    };

    const iconButton = {
        id: 'btn-unmuted-check-start-play',
    };

    const playImage = {
        id: 'img-unmuted-check-start-play',
        src: '/resources/sharedAcrossWorkflows/play-icon.svg',
        alt: 'play',
    };

    return {
        infoText,
        progress,
        iconButton,
        playImage,
    };
}
