import { useContext } from 'react';
import { MobileDeviceUnmutedCheckContext } from '../MobileDeviceUnmutedCheckContext';

export type MobileDeviceUnmutedCheckDonePageHandler = {
    doneAction: () => void;
};

export default function useMobileDeviceUnmutedCheckDonePageHandler(): MobileDeviceUnmutedCheckDonePageHandler {
    const { onClose } = useContext(MobileDeviceUnmutedCheckContext);

    const doneAction = () => {
        onClose();
    };

    return {
        doneAction,
    };
}
