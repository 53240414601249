import { useContext } from 'react';
import AudioService from '../../../Services/AudioService';
import { CustomerServiceContactService } from '../../../Services/Configuration/CustomerServiceContactService';
import { goToUrl } from '../../../Utils/NavigationUtils';
import { MainViewContext } from '../../MainComponents/MainView/MainViewContext';
import { TransitionAction } from '../../MainComponents/MainView/MainViewModel';
import { TroubleShootingHandler } from '../../MainComponents/TroubleShooting/TroubleShooting';
import { HearingAssessmentState, HLAAContext } from '../HLAAContext';

function useHearingAssessmentTroubleShootingHandler(): TroubleShootingHandler {
    const { info, setHearingAssessmentState } = useContext(HLAAContext);

    const viewContext = useContext(MainViewContext);

    const onStartHLAA = async () => {
        await AudioService.serviceInstance.createNewAudioContext();

        info.current.startKeepAlive = false;
        info.current.repeatDeviceSetup = true;
        info.current.freqStartTime = new Date().getTime();
    };

    const retryBeepConfirmationThresholdReached = () => {
        return (
            info.current.retryBeepConfirmationCount >=
            info.current.retryBeepConfirmationCountThreshold
        );
    };
    const openConnectionSupportLink = () => {
        goToUrl(
            CustomerServiceContactService.getCountrySpecificOrionConnectionCheckAddress(
                false
            ),
            true
        );
    };

    const startMobileDeviceUnmutedCheck = () => {
        viewContext.doTransition(
            TransitionAction.DoMobileDeviceUnmutedCheckDialogOpen
        );
    };

    const openCustomerServiceContactHyperLink = () => {
        goToUrl(
            CustomerServiceContactService.getCountrySpecificOrionCustomerServiceContact(
                false
            ),
            true
        );
    };

    const linkCallBacks = {
        customerSupport: openCustomerServiceContactHyperLink,
        connectionCheckSupport: openConnectionSupportLink,
        startMobileDeviceUnmutedCheck: startMobileDeviceUnmutedCheck,
    };

    const onRetry = async () => {
        await onStartHLAA();
        setHearingAssessmentState(HearingAssessmentState.SetUp);
    };

    return {
        onRetry,
        linkCallBacks,
        retryBeepConfirmationThresholdReached,
    };
}

export default useHearingAssessmentTroubleShootingHandler;
