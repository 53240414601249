import { useFineTuningSolutionContext } from '../../FineTuningSolutionContext';

type FTFooterApplyingPageResource = {
    progressText: {
        id: string;
        text: string;
    };
};

export default function useFTFooterApplyingPageResource(): FTFooterApplyingPageResource {
    const { percentage } = useFineTuningSolutionContext();
    const progressText = {
        id: 'txt-progress',
        text: `${percentage}%`,
    };

    return {
        progressText,
    };
}
