import { VoidReturn } from '../../../Utils/PageUtils';
import { useHistory } from 'react-router-dom';

export type PageNotFoundDialogHandler = {
    backToOrionNow: () => VoidReturn;
};

function usePageNotFoundDialogHandler(): PageNotFoundDialogHandler {
    const history = useHistory();

    const backToOrionNow = () => {
        history.push('/');
    };

    return {
        backToOrionNow,
    };
}

export default usePageNotFoundDialogHandler;
