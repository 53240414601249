import './ContainerStyle.scss';
import React, { HTMLAttributes } from 'react';
import { joinMultipleStyle } from '../../Utils/ScssUtils';

export interface IFlexContainerProps extends HTMLAttributes<HTMLDivElement> {
    flexRow?: boolean;
    flexGrow?: boolean;
}

function FlexContainer({
    flexRow,
    flexGrow,
    className,
    ...others
}: IFlexContainerProps): React.ReactElement {
    if (flexRow) className = `flexRow ${className}`;
    if (flexGrow) className = `flexGrow ${className}`;
    return (
        <div
            className={joinMultipleStyle('flexContainer', className)}
            {...others}
        />
    );
}

export default FlexContainer;
