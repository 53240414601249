import { useContext } from 'react';
import { CustomerServiceContactService } from '../../../../Services/Configuration/CustomerServiceContactService';
import { goToUrl } from '../../../../Utils/NavigationUtils';
import { linkCallBacksResource, VoidReturn } from '../../../../Utils/PageUtils';
import { RedoHLAAContext, RedoHLAAState } from '../RedoHLAAContext';
import { MainViewContext } from '../../../../Pages/MainComponents/MainView/MainViewContext';
import { TransitionAction } from '../../../../Pages/MainComponents/MainView/MainViewModel';

export type TechnicalSetupHandler = {
    onContinue: () => VoidReturn;
    linkCallBacks: linkCallBacksResource;
};

function useRedoHLAATechnicalSetupHandler(): TechnicalSetupHandler {
    const { setRedoHLAAState } = useContext(RedoHLAAContext);

    const viewContext = useContext(MainViewContext);

    const onContinue = () => {
        setRedoHLAAState(RedoHLAAState.OneSideSetUp);
    };

    const startMobileDeviceUnmutedCheck = () => {
        viewContext.doTransition(
            TransitionAction.DoMobileDeviceUnmutedCheckDialogOpen
        );
    };

    const openHyperLink = () => {
        goToUrl(
            CustomerServiceContactService.getCountrySpecificOrionConnectionCheckAddress(
                false
            ),
            true
        );
    };

    const openConnectionSupportLink = () => {
        goToUrl(
            CustomerServiceContactService.getCountrySpecificOrionConnectionCheckAddress(
                false
            ),
            true
        );
    };

    const linkCallBacks = {
        customerSupport: openHyperLink,
        startMobileDeviceUnmutedCheck: startMobileDeviceUnmutedCheck,
        connectionCheckSupport: openConnectionSupportLink,
    };

    return {
        onContinue,
        linkCallBacks,
    };
}

export default useRedoHLAATechnicalSetupHandler;
