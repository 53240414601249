import { useTranslation } from 'react-i18next';
import { getUserDeviceModel } from '../../../../Services/ResourceService';
import { HeaderActionType } from '../../../../SharedComponents/Header/HeaderActionType';
import { isBluetoothDeviceByModel } from '../../../../Utils/BluetoothDeviceUtils';
import {
    ButtonResource,
    HeaderResource,
    ImageResource,
    TextResource,
} from '../../../../Utils/PageUtils';

export type AppLandingPageResource = {
    header: HeaderResource;
    illustrationImage: ImageResource;
    subtitle: TextResource;
    instruction: TextResource;
    buttons: {
        continue: ButtonResource;
        return: ButtonResource;
    };
};

export default function useAppLandingPageResource(): AppLandingPageResource {
    const { t } = useTranslation();
    const header = {
        id: 'header-welcome',
        type: HeaderActionType.Menu,
        text: t('orionApp:OrionApp_Workflow:FineTuning_Feature:Welcome:Header'),
    };

    const illustrationImage = {
        id: 'img-easyfitillustration',
        src: '/resources/sharedAcrossWorkflows/easyfit-illustration.png',
        alt: t('welcome:header'),
    };

    const subtitle = {
        id: 'txt-subtitle',
        text: isBluetoothDeviceByModel(getUserDeviceModel())
            ? t('orionApp:OrionApp_Workflow:FineTuning_Feature:Welcome:Text')
            : t(
                  'completeAndLanding:CompleteAndLanding_Feature:Landing:TextApp'
              ),
    };

    const instruction = {
        id: 'txt-instruction',
        text: isBluetoothDeviceByModel(getUserDeviceModel())
            ? t(
                  'orionApp:OrionApp_Workflow:FineTuning_Feature:Welcome:TextInstruction'
              )
            : t(
                  'completeAndLanding:CompleteAndLanding_Feature:Landing:TextInstruction'
              ),
    };

    const continueButton = {
        id: 'btn-continue',
        label: t('common:Common_Feature:FineTuning'),
    };

    const returnButton = {
        id: 'btn-return',
        label: t(
            'orionApp:OrionApp_Workflow:FineTuning_Feature:Welcome:BackButton'
        ),
    };

    const buttons = {
        continue: continueButton,
        return: returnButton,
    };

    return {
        header,
        illustrationImage,
        subtitle,
        instruction,
        buttons,
    };
}
