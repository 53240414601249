import { t } from '../../../../../Services/LocalizationService';
import { IFineTuningApplyingPage } from './FineTuningApplyingPage';

type FineTuningApplyingPageResource = {
    solutionText: {
        id: string;
        text: string;
    };
    progress: {
        id: string;
        text: string;
    };
};

export default function useFineTuningApplyingPageResource(
    props: IFineTuningApplyingPage
): FineTuningApplyingPageResource {
    const solutionText = {
        id: 'txt-solution',
        text: `${t('fineTuning:FineTuning_Feature:Solution:Text12')} 0${
            props.solutionNumber
        }`,
    };

    const progress = {
        id: 'progress-solution',
        text: t('fineTuning:FineTuning_Feature:Solution:Text13'),
    };
    return {
        solutionText,
        progress,
    };
}
