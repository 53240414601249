import React from 'react';
import Header from '../../../SharedComponents/Header/HeaderRefactor';
import AppContainer from '../../../SharedComponents/Container/AppContainer';
import BodyContainer from '../../../SharedComponents/Container/BodyContainer';
import useFineTuningSolutionPageResource from './FineTuningSolutionPageResourceHook';
import useFineTuningSolutionPageRender from './FineTuningSolutionPageRenderHooks';

const FineTuningSolutionPage = (): React.ReactElement => {
    const { headerProps } = useFineTuningSolutionPageResource();

    const {
        renderTable,
        renderInstructions,
        renderPrimaryComponent,
        renderFooterComponent,
    } = useFineTuningSolutionPageRender();

    return (
        <AppContainer>
            <Header {...headerProps} />
            <BodyContainer flexStart noRowMargin>
                {renderTable()}
                {renderInstructions()}
                {renderPrimaryComponent()}
                {renderFooterComponent()}
            </BodyContainer>
        </AppContainer>
    );
};

export default FineTuningSolutionPage;
