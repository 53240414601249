import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderActionType } from '../../../../SharedComponents/Header/HeaderActionType';
import {
    HeaderResource,
    ImageResource,
    TextResource,
    ButtonResource,
} from '../../../../Utils/PageUtils';
import { InitialSettingContext } from '../InitialSettingContext';

export type InitialSettingApplicationSideResource = {
    header: HeaderResource;
    instructionApplication: TextResource;
    instructionImageApplication: ImageResource;
    button: ButtonResource;
};

export default function useInitialSettingApplicationSideResource(): InitialSettingApplicationSideResource {
    const { t } = useTranslation();
    const { info } = useContext(InitialSettingContext);

    return {
        header: {
            id: 'initial-setting-header',
            text: t(`common:Common_Feature:ApplySettings`),
            type: HeaderActionType.Menu,
        },
        instructionApplication: {
            id: `txt-info1-${
                info.completedSide !== '' ? info.completedSide : info.activeSide
            }`,
            text: t(
                `initialSettings:InitialSettings_Feature:Apply_${
                    info.completedSide.toLowerCase() === 'left'
                        ? 'Left'
                        : 'Right'
                }:Text`
            ),
        },
        instructionImageApplication: {
            id: 'img-successful',
            src: `/resources/sharedAcrossWorkflows/successful_tick-mark.svg`,
        },
        button: {
            id: `btn-apply`,
            label: t(`common:Common_Feature:Continue`),
        },
    };
}
