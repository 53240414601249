import { ButtonProps, CircularProgress } from '@material-ui/core';
import React, { Dispatch, useState } from 'react';
import { ReactNode } from 'react-markdown';
import LoggingService from '../../Services/LoggingService';
import { joinMultipleStyle } from '../../Utils/ScssUtils';
import ActionContainer from '../Container/ActionContainer';
import ButtonContainer from '../Container/ButtonContainer';
import ButtonStickyContainer from '../Container/ButtonStickyContainer';
import { createButton } from './ButtonFactory';
import { ButtonType } from './ButtonType';

export interface IPairButton {
    secondaryButton: ButtonProps;
    primaryButton: ButtonProps;
    withDefaultContainer?: boolean;
    sticky?: boolean;
    columnAlign?: boolean;
    withLoading?: boolean;
}

function PairButton({
    secondaryButton,
    primaryButton,
    withDefaultContainer = true,
    sticky = true,
    columnAlign = false,
    withLoading = false,
}: IPairButton): JSX.Element {
    const pairButton: React.ReactElement[] = [];
    const loadingSpinner = <CircularProgress color="inherit" size="2rem" />;
    const [primloading, setPrimLoading] = useState(false);
    const [secondLoading, setSecondLoading] = useState(false);

    const loadingConfig = (
        isLoading: boolean,
        setIsLoading: Dispatch<boolean>,
        children?: ReactNode,
        onClick?: React.MouseEventHandler<HTMLButtonElement>
    ) => {
        return withLoading
            ? {
                  disabled: primloading || secondLoading,
                  children: isLoading ? loadingSpinner : <div>{children}</div>,
                  onClick: async (
                      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                  ) => {
                      if (onClick) {
                          setIsLoading(true);
                          try {
                              await onClick(event);
                          } catch (err) {
                              LoggingService.error({
                                  componentName: 'PairButton',
                                  args: [
                                      `onClick failed in pairbutton onClick: ${err}`,
                                  ],
                              });
                          }
                          setIsLoading(false);
                      }
                  },
              }
            : {};
    };

    pairButton.push(
        createButton(ButtonType.Secondary, {
            ...secondaryButton,
            className: columnAlign
                ? secondaryButton.className
                : joinMultipleStyle(
                      'buttonHalfScreen',
                      secondaryButton.className
                  ),
            key: 'secondaryActionButton',
            ...loadingConfig(
                secondLoading,
                setSecondLoading,
                secondaryButton.children,
                secondaryButton.onClick
            ),
        })
    );
    pairButton.push(
        createButton(ButtonType.Primary, {
            ...primaryButton,
            className: columnAlign
                ? primaryButton.className
                : joinMultipleStyle(
                      'buttonHalfScreen',
                      primaryButton.className
                  ),
            key: 'primaryActionButton',
            ...loadingConfig(
                primloading,
                setPrimLoading,
                primaryButton.children,
                primaryButton.onClick
            ),
        })
    );

    if (withDefaultContainer) {
        if (sticky)
            return (
                <ButtonStickyContainer
                    className={columnAlign ? 'flexColumn' : ''}
                >
                    {pairButton}
                </ButtonStickyContainer>
            );
        else
            return (
                <ActionContainer
                    sticky={false}
                    className={columnAlign ? 'flexColumn' : ''}
                >
                    <ButtonContainer>{pairButton}</ButtonContainer>
                </ActionContainer>
            );
    } else return <>{pairButton}</>;
}

export default PairButton;
