import React, { useRef, useState } from 'react';
import FineTuningStartPage from './Start/FineTuningStartPage';
import FineTuningSelectionPage from './Selection/FineTuningSelectionPage';
import FineTuningSolutionPage from './Solution/FineTuningSolutionPage';
import { withTracking } from '../../Services/Monitoring/ApplicationInsights';
import { FineTuningContext, IFineTuningInfo } from './FineTuningContext';
import useFineTuningPageHandler from './FineTuningPageHandlerHook';
import {
    FineTuningSolutionContext,
    IFineTuningSolutionContext,
    IFineTuningSolutionInfo,
} from './Solution/FineTuningSolutionContext';
import { FineTuningSolutionState } from '../../Models/FineTuning/FineTuningSolutionState';
import { FineTuningSolution } from '../../Models/FineTuning/FineTuningSolution';
import FineTuningInfoPage from './Info/FineTuningInfoPage';
import { useMachine } from '@xstate/react';
import FineTuningStateMachine from './FineTuningStateMachine';
import FineTuningBeforeResetPage from './Reset/BeforeReset/FineTuningBeforeResetPage';
import FineTuningDuringResetPage from './Reset/DuringReset/FineTuningDuringResetPage';
import FineTuningAfterResetPage from './Reset/AfterReset/FineTuningAfterResetPage';

function FineTuningPage() {
    const [state, send] = useMachine(FineTuningStateMachine());
    const info = useRef<IFineTuningInfo>({
        selected: { category: '-1', problem: '-1' },
        fineTuningSolution: undefined,
        deviceState: {},
        defaultMasterGain: { left: 0, right: 0 },
        isResetDisabled: true,
        isCategory: true,
        supportedCategoryList: [],
        deviceStateFinal: {},
    });

    const { handleStartFineTuning, spinner, loading } =
        useFineTuningPageHandler({
            info,
            send,
        });

    const initialMainContextValue = {
        info,
        state,
        send,
        handleStartFineTuning,
    };

    const solutionList: FineTuningSolution[] = [];

    const [fineTuningSolutionState, setFineTuningSolutionState] =
        useState<FineTuningSolutionState>(FineTuningSolutionState.BeforeApply);
    const [activeSolutionStep, setActiveSolutionStep] = useState(0);
    const [percentage, setPercentage] = useState(0);

    const createFineTuningSolutionInfo = (): IFineTuningSolutionInfo => {
        return {
            solutionList: solutionList,
            isWarningGoHome: false,
            previousWarningState: FineTuningSolutionState.BeforeApply,
        };
    };

    const solutionInfo = useRef<IFineTuningSolutionInfo>(
        createFineTuningSolutionInfo()
    );

    const initialSolutionContextValue: IFineTuningSolutionContext = {
        fineTuningSolutionInfo: solutionInfo,
        fineTuningSolutionState: fineTuningSolutionState,
        setFineTuningSolutionState: setFineTuningSolutionState,
        activeSolutionStep: activeSolutionStep,
        setActiveSolutionStep: setActiveSolutionStep,
        percentage: percentage,
        setPercentage: setPercentage,
    };

    const renderSwitch = () => {
        window.scrollTo(0, 0);
        switch (true) {
            case state.matches('Start'):
                return <FineTuningStartPage />;
            case state.matches('Selection'):
                return <FineTuningSelectionPage />;
            case state.matches('Solution'):
                return <FineTuningSolutionPage />;
            case state.matches('BeforeReset'):
                return <FineTuningBeforeResetPage />;
            case state.matches('DuringReset'):
                return <FineTuningDuringResetPage />;
            case state.matches('AfterReset'):
                return <FineTuningAfterResetPage />;
            case state.matches('Info'):
                return <FineTuningInfoPage />;
        }
    };

    return (
        <FineTuningContext.Provider value={initialMainContextValue}>
            <FineTuningSolutionContext.Provider
                value={initialSolutionContextValue}
            >
                {loading ? spinner : renderSwitch()}
            </FineTuningSolutionContext.Provider>
        </FineTuningContext.Provider>
    );
}

export default withTracking(FineTuningPage);
