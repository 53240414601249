import React from 'react';
import { withTracking } from '../../../../Services/Monitoring/ApplicationInsights';
import { getUserDeviceModel } from '../../../../Services/ResourceService';
import { ButtonType } from '../../../../SharedComponents/Button/ButtonType';
import CustomButton from '../../../../SharedComponents/Button/CustomButton';
import AppContainer from '../../../../SharedComponents/Container/AppContainer';
import BodyContainer from '../../../../SharedComponents/Container/BodyContainer';
import CustomImg from '../../../../SharedComponents/CustomImage/CustomImg';
import Header from '../../../../SharedComponents/Header/HeaderRefactor';
import CustomTypography, {
    TypographyType,
} from '../../../../SharedComponents/Typography/CustomTypography';
import VideoPlayer from '../../../../SharedComponents/VideoPlayer/VideoPlayer';
import { switchBasedOnDevice } from '../../../../Utils/PageUtils';
import useHIAssemblyBatteryPageHandler from './HIAssemblyBatteryPageHandlerHook';
import useHIAssemblyBatteryPageResource from './HIAssemblyBatteryPageResourceHook';
import '.././HIAssemblyPageStyle.scss';

export interface IHIAssemblyPageProps {
    workflow: string;
}

function HIAssemblyBatteryPage() {
    const { onContinueAction, logVideoLoadingError } =
        useHIAssemblyBatteryPageHandler();

    const {
        header,
        title,
        instructionsText,
        video,
        image,
        continueText,
        continueButton,
    } = useHIAssemblyBatteryPageResource();

    return (
        <AppContainer>
            <Header headerContent={header} />
            <BodyContainer>
                {switchBasedOnDevice(getUserDeviceModel(), {
                    OrionAir: <></>,
                    OrionC20: (
                        <>
                            <CustomTypography
                                id={title.id}
                                type={TypographyType.notoSansSubHeading}
                                text={title.text}
                                className={'hiAssemblyTitle'}
                            />
                            <CustomImg
                                {...image}
                                imgType="small"
                                style={{ margin: '4vh 0' }}
                            />
                        </>
                    ),
                    OrionGo: (
                        <>
                            <CustomTypography
                                id={title.id}
                                type={TypographyType.notoSansSubHeading}
                                text={title.text}
                                className={'hiAssemblyTitle'}
                            />
                            <CustomImg
                                {...image}
                                imgType="small"
                                style={{ margin: '4vh 0' }}
                            />
                        </>
                    ),
                    OrionComplete: (
                        <CustomImg
                            {...image}
                            imgType="small"
                            style={{ margin: '4vh 0' }}
                        />
                    ),
                })}
                <CustomTypography
                    id={instructionsText.id}
                    type={TypographyType.notoSansBody16px}
                    text={instructionsText.text}
                    className={'hiAssemblyInstructions'}
                />
                {switchBasedOnDevice(getUserDeviceModel(), {
                    OrionAir: (
                        <VideoPlayer
                            id={video.id}
                            url={video.url}
                            videoType={video.videoType}
                            previewImageSrc={video.previewImageSrc}
                            onError={() =>
                                logVideoLoadingError(HIAssemblyBatteryPage.name)
                            }
                        />
                    ),
                    OrionC20: <></>,
                    OrionGo: <></>,
                    OrionComplete: <></>,
                })}
                <CustomTypography
                    id={continueText.id}
                    type={TypographyType.notoSansBody16px}
                    text={continueText.text}
                />
            </BodyContainer>
            <CustomButton
                id={continueButton.id}
                buttonType={ButtonType.PrimaryLoading}
                onClick={async () => {
                    await onContinueAction();
                }}
            >
                {continueButton.label}
            </CustomButton>
        </AppContainer>
    );
}

export default withTracking(HIAssemblyBatteryPage);
