import React, { useEffect, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import AuthService from '../../../Services/Authentication/AuthService';
import LocalStorageService from '../../../Services/LocalStorageService';
import { VoidReturn } from '../../../Utils/PageUtils';
import { goToUrl } from '../../../Utils/NavigationUtils';
import { PrivacyPolicyAndTermsAndConditionsLink } from '../../../CommonAssets/Configuration/PrivacyPolicyAndTermsAndConditions';
import { setPatientInfo } from '../../../Utils/PatientUtils';
import { TransitionAction } from '../MainView/MainViewModel';
import { MainViewContext } from '../MainView/MainViewContext';

export type ConsentAnalyticHandler = {
    openHyperLinkPrivacy: () => VoidReturn;
    buttonContinueAction: (consented: boolean) => VoidReturn;
};

function useConsentAnalyticHandler(
    setLoading: React.Dispatch<boolean>
): ConsentAnalyticHandler {
    const history = useHistory();
    const viewContext = useContext(MainViewContext);

    const loading = useCallback(async () => {
        setLoading(true);
        const patientId = LocalStorageService.serviceInstance.getPatientId();
        if (patientId === '') {
            const refreshCookies = async () => {
                await AuthService.adB2cInstance.refreshCookies();
            };
            refreshCookies();
        }

        setLoading(false);
    }, [setLoading]);

    useEffect(() => {
        loading();
    }, [history, loading]);

    const openHyperLinkPrivacy = () => {
        goToUrl(PrivacyPolicyAndTermsAndConditionsLink);
    };

    const buttonContinueAction = (consented: boolean) => {
        setPatientInfo(consented);
        viewContext.doTransition(TransitionAction.AnalyticsConsentContinue);
    };

    return {
        openHyperLinkPrivacy,
        buttonContinueAction,
    };
}

export default useConsentAnalyticHandler;
