import { FineTuningDeviceStates } from '../../../../../Models/FineTuning/FineTuningDeviceStates';
import { FineTuningSolution } from '../../../../../Models/FineTuning/FineTuningSolution';
import { FineTuningSolutionState } from '../../../../../Models/FineTuning/FineTuningSolutionState';
import { SolutionValueChanged } from '../../../../../Models/FineTuning/SolutionValueChanged';
import FineTuningService from '../../../../../Services/FineTuningService';
import { t } from '../../../../../Services/LocalizationService';
import LocalStorageService from '../../../../../Services/LocalStorageService';
import TelemetryService from '../../../../../Services/Monitoring/TelemetryService';
import { getCouplingOrDefault } from '../../../../../Utils/CouplingUtils';
import { useFineTuningContext } from '../../../FineTuningContext';
import { useFineTuningSolutionContext } from '../../FineTuningSolutionContext';
import useFineTuningSolutionPageHandler from '../../FineTuningSolutionPageHandlerHook';
import { isChangeSleeveSolution } from '../../../../../Utils/ChangeSleeveUtils';
import { getUserDeviceModel } from '../../../../../Services/ResourceService';
import HLAAService from '../../../../../Services/HLAAService';
import LoggingService from '../../../../../Services/LoggingService';
import DeviceManagementService from '../../../../../Services/DeviceManagementService';

type FTFooterAfterApplyPageHandler = {
    handleSolutionSolved: () => Promise<void>;
    handleSolutionNotSolved: () => Promise<void>;
};

const useFTFooterAfterApplyPageHandler = (): FTFooterAfterApplyPageHandler => {
    const { info } = useFineTuningContext();
    const {
        fineTuningSolutionInfo,
        activeSolutionStep,
        setFineTuningSolutionState,
        setActiveSolutionStep,
    } = useFineTuningSolutionContext();
    const { updateMasterGainAndBandEqualizer } =
        useFineTuningSolutionPageHandler();

    const sendTrackingAudiogramEvent = (value: string, action: string) => {
        const solutionName =
            fineTuningSolutionInfo.current.solutionList[activeSolutionStep]
                .name;
        TelemetryService.serviceInstance.TrackAnonymous('Orion-FineTuning', {
            Solution: solutionName + action,
            CategoryProblem:
                t(
                    `fineTuning:FineTuning_Feature:Category:${info.current.selected.category}`,
                    {
                        lng: 'en',
                    }
                ) +
                '_' +
                t(
                    `fineTuning:FineTuning_Feature:Problem:${info.current.selected.problem.replace(
                        /_/g,
                        ':'
                    )}`,
                    {
                        lng: 'en',
                    }
                ),
            Model: getUserDeviceModel(),
            FreqAndDb: value,
        });
    };

    const handleSolutionSolved = async () => {
        const hlaaTestId =
            LocalStorageService.serviceInstance.getLastCompletedHlaaTestId();
        const audiogram = await HLAAService.getResult(hlaaTestId);
        if (audiogram) {
            for (const value of Object.values(audiogram.leftAudiogramIndex)) {
                sendTrackingAudiogramEvent(value, '_Applied');
            }
            for (const value of Object.values(audiogram.rightAudiogramIndex)) {
                sendTrackingAudiogramEvent(value, '_Applied');
            }
        } else {
            LoggingService.error({
                componentName: useFTFooterAfterApplyPageHandler.name,
                args: ['Audiogram was not found'],
            });
        }

        const leftDeviceId =
            LocalStorageService.serviceInstance.getDeviceId('left');
        const rightDeviceId =
            LocalStorageService.serviceInstance.getDeviceId('right');
        const leftCluster = await DeviceManagementService.getClusterId(
            leftDeviceId
        );
        const rightCluster = await DeviceManagementService.getClusterId(
            rightDeviceId
        );
        const prevLeftDevice = info.current.deviceState[leftDeviceId];
        const prevRightDevice = info.current.deviceState[rightDeviceId];
        const leftDevice = info.current.deviceStateFinal[leftDeviceId];
        const rightDevice = info.current.deviceStateFinal[rightDeviceId];
        const solution = info.current.fineTuningSolution?.name;
        const userDeviceModel = getUserDeviceModel();

        TelemetryService.serviceInstance.TrackAnonymous(
            'Orion-FineTuned-MasterGain',
            {
                Model: userDeviceModel,
                SolutionName: `${solution}_Apply` ?? '',
                LeftCluster: leftCluster.toString(),
                PreviousLeftMasterGain:
                    prevLeftDevice.easyfitMasterGain.toString(),
                CurrentLeftMasterGain: leftDevice.easyfitMasterGain.toString(),
            }
        );

        TelemetryService.serviceInstance.TrackAnonymous(
            'Orion-FineTuned-MasterGain',
            {
                Model: userDeviceModel,
                SolutionName: `${solution}_Apply` ?? '',
                RightCluster: rightCluster.toString(),
                PreviousRightMasterGain:
                    prevRightDevice.easyfitMasterGain.toString(),
                CurrentRightMasterGain:
                    rightDevice.easyfitMasterGain.toString(),
            }
        );

        TelemetryService.serviceInstance.TrackAnonymous(
            'Orion-Left-FineTuned-BandEqualizer',
            {
                Model: userDeviceModel,
                SolutionName: `${solution}_Apply` ?? '',
                LeftCluster: leftCluster.toString(),
                PreviousBand1: prevLeftDevice.band1EqualizerGain.toString(),
                PreviousBand2: prevLeftDevice.band2EqualizerGain.toString(),
                PreviousBand3: prevLeftDevice.band3EqualizerGain.toString(),
                PreviousBand4: prevLeftDevice.band4EqualizerGain.toString(),
                CurrentBand1: leftDevice.band1EqualizerGain.toString(),
                CurrentBand2: leftDevice.band2EqualizerGain.toString(),
                CurrentBand3: leftDevice.band3EqualizerGain.toString(),
                CurrentBand4: leftDevice.band4EqualizerGain.toString(),
            }
        );

        TelemetryService.serviceInstance.TrackAnonymous(
            'Orion-Right-FineTuned-BandEqualizer',
            {
                Model: userDeviceModel,
                SolutionName: `${solution}_Apply` ?? '',
                RightCluster: rightCluster.toString(),
                PreviousBand1: prevRightDevice.band1EqualizerGain.toString(),
                PreviousBand2: prevRightDevice.band2EqualizerGain.toString(),
                PreviousBand3: prevRightDevice.band3EqualizerGain.toString(),
                PreviousBand4: prevRightDevice.band4EqualizerGain.toString(),
                CurrentBand1: rightDevice.band1EqualizerGain.toString(),
                CurrentBand2: rightDevice.band2EqualizerGain.toString(),
                CurrentBand3: rightDevice.band3EqualizerGain.toString(),
                CurrentBand4: rightDevice.band4EqualizerGain.toString(),
            }
        );

        await FineTuningService.fineTuningConfirmed(
            LocalStorageService.serviceInstance.getActiveHearingProfileId()
        );
        setFineTuningSolutionState(FineTuningSolutionState.AppliedSuccessfully);
    };

    const resetFineTuningSolution = async () => {
        await updateMasterGainAndBandEqualizer(
            SolutionValueChanged.Both,
            info.current.deviceState
        );

        await FineTuningService.fineTuningReset(
            LocalStorageService.serviceInstance.getActiveHearingProfileId(),
            false,
            new FineTuningDeviceStates(info.current.deviceState)
        );
    };

    const handleSolutionNotSolved = async () => {
        const hlaaTestId =
            LocalStorageService.serviceInstance.getLastCompletedHlaaTestId();
        const audiogram = await HLAAService.getResult(hlaaTestId);
        if (audiogram) {
            for (const value of Object.values(audiogram.leftAudiogramIndex)) {
                sendTrackingAudiogramEvent(value, '_Discarded');
            }
            for (const value of Object.values(audiogram.rightAudiogramIndex)) {
                sendTrackingAudiogramEvent(value, '_Discarded');
            }
        } else {
            LoggingService.error({
                componentName: useFTFooterAfterApplyPageHandler.name,
                args: ['Audiogram was not found'],
            });
        }

        setFineTuningSolutionState(FineTuningSolutionState.Waiting);
        await resetFineTuningSolution();
        if (
            activeSolutionStep ===
            fineTuningSolutionInfo.current.solutionList.length - 1
        ) {
            const nextSolution = await FineTuningService.getFineTuningSolution(
                LocalStorageService.serviceInstance.getActiveHearingProfileId(),
                LocalStorageService.serviceInstance.getDeviceModelId('left'),
                info.current.selected.category,
                info.current.selected.problem,
                activeSolutionStep + 2,
                getCouplingOrDefault('left') | 0
            );
            if (nextSolution !== undefined) {
                setActiveSolutionStep((prevStep) => prevStep + 1);
                fineTuningSolutionInfo.current.solutionList.push(nextSolution);
                if (isChangeSleeveSolution(nextSolution.name)) {
                    setFineTuningSolutionState(
                        FineTuningSolutionState.ChangeSleeveRecommend
                    );
                } else {
                    setFineTuningSolutionState(
                        FineTuningSolutionState.BeforeApply
                    );
                }
            } else {
                fineTuningSolutionInfo.current.solutionList.push(
                    {} as FineTuningSolution
                );
                setActiveSolutionStep((prevStep) => prevStep + 1);
                setFineTuningSolutionState(
                    FineTuningSolutionState.CustomerService
                );
            }
        } else {
            if (
                Object.keys(
                    fineTuningSolutionInfo.current.solutionList[
                        activeSolutionStep + 1
                    ]
                ).length === 0
            ) {
                setActiveSolutionStep((prevStep) => prevStep + 1);
                setFineTuningSolutionState(
                    FineTuningSolutionState.CustomerService
                );
            } else {
                setActiveSolutionStep((prevStep) => prevStep + 1);
                setFineTuningSolutionState(FineTuningSolutionState.BeforeApply);
            }
        }
    };

    return {
        handleSolutionNotSolved,
        handleSolutionSolved,
    };
};

export default useFTFooterAfterApplyPageHandler;
