import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { getUserDeviceModel } from '../../../../Services/ResourceService';
import { HeaderActionType } from '../../../../SharedComponents/Header/HeaderActionType';
import {
    HeaderResource,
    ImageResource,
    switchBasedOnDevice,
    TextResource,
} from '../../../../Utils/PageUtils';
import { InitialSettingContext } from '../InitialSettingContext';

export type InitialSettingWritingSideResource = {
    header: HeaderResource;
    titleWriting: TextResource;
    instructionWriting: TextResource;
    instructionImageWriting: ImageResource;
};

export default function useInitialSettingWritingSideResource(): InitialSettingWritingSideResource {
    const { t } = useTranslation();
    const { info } = useContext(InitialSettingContext);
    return {
        header: {
            id: 'header-initial-setting',
            text: t(`common:Common_Feature:ApplySettings`),
            type: HeaderActionType.Menu,
        },
        titleWriting: {
            id: `txt-initial-setting-title-${info.activeSide}`,
            text: t(
                `pairing:Pairing_Feature:${
                    info.activeSide.toLowerCase() === 'left' ? 'Left' : 'Right'
                }Title`
            ),
        },
        instructionWriting: {
            id: `txt-initial-setting-instruction-${info.activeSide}`,
            text: t(`initialSettings:InitialSettings_Feature:Writing:Text`),
        },
        instructionImageWriting: {
            id: `img-initial-setting-writing-side-${info.activeSide.toLowerCase()}`,
            src: switchBasedOnDevice(getUserDeviceModel(), {
                OrionAir: `/resources/OrionAir/evaluation_${info.activeSide.toLowerCase()}_orionAir.svg`,
                OrionC20: `/resources/OrionC20/evaluation_${info.activeSide.toLowerCase()}_orionC20.svg`,
                OrionGo: `/resources/OrionGo/evaluation_${info.activeSide.toLowerCase()}_orionGo.svg`,
            }),
        },
    };
}
