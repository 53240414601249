import { useTranslation } from 'react-i18next';

export type FineTuningInfoPageResource = {
    headerText: string;
    infoImgSrc: string;
    instruction1: string;
    instruction2: string;
    instructionBullet: string;
    instruction3: string;
    buttonText: string;
};

const useFineTuningInfoPageResource = (): FineTuningInfoPageResource => {
    const { t } = useTranslation();
    const headerText = t('common:Common_Feature:FineTuning');
    const infoImgSrc =
        '/resources/fineTuningWorkflow/know-how-for_center-screen.svg';
    const instruction1 = t('fineTuning:FineTuning_Feature:Info:TitleText');
    const instruction2 = t('fineTuning:FineTuning_Feature:Info:Text');
    const instructionBullet = t('fineTuning:FineTuning_Feature:Info:Text2');
    const instruction3 = t('fineTuning:FineTuning_Feature:Info:Text3');
    const buttonText = t('fineTuning:FineTuning_Feature:Info:NextButton');

    return {
        headerText,
        infoImgSrc,
        instruction1,
        instruction2,
        instructionBullet,
        instruction3,
        buttonText,
    };
};

export default useFineTuningInfoPageResource;
