import { Patient } from '../../../Models/Patient/Patient';

export type ViewState = {
    dialog: DialogState;
    page: PageState;
};

export type ITransitionHandler = {
    doTransition: (
        currentView: ViewState,
        action: TransitionAction,
        patient: Patient | undefined
    ) => Promise<ViewState>;
    goToInitialState: (
        patient: Patient | undefined,
        debugRoute?: PageState,
        initial?: PageState
    ) => Promise<ViewState>;
};

export enum MainViewWorkflow {
    Web = '',
    AppFitting = 'app/fitting',
    AppFineTuning = 'app/finetuning',
}

export enum PageState {
    Loading = 'Loading',
    SplashScreen = 'SplashScreen',
    Welcome = 'Welcome',
    Consent = 'Consent',
    ConsentAnalytic = 'ConsentAnalytic',
    HiAssemble = 'HiAssemble',
    Contraindication = 'Contraindication',
    Journey = 'Journey',
    Pairing = 'Pairing',
    RePairing = 'RePairing',
    Assessment = 'Assessment',
    RedoHLAA = 'RedoHLAA',
    InitialSettings = 'InitialSettings',
    CompleteRecommendation = 'CompleteRecommendation',
    SpeechComfort = 'SpeechComfort',
    RedoLoudness = 'RedoLoudness',
    Landing = 'Landing',
    FineTuning = 'FineTuning',
    FineTuningComplete = 'FineTuningComplete',
    SmartRemote = 'SmartRemote',
    RedoAssessmentOption = 'RedoAssessmentOption',
    FineTuningWorkflowError = 'FineTuningWorkflowError',
    DeviceIncompatibleError = 'DeviceIncompatibleError',
    SleeveSelection = 'SleeveSelection',
    JourneyReturnToTimeline = 'JourneyReturnToTimeline',
    None = 'None',
}

export enum DialogState {
    PairingConnectionLost = 'PairingConnectionLost',
    DeviceInfoCheck = 'DeviceInfoCheck',
    UpdateApp = 'UpdateApp',
    ReturnToTimeline = 'ReturnToTimeline',
    MobileDeviceUnmutedCheck = 'MobileDeviceUnmutedCheck',
    LeaveApp = 'LeaveApp',
    None = 'None',
}

export enum TransitionAction {
    BackButton,
    WelcomeContinue,
    JourneyResume,
    HiAssembleContinue,
    ConsentContinue,
    AnalyticsConsentContinue,
    ContraindicationContinue,
    PairingContinue,
    AssessmentContinue,
    AssessmentZeroTone,
    AssessmentReinstructExit,
    AssessmentMaxToneReachedExit,
    InitialSettingsContinue,
    InitialSettingsSevereLoss,
    InitialSettingsMismatchedLoss,
    SpeechComfortContinue,
    RedoLoudnessContinue,
    CompleteRecommendationContinue,
    GoToFineTuningLandingPage,
    FineTuningComplete,
    FineTuningWorkflowError,
    GoFineTuning,
    GoRedoLoudness,
    RedoHLAAViaJourney,
    DoRepeatPairing,
    DoRedoEasyFit,
    RedoEasyFitContinue,
    DoRedoHLAA,
    GoToAssessment,
    SkipRedoEasyFit,
    SkipRedoHLAA,
    ResetUserDeviceProfileAndWorkflow,
    ExitInAppBrowser,
    ChangeSleeveCustomerServiceContinue,
    UpdateAppDialog,
    UpdateAppDialogExit,
    SplashScreenContinue,
    DoReturnToTimelineDialogOpen,
    DoMobileDeviceUnmutedCheckDialogOpen,
    DoReturnToPageDialogClose,
    GoToTimeline,
    LoopConditionExit,
    RedoStep,
    GoSplashScreen,
    DoLeaveApp,
    DoLeaveAppDialogOpen,
    DoLeaveAppDialogClose,
}

export interface IMainViewProps {
    workflowType: MainViewWorkflow;
    useTransitionHandler: () => ITransitionHandler;
}
