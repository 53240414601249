import { useTranslation } from 'react-i18next';
import { ButtonResource, TextResource } from '../../../../../Utils/PageUtils';

export type ChangeSleeveRecommendPageResource = {
    instruction: TextResource;
    yesButton: ButtonResource;
    noButton: ButtonResource;
};

const useChangeSleeveRecommendPageResource =
    (): ChangeSleeveRecommendPageResource => {
        const { t } = useTranslation();
        const instruction = {
            id: 'change-sleeve-instruction',
            text: t('fineTuning:FineTuning_Feature:Solution:Text18'),
        };
        const yesButton = {
            id: 'change-sleeve-yes-button',
            label: t('common:Common_Feature:Yes'),
        };
        const noButton = {
            id: 'change-sleeve-no-button',
            label: t('common:Common_Feature:No'),
        };
        return {
            instruction,
            yesButton,
            noButton,
        };
    };

export default useChangeSleeveRecommendPageResource;
