import { useTranslation } from 'react-i18next';
import { getUserDeviceModel } from '../../../../Services/ResourceService';
import { HeaderActionType } from '../../../../SharedComponents/Header/HeaderActionType';
import {
    ButtonResource,
    HeaderResource,
    ImageResource,
    switchBasedOnDevice,
    TextResource,
} from '../../../../Utils/PageUtils';

export type SpeechComfortTestDetailPageResource = {
    header: HeaderResource;
    instructionImage: ImageResource;
    instructionImageText: TextResource;
    instructionText: TextResource;
    button: ButtonResource;
};

export default function useSpeechComfortTestDetailPageResource(): SpeechComfortTestDetailPageResource {
    const { t } = useTranslation();
    return {
        header: {
            id: 'speech-comfort-header',
            text: t('common:Common_Feature.AdjustLoudness'),
            type: HeaderActionType.Menu,
        },
        instructionImage: {
            id: 'result-center-illustration',
            src: switchBasedOnDevice(getUserDeviceModel(), {
                OrionAir:
                    '/resources/OrionAir/result-center-illustration-orionAir.svg',
                OrionC20:
                    '/resources/OrionC20/result-center-illustration-orionC20.svg',
                OrionGo:
                    '/resources/OrionGo/result-center-illustration-orionGo.svg',
            }),
            alt: t('speechComfort:detail:label'),
        },
        instructionImageText: {
            id: 'image-label',
            text: `\`redorange{${t('common:Common_Feature:Loudness')}}\``,
        },
        instructionText: {
            id: 'instruction-text',
            text: t('speechComfort:SpeechComfort_Feature:Detail:Text'),
        },
        button: {
            id: 'btn-finish-setup',
            label: t('common:Common_Feature:Continue'),
        },
    };
}
