import React from 'react';
import { withTracking } from '../../../../Services/Monitoring/ApplicationInsights';
import CustomTypography, {
    TypographyType,
} from '../../../Typography/CustomTypography';
import useMobileDeviceUnmutedCheckStartPageResource from './MobileDeviceUnmutedCheckStartPageResourceHook';
import useMobileDeviceUnmutedCheckStartPageHandler from './MobileDeviceUnmutedCheckStartPageHandlerHook';
import ButtonStickyContainer from '../../../Container/ButtonStickyContainer';
import CustomIconButton from '../../../Button/CustomIconButton';
import { CircularProgress } from '@material-ui/core';
import './MobileDeviceUnmutedCheckStartPage.scss';
import BodyContainer from '../../../Container/BodyContainer';

function MobileDeviceUnmutedCheckStartPage() {
    const { infoText, progress, iconButton, playImage } =
        useMobileDeviceUnmutedCheckStartPageResource();

    const { playMedia } = useMobileDeviceUnmutedCheckStartPageHandler();

    return (
        <>
            <BodyContainer>
                <CustomTypography
                    id={infoText.id}
                    type={TypographyType.notoSansBody16px}
                    text={infoText.text}
                    className={'MobileDeviceUnmutedCheckStartPageInfoText'}
                />
            </BodyContainer>
            <ButtonStickyContainer>
                <div className={'actionArea'}>
                    <CircularProgress
                        id={progress.id}
                        className={'staticProgress'}
                        variant="determinate"
                        size={'12vh'}
                        value={100}
                        thickness={1}
                    ></CircularProgress>

                    <CustomIconButton
                        id={iconButton.id}
                        onClick={playMedia}
                        className={'playImage'}
                        imgSrc={playImage.src}
                        imgId={playImage.id}
                        iconSize="iconLarge"
                    />
                </div>
            </ButtonStickyContainer>
        </>
    );
}

export default withTracking(MobileDeviceUnmutedCheckStartPage);
