import React from 'react';
import { ButtonType } from '../../../SharedComponents/Button/ButtonType';
import CustomButton from '../../../SharedComponents/Button/CustomButton';
import AppContainer from '../../../SharedComponents/Container/AppContainer';
import BodyContainer from '../../../SharedComponents/Container/BodyContainer';
import CustomImg from '../../../SharedComponents/CustomImage/CustomImg';
import Header from '../../../SharedComponents/Header/HeaderRefactor';
import CustomTypography, {
    TypographyType,
} from '../../../SharedComponents/Typography/CustomTypography';
import {
    ButtonResource,
    HeaderResource,
    ImageResource,
    linkCallBacksResource,
    TextResource,
    VoidReturn,
} from '../../../Utils/PageUtils';
import './TroubleShootingStyle.scss';

export type TroubleShootingHandler = {
    onRetry: () => VoidReturn;
    linkCallBacks: linkCallBacksResource;
    retryBeepConfirmationThresholdReached?: () => boolean;
};

export type TroubleShootingResource = {
    header: HeaderResource;
    image: ImageResource;
    troubleShootingMessage: TextResource;
    buttonLabel: ButtonResource;
    customerSupportMessage: TextResource;
    help: TextResource;
};

export interface ITroubleShooting {
    useHandler: () => TroubleShootingHandler;
    useResource: () => TroubleShootingResource;
}

export default function TroubleShooting({
    useHandler,
    useResource,
}: ITroubleShooting): JSX.Element {
    const {
        header,
        image,
        troubleShootingMessage,
        buttonLabel,
        customerSupportMessage,
        help,
    } = useResource();

    const { onRetry, linkCallBacks } = useHandler();

    return (
        <AppContainer>
            <Header headerContent={header} />
            <BodyContainer>
                <CustomImg
                    imgType={'small'}
                    id={image.id}
                    src={image.src}
                    alt={image.alt}
                    className="TroubleShooting-image"
                />
                <CustomTypography
                    id={troubleShootingMessage.id}
                    type={TypographyType.notoSansBody16px}
                    text={troubleShootingMessage.text}
                    textAlign={'left'}
                    linkCallBacks={linkCallBacks}
                />
            </BodyContainer>
            <CustomButton
                buttonType={ButtonType.Primary}
                id={buttonLabel.id}
                onClick={onRetry}
            >
                {buttonLabel.label}
            </CustomButton>
            <CustomTypography
                id={customerSupportMessage.id}
                type={TypographyType.notoSansBody16px}
                text={customerSupportMessage.text}
                linkCallBacks={linkCallBacks}
                className="TroubleShooting-customer-support-link"
            />
            <BodyContainer className="TroubleShootingIssueBottomHelpContainer">
                <CustomTypography
                    id={help.id}
                    type={TypographyType.notoSansBody14px}
                    text={help.text}
                    linkCallBacks={linkCallBacks}
                />
            </BodyContainer>
        </AppContainer>
    );
}
