/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Volume } from './Volume';

export class VolumeUIMapping {
    public display: {
        [side: string]: {
            volumeGain: number;
            startingPosition: number;
            min: number;
            max: number;
        };
    };

    public uiToValueMap: Map<string, Map<number, number>>;
    public valueToUiMap: Map<string, Map<number, number>>;

    constructor(volumeGains: Volume[]) {
        this.uiToValueMap = new Map<string, Map<number, number>>();
        this.valueToUiMap = new Map<string, Map<number, number>>();
        this.initializeMaps(volumeGains);
        this.display = this.convertToDisplay(volumeGains);
    }

    private initializeMaps(masterGains: Volume[]) {
        masterGains.forEach((x) => {
            this.uiToValueMap.set(
                x.side,
                this.createDisplayMapForUI(
                    x.volumeId,
                    x.volumeUiMinId,
                    x.volumeUiMaxId
                )
            );
            this.valueToUiMap.set(
                x.side,
                this.createValueMapForBack(
                    x.volumeId,
                    x.volumeUiMinId,
                    x.volumeUiMaxId
                )
            );
        });
    }

    private convertToDisplay(masterGains: Volume[]) {
        return Object.assign(
            {},
            ...masterGains.map((x) => ({
                [x.side]: {
                    masterGain: this.valueToUiMap.get(x.side)!.get(x.volumeId),
                    startingPosition: this.valueToUiMap
                        .get(x.side)!
                        .get(x.volumeId),
                    min: this.valueToUiMap.get(x.side)!.get(x.volumeUiMinId),
                    max: this.valueToUiMap.get(x.side)!.get(x.volumeUiMaxId),
                },
            }))
        );
    }

    /**
     * Backend returns absolute values for min and max.
     * Which means e.g. 0 - max, 15 - min
     * We invert it to be user friendly:
     *
     * e.g.
     * backend gives:
     * max : 6
     * min : 14
     *
     * returns: a relative value from absGain will be displayed (-/+)
     *  {
     *      {4: 6},
     *      {3: 7},
     *      {2: 8},
     *      {1: 9},
     *      {0: 10},
     *      {-1: 11},
     *      {-2: 12},
     *      {-3: 13},
     *      {-4: 14},
     * }
     */
    private createDisplayMapForUI(
        absGain: number,
        absMin: number,
        absMax: number
    ) {
        const map = new Map<number, number>();

        const startingIndex = (absGain - absMax) * -1; // base point being absGain
        const endingIndex = absMin - absGain;

        for (
            let val = absMin, index = startingIndex;
            index <= endingIndex;
            val--, index++
        ) {
            map.set(index, val);
        }

        return map;
    }

    /**
     * Reverse the above to make our life easier when fetching..
     */
    private createValueMapForBack(
        absGain: number,
        absMin: number,
        absMax: number
    ) {
        const map = new Map<number, number>();

        const startingIndex = (absGain - absMax) * -1; // base point being absGain
        const endingIndex = absMin - absGain;

        for (
            let val = absMin, index = startingIndex;
            index <= endingIndex;
            val--, index++
        ) {
            map.set(val, index);
        }

        return map;
    }
}
