import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './InitialState';

const hlaaSlice = createSlice({
    name: 'hlaaSlice',
    initialState: initialState.hlaaState,
    reducers: {
        updateHlaa: (state, action) => {
            return action.payload;
        },
    },
});

export const { updateHlaa } = hlaaSlice.actions;

export const hlaaReducer = hlaaSlice.reducer;
