import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { deviceModel } from '../../../Models/SupportedDeviceModel';
import { getUserDeviceModel } from '../../../Services/ResourceService';
import { HeaderActionType } from '../../../SharedComponents/Header/HeaderActionType';
import {
    ButtonResource,
    HeaderResource,
    ImageResource,
    switchBasedOnDevice,
    TextResource,
} from '../../../Utils/PageUtils';
import { PairingContext } from '../PairingContext';

export type PairingPrepareResource = {
    header: HeaderResource;
    instructionImage: ImageResource;
    instruction: TextResource;
    prepareCompleteButton: ButtonResource;
};

export default function usePairingPrepareResource(): PairingPrepareResource {
    const { t } = useTranslation();
    const {
        state: { context },
    } = useContext(PairingContext);

    const generalKey = switchBasedOnDevice(getUserDeviceModel(), {
        OrionAir: 'orionAir:OrionAir_Device:Pairing_Feature:Prepare',
        OrionC20: 'orionC20:OrionC20_Device:Pairing_Feature:Prepare',
    }) as string;

    const header = {
        id: 'header-pairing-prepare',
        text: t(
            `${generalKey}:${
                context.activeSide === 'left' ? 'Left' : 'Right'
            }:Header`
        ),
        type: HeaderActionType.Menu,
    };

    const instructionImage = {
        id: 'img-instruction',
        src:
            getUserDeviceModel() === deviceModel.OrionAir
                ? `/resources/OrionAir/repair-${context.activeSide}.svg`
                : `/resources/OrionC20/charger-${context.activeSide}.svg`,
        alt: 'open-battery-door',
    };
    const instruction = {
        id: 'txt-instruction',
        text: t(
            `${generalKey}:${context.activeSide === 'left' ? 'Text1' : 'Text2'}`
        ),
    };
    const prepareCompleteButton = {
        label: t(`common:Common_Feature:Next`),
        id: 'btn-next',
    };

    return {
        header,
        instructionImage,
        instruction,
        prepareCompleteButton,
    };
}
