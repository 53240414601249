import { useTranslation } from 'react-i18next';
import { WarningDialogResource } from '../Warning/WarningDialog';
import { HeaderActionType } from '../../Header/HeaderActionType';

export default function useFineTuningErrorResource(): WarningDialogResource {
    const { t } = useTranslation();

    const header = {
        id: 'header-finetuning-error',
        text: t('common:Common_Feature:Alert'),
        type: HeaderActionType.NoAction,
    };

    const image = {
        id: 'img-errorFinetuning',
        src: '/resources/sharedAcrossWorkflows/Alert.svg',
        alt: '',
    };

    const warningTitle = {
        id: 'txt-title',
        text: t('fineTuning:FineTuning_Feature:Error:TitleText'),
    };

    const warningMessage = {
        id: 'txt-message',
        text: t('fineTuning:FineTuning_Feature:Error:Text'),
    };

    const warningInfo = {
        id: 'txt-info',
        text: '',
    };

    const buttonLabels = {
        primaryLabel: {
            id: 'btn-exit',
            label: t('common:Common_Feature:Exit'),
        },
    };

    return {
        header,
        image,
        warningTitle,
        warningMessage,
        warningInfo,
        buttonLabels,
    };
}
