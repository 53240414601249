import { useFineTuningSolutionContext } from '../../FineTuningSolutionContext';
import { FineTuningSolutionState } from '../../../../../Models/FineTuning/FineTuningSolutionState';
import { t } from '../../../../../Services/LocalizationService';
type FTFooterWarningPageResource = {
    warningText: {
        id: string;
        text: string;
    };

    warningButtonTexts: {
        primary: string;
        secondary: string;
    };
};

export default function useFTFooterWarningPageResource(): FTFooterWarningPageResource {
    const { fineTuningSolutionState } = useFineTuningSolutionContext();

    const isWarningCustomerServiceState =
        fineTuningSolutionState ===
        FineTuningSolutionState.WarningCustomerService;

    const warningText = {
        id: 'txt-warning',
        text: t(
            `fineTuning:FineTuning_Feature:Solution:Text${
                isWarningCustomerServiceState ? '16' : '7'
            }`
        ),
    };

    const warningButtonTexts = {
        primary: t(
            `common:Common_Feature:${
                isWarningCustomerServiceState ? 'Leave' : 'Refine'
            }`
        ),
        secondary: t(
            `common:Common_Feature:${
                isWarningCustomerServiceState ? 'Stay' : 'KeepButtonText'
            }`
        ),
    };

    return {
        warningText,
        warningButtonTexts,
    };
}
