import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderActionType } from '../../../SharedComponents/Header/HeaderActionType';
import { HeaderResource } from '../../../Utils/PageUtils';
import { HLAAContext } from '../HLAAContext';

type HearingAssessmentOneSideDoneResource = {
    header: HeaderResource;

    image: {
        id: string;
        src: string;
        alt: string;
    };

    lodingImage: {
        id: string;
        src: string;
        alt: string;
    };

    instruction: {
        id1: string;
        id2: string;
        id3: string;
        text1: string;
    };

    errorText: {
        id: string;
        color: string;
    };

    button: {
        id: string;
        text: string;
    };

    infoText: {
        id: string;
        text: string;
    };

    progress: {
        id: string;
    };
};

function useHearingAssessmentOneSideDoneResource(): HearingAssessmentOneSideDoneResource {
    const { t } = useTranslation();
    const { info } = useContext(HLAAContext);

    const header = {
        id: 'header-hlaa-oneSide-done',
        text: t('common:Common_Feature:HearingProfileCheck'),
        type: HeaderActionType.Menu,
    };

    const image = {
        id: 'img-oneSide-done',
        src: `/resources/sharedAcrossWorkflows/successful_tick-mark.svg`,
        alt: `${info.current.activeSide.toLowerCase()}`,
    };

    const instruction = {
        id1: 'txt-content',
        id2: 'txt-content1',
        id3: 'txt-content2',
        text1: t(
            info.current.activeSide.toLowerCase() === 'left'
                ? 'hlaa:Hlaa_Feature:OneSideDone:Text'
                : 'hlaa:Hlaa_Feature:BothSideDone:Text'
        ),
    };

    const progress = {
        id: 'one-side-progress-loading',
    };

    const errorText = {
        id: 'txt-error',
        color: 'error',
    };

    const button = {
        id: `btn-one-side-done`,
        text: t(
            info.current.activeSide.toLowerCase() === 'left'
                ? 'common:Common_Feature:Continue'
                : 'common:Common_Feature:Next'
        ),
    };

    const lodingImage = {
        id: `img-${info.current.activeSide.toLowerCase()}`,
        src: `/resources/sharedAcrossWorkflows/wait-asset.svg`,
        alt: `${info.current.activeSide.toLowerCase()}`,
    };

    const infoText = {
        id: 'txt-info',
        text: t('hlaa:Hlaa_Feature:Setup:Text'),
    };

    return {
        header,
        image,
        instruction,
        errorText,
        button,
        infoText,
        lodingImage,
        progress,
    };
}

export default useHearingAssessmentOneSideDoneResource;
