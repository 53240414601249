import { useTranslation } from 'react-i18next';
import {
    ButtonResource,
    ImageResource,
    TextResource,
} from '../../../../Utils/PageUtils';

type MobileDeviceUnmutedCheckDonePageResource = {
    infoText: TextResource;
    image: ImageResource;
    button: ButtonResource;
};

export default function useMobileDeviceUnmutedCheckDonePageResource(): MobileDeviceUnmutedCheckDonePageResource {
    const { t } = useTranslation();

    const infoText = {
        id: 'txt-unmuted-check-done-info',
        text: t('common:Common_Feature:MobileDeviceUnmutedCheck:Done:Text1'),
    };

    const image = {
        id: 'img-melody-played',
        src: `/resources/sharedAcrossWorkflows/successful_tick-mark.svg`,
        alt: 'success tick',
    };

    const button = {
        id: 'btn-done',
        label: t('common:Common_Feature:ok'),
    };

    return {
        infoText,
        image,
        button,
    };
}
