import { BestCluster } from '../../../Models/HearingProfile/BestCluster';

export interface InitialSettingStateContext {
    activeSide: Side;
    completedSide: Side;
    initialSettingStep: InitialSettingApplicationState;
    initialized: boolean;
    isOneSideDone: boolean;
    loading: boolean;
    progress: number;
    totalPageCount: number;
    totalPagesForProgress: number;
    sideStates: {
        side: Side;
        isDone: boolean;
        bestCluster: BestCluster;
    }[];
    view: () => JSX.Element;
}

export type Side = 'left' | 'right' | '';

export enum InitialSettingApplicationState {
    Result = 'result',
    LeftRightDifference = 'leftRightDifference',
    Application = 'apply',
    Writing = 'writing',
    Confirming = 'confirm',
    Done = 'done',
    CustomerService = 'customerService',
}
