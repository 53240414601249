import { useTranslation } from 'react-i18next';
import { getUserDeviceModel } from '../../../../Services/ResourceService';
import { HeaderActionType } from '../../../../SharedComponents/Header/HeaderActionType';
import {
    BulletListResource,
    ButtonResource,
    HeaderResource,
    switchBasedOnDevice,
    TextResource,
} from '../../../../Utils/PageUtils';
import hiAssemblyClickSleevePageResource from './HIAssemblyClickSleevePageResource.json';
import { HIAssemblyVideoResource } from '../HIAssemblyUtils';

export type HIAssemblyClickSleevePageResource = {
    header: HeaderResource;
    instructionsText: TextResource;
    videoTitle: TextResource;
    smallerSizeTitle: TextResource;
    smallerSizePoints: BulletListResource;
    largerSizeTitle: TextResource;
    largerSizePoints: BulletListResource;
    video: HIAssemblyVideoResource;
    continueButton: ButtonResource;
};

export default function useHIAssemblyClickSleevePageResource(): HIAssemblyClickSleevePageResource {
    const { t } = useTranslation();

    const smallerSizeBulletTexts: string[] = [];
    const largerSizeBulletTexts: string[] = [];
    const {
        headerResource,
        instructionsResource,
        videoTitleResource,
        smallerSizeTitleResource,
        largerSizeTitleResource,
        smallerSizeBulletListResource,
        largerSizeBulletListResource,
        videoResource,
        videoPreviewImageResource,
        videoURLs,
        continueButtonResource,
    } = hiAssemblyClickSleevePageResource;

    const generalKey = switchBasedOnDevice(getUserDeviceModel(), {
        OrionAir: 'orionAir:OrionAir_Device:HiAssemble_Feature',
        OrionC20: 'orionC20:OrionC20_Device:HiAssemble_Feature',
        OrionGo: 'orionGo:OrionGo_Device:HiAssemble_Feature',
        OrionComplete: 'orionComplete:OrionComplete_Device:HiAssemble_Feature',
    }) as string;

    const header = {
        id: headerResource.id,
        text: t(`${generalKey}${headerResource.text}`),
        type: HeaderActionType.Menu,
        backButton: true,
    };

    const instructionsText = {
        id: instructionsResource.id,
        text: t(`${generalKey}${instructionsResource.text}`),
    };

    const videoTitle = {
        id: videoTitleResource.id,
        text: t(`${generalKey}${videoTitleResource.text}`),
    };

    const smallerSizeTitle = {
        id: smallerSizeTitleResource.id,
        text: t(`${generalKey}${smallerSizeTitleResource.text}`),
    };

    smallerSizeBulletListResource.points.forEach((pointText) => {
        smallerSizeBulletTexts.push(t(`${generalKey}${pointText.text}`));
    });

    const smallerSizePoints = {
        id: smallerSizeBulletListResource.id,
        bulletItems: smallerSizeBulletTexts,
    };

    const largerSizeTitle = {
        id: largerSizeTitleResource.id,
        text: t(`${generalKey}${largerSizeTitleResource.text}`),
    };

    largerSizeBulletListResource.points.forEach((pointText) => {
        largerSizeBulletTexts.push(t(`${generalKey}${pointText.text}`));
    });

    const largerSizePoints = {
        id: largerSizeBulletListResource.id,
        bulletItems: largerSizeBulletTexts,
    };

    videoResource.url = videoURLs[getUserDeviceModel('left')];
    videoResource.previewImageSrc =
        videoPreviewImageResource[getUserDeviceModel('left')];

    const video = videoResource;

    const continueButton = {
        id: continueButtonResource.id,
        label: t(continueButtonResource.text),
    };

    return {
        header,
        instructionsText,
        videoTitle,
        smallerSizeTitle,
        smallerSizePoints,
        largerSizeTitle,
        largerSizePoints,
        video,
        continueButton,
    };
}
