export class Device {
    public id: string;
    public side: string;
    public brandId: number;
    public modelId: string;
    public pairingAddress: number;
    public couplingId: number;

    constructor(
        id: string,
        side: string,
        brandId: number,
        modelId: string,
        pairingAddress: number,
        couplingId: number
    ) {
        this.id = id;
        this.side = side;
        this.brandId = brandId;
        this.modelId = modelId;
        this.pairingAddress = pairingAddress;
        this.couplingId = couplingId;
    }
}
