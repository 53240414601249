import { useTranslation } from 'react-i18next';
import { HeaderActionType } from '../../../SharedComponents/Header/HeaderActionType';
import {
    ButtonResource,
    HeaderResource,
    TextResource,
} from '../../../Utils/PageUtils';
import './UserJourneyStyle.scss';
import userJourneyPageResource from './UserJourneyPageResource.json';
import { getUserDeviceModel } from '../../../Services/ResourceService';
import { VerticalStepperContent } from '../../../SharedComponents/Stepper/StepperModel';
import { PartialDialogResource } from '../../../SharedComponents/Dialog/PartialDialog/PartialDialog';
import { isBluetoothDeviceByModel } from '../../../Utils/BluetoothDeviceUtils';

export type UserJourneyResource = {
    useWarningDialogResource: () => PartialDialogResource;
    header: HeaderResource;
    stepContents: VerticalStepperContent[];
    buttonLabels: {
        primary: ButtonResource;
        secondary?: ButtonResource;
    };
    informationText: TextResource;
};

function useUserJourneyResource(activeStep: number): UserJourneyResource {
    const [t] = useTranslation();
    const steps = [
        isBluetoothDeviceByModel(getUserDeviceModel())
            ? 'InsertInstrucions'
            : 'Pairing',
        'Evaluation',
        'PersonalizedSettings',
        'MyComfort',
    ] as string[];

    const { startOver, pageHeader, buttons, information } =
        userJourneyPageResource;

    const useWarningDialogResource = () => {
        const { startOverTitleContent, startOverMessageContent, buttons } =
            startOver;

        const dialogTitle = {
            id: startOverTitleContent.id,
            text: t(startOverTitleContent.translationKey),
        };

        const dialogMessage = {
            id: startOverMessageContent.id,
            text: t(startOverMessageContent.translationKey),
        };

        const buttonLabels = {
            primaryLabel: {
                id: buttons.primary.id,
                label: t(buttons.primary.translationKey),
            },
            secondaryLabel: {
                id: buttons.secondary.id,
                label: t(buttons.secondary.translationKey),
            },
        };

        return {
            dialogTitle,
            dialogMessage,
            buttonLabels,
        };
    };

    const header = {
        id: pageHeader.id,
        text: t(pageHeader.translationKey),
        type: HeaderActionType.Menu,
    };

    const stepContents = steps.map((step, index) => {
        return {
            index: index,
            stepIcon: `/resources/fittingWorkflow/${step}.svg`,
            stepIconCompleted: `/resources/fittingWorkflow/${step}_completed.svg`,
            stepLabel: step,
            stepLabelContent: t(
                `userJourney:UserJourney_Feature:${step}:TitleText`
            ),
            stepTextContent: t(`userJourney:UserJourney_Feature:${step}:Text`),
            className: 'userJourneyStepText',
        };
    });

    const buttonLabels = {
        primary: {
            id: activeStep > 0 ? buttons.primary.id : buttons.initial.id,
            label: t(
                activeStep > 0 ? buttons.primary.label : buttons.initial.label
            ),
        },
        secondary:
            activeStep > 0
                ? {
                      id: buttons.secondary.id,
                      label: t(buttons.secondary.label),
                  }
                : undefined,
    };

    const informationText = {
        id: information.initial.id,
        text: t(information.initial.translationKey),
    };

    return {
        useWarningDialogResource,
        header,
        stepContents,
        buttonLabels,
        informationText,
    };
}

export default useUserJourneyResource;
