import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './InitialState';

const deviceSlice = createSlice({
    name: 'deviceSlice',
    initialState: initialState.deviceState,
    reducers: {
        updateDevice: (state, action) => {
            return action.payload;
        },
    },
});

export const { updateDevice } = deviceSlice.actions;

export const deviceReducer = deviceSlice.reducer;
