import Telemetry from './Telemetry';
import AnonymousTelemetry from './AnonymousTelemetry';
import LocalStorageService from '../LocalStorageService';

export default class TelemetryService {
    public static serviceInstance: TelemetryService = new TelemetryService();
    private readonly datacollectionkey: string = process.env
        .REACT_APP_DataCollectionKey as string;
    private readonly anonymous: string = process.env
        .REACT_APP_DataCollectionAnonymous as string;
    private readonly privacyNotesVersion: string = process.env
        .REACT_APP_PrivacyNotesVersion as string;
    private readonly serviceName: string = process.env
        .REACT_APP_DcsServiceName as string;
    private telemetryWithIdentity: Telemetry = Telemetry.getInstance();
    private telemetryAnonymous: AnonymousTelemetry =
        AnonymousTelemetry.getInstance();

    public TrackAnonymous(
        eventName: string,
        payLoad: {
            [name: string]: string;
        }
    ): void {
        const newPayLoad = Object.assign(
            { [this.datacollectionkey]: this.anonymous },
            payLoad
        );
        this.telemetryAnonymous.trackEvent(eventName, newPayLoad);

        // payload to be used to verify data collection events. Anonymous payload unusable for testing
        const testPayload = {
            CollectionEvent: eventName,
            ...payLoad,
        };
        Telemetry.getInstance().trackEvent(eventName, testPayload);
    }

    public TrackWithIdentity(
        eventName: string,
        payLoad: {
            [name: string]: string;
        }
    ): void {
        const patientId = LocalStorageService.serviceInstance.getPatientId();
        let country = LocalStorageService.serviceInstance.getPatientCountry();
        if (country === '')
            country = LocalStorageService.serviceInstance.getSelectedCountry();
        const newPayLoad = Object.assign(
            {
                [this
                    .datacollectionkey]: `${patientId}:${this.serviceName}:${this.privacyNotesVersion}:${country}`,
            },
            payLoad
        );
        this.telemetryWithIdentity.trackEvent(eventName, newPayLoad);
    }

    public UnTrackPatientId(): void {
        this.telemetryWithIdentity.tracePatientId('');
    }
}
