import { useContext, useState } from 'react';
import HLAAService from '../../../Services/HLAAService';
import LocalStorageService from '../../../Services/LocalStorageService';
import LoggingService from '../../../Services/LoggingService';
import { ProgressBarCalculateCurrentProgress } from '../../../Utils/ProgressBarService';
import { RunningState, useRunning } from '../../../Utils/UseRunning';
import { HearingAssessmentState, HLAAContext } from '../HLAAContext';
import { v4 as uuid } from 'uuid';
import AudioService from '../../../Services/AudioService';
import ArcToneService from '../../../Services/ArcToneService';
import { sleep } from '../../../Utils/ResourcesUtils';
import TelemetryService from '../../../Services/Monitoring/TelemetryService';
import { OneSideSetUpHandler } from '../../MainComponents/OneSideSetUp/OneSideSetUp';
import { getCouplingOrDefault } from '../../../Utils/CouplingUtils';
import { getUserDeviceModel } from '../../../Services/ResourceService';

export default function useHearingAssessmentOneSideSetUpHandler(): OneSideSetUpHandler {
    const [progressSetUp, setProgressSetUp] = useState(0);
    const { info, setHearingAssessmentState } = useContext(HLAAContext);
    const [_Ignore, _ignore, setRunning] = useRunning();

    const getTopProgress = (): number => {
        return ProgressBarCalculateCurrentProgress(
            info.current.totalPageCount,
            info.current.totalPagesForProgress
        );
    };

    const onSetUpDevices = async () => {
        if (info.current.hlaaState.action === '') {
            await onStartSetUpDevices();
        } else if (info.current.repeatDeviceSetup) {
            await onRepeatDeviceSetup();
        } else {
            await onSetupSwitchHLAASide();
        }
    };

    const onStartSetUpDevices = async () => {
        setRunning(RunningState.Reset);
        const hlaaTestId = uuid();
        info.current.hlaaTestId = hlaaTestId;

        LoggingService.info({
            componentName: onStartSetUpDevices.name,
            args: [
                `HLAA TestId ${hlaaTestId} started -----------------------------------`,
            ],
        });
        const patientId = LocalStorageService.serviceInstance.getPatientId();
        try {
            await HLAAService.startHLAA(
                patientId,
                hlaaTestId,
                info.current.activeSide,
                LocalStorageService.serviceInstance.getDeviceModelId(
                    info.current.activeSide
                )
            );
            const brandId =
                LocalStorageService.serviceInstance.getDeviceBrandId(
                    info.current.activeSide
                );
            const pairingAddressActive =
                LocalStorageService.serviceInstance.getPairingAddress(
                    info.current.activeSide
                );
            const pairingAddressInactive =
                LocalStorageService.serviceInstance.getPairingAddress(
                    info.current.activeSide === 'left' ? 'right' : 'left'
                );
            await prepareDeviceForHLAA(
                brandId,
                pairingAddressActive,
                pairingAddressInactive,
                hlaaTestId,
                info.current.activeSide
            );

            setHearingAssessmentState(HearingAssessmentState.BeepConfirmation);
        } catch (error) {
            setRunning(RunningState.Error, `Error starting HLAA: ${error}.`);
            throw error;
        }
    };

    const prepareDeviceForHLAA = async (
        brandId: number,
        pairingAddressActive: number,
        pairingAddressInactive: number,
        hlaaTestId: string,
        side: string
    ) => {
        setProgressSetUp(15);

        // set assessment state
        info.current.hlaaState = await HLAAService.getHLAAState(hlaaTestId);
        //get couplingID from local storage
        const couplingId = getCouplingOrDefault(side);

        await exitHLAA(brandId, pairingAddressActive);

        if (couplingId !== -1) {
            await AudioService.serviceInstance.prepAudio(
                ArcToneService.setCoupling(
                    brandId,
                    pairingAddressActive,
                    couplingId
                ),
                'setCoupling'
            );
        }

        await sleep(1000);

        // initialize HLAA
        await AudioService.serviceInstance.prepAudio(
            ArcToneService.hlaaInitialize(
                brandId,
                pairingAddressActive,
                info.current.hlaaState.freqId
            ),
            'hlaaInitialize'
        );
        setProgressSetUp(30);

        // exit the other side
        await exitHLAA(brandId, pairingAddressInactive);

        setProgressSetUp(80);
        // mute other side
        await AudioService.serviceInstance.prepAudio(
            ArcToneService.muteDevice(brandId, pairingAddressInactive),
            'MuteDevice'
        );
        setProgressSetUp(100);
        await sleep(500);
        setRunning(RunningState.Completed);

        setProgressSetUp(0);

        if (side.toLowerCase() === 'left') {
            TelemetryService.serviceInstance.TrackAnonymous('Orion-Coupling', {
                ModelLeft: getUserDeviceModel(),
                Left: couplingId.toString(),
            });
        } else {
            TelemetryService.serviceInstance.TrackAnonymous('Orion-Coupling', {
                ModelRight: getUserDeviceModel(),
                Right: couplingId.toString(),
            });
        }
    };

    const exitHLAA = async (brandId: number, pairingAddress: number) => {
        await AudioService.serviceInstance.prepAudio(
            ArcToneService.getExitAssessment(brandId, pairingAddress),
            'ExitAssessment'
        );
    };

    const onRepeatDeviceSetup = async () => {
        try {
            const hlaaTestId = info.current.hlaaTestId;
            const brandId =
                LocalStorageService.serviceInstance.getDeviceBrandId(
                    info.current.activeSide
                );
            const pairingAddressActive =
                LocalStorageService.serviceInstance.getPairingAddress(
                    info.current.activeSide
                );
            const pairingAddressInactive =
                LocalStorageService.serviceInstance.getPairingAddress(
                    info.current.activeSide === 'left' ? 'right' : 'left'
                );

            await prepareDeviceForHLAA(
                brandId,
                pairingAddressActive,
                pairingAddressInactive,
                hlaaTestId,
                info.current.activeSide
            );

            info.current.repeatDeviceSetup = false;
            setHearingAssessmentState(HearingAssessmentState.BeepConfirmation);
        } catch (error) {
            setRunning(RunningState.Error, `Error when start HLAA: ${error}.`);
            throw error;
        }
    };

    const onSetupSwitchHLAASide = async () => {
        setRunning(RunningState.Reset);

        const hlaaTestId = info.current.hlaaTestId;

        await HLAAService.switchHLAASide(hlaaTestId, info.current.activeSide);

        try {
            const brandId =
                LocalStorageService.serviceInstance.getDeviceBrandId(
                    info.current.activeSide
                );
            const pairingAddressActive =
                LocalStorageService.serviceInstance.getPairingAddress(
                    info.current.activeSide
                );
            const pairingAddressInactive =
                LocalStorageService.serviceInstance.getPairingAddress(
                    info.current.activeSide === 'left' ? 'right' : 'left'
                );

            await prepareDeviceForHLAA(
                brandId,
                pairingAddressActive,
                pairingAddressInactive,
                hlaaTestId,
                info.current.activeSide
            );

            setHearingAssessmentState(HearingAssessmentState.BeepConfirmation);
        } catch (error) {
            setRunning(
                RunningState.Error,
                `Error when switch HLAA side: ${error}.`
            );
            throw error;
        }
    };

    return {
        onSetUpDevices,
        getTopProgress,
        progressSetUp,
    };
}
