import React from 'react';
import { withTracking } from '../../../Services/Monitoring/ApplicationInsights';
import { VoidReturn } from '../../../Utils/PageUtils';
import CustomDialog from '../CustomDialog';
import WarningDialog from '../Warning/WarningDialog';
import useDeviceCompatibilityCheckDialogHandler from './DeviceCompatibilityCheckDialogHandlerHook';
import useDeviceCompatibilityCheckDialogResource from './DeviceCompatibilityCheckDialogResourceHook';

export interface IDeviceCompatibilityCheckDialogProps {
    displayed: boolean;
    onClose: () => VoidReturn;
}

function DeviceCompatibilityCheckDialog({
    displayed,
    onClose,
}: IDeviceCompatibilityCheckDialogProps) {
    const useHandler = () =>
        useDeviceCompatibilityCheckDialogHandler({
            onClose,
        });

    const useResource = () => useDeviceCompatibilityCheckDialogResource();

    return (
        <CustomDialog fullScreen open={displayed}>
            <WarningDialog useHandler={useHandler} useResource={useResource} />
        </CustomDialog>
    );
}

export default withTracking(DeviceCompatibilityCheckDialog);
