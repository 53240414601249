import { useTranslation } from 'react-i18next';
import {
    ListItemType,
    SubMenuItem,
} from '../../../SharedComponents/List/SubMenuList';
import { useFineTuningContext } from '../FineTuningContext';

export interface IFineTuningSelectionPageResourceProps {
    itemClickHandler: (item: ListItemType) => void;
}

export type FineTuningSelectionPageResource = {
    menuItems: SubMenuItem[];
    headerText: string;
};

const useFineTuningSelectionPageResource = (
    props: IFineTuningSelectionPageResourceProps
): FineTuningSelectionPageResource => {
    const { t } = useTranslation();

    const { info } = useFineTuningContext();

    const getCurrentSelection = () => {
        return info.current.isCategory ? 'Text1' : 'Text2';
    };

    const headerText = t(
        `fineTuning:FineTuning_Feature:${getCurrentSelection()}`
    );

    const getCategories = (): SubMenuItem[] => {
        return info.current.supportedCategoryList.map((category) => {
            return {
                id: `item-category-${category.id}`,
                displayText: t(
                    `fineTuning:FineTuning_Feature:Category:${category.id}`
                ),
                onClickHandler: props.itemClickHandler,
            };
        });
    };

    const getProblems = (): SubMenuItem[] => {
        const currentCategoryId = info.current.selected.category;
        const categoryObject = info.current.supportedCategoryList.find(
            (category) => category.id === currentCategoryId
        );
        const problemList = categoryObject ? categoryObject.attributes : [];

        return problemList.map((problem) => {
            return {
                id: `item-problem-${problem.id}`,
                displayText: t(
                    `fineTuning:FineTuning_Feature:Problem:${problem.id.replace(
                        /_/g,
                        ':'
                    )}`
                ),
                onClickHandler: props.itemClickHandler,
            };
        });
    };

    const menuItems = info.current.isCategory ? getCategories() : getProblems();

    return {
        menuItems,
        headerText,
    };
};

export default useFineTuningSelectionPageResource;
