import React, { useContext } from 'react';
import PlayToneBubble from '../../../SharedComponents/BubblePlayToneIcon/PlayToneBubble';
import CustomImg from '../../../SharedComponents/CustomImage/CustomImg';
import { ImageResource } from '../../../Utils/PageUtils';
import '../HearingAssessmentStyle.scss';
import { HLAAContext } from '../HLAAContext';
import './HearingAssessmentOneSideStyle.scss';

export interface PlayBubble {
    img: ImageResource;
}
export default function OneSidePlayBubble({
    img,
}: PlayBubble): React.ReactElement {
    const {
        info: {
            current: { activeSide },
        },
    } = useContext(HLAAContext);
    return (
        <div id="div-bubbleContainer" className={'bubbleAndHeadArea'}>
            <div className={`bubbleContainer${activeSide}`}>
                <div id="bubble-top" className={`bubbleTopArea${activeSide}`}>
                    <PlayToneBubble
                        displayDuration={500}
                        waitingDuration={1500}
                        size="5vh"
                        rotation={0}
                    />
                </div>
                <div
                    id="bubble-middle"
                    className={`bubbleMiddleArea${activeSide}`}
                >
                    <PlayToneBubble
                        displayDuration={1000}
                        waitingDuration={1000}
                        size="4vh"
                        rotation={20}
                    />
                </div>

                <div
                    id="bubble-bottom"
                    className={`bubbleBottomArea${activeSide}`}
                >
                    <PlayToneBubble
                        displayDuration={1500}
                        waitingDuration={500}
                        size="3vh"
                        rotation={20}
                    />
                </div>
            </div>
            <CustomImg
                id={img.id}
                className={'headImage'}
                src={img.src}
                alt={img.alt}
                imgType="medium"
            />
        </div>
    );
}
