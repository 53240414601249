import { useTranslation } from 'react-i18next';
import { getUserDeviceModel } from '../../../../Services/ResourceService';
import { HeaderActionType } from '../../../../SharedComponents/Header/HeaderActionType';
import {
    ButtonResource,
    HeaderResource,
    ImageResource,
    switchBasedOnDevice,
    TextResource,
} from '../../../../Utils/PageUtils';
import hiAssemblyBatteryPageResource from './HIAssemblyBatteryPageResource.json';
import { deviceModel } from '../../../../Models/SupportedDeviceModel';
import { HIAssemblyVideoResource } from '../HIAssemblyUtils';

export type HIAssemblyClickSleevePageResource = {
    header: HeaderResource;
    title: TextResource;
    image: ImageResource;
    instructionsText: TextResource;
    video: HIAssemblyVideoResource;
    continueText: TextResource;
    continueButton: ButtonResource;
};

export default function useHIAssemblyClickSleevePageResource(): HIAssemblyClickSleevePageResource {
    const { t } = useTranslation();
    const generalKey = switchBasedOnDevice(getUserDeviceModel(), {
        OrionAir: 'orionAir:OrionAir_Device:HiAssemble_Feature',
        OrionC20: 'orionC20:OrionC20_Device:HiAssemble_Feature',
        OrionGo: 'orionGo:OrionGo_Device:HiAssemble_Feature',
        OrionComplete: 'orionComplete:OrionComplete_Device:HiAssemble_Feature',
    }) as string;

    const {
        headerResource,
        titleResource,
        instructionsResource,
        videoResource,
        continueTextResource,
        continueButtonResource,
    } = hiAssemblyBatteryPageResource;

    const header = {
        id: headerResource.id,
        text: t(`${generalKey}${headerResource.text}`),
        type: HeaderActionType.Menu,
        backButton: true,
    };

    const title = {
        id: titleResource.id,
        text: t(`${generalKey}${titleResource.text}`),
    };

    const instructionsText = {
        id: instructionsResource.id,
        text: t(`${generalKey}${instructionsResource.text}`),
    };

    const video = videoResource;

    const image = {
        id: 'img-hearing-aids',
        src:
            //for orion air, no hi-assemble image, see HIAssemblyPage for display details
            getUserDeviceModel() === deviceModel.OrionGo
                ? '/resources/OrionGo/hi-assemble.svg'
                : '/resources/OrionC20/hi-assemble.svg',
        alt: 'hearing aids image',
    };

    const continueText = {
        id: continueTextResource.id,
        text: t(`${generalKey}${continueTextResource.text}`),
    };

    const continueButton = {
        id: continueButtonResource.id,
        label: t(continueButtonResource.text),
    };

    return {
        header,
        title,
        instructionsText,
        video,
        image,
        continueText,
        continueButton,
    };
}
