import React, { ImgHTMLAttributes } from 'react';
import { createCustomImg } from './ImgFactory';

export type CustomImgType =
    | 'iconSmall'
    | 'iconMediumSmall'
    | 'icon'
    | 'iconMedium'
    | 'iconMediumLarge'
    | 'iconLarge'
    | 'small'
    | 'mediumSmall'
    | 'medium'
    | 'large'
    | 'fullwidth';

export interface ICustomImg extends ImgHTMLAttributes<HTMLImageElement> {
    imgType?: CustomImgType;
}

function CustomImg({ imgType, ...props }: ICustomImg): React.ReactElement {
    return createCustomImg(props, imgType);
}

export default CustomImg;
