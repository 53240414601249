import React, { useContext, useState } from 'react';
import { BestCluster } from '../../../Models/HearingProfile/BestCluster';
import { Side } from '../../../Models/Side';
import { withTracking } from '../../../Services/Monitoring/ApplicationInsights';
import BeepConfirmation from '../../MainComponents/BeepConfirmation/BeepConfirmation';
import useInitialSettingBeepConfirmationResource from './InitialSettingBeepConfirmation/InitialSettingBeepConfirmationResourceHook';
import {
    IInitialSettingContext,
    IInitialSettingInfo,
    InitialSettingContext,
} from './InitialSettingContext';
import LeftRightDifferencePage from './LeftRightDifference/LeftRightDifferencePage';
import HearingAssessmentResultPage from './Result/HearingAssessmentResultPage';
import useInitialSettingConfirmationHandler from './InitialSettingBeepConfirmation/InitialSettingConfirmationHandlerHook';
import InitialSettingApplicationSide from './SideApplication/InitialSettingApplicationSide';
import InitialSettingWritingSide from './SideWriting/InitialSettingWritingSide';
import InitialSettingCustomerService from './CustomerService/InitialSettingCustomerService';
import { InitialSettingApplicationState } from './InitialSettingModels';
import { MainViewContext } from '../../MainComponents/MainView/MainViewContext';
import { TransitionAction } from '../../MainComponents/MainView/MainViewModel';
import ReturnToTimelineStateService, {
    ReturnToTimelineState,
} from '../../../Services/ReturnToTimelineService';

export interface IInitialSettingApplicationPage {
    isLeftRightDifference: boolean;
}

function InitialSettingApplicationPage(props: IInitialSettingApplicationPage) {
    const sides: string[] = ['left', 'right'];
    const viewContext = useContext(MainViewContext);

    const [info, setInfo] = useState<IInitialSettingInfo>({
        activeSide: 'left',
        completedSide: '',
        initialSettingStep: props.isLeftRightDifference
            ? InitialSettingApplicationState.LeftRightDifference
            : InitialSettingApplicationState.Result,
        initialized: false,
        isOneSideDone: false,
        loading: false,
        progress: 0,
        totalPageCount: 1,
        totalPagesForProgress: 1,
        beepConfirmationCount: 0,
        beepConfirmationCountLimit: 3,
        sideStates: sides.map((x) => ({
            side: x as Side,
            isDone: false,
            bestCluster: new BestCluster(-1, -1, -1, -1),
        })),
    });

    ReturnToTimelineStateService.setCurrentReturnToTimelineState(
        ReturnToTimelineState.ApplyInitialSettings
    );

    const exitInitialSetting = async (message: string) => {
        switch (message) {
            case 'mismatch':
                viewContext.doTransition(
                    TransitionAction.InitialSettingsMismatchedLoss
                );
                break;
            case 'continue':
                viewContext.doTransition(
                    TransitionAction.InitialSettingsContinue
                );
                break;
        }
    };

    const initialValue: IInitialSettingContext = {
        info: info,
        setInitialSettingInfo: setInfo,
        exitInitialSetting: exitInitialSetting,
    };

    const renderSwitch = (state: InitialSettingApplicationState) => {
        window.scrollTo(0, 0);
        switch (state) {
            case InitialSettingApplicationState.LeftRightDifference: {
                return <LeftRightDifferencePage />;
            }
            case InitialSettingApplicationState.Result: {
                return <HearingAssessmentResultPage />;
            }
            case InitialSettingApplicationState.Application: {
                return <InitialSettingApplicationSide />;
            }
            case InitialSettingApplicationState.Writing: {
                return <InitialSettingWritingSide />;
            }
            case InitialSettingApplicationState.Confirming: {
                return (
                    <BeepConfirmation
                        useHandler={useInitialSettingConfirmationHandler}
                        useResource={useInitialSettingBeepConfirmationResource}
                    />
                );
            }
            case InitialSettingApplicationState.CustomerService: {
                return <InitialSettingCustomerService />;
            }
            default: {
                return <></>;
            }
        }
    };

    return (
        <InitialSettingContext.Provider value={initialValue}>
            {renderSwitch(info.initialSettingStep)}
        </InitialSettingContext.Provider>
    );
}

export default withTracking(InitialSettingApplicationPage);
