import React from 'react';
import { withTracking } from '../../../../Services/Monitoring/ApplicationInsights';
import PairButton from '../../../../SharedComponents/Button/PairButton';
import AppContainer from '../../../../SharedComponents/Container/AppContainer';
import BodyContainer from '../../../../SharedComponents/Container/BodyContainer';
import CustomImg from '../../../../SharedComponents/CustomImage/CustomImg';
import Header from '../../../../SharedComponents/Header/HeaderRefactor';
import BulletList from '../../../../SharedComponents/List/BulletList';
import CustomTypography, {
    TypographyType,
} from '../../../../SharedComponents/Typography/CustomTypography';
import useAppLandingPageHandler from './AppLandingPageHandlerHook';
import useAppLandingPageResource from './AppLandingPageResourceHook';
import './AppLandingPageStyle.scss';

const AppLandingPage = () => {
    const { onContinue, onReturn } = useAppLandingPageHandler();
    const { header, illustrationImage, subtitle, instruction, buttons } =
        useAppLandingPageResource();
    return (
        <AppContainer>
            <Header headerContent={header} textStyle={'customHeader'} />
            <CustomImg
                imgType="fullwidth"
                id={illustrationImage.id}
                src={illustrationImage.src}
                alt={illustrationImage.alt}
            />
            <BodyContainer flexStart>
                <CustomTypography
                    textAlign={'left'}
                    type={TypographyType.notoSansBody16px}
                    id={subtitle.id}
                    text={subtitle.text}
                    className={'welcome-subtitle'}
                />
                {instruction && (
                    <BulletList
                        id={instruction.id}
                        bulletItems={instruction.text
                            .split('|')
                            .filter(Boolean)}
                        className={`welcome-instruction itemLeftAlign`}
                    />
                )}
            </BodyContainer>
            <PairButton
                primaryButton={{
                    id: buttons.continue.id,
                    onClick: onContinue,
                    children: buttons.continue.label,
                }}
                secondaryButton={{
                    id: buttons.return.id,
                    onClick: onReturn,
                    children: buttons.return.label,
                }}
            />
        </AppContainer>
    );
};

export default withTracking(AppLandingPage);
