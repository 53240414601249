import { MainViewWorkflow } from '../Pages/MainComponents/MainView/MainViewModel';
import { goToUrl } from '../Utils/NavigationUtils';

enum ExitWorkflow {
    AppFitting = 'fitting',
    AppFineTuning = 'finetuning',
}

export enum ExitStatus {
    FirstFitSuccess = 'success/firstfit',
    RedoFitSuccess = 'success/redofit',
    SkipSuccess = 'success/skip',
    CompleteSuccess = 'success/complete',
    RedoLoudnessSuccess = 'success/complete-redo-loudness',
    UserLoopConditionFail = 'fail/userloopcondition',
    SevereHearingLossFail = 'fail/severe',
    MismatchFail = 'fail/mismatched',
    InvalidTokenFail = 'fail/invalidtoken',
    NetworkLossFail = 'fail/networkloss',
    GenericErrorFail = 'fail/generic-error',
    DeviceCompatibilityFail = 'fail/incompatible',
    UserAccountDeletedFail = 'fail/user-account-deleted',
}

export default class ExitRouteService {
    private static appExit(route: string, status: string): void {
        const magicUrl = `appexit:${route}/${status}`;
        goToUrl(magicUrl);
    }

    public static async exit(
        exitStatus: ExitStatus,
        additionalStatus?: string | undefined
    ): Promise<void> {
        let status = exitStatus.toString();
        if (additionalStatus) status += additionalStatus;

        const workflow = window.location.pathname.substring(1);
        switch (workflow) {
            case MainViewWorkflow.AppFitting.toString():
                this.appExit(ExitWorkflow.AppFitting, status);
                break;
            case MainViewWorkflow.AppFineTuning.toString():
                this.appExit(ExitWorkflow.AppFineTuning, status);
                break;
            default:
                window.location.reload();
                break;
        }
    }
}
