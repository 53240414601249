import React from 'react';
import useHearingAssessmentOneSideSetUpResource from './HearingAssessmentOneSideSetUpResource';
import useHearingAssessmentOneSideSetUpHandler from './HearingAssessmentOneSideSetUpHandler';
import OneSideSetUp from '../../MainComponents/OneSideSetUp/OneSideSetUp';

export default function HearingAssessmentOneSideSetUp(): React.ReactElement {
    return (
        <OneSideSetUp
            useHandler={useHearingAssessmentOneSideSetUpHandler}
            useResource={useHearingAssessmentOneSideSetUpResource}
        />
    );
}
