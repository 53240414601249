import Slider from '@material-ui/core/Slider';
import React, { ChangeEvent, HTMLAttributes, useCallback } from 'react';
import { joinMultipleStyle } from '../../../Utils/ScssUtils';
import CustomIconButton from '../../Button/CustomIconButton';
import FlexContainer from '../../Container/FlexContainer';
import CustomImg from '../../CustomImage/CustomImg';
import { IVolumeSlider } from '../SliderFactory';
import useCustomSliderHandler from '../SliderHandlerHook';
import '../SliderStyle.scss';

export type omitProps =
    | 'orientation'
    | 'className'
    | 'defaultValue'
    | 'min'
    | 'max'
    | 'disabled'
    | 'onChange'
    | 'onChangeCommitted'
    | 'onTouchMove'
    | 'onTouchCancel'
    | 'value';

function SliderWithControlButton({
    handleSliderChange,
    handleSliderChangeCommitted,
    onTouchStart,
    sliderState,
    stepAdjustValue = 1,
    thumbImgSrc = `/resources/sharedAcrossWorkflows/volume-slider.svg`,
    loadingPage = false,
    ...others
}: IVolumeSlider): React.ReactElement {
    const {
        sliderPos,
        loading,
        onClickAdjustButton,
        handleOnChangeCommittedSlider,
        handleOnChangeSlider,
        handleSliderStart,
        handleSliderMove,
        handleSliderCancel,
    } = useCustomSliderHandler(
        handleSliderChange,
        handleSliderChangeCommitted,
        sliderState,
        onTouchStart
    );

    const isSliderDisabled = loading || loadingPage;
    const isSliderMinButtonDisabled =
        loading || loadingPage || sliderPos <= sliderState.posMin;
    const isSliderMaxButtonDisabled =
        loading || loadingPage || sliderPos >= sliderState.posMax;

    const thumbComponent = useCallback(
        (props: HTMLAttributes<HTMLSpanElement>) => {
            return (
                <span {...props}>
                    <CustomImg
                        src={thumbImgSrc}
                        imgType="icon"
                        alt="Slider thumb"
                    />
                </span>
            );
        },
        [thumbImgSrc]
    );

    return (
        <FlexContainer>
            <CustomIconButton
                imgSrc={`/resources/sharedAcrossWorkflows/volume_increase.svg`}
                imgClassName={joinMultipleStyle(
                    'sliderButton',
                    isSliderMaxButtonDisabled ? 'disabled' : ''
                )}
                id={`btn-increment-${others.id}`}
                disabled={isSliderMaxButtonDisabled}
                onClick={async () => {
                    await onClickAdjustButton(stepAdjustValue);
                }}
            />
            <FlexContainer className="sliderContainer">
                <Slider
                    id={others.id}
                    orientation="vertical"
                    className={joinMultipleStyle(
                        'customSlider',
                        isSliderDisabled ? 'disabled' : ''
                    )}
                    aria-label={
                        others['aria-label']
                            ? others['aria-label']
                            : 'Volume Slider'
                    }
                    defaultValue={sliderState.initialPosition}
                    min={sliderState.posMin}
                    max={sliderState.posMax}
                    disabled={isSliderDisabled}
                    // issue on material UI slider https://github.com/mui-org/material-ui/issues/20191
                    onChange={(
                        event: ChangeEvent<{}>,
                        value: number | number[]
                    ) => {
                        handleOnChangeSlider(value);
                    }}
                    onChangeCommitted={(
                        event: ChangeEvent<{}>,
                        value: number | number[]
                    ) => {
                        handleOnChangeCommittedSlider(value);
                    }}
                    onTouchStart={handleSliderStart}
                    onTouchMove={handleSliderMove}
                    onTouchCancel={handleSliderCancel}
                    step={stepAdjustValue}
                    value={sliderPos}
                    ThumbComponent={
                        others.ThumbComponent
                            ? others.ThumbComponent
                            : thumbComponent
                    }
                />
            </FlexContainer>
            <CustomIconButton
                imgSrc={`/resources/sharedAcrossWorkflows/volume_decrease.svg`}
                imgClassName={joinMultipleStyle(
                    'sliderButton',
                    isSliderMinButtonDisabled ? 'disabled' : ''
                )}
                id={`btn-decrement-${others.id}`}
                disabled={isSliderMinButtonDisabled}
                onClick={async () => {
                    await onClickAdjustButton(-stepAdjustValue);
                }}
            />
        </FlexContainer>
    );
}

export default SliderWithControlButton;
