import React from 'react';
import { ButtonType } from '../../../SharedComponents/Button/ButtonType';
import CustomButton from '../../../SharedComponents/Button/CustomButton';
import AppContainer from '../../../SharedComponents/Container/AppContainer';
import BodyContainer from '../../../SharedComponents/Container/BodyContainer';
import Header from '../../../SharedComponents/Header/HeaderRefactor';
import BulletList from '../../../SharedComponents/List/BulletList';
import TopProgressBar from '../../../SharedComponents/TopProgressBar/TopProgressBar';
import CustomTypography, {
    TypographyType,
} from '../../../SharedComponents/Typography/CustomTypography';
import '../HearingAssessmentStyle.scss';
import useHearingAssessmentInstructionHandler from './HearingAssessmentInstructionHandler';
import useHearingAssessmentInstructionResource from './HearingAssessmentInstructionResource';

export default function HearingAssessmentInstruction(): React.ReactElement {
    const { goToHLAATest, getTopProgress } =
        useHearingAssessmentInstructionHandler();
    const {
        header,
        instructionTitle,
        instructionGeneral,
        instruction,
        startButton,
    } = useHearingAssessmentInstructionResource();

    return (
        <AppContainer>
            <TopProgressBar progress={getTopProgress()} />
            <Header headerContent={header} />
            <BodyContainer className={'hlaaStartBody'}>
                <CustomTypography
                    type={TypographyType.notoSansSubHeading}
                    text={instructionTitle.text}
                    textAlign="center"
                    id={instructionTitle.id}
                    className={'hlaaTitle'}
                />
                <CustomTypography
                    type={TypographyType.notoSansBody16px}
                    text={instructionGeneral.text}
                    textAlign="center"
                    id={instructionGeneral.id}
                    className={'hlaaInstructionGeneral'}
                />
                <BulletList
                    id={instruction.id}
                    bulletItems={instruction.text.split('|')}
                />
            </BodyContainer>
            <CustomButton
                id={startButton.id}
                buttonType={ButtonType.PrimaryLoading}
                onClick={goToHLAATest}
            >
                {startButton.text}
            </CustomButton>
        </AppContainer>
    );
}
