import { useContext, useEffect, useRef, useState } from 'react';
import AuthService from '../../../Services/Authentication/AuthService';
import { EnvironmentInfoService } from '../../../Services/EnvironmentInfoService';
import useLoading from '../../../SharedComponents/Spinner/PageLoadSpinner';
import { VoidReturn } from '../../../Utils/PageUtils';
import { MainViewContext } from '../MainView/MainViewContext';
import { TransitionAction } from '../MainView/MainViewModel';

export type SplashScreenHandler = {
    showLegalInfo: boolean;
    gotoLoginPage: () => VoidReturn;
    closeCookiePolicy: () => VoidReturn;
    openCookiePolicy: () => VoidReturn;
    spinner: JSX.Element;
    loading: boolean;
};

function useSplashScreenHandler(): SplashScreenHandler {
    const [showLegalInfo, setShowLegalInfo] = useState(false);

    const [spinner, setLoading, loading] = useLoading();
    const isComponentMounted = useRef(true);
    const viewContext = useContext(MainViewContext);

    useEffect(() => {
        const initialization = async () => {
            await EnvironmentInfoService.backEndVersion();
            if (isComponentMounted.current) setLoading(false);
        };
        (async () => {
            if (isComponentMounted.current) setLoading(true);
            await initialization();
        })();
        return () => {
            isComponentMounted.current = false;
        };
    }, [setLoading]);

    const gotoLoginPage = async () => {
        setLoading(true);
        if (AuthService.getInstance().getAccessTokenFromLocalStorage()) {
            AuthService.getInstance().login();
            viewContext.doTransition(TransitionAction.SplashScreenContinue);
        } else viewContext.doTransition(TransitionAction.UpdateAppDialog);
    };

    const closeCookiePolicy = () => {
        setShowLegalInfo(false);
    };

    const openCookiePolicy = () => {
        setShowLegalInfo(true);
    };

    return {
        showLegalInfo,
        gotoLoginPage,
        closeCookiePolicy,
        openCookiePolicy,
        spinner,
        loading,
    };
}

export default useSplashScreenHandler;
