import React from 'react';
import BodyContainer from '../../../SharedComponents/Container/BodyContainer';
import CustomImg from '../../../SharedComponents/CustomImage/CustomImg';
import Header from '../../../SharedComponents/Header/HeaderRefactor';
import BulletList from '../../../SharedComponents/List/BulletList';
import CustomTypography, {
    TypographyType,
} from '../../../SharedComponents/Typography/CustomTypography';
import {
    HeaderResource,
    ImageResource,
    TextResource,
} from '../../../Utils/PageUtils';
import './WelcomeStyle.scss';

export type WelcomeViewResource = {
    header: HeaderResource;
    illustrationImage: ImageResource;
    title?: TextResource;
    subtitle: TextResource;
    instruction?: TextResource;
};

export function WelcomeView({
    header,
    illustrationImage,
    title,
    subtitle,
    instruction,
}: WelcomeViewResource): React.ReactElement {
    return (
        <>
            <Header headerContent={header} />
            <CustomImg
                imgType="fullwidth"
                id={illustrationImage.id}
                src={illustrationImage.src}
                alt={illustrationImage.alt}
            />
            <BodyContainer>
                {title && (
                    <CustomTypography
                        textAlign="center"
                        type={TypographyType.comfortaaHeading}
                        id={title.id}
                        text={title.text}
                        className={'welcome-title'}
                    />
                )}
                <CustomTypography
                    textAlign="center"
                    type={TypographyType.notoSansBody16px}
                    id={subtitle.id}
                    text={subtitle.text}
                    className={'welcome-subtitle'}
                />
                {instruction && (
                    <BulletList
                        id={instruction.id}
                        bulletItems={instruction.text
                            .split('|')
                            .filter(Boolean)}
                        className={'welcome-instruction'}
                    />
                )}
            </BodyContainer>
        </>
    );
}
