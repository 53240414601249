import React from 'react';
import { FineTuningSolution } from '../../../Models/FineTuning/FineTuningSolution';
import { FineTuningSolutionState } from '../../../Models/FineTuning/FineTuningSolutionState';
import Carousel from '../../../SharedComponents/Carousel/Carousel';
import CustomTable from '../../../SharedComponents/Table/CustomTable';
import CustomTypography from '../../../SharedComponents/Typography/CustomTypography';
import { useFineTuningContext } from '../FineTuningContext';
import FineTuningAfterApplyPage from './PrimaryComponent/AfterApply/FineTuningAfterApplyPage';
import FineTuningAppliedSuccessPage from './PrimaryComponent/AppliedSuccess/FineTuningAppliedSuccessPage';
import FineTuningApplyingPage from './PrimaryComponent/Applying/FineTuningApplyingPage';
import FineTuningBeforeApplyPage from './PrimaryComponent/BeforeApply/FineTuningBeforeApplyPage';
import FineTuningCustomerServicePage from './PrimaryComponent/CustomerService/FineTuningCustomerServicePage';
import { useFineTuningSolutionContext } from './FineTuningSolutionContext';
import useFineTuningSolutionPageHandler from './FineTuningSolutionPageHandlerHook';
import useFineTuningSolutionPageResource from './FineTuningSolutionPageResourceHook';
import FineTuningWaitingPage from './PrimaryComponent/Waiting/FineTuningWaitingPage';
import FTFooterAfterApplyPage from './FooterComponent/AfterApply/FTFooterAfterApplyPage';
import FTFooterAppliedSuccessPage from './FooterComponent/AppliedSuccess/FTFooterAppliedSuccessPage';
import FTFooterApplyingPage from './FooterComponent/Applying/FTFooterApplyingPage';
import FTFooterCustomerServicePage from './FooterComponent/CustomerService/FTFooterCustomerServicePage';
import FTFooterWarningPage from './FooterComponent/Warning/FTFooterWarningPage';
import ChangeSleeveRecommendPage from './PrimaryComponent/ChangeSleeveRecommend/ChangeSleeveRecommendPage';
import './FineTuningSolutionPage.scss';

export type FineTuningSolutionPageRender = {
    renderTable: () => JSX.Element;
    renderInstructions: () => JSX.Element;
    renderPrimaryComponent: () => JSX.Element;
    renderFooterComponent: () => JSX.Element;
};

const useFineTuningSolutionPageRender = (): FineTuningSolutionPageRender => {
    const { isContentEnabled, setStepInCarousel } =
        useFineTuningSolutionPageHandler();
    const { info } = useFineTuningContext();

    const {
        fineTuningSolutionInfo,
        fineTuningSolutionState,
        activeSolutionStep,
        percentage,
    } = useFineTuningSolutionContext();

    if (fineTuningSolutionInfo.current.solutionList.length === 0) {
        fineTuningSolutionInfo.current.solutionList.push(
            info.current.fineTuningSolution
                ? info.current.fineTuningSolution
                : ({} as FineTuningSolution)
        );
    }

    const { tableProps, instructionsProps } =
        useFineTuningSolutionPageResource();

    const renderTable = () => {
        return <CustomTable id={'tbl-category-detail'} {...tableProps} />;
    };

    const renderInstructions = () => {
        return instructionsProps.text === '' ? (
            <></>
        ) : (
            <CustomTypography {...instructionsProps} />
        );
    };

    const solutionCardList = () => {
        return fineTuningSolutionInfo.current.solutionList.map(
            (solution: FineTuningSolution, index: number) =>
                renderApplySolution(
                    fineTuningSolutionState,
                    index + 1,
                    solution
                )
        );
    };

    const renderApplySolution = (
        state: FineTuningSolutionState,
        solutionNumber: number,
        currentSolution: FineTuningSolution
    ) => {
        switch (state) {
            case FineTuningSolutionState.Applying: {
                return (
                    <FineTuningApplyingPage
                        solutionNumber={solutionNumber}
                    ></FineTuningApplyingPage>
                );
            }
            case FineTuningSolutionState.AfterApply: {
                return (
                    <FineTuningAfterApplyPage
                        solutionNumber={solutionNumber}
                        percentage={percentage}
                    ></FineTuningAfterApplyPage>
                );
            }
            default:
                return currentSolution.name ? (
                    <FineTuningBeforeApplyPage
                        solutionNumber={solutionNumber}
                        currentSolution={currentSolution}
                    ></FineTuningBeforeApplyPage>
                ) : (
                    <FineTuningCustomerServicePage></FineTuningCustomerServicePage>
                );
        }
    };

    const renderPrimaryComponent = () => {
        switch (fineTuningSolutionState) {
            case FineTuningSolutionState.Waiting: {
                return <FineTuningWaitingPage></FineTuningWaitingPage>;
            }
            case FineTuningSolutionState.AppliedSuccessfully: {
                return (
                    <FineTuningAppliedSuccessPage></FineTuningAppliedSuccessPage>
                );
            }
            case FineTuningSolutionState.ChangeSleeveRecommend:
                return <ChangeSleeveRecommendPage></ChangeSleeveRecommendPage>;
            case FineTuningSolutionState.ChangeSleeveCustomerService: {
                return (
                    <FineTuningCustomerServicePage></FineTuningCustomerServicePage>
                );
            }
            default: {
                return (
                    <Carousel
                        step={activeSolutionStep}
                        setStep={setStepInCarousel}
                        buttonsDisabled={!isContentEnabled()}
                        carouselContents={solutionCardList()}
                    />
                );
            }
        }
    };

    const renderFooterComponent = () => {
        switch (fineTuningSolutionState) {
            case FineTuningSolutionState.AfterApply: {
                return <FTFooterAfterApplyPage></FTFooterAfterApplyPage>;
            }
            case FineTuningSolutionState.ChangeSleeveCustomerService:
                return (
                    <FTFooterCustomerServicePage
                        style={{ marginTop: '12vh' }}
                    ></FTFooterCustomerServicePage>
                );
            case FineTuningSolutionState.CustomerService: {
                return (
                    <FTFooterCustomerServicePage></FTFooterCustomerServicePage>
                );
            }
            case FineTuningSolutionState.AppliedSuccessfully: {
                return (
                    <FTFooterAppliedSuccessPage></FTFooterAppliedSuccessPage>
                );
            }
            case FineTuningSolutionState.Warning:
            case FineTuningSolutionState.WarningCustomerService: {
                return <FTFooterWarningPage></FTFooterWarningPage>;
            }
            case FineTuningSolutionState.Applying: {
                return <FTFooterApplyingPage></FTFooterApplyingPage>;
            }
            default:
                return <></>;
        }
    };

    return {
        renderTable,
        renderInstructions,
        renderPrimaryComponent,
        renderFooterComponent,
    };
};

export default useFineTuningSolutionPageRender;
