import { useContext, useState } from 'react';
import { OneSideSetUpHandler } from '../../../../Pages/MainComponents/OneSideSetUp/OneSideSetUp';
import ArcToneService from '../../../../Services/ArcToneService';
import AudioService from '../../../../Services/AudioService';
import LocalStorageService from '../../../../Services/LocalStorageService';
import { ProgressBarCalculateCurrentProgress } from '../../../../Utils/ProgressBarService';
import { sleep } from '../../../../Utils/ResourcesUtils';
import { RedoHLAAContext, RedoHLAAState } from '../RedoHLAAContext';

export default function useRedoHLAAOneSideSetUpHandler(): OneSideSetUpHandler {
    const [progressSetUp, setProgressSetUp] = useState(0);
    const { info, setRedoHLAAState } = useContext(RedoHLAAContext);

    const getTopProgress = (): number => {
        return ProgressBarCalculateCurrentProgress(
            info.current.totalPageCount,
            info.current.totalPagesForProgress
        );
    };

    const onSetUpDevices = async () => {
        const brandId = LocalStorageService.serviceInstance.getDeviceBrandId(
            info.current.activeSide
        );
        const pairingAddressActive =
            LocalStorageService.serviceInstance.getPairingAddress(
                info.current.activeSide
            );

        setProgressSetUp(30);

        await AudioService.serviceInstance.prepAudio(
            ArcToneService.resetEasyFitControls(brandId, pairingAddressActive),
            `${info.current.activeSide} hi reset factory`
        );

        setProgressSetUp(100);
        await sleep(500);

        setProgressSetUp(0);
        setRedoHLAAState(RedoHLAAState.BeepConfirmation);
    };

    return {
        onSetUpDevices,
        getTopProgress,
        progressSetUp,
    };
}
