import { Patient } from '../../../Models/Patient/Patient';
import { SupportedCountries } from '../../../Models/SupportedCountries';
import CountryConsentConfigService from '../../../Services/Configuration/CountryConsentConfigService';
import LocalStorageService from '../../../Services/LocalStorageService';
import LoggingService from '../../../Services/LoggingService';
import {
    checkPairingStatus,
    getUserDeviceModel,
} from '../../../Services/ResourceService';
import CompletedWorkflowService, {
    Workflow,
} from '../../../Utils/CompletedWorkflow';
import {
    DialogState,
    ITransitionHandler,
    MainViewWorkflow,
    PageState,
    TransitionAction,
    ViewState,
} from './MainViewModel';
import {
    handleActionError,
    validatePreviousState,
    checkAndGetNotCompletedWorkflowNextPage,
    supportedPageStateQueryParamsAppFitting,
    getPageStateParam,
    handleInvalidUserAndToken,
    determineInitialState,
    getExitUrlFitStatus,
    getPairingAddressParam,
} from './TransitionHandlerUtils';
import { isBluetoothDeviceByModel } from '../../../Utils/BluetoothDeviceUtils';
import { setCouplingIfRequired } from '../../../Utils/CouplingUtils';
import { DeviceRepairingState } from '../../../Models/DeviceRepairingState';
import axios from 'axios';
import { useCallback, useEffect } from 'react';
import PatientManagementService from '../../../Services/PatientManagementService';
import { updateHlaa } from '../../../Redux/Reducers/HlaaSlice';
import { useDispatch, useSelector } from 'react-redux';
import IHlaaState from '../../../Redux/Models/HLAA/IHlaaState';
import { RootState } from '../../../Redux/Reducers/RootReducer';
import ReturnToTimelineStateService from '../../../Services/ReturnToTimelineService';
import { setPatientInfo } from '../../../Utils/PatientUtils';
import TelemetryService from '../../../Services/Monitoring/TelemetryService';
import ExitRouteService, {
    ExitStatus,
} from '../../../Services/ExitRouteService';
import {
    preloadImages,
    imageFolderEnum,
} from '../../../Utils/PreloadImageUtils';

function useAppFittingTransitionHandler(): ITransitionHandler {
    const dispatch = useDispatch();
    const hlaaState = useSelector(
        (state: RootState) => state.hlaa
    ) as IHlaaState;

    const axiosTokenInterceptor = useCallback(() => {
        axios.interceptors.response.use(async (response) => {
            return handleInvalidUserAndToken(
                response,
                MainViewWorkflow.AppFitting
            );
        });
    }, []);

    useEffect(() => {
        preloadImages(imageFolderEnum.fittingWorkflow);
        axiosTokenInterceptor();
    }, [axiosTokenInterceptor]);

    const doTransition = async (
        currentView: ViewState,
        action: TransitionAction
    ): Promise<ViewState> => {
        const currentPage = currentView.page;
        let nextPage = PageState.None;
        let nextDialog = currentView.dialog;

        const currentCompletedWorkflow =
            LocalStorageService.serviceInstance.getCompletedWorkflow();

        switch (action) {
            case TransitionAction.WelcomeContinue:
                validatePreviousState(currentPage, action, PageState.Welcome);
                switch (currentPage) {
                    case PageState.Welcome: {
                        const country =
                            LocalStorageService.serviceInstance.getSelectedCountry();
                        const enumValue =
                            SupportedCountries[
                                country as keyof typeof SupportedCountries
                            ];
                        const countryConsentConfig =
                            CountryConsentConfigService.getCountryConfig(
                                enumValue
                            );
                        const isUserRequiredToDoConsent =
                            countryConsentConfig.operationalDataConsent &&
                            !LocalStorageService.serviceInstance.getOperationConsentState();
                        const isUserRequiredToDoContra =
                            CompletedWorkflowService.getFirstMissingWorkflow(
                                currentCompletedWorkflow
                            ) == Workflow.Contraindication;
                        if (
                            LocalStorageService.serviceInstance.getIsDataPrivacyAndSafetyInfoAccepted()
                        ) {
                            await setPatientInfo(false);
                            TelemetryService.serviceInstance.TrackAnonymous(
                                'Orion-FeatureUsage',
                                {
                                    Feature: 'OperationConsented',
                                    Model: getUserDeviceModel(),
                                }
                            );
                            await PatientManagementService.endWorkflow(
                                Workflow.Contraindication
                            );
                            nextPage = PageState.HiAssemble;
                        } else {
                            if (isUserRequiredToDoConsent) {
                                nextPage = PageState.Consent;
                            } else {
                                nextPage = isUserRequiredToDoContra
                                    ? PageState.Contraindication
                                    : PageState.HiAssemble;
                            }
                        }
                        break;
                    }
                    default:
                        // Do nothing. Error logging handled by validatePreviousState
                        break;
                }
                break;
            case TransitionAction.JourneyResume: {
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.Journey,
                    PageState.JourneyReturnToTimeline
                );
                const resumeState =
                    LocalStorageService.serviceInstance.getCompletedWorkflow();
                nextPage =
                    CompletedWorkflowService.getNextMandatoryWorkflow(
                        resumeState
                    );
                break;
            }
            case TransitionAction.RedoStep: {
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.JourneyReturnToTimeline
                );
                nextPage =
                    ReturnToTimelineStateService.getExpectedPageFromReturnToTimelineRedo();
                break;
            }
            case TransitionAction.HiAssembleContinue:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.HiAssemble
                );
                nextPage = PageState.Journey;
                break;
            case TransitionAction.ConsentContinue:
                // validatePreviousState(currentPage, PageState.Journey); TODO: ???
                nextPage = PageState.ConsentAnalytic;
                break;
            case TransitionAction.AnalyticsConsentContinue:
                validatePreviousState(currentPage, action, PageState.Consent);
                nextPage = PageState.Contraindication;
                break;
            case TransitionAction.ContraindicationContinue:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.Contraindication
                );
                nextPage = PageState.HiAssemble;
                break;
            case TransitionAction.PairingContinue: {
                validatePreviousState(currentPage, action, PageState.Pairing);
                setCouplingIfRequired();
                nextPage = CompletedWorkflowService.showSleeveSelection()
                    ? PageState.SleeveSelection
                    : !isBluetoothDeviceByModel(getUserDeviceModel()) &&
                      CompletedWorkflowService.isCompletedMandatoryWorkflow(
                          LocalStorageService.serviceInstance.getCompletedWorkflow()
                      )
                    ? PageState.RedoAssessmentOption
                    : PageState.Assessment;
                break;
            }
            case TransitionAction.AssessmentContinue:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.Assessment
                );
                nextPage = PageState.InitialSettings; // don't move pages.
                break;
            case TransitionAction.AssessmentZeroTone:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.Assessment
                );
                nextPage = PageState.Pairing;
                break;
            case TransitionAction.AssessmentReinstructExit:
            case TransitionAction.AssessmentMaxToneReachedExit:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.Assessment
                );
                ExitRouteService.exit(ExitStatus.UserLoopConditionFail);
                nextPage = currentPage;
                break;
            case TransitionAction.InitialSettingsContinue:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.InitialSettings
                );
                nextPage = PageState.SpeechComfort;
                break;
            case TransitionAction.InitialSettingsSevereLoss:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.InitialSettings
                );
                ExitRouteService.exit(ExitStatus.SevereHearingLossFail);
                nextPage = currentPage; // don't move pages.
                break;
            case TransitionAction.InitialSettingsMismatchedLoss:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.InitialSettings
                );
                ExitRouteService.exit(ExitStatus.MismatchFail);
                nextPage = currentPage; // don't move pages.
                break;
            case TransitionAction.SpeechComfortContinue:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.SpeechComfort
                );
                nextPage = PageState.CompleteRecommendation;
                break;
            case TransitionAction.CompleteRecommendationContinue:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.CompleteRecommendation
                );
                isBluetoothDeviceByModel(getUserDeviceModel()) ||
                getPageStateParam() == PageState.RedoHLAA
                    ? ExitRouteService.exit(getExitUrlFitStatus())
                    : ExitRouteService.exit(
                          getExitUrlFitStatus(),
                          getPairingAddressParam()
                      );
                nextPage = currentPage;
                break;
            case TransitionAction.DoRedoEasyFit:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.RedoAssessmentOption
                );
                nextPage = PageState.RedoAssessmentOption;
                break;
            case TransitionAction.RedoEasyFitContinue:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.RedoAssessmentOption
                );
                nextPage = CompletedWorkflowService.getNextMandatoryWorkflow(
                    LocalStorageService.serviceInstance.getCompletedWorkflow()
                );
                break;
            case TransitionAction.DoRedoHLAA: {
                const devicePairingState =
                    LocalStorageService.serviceInstance.getDevicePairingRequired();
                nextDialog = DialogState.None;
                if (
                    devicePairingState ===
                    DeviceRepairingState.NoAction.toString()
                ) {
                    nextPage = PageState.Assessment;
                } else {
                    nextPage = PageState.DeviceIncompatibleError;
                }
                break;
            }
            case TransitionAction.GoToAssessment:
                nextDialog = DialogState.None;
                nextPage = PageState.Assessment;
                break;
            case TransitionAction.SkipRedoEasyFit:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.RedoAssessmentOption
                );
                isBluetoothDeviceByModel(getUserDeviceModel())
                    ? ExitRouteService.exit(ExitStatus.SkipSuccess)
                    : ExitRouteService.exit(
                          ExitStatus.SkipSuccess,
                          getPairingAddressParam()
                      );
                nextPage = currentPage;
                break;
            case TransitionAction.SkipRedoHLAA:
                validatePreviousState(currentPage, action, PageState.RedoHLAA);
                ExitRouteService.exit(ExitStatus.SkipSuccess);
                nextPage = currentPage;
                break;
            case TransitionAction.GoRedoLoudness:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.CompleteRecommendation,
                    PageState.RedoLoudness
                );
                nextPage = PageState.RedoLoudness;
                break;
            case TransitionAction.RedoHLAAViaJourney:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.Assessment,
                    PageState.InitialSettings
                );
                nextPage = PageState.Journey;
                break;
            case TransitionAction.RedoLoudnessContinue:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.RedoLoudness
                );
                nextPage = PageState.CompleteRecommendation;
                break;
            case TransitionAction.DoRepeatPairing:
                // This ensures pairing connection loss dialog is closed & that pairing connect loss dialog is not shown on repeat-pairing menu item
                nextDialog = DialogState.None;
                nextPage = PageState.RePairing;
                break;
            case TransitionAction.UpdateAppDialog:
                nextDialog = DialogState.UpdateApp;
                nextPage = PageState.SplashScreen;
                break;
            case TransitionAction.UpdateAppDialogExit:
                ExitRouteService.exit(ExitStatus.InvalidTokenFail);
                nextPage = currentPage;
                break;
            case TransitionAction.SplashScreenContinue:
                nextDialog = DialogState.None;
                nextPage = PageState.Welcome;
                break;
            case TransitionAction.DoReturnToTimelineDialogOpen:
                nextDialog = DialogState.ReturnToTimeline;
                nextPage = currentPage;
                break;
            case TransitionAction.DoMobileDeviceUnmutedCheckDialogOpen:
                nextDialog = DialogState.MobileDeviceUnmutedCheck;
                nextPage = currentPage;
                break;
            case TransitionAction.DoReturnToPageDialogClose:
                nextDialog = DialogState.None;
                nextPage = currentPage;
                break;
            case TransitionAction.GoToTimeline:
                nextDialog = DialogState.None;
                nextPage = PageState.JourneyReturnToTimeline;
                break;
            case TransitionAction.LoopConditionExit:
                ExitRouteService.exit(ExitStatus.UserLoopConditionFail);
                nextPage = currentPage;
                break;
            case TransitionAction.DoLeaveApp:
                nextDialog = DialogState.None;
                nextPage = PageState.Journey;
                break;
            case TransitionAction.DoLeaveAppDialogOpen:
                nextDialog = DialogState.LeaveApp;
                nextPage = currentPage;
                break;
            case TransitionAction.DoLeaveAppDialogClose:
                nextDialog = DialogState.None;
                nextPage = currentPage;
                break;
            case TransitionAction.FineTuningComplete: // fall through
            case TransitionAction.GoFineTuning: // fall through
            case TransitionAction.ChangeSleeveCustomerServiceContinue: // fall through
            default:
                nextPage = handleActionError(currentPage, action);
                break;
        }

        LoggingService.info({
            componentName: 'AppFittingTransitionHandlerHook.doTransition',
            args: [
                `Transitioning from ${PageState[currentView.page]} (action: ${
                    TransitionAction[action]
                }) to ${PageState[nextPage]} with dialog ${
                    DialogState[nextDialog]
                }`,
            ],
        });
        return { dialog: nextDialog, page: nextPage } as ViewState;
    };

    const goToInitialState = async (
        patient: Patient | undefined,
        debugRoute?: PageState,
        initial?: PageState
    ): Promise<ViewState> => {
        let nextPage = PageState.None;
        const nextDialog = DialogState.None;

        dispatch(
            updateHlaa({
                ...hlaaState,
                hlaa0ToneFirstRunState: {
                    left: true,
                    right: true,
                },
            })
        );

        const nextView = await determineInitialState(patient);
        if (
            nextView.dialog !== DialogState.None ||
            nextView.page !== PageState.None
        ) {
            return nextView;
        }

        patient = await PatientManagementService.getPatient(); // need call patient as patient has been updated from determine initial state

        if (debugRoute) {
            nextPage = debugRoute;
        } else {
            if (!patient) {
                // No patient record found
                nextPage = PageState.SplashScreen;
            } else {
                setCouplingIfRequired();

                const completedWorkflows = patient.easyFitWorkflows;

                if (
                    initial &&
                    supportedPageStateQueryParamsAppFitting.includes(initial)
                ) {
                    nextPage = checkAndGetNotCompletedWorkflowNextPage(
                        completedWorkflows,
                        initial
                    );

                    if (!checkPairingStatus()) {
                        nextPage = PageState.DeviceIncompatibleError;
                    }
                } else {
                    nextPage = checkAndGetNotCompletedWorkflowNextPage(
                        completedWorkflows,
                        isBluetoothDeviceByModel(getUserDeviceModel())
                            ? PageState.RedoAssessmentOption
                            : PageState.Pairing
                    );
                }
            }
        }

        LoggingService.info({
            componentName: 'AppFittingTransitionHandlerHook.goToInitialState',
            args: [
                `Initial state: ${PageState[nextPage]} with dialog ${DialogState[nextDialog]}`,
            ],
        });
        //nextDialog
        return { dialog: nextDialog, page: nextPage } as ViewState;
    };

    return {
        doTransition,
        goToInitialState,
    };
}

export default useAppFittingTransitionHandler;
