import { useTranslation } from 'react-i18next';
import { ButtonResource, TextResource } from '../../../../Utils/PageUtils';

type MobileDeviceUnmutedCheckTroubleshootingPageResource = {
    infoText: TextResource;
    buttons: {
        primary: ButtonResource;
        secondary: ButtonResource;
    };
};

export default function useMobileDeviceUnmutedChecktroubleshootingPageResource(): MobileDeviceUnmutedCheckTroubleshootingPageResource {
    const { t } = useTranslation();

    const infoText = {
        id: 'txt-unmuted-check-troubleshooting-info',
        text: t(
            'common:Common_Feature:MobileDeviceUnmutedCheck:Troubleshooting:Text'
        ),
    };

    const buttons = {
        primary: {
            id: 'btn-confirmation-yes',
            label: t(
                'common:Common_Feature:MobileDeviceUnmutedCheck:LearnHowButton'
            ),
        },
        secondary: {
            id: 'btn-troubleshooting-close',
            label: t(
                'common:Common_Feature:MobileDeviceUnmutedCheck:CloseButton'
            ),
        },
    };

    return {
        infoText,
        buttons,
    };
}
