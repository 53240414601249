import React, { useLayoutEffect } from 'react';
import { withTracking } from '../../../../Services/Monitoring/ApplicationInsights';
import CustomTypography, {
    TypographyType,
} from '../../../Typography/CustomTypography';
import useMobileDeviceUnmutedCheckPlayPageResource from './MobileDeviceUnmutedCheckPlayPageResourceHook';
import useMobileDeviceUnmutedCheckPlayPageHandler from './MobileDeviceUnmutedCheckPlayPageHandlerHook';
import ButtonStickyContainer from '../../../Container/ButtonStickyContainer';
import CustomizedCircularProgress from '../../../Spinner/CustomizedCircularProgress';
import './MobileDeviceUnmutedCheckPlayPage.scss';
import BodyContainer from '../../../Container/BodyContainer';

function MobileDeviceUnmutedCheckPlayPage() {
    const { infoText, circularProgress } =
        useMobileDeviceUnmutedCheckPlayPageResource();

    const { onPlayMedia } = useMobileDeviceUnmutedCheckPlayPageHandler();

    useLayoutEffect(() => {
        const doAction = async () => {
            await onPlayMedia();
        };
        doAction();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    });

    return (
        <>
            <BodyContainer>
                <CustomTypography
                    id={infoText.id}
                    type={TypographyType.notoSansBody16px}
                    text={infoText.text}
                    className={'MobileDeviceUnmutedCheckPlayPageInfoText'}
                />
            </BodyContainer>
            <ButtonStickyContainer
                className={'MobileDeviceUnmutedCheckPlayPageCircularProgress'}
            >
                <CustomizedCircularProgress
                    id={circularProgress.id}
                    size={'12vh'}
                    loadingtext={circularProgress.text}
                />
            </ButtonStickyContainer>
        </>
    );
}

export default withTracking(MobileDeviceUnmutedCheckPlayPage);
