import axios from 'axios';
import { ServiceConfigFactory } from './ServiceConfigFactory';
import { BestClusterRecommendation } from '../Models/HearingProfile/BestClusterRecommendation';
import AuthService from './Authentication/AuthService';

export default class HearingProfileService {
    public static async getBestCluster(
        hearingProfileId: string,
        hlaaTestId: string
    ): Promise<BestClusterRecommendation | undefined> {
        const res = await axios.get<BestClusterRecommendation>(
            process.env.REACT_APP_EASYFIT_API_URL +
                `/HearingProfile/BestClusterForDevices?hearingProfileId=${hearingProfileId}&hlaaTestId=${hlaaTestId}`,
            ServiceConfigFactory.CreateAxiosConfigWithAuth(
                await AuthService.adB2cInstance.acquireAccessToken()
            )
        );

        if (res.status === 200) {
            if (res.data) {
                return res.data;
            } else {
                return undefined;
            }
        }
        return Promise.reject(
            `Fail get best cluster info: ${res.status} - ${res.statusText}`
        );
    }

    public static async getBestClusterInfo(
        hearingProfileId: string
    ): Promise<BestClusterRecommendation> {
        const res = await axios.get<BestClusterRecommendation>(
            process.env.REACT_APP_EASYFIT_API_URL +
                `/HearingProfile/GetBestClusterInfo?hearingProfileId=${hearingProfileId}`,
            ServiceConfigFactory.CreateAxiosConfigWithAuth(
                await AuthService.adB2cInstance.acquireAccessToken()
            )
        );

        if (res.status < 300) {
            return res.data;
        }

        return Promise.reject('Fail get best cluster info ' + res.statusText);
    }
}
