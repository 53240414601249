import { ButtonProps } from '@material-ui/core';
import React from 'react';
import { withTracking } from '../../../../Services/Monitoring/ApplicationInsights';
import { ButtonType } from '../../../../SharedComponents/Button/ButtonType';
import CustomButton from '../../../../SharedComponents/Button/CustomButton';
import CustomIconButton from '../../../../SharedComponents/Button/CustomIconButton';
import AppContainer from '../../../../SharedComponents/Container/AppContainer';
import BodyContainer from '../../../../SharedComponents/Container/BodyContainer';
import ButtonStickyContainer from '../../../../SharedComponents/Container/ButtonStickyContainer';
import FlexContainer from '../../../../SharedComponents/Container/FlexContainer';
import CustomImg from '../../../../SharedComponents/CustomImage/CustomImg';
import CustomDialog from '../../../../SharedComponents/Dialog/CustomDialog';
import { IDialogProps } from '../../../../SharedComponents/Dialog/DialogUtils';
import Header from '../../../../SharedComponents/Header/HeaderRefactor';
import CustomTypography, {
    TypographyType,
} from '../../../../SharedComponents/Typography/CustomTypography';
import { VoidReturn } from '../../../../Utils/PageUtils';
import './DownloadAppDialog.scss';
import { DownloadDialogResource } from './DownloadAppDialogResourceHook';

export interface IDownloadAppDialogProps extends IDialogProps {
    useResource: () => DownloadDialogResource;
    onContinue?: () => VoidReturn;
    onContinueApple?: () => VoidReturn;
    onContinueAndroid?: () => VoidReturn;
}

function DownloadAppDialog(props: IDownloadAppDialogProps) {
    const {
        displayed,
        onClose,
        useResource,
        onContinueApple,
        onContinueAndroid,
    } = props;
    const {
        header,
        logoImage,
        instructionsText1,
        instructionsText2,
        appleImage,
        androidImage,
        primaryButton,
    } = useResource();

    const primButtonConfig: ButtonProps = {
        id: primaryButton?.id,
        onClick: onClose,
        children: primaryButton?.label,
    };

    return (
        <CustomDialog open={displayed} onClose={onClose}>
            <AppContainer>
                <Header headerContent={header} onClick={onClose} />
                <BodyContainer>
                    <CustomImg
                        id={logoImage.id}
                        imgType={'small'}
                        src={logoImage.src}
                        alt={logoImage.alt}
                        className={'orionAppIconDownloadAppDialog'}
                    />
                    <CustomTypography
                        id={instructionsText1.id}
                        type={TypographyType.notoSansBody18px}
                        text={instructionsText1.text}
                        className={'instructionTitleDownloadAppDialog'}
                    />
                    <CustomTypography
                        id={instructionsText2.id}
                        type={TypographyType.notoSansBody16px}
                        className={'instructionTextDownloadAppDialog'}
                        text={instructionsText2.text}
                    />
                </BodyContainer>
                <ButtonStickyContainer>
                    <FlexContainer
                        flexRow
                        className={'appDownloadImageAreaDownloadAppDialog'}
                    >
                        <CustomIconButton
                            imgSrc={appleImage.src}
                            className={'appDownloadImageDownloadDialog'}
                            id={appleImage.id}
                            onClick={onContinueApple}
                        />
                        <CustomIconButton
                            imgSrc={androidImage.src}
                            className={'appDownloadImageDownloadDialog'}
                            id={androidImage.id}
                            onClick={onContinueAndroid}
                        />
                    </FlexContainer>
                </ButtonStickyContainer>
                {onClose ? (
                    <CustomButton
                        {...primButtonConfig}
                        buttonType={ButtonType.Primary}
                    />
                ) : (
                    <></>
                )}
            </AppContainer>
        </CustomDialog>
    );
}

export default withTracking(DownloadAppDialog);
