import React from 'react';
import { withTracking } from '../../../Services/Monitoring/ApplicationInsights';
import PairButton from '../../../SharedComponents/Button/PairButton';
import AppContainer from '../../../SharedComponents/Container/AppContainer';
import BodyContainer from '../../../SharedComponents/Container/BodyContainer';
import CustomImg from '../../../SharedComponents/CustomImage/CustomImg';
import Header from '../../../SharedComponents/Header/HeaderRefactor';
import CustomLink from '../../../SharedComponents/Link/CustomLink';
import useLoading from '../../../SharedComponents/Spinner/PageLoadSpinner';
import CustomTypography, {
    TypographyType,
} from '../../../SharedComponents/Typography/CustomTypography';
import useConsentAnalyticHandle from './ConsentAnalyticHandlerHook';
import './ConsentAnalyticPageStyle.scss';
import useConsentAnalyticResource from './ConsentAnalyticResourceHook';

const ConsentAnalyticPage = () => {
    const [loadingSpinner, setLoading] = useLoading();

    const { openHyperLinkPrivacy, buttonContinueAction } =
        useConsentAnalyticHandle(setLoading);

    const {
        header,
        consentImage,
        title,
        consentAnalyticText,
        privacyLink,
        analyticDataConsentAgreement,
        yesButton,
        noButton,
    } = useConsentAnalyticResource();

    return (
        <>
            {loadingSpinner}
            <AppContainer>
                <Header headerContent={header} />
                <BodyContainer>
                    <CustomImg
                        imgType="small"
                        src={consentImage.src}
                        alt={consentImage.alt}
                        id={consentImage.id}
                    />

                    <CustomTypography
                        textAlign="left"
                        type={TypographyType.notoSansBody18px}
                        text={title.text}
                        id={title.id}
                        className={'consent-analytic-page-title'}
                    />

                    <CustomTypography
                        textAlign="left"
                        type={TypographyType.notoSansBody16px}
                        text={consentAnalyticText.text}
                        id={consentAnalyticText.id}
                    />

                    <CustomLink
                        id={privacyLink.id}
                        onClick={openHyperLinkPrivacy}
                        color="inherit"
                        className={'consent-page-privacy-link'}
                    >
                        {privacyLink.text}
                    </CustomLink>

                    <CustomTypography
                        textAlign="left"
                        type={TypographyType.notoSansBody16px}
                        text={analyticDataConsentAgreement.text}
                        id={analyticDataConsentAgreement.id}
                        className={'consent-analytic-page-agreement'}
                    />
                </BodyContainer>
                <PairButton
                    withLoading
                    sticky={false}
                    primaryButton={{
                        children: yesButton.label,
                        id: yesButton.id,
                        onClick: () => buttonContinueAction(true),
                    }}
                    secondaryButton={{
                        children: noButton.label,
                        id: noButton.id,
                        onClick: () => buttonContinueAction(false),
                    }}
                />
            </AppContainer>
        </>
    );
};

export default withTracking(ConsentAnalyticPage);
