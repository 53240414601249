import { useTranslation } from 'react-i18next';
import { TextResource } from '../../../../Utils/PageUtils';

type MobileDeviceUnmutedCheckPlayPageResource = {
    infoText: TextResource;
    circularProgress: {
        id: string;
        text: string;
    };
};

export default function useMobileDeviceUnmutedCheckPlayPageResource(): MobileDeviceUnmutedCheckPlayPageResource {
    const { t } = useTranslation();

    const infoText = {
        id: 'txt-unmuted-check-play-info',
        text: t('common:Common_Feature:MobileDeviceUnmutedCheck:Play:Text'),
    };

    const circularProgress = {
        id: 'circular-progress-unmuted-check-play',
        text: t('hlaa:Hlaa_Feature:OneSide:Text2'),
    };

    return {
        infoText,
        circularProgress,
    };
}
