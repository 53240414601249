import './ContainerStyle.scss';
import React, { HTMLAttributes } from 'react';
import { joinMultipleStyle } from '../../Utils/ScssUtils';

function TextAreaContainer({
    className,
    ...others
}: HTMLAttributes<HTMLDivElement>): React.ReactElement {
    return (
        <div
            className={joinMultipleStyle('textDefaultAlign', className)}
            {...others}
        />
    );
}

export default TextAreaContainer;
