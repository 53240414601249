import { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MainViewContext } from '../../../Pages/MainComponents/MainView/MainViewContext';
import {
    PageState,
    TransitionAction,
} from '../../../Pages/MainComponents/MainView/MainViewModel';
import ILeaveAppState from '../../../Redux/Models/LeaveApp/ILeaveAppState';
import { updateLeaveApp } from '../../../Redux/Reducers/LeaveAppSlice';
import { RootState } from '../../../Redux/Reducers/RootReducer';
import { goToUrl } from '../../../Utils/NavigationUtils';
import { MenuDialogType } from './MenuDialogType';

export type MenuDialogHandler = {
    popMenu: (noToClose?: number) => void;
    pushMenu: (menuName: MenuDialogType) => void;
    peekMenu: () => string;
    onClickExternalLinkMenu: (link: string) => void;
};

export default function useMenuDialogHandler(): MenuDialogHandler {
    const [menuStateStack, setMenuStateStack] = useState(['main']);
    const viewContext = useContext(MainViewContext); // todo: move somewhere else?
    useSelector((state: RootState) => state.leaveApp) as ILeaveAppState;
    const dispatch = useDispatch();

    function popMenu(noToClose = 1) {
        setMenuStateStack((prevState) => {
            while (noToClose-- > 0 && prevState.length > 1) prevState.pop();
            return [...prevState];
        });
    }

    function pushMenu(menuName: MenuDialogType) {
        setMenuStateStack((prevState) => {
            prevState.push(menuName);
            return [...prevState];
        });
    }

    function peekMenu() {
        return menuStateStack[menuStateStack.length - 1];
    }

    function onClickExternalLinkMenu(link: string) {
        const pagesToShowDialog = [
            PageState.Assessment,
            PageState.RedoHLAA,
            PageState.SpeechComfort,
            PageState.RedoLoudness,
        ];

        if (pagesToShowDialog.includes(viewContext.pageState)) {
            dispatch(
                updateLeaveApp({
                    leaveAppUrl: link,
                })
            );
            viewContext.doTransition(TransitionAction.DoLeaveAppDialogOpen);
        } else goToUrl(link, true);
    }

    return {
        popMenu,
        pushMenu,
        peekMenu,
        onClickExternalLinkMenu,
    };
}
