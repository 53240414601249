import { DeviceState } from '../../../../Models/FineTuning/DeviceState';
import { FineTuningDeviceStates } from '../../../../Models/FineTuning/FineTuningDeviceStates';
import ArcToneService from '../../../../Services/ArcToneService';
import AudioService from '../../../../Services/AudioService';
import DeviceManagementService from '../../../../Services/DeviceManagementService';
import FineTuningService from '../../../../Services/FineTuningService';
import LocalStorageService from '../../../../Services/LocalStorageService';
import LoggingService from '../../../../Services/LoggingService';
import TelemetryService from '../../../../Services/Monitoring/TelemetryService';
import PatientManagementService from '../../../../Services/PatientManagementService';
import { getUserDeviceModel } from '../../../../Services/ResourceService';
import { useFineTuningContext } from '../../FineTuningContext';

export type FineTuningDuringResetPageHandler = {
    resetFineTuning: () => Promise<void>;
};

const useFineTuningDuringResetPageHandler = (
    setPercentage: React.Dispatch<React.SetStateAction<number>>
): FineTuningDuringResetPageHandler => {
    const { send, info } = useFineTuningContext();

    const sleep = (milliseconds: number) => {
        return new Promise((resolve) => setTimeout(resolve, milliseconds));
    };

    const getMasterGainAudio = async (
        brandId: number,
        pairingAddress: number,
        side: string,
        masterGain: number
    ) => {
        const modelId = LocalStorageService.serviceInstance
            .getDeviceModelId(side)
            .toString();
        const couplingId =
            LocalStorageService.serviceInstance.getDeviceCouplingId(side);
        await AudioService.serviceInstance.prepAudio(
            ArcToneService.getMasterGain(
                brandId,
                pairingAddress,
                masterGain,
                modelId,
                couplingId
            ),
            `${side.toUpperCase()} side masterGainId ${masterGain}`
        );
    };

    const updateFineTuning = async (
        deviceId: string,
        brandId: number,
        pairingAddress: number,
        side: string,
        masterGain: number,
        band1EqualizerGain: number,
        band2EqualizerGain: number,
        band3EqualizerGain: number,
        band4EqualizerGain: number
    ) => {
        await getMasterGainAudio(brandId, pairingAddress, side, masterGain);
        setPercentage((prev) => prev + 25);
        await AudioService.serviceInstance.prepAudio(
            ArcToneService.getBandEqualizerGain(
                brandId,
                pairingAddress,
                band1EqualizerGain,
                band2EqualizerGain,
                band3EqualizerGain,
                band4EqualizerGain
            ),
            `${side} side bandEqualizerGain ${band1EqualizerGain} ${band2EqualizerGain} ${band3EqualizerGain} ${band4EqualizerGain}`
        );

        setPercentage((prev) => prev + 25);

        await DeviceManagementService.fineTuningApplied(
            2,
            LocalStorageService.serviceInstance.getPatientId(),
            deviceId,
            masterGain,
            band1EqualizerGain,
            band2EqualizerGain,
            band3EqualizerGain,
            band4EqualizerGain
        );

        info.current.deviceStateFinal[deviceId] = {
            easyfitMasterGain: masterGain,
            band1EqualizerGain: band1EqualizerGain,
            band2EqualizerGain: band2EqualizerGain,
            band3EqualizerGain: band3EqualizerGain,
            band4EqualizerGain: band4EqualizerGain,
        };
    };

    const onReset = async () => {
        const userDeviceModel = getUserDeviceModel();
        TelemetryService.serviceInstance.TrackAnonymous('Orion-FeatureUsage', {
            Feature: 'FineTuningRevertInitialSettings',
            Model: userDeviceModel,
        });
        setPercentage(0);
        LoggingService.log({
            componentName: onReset.name,
            args: [
                'Reset master gain and band equalizer to settings after recommendation engine.',
            ],
        });
        const deviceState: { [deviceId: string]: DeviceState } = {};
        const leftDeviceId =
            LocalStorageService.serviceInstance.getDeviceId('left');
        const rightDeviceId =
            LocalStorageService.serviceInstance.getDeviceId('right');

        const leftBrandId =
            LocalStorageService.serviceInstance.getDeviceBrandId('left');
        const leftPairingAddress =
            LocalStorageService.serviceInstance.getPairingAddress('left');
        const rightBrandId =
            LocalStorageService.serviceInstance.getDeviceBrandId('right');
        const rightPairingAddress =
            LocalStorageService.serviceInstance.getPairingAddress('right');
        const prevLeftDevice = info.current.deviceState[leftDeviceId];
        const prevRightDevice = info.current.deviceState[rightDeviceId];

        deviceState[leftDeviceId] = {
            easyfitMasterGain: info.current.defaultMasterGain.left,
            band1EqualizerGain: 0,
            band2EqualizerGain: 0,
            band3EqualizerGain: 0,
            band4EqualizerGain: 0,
        };
        deviceState[rightDeviceId] = {
            easyfitMasterGain: info.current.defaultMasterGain.right,
            band1EqualizerGain: 0,
            band2EqualizerGain: 0,
            band3EqualizerGain: 0,
            band4EqualizerGain: 0,
        };

        await updateFineTuning(
            leftDeviceId,
            leftBrandId,
            leftPairingAddress,
            'left',
            deviceState[leftDeviceId].easyfitMasterGain,
            deviceState[leftDeviceId].band1EqualizerGain,
            deviceState[leftDeviceId].band2EqualizerGain,
            deviceState[leftDeviceId].band3EqualizerGain,
            deviceState[leftDeviceId].band4EqualizerGain
        );

        await updateFineTuning(
            rightDeviceId,
            rightBrandId,
            rightPairingAddress,
            'right',
            deviceState[rightDeviceId].easyfitMasterGain,
            deviceState[rightDeviceId].band1EqualizerGain,
            deviceState[rightDeviceId].band2EqualizerGain,
            deviceState[rightDeviceId].band3EqualizerGain,
            deviceState[rightDeviceId].band4EqualizerGain
        );
        setPercentage(100);
        await sleep(1000);
        await FineTuningService.fineTuningReset(
            LocalStorageService.serviceInstance.getActiveHearingProfileId(),
            true,
            new FineTuningDeviceStates(deviceState)
        );

        const leftCluster = await DeviceManagementService.getClusterId(
            leftDeviceId
        );
        const rightCluster = await DeviceManagementService.getClusterId(
            rightDeviceId
        );

        const leftDevice = info.current.deviceStateFinal[leftDeviceId];
        const rightDevice = info.current.deviceStateFinal[rightDeviceId];

        TelemetryService.serviceInstance.TrackAnonymous(
            `Orion-FineTuned-MasterGain`,
            {
                Model: userDeviceModel,
                LeftCluster: leftCluster.toString(),
                PreviousLeftMasterGain:
                    prevLeftDevice.easyfitMasterGain.toString(),
                CurrentLeftMasterGain: leftDevice.easyfitMasterGain.toString(),
            }
        );

        TelemetryService.serviceInstance.TrackAnonymous(
            'Orion-FineTuned-MasterGain',
            {
                Model: userDeviceModel,
                RightCluster: rightCluster.toString(),
                PreviousRightMasterGain:
                    prevRightDevice.easyfitMasterGain.toString(),
                CurrentRightMasterGain:
                    rightDevice.easyfitMasterGain.toString(),
            }
        );

        TelemetryService.serviceInstance.TrackAnonymous(
            'Orion-Left-FineTuned-BandEqualizer',
            {
                Model: userDeviceModel,
                PreviousBand1: prevLeftDevice.band1EqualizerGain.toString(),
                PreviousBand2: prevLeftDevice.band2EqualizerGain.toString(),
                PreviousBand3: prevLeftDevice.band3EqualizerGain.toString(),
                PreviousBand4: prevLeftDevice.band4EqualizerGain.toString(),
                CurrentBand1: leftDevice.band1EqualizerGain.toString(),
                CurrentBand2: leftDevice.band2EqualizerGain.toString(),
                CurrentBand3: leftDevice.band3EqualizerGain.toString(),
                CurrentBand4: leftDevice.band4EqualizerGain.toString(),
            }
        );

        TelemetryService.serviceInstance.TrackAnonymous(
            'Orion-Right-FineTuned-BandEqualizer',
            {
                Model: userDeviceModel,
                PreviousBand1: prevRightDevice.band1EqualizerGain.toString(),
                PreviousBand2: prevRightDevice.band2EqualizerGain.toString(),
                PreviousBand3: prevRightDevice.band3EqualizerGain.toString(),
                PreviousBand4: prevRightDevice.band4EqualizerGain.toString(),
                CurrentBand1: rightDevice.band1EqualizerGain.toString(),
                CurrentBand2: rightDevice.band2EqualizerGain.toString(),
                CurrentBand3: rightDevice.band3EqualizerGain.toString(),
                CurrentBand4: rightDevice.band4EqualizerGain.toString(),
            }
        );
        send('finishReset');
        const workflow = await PatientManagementService.getCompletedWorkflow();
        LocalStorageService.serviceInstance.setCompletedWorkflow(
            Number(workflow)
        );
    };

    return {
        resetFineTuning: onReset,
    };
};

export default useFineTuningDuringResetPageHandler;
