import { useTranslation } from 'react-i18next';
import { WarningDialogResource } from '../../../../SharedComponents/Dialog/Warning/WarningDialog';
import { HeaderActionType } from '../../../../SharedComponents/Header/HeaderActionType';
import {
    ButtonResource,
    HeaderResource,
    ImageResource,
    TextResource,
} from '../../../../Utils/PageUtils';

export type LeftRightDifferencePageResource = {
    header: HeaderResource;
    instructionImage: ImageResource;
    instruction: TextResource;
    buttons: {
        primary: ButtonResource;
        secondary: ButtonResource;
    };
};

export default function useLeftRightDifferencePageResource(): WarningDialogResource {
    const { t } = useTranslation();

    const header = {
        id: 'left-right-difference-header',
        text: t(
            `initialSettings:InitialSettings_Feature:ResultPage:LeftRightDifference:Header`
        ),
        type: HeaderActionType.Menu,
    };

    const image = {
        id: 'img-alert',
        src: `/resources/sharedAcrossWorkflows/Alert.svg`,
        alt: 'initialSettings:resultPage:leftRightDifference:header',
    };

    const warningTitle = {
        id: 'leftRightDifference-title',
        text: t(
            `initialSettings:InitialSettings_Feature:ResultPage:LeftRightDifference:TitleText`
        ),
    };

    const warningMessage = {
        id: 'leftRightDifference-instruction',
        text: t(
            `initialSettings:InitialSettings_Feature:ResultPage:LeftRightDifference:Text`
        ),
    };

    const warningInfo = {
        text: '',
    };

    const buttonLabels = {
        primaryLabel: {
            id: 'btn-apply',
            label: t(`common:Common_Feature:Continue`),
        },
        secondaryLabel: {
            id: 'btn-exit',
            label: t(`common:Common_Feature:Exit`),
        },
    };

    return {
        header,
        image,
        warningTitle,
        warningMessage,
        warningInfo,
        buttonLabels,
    };
}
