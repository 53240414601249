import { useContext } from 'react';
import { useTranslation } from 'react-i18next/';
import { BeepConfirmationResource } from '../../../../Pages/MainComponents/BeepConfirmation/BeepConfirmation';
import { getUserDeviceModel } from '../../../../Services/ResourceService';
import { switchBasedOnDevice } from '../../../../Utils/PageUtils';
import { HeaderActionType } from '../../../Header/HeaderActionType';
import { RedoHLAAContext } from '../RedoHLAAContext';

export const useRedoHLAAConfirmationResource = (): BeepConfirmationResource => {
    const { info } = useContext(RedoHLAAContext);
    const { t } = useTranslation();

    const side =
        info.current.activeSide.toLowerCase() === 'left' ? 'Left' : 'Right';
    return {
        header: {
            id: 'redo-HLAA-header',
            text: t(
                'orionApp:OrionApp_Workflow:RedoAssessment_Feature:OneSideSetup:Header'
            ),
            type: HeaderActionType.Menu,
        },
        title: {
            id: 'redo-HLAA-title',
            text: t(`pairing:Pairing_Feature:${side}Title`),
        },
        instruction: {
            id: 'text-instruction',
            text: t(`common:Common_Feature:ConfirmationBeepMessage`),
        },
        instructionImage: {
            id: 'img-instruction',
            src: switchBasedOnDevice(getUserDeviceModel(), {
                OrionGo: `/resources/OrionGo/beep-confirmation_${info.current.activeSide.toLowerCase()}_orionGo.svg`,
            }),
        },
        buttons: {
            primary: {
                id: 'btn-confirm-yes',
                label: t(`common:Common_Feature:Yes`),
            },
            secondary: {
                id: 'btn-confirm-no',
                label: t(`common:Common_Feature:No`),
            },
        },
    };
};
