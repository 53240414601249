import React from 'react';
import CircularProgress, {
    CircularProgressProps,
} from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import './SpinnerStyle.scss';

export interface ICustomizedCircularProgress extends CircularProgressProps {
    loadingtext: string;
}

export default function CustomizedCircularProgress({
    loadingtext,
    ...others
}: ICustomizedCircularProgress): JSX.Element {
    return (
        <div className={'spinnerContainer'}>
            <CircularProgress
                className={'staticprogress'}
                variant="determinate"
                value={100}
                thickness={1}
                {...others}
            ></CircularProgress>
            <CircularProgress
                className={'movingprogress'}
                thickness={1}
                disableShrink
                {...others}
            />
            <Typography className={'displaytext'} variant="caption">
                {loadingtext}
            </Typography>
        </div>
    );
}
