export const getMasterGainIdFromObject = (
    gainUIMap: Map<string, Map<number, number>>,
    side: string,
    key: number
): number => {
    let masterGainIdForSide = 0;
    if (gainUIMap.get(side) !== undefined) {
        const masterGainUiLeftObject = gainUIMap.get(side);
        if (masterGainUiLeftObject !== undefined) {
            const masterGainUiLeftObjectValue = masterGainUiLeftObject.get(key);
            if (masterGainUiLeftObjectValue !== undefined) {
                masterGainIdForSide = masterGainUiLeftObjectValue;
            }
        }
    }

    return masterGainIdForSide;
};
