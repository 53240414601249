import React from 'react';
import FlexContainer from '../../../../../SharedComponents/Container/FlexContainer';
import CustomImg from '../../../../../SharedComponents/CustomImage/CustomImg';
import CustomTypography, {
    TypographyType,
} from '../../../../../SharedComponents/Typography/CustomTypography';
import useFineTuningSolutionPageHandler from '../../FineTuningSolutionPageHandlerHook';
import useFineTuningCustomerServicePageResource from './FineTuningCustomerServicePageResource';
import '../../FineTuningSolutionPage.scss';
import CustomButton from '../../../../../SharedComponents/Button/CustomButton';
import { CustomerServiceContactService } from '../../../../../Services/Configuration/CustomerServiceContactService';
import { ButtonType } from '../../../../../SharedComponents/Button/ButtonType';

export default function FineTuningCustomerServicePage(): React.ReactElement {
    const { isContentEnabled, handleCustomerService } =
        useFineTuningSolutionPageHandler();
    const { makeCallText, image, customerServiceLink, linkCallBacks } =
        useFineTuningCustomerServicePageResource(handleCustomerService);

    const getCustomerServiceSupportLink = () => {
        switch (
            CustomerServiceContactService.getCountrySpecificPrimaryContactType()
        ) {
            case 'phone':
                return (
                    <CustomButton
                        id={customerServiceLink.id}
                        buttonType={ButtonType.Primary}
                        withDefaultContainer={false}
                        disabled={!isContentEnabled()}
                        className={'beforeApplyButton'}
                        onClick={handleCustomerService}
                    >
                        <CustomTypography
                            type={TypographyType.notoSansBody16px}
                            text={customerServiceLink.text}
                            className={'applyButtonText'}
                        />
                    </CustomButton>
                );
            case 'email':
                return (
                    <CustomTypography
                        id={makeCallText.id}
                        type={TypographyType.notoSansBody16px}
                        text={customerServiceLink.text}
                        className={'finetuning-support-text'}
                    />
                );
            case 'contactCustomerService':
                return (
                    <CustomTypography
                        id={customerServiceLink.id}
                        type={TypographyType.notoSansBody16px}
                        text={customerServiceLink.text}
                        className={
                            'finetuning-support-text finetuning-webSupport'
                        }
                        linkCallBacks={linkCallBacks}
                    />
                );
        }
    };

    return (
        <FlexContainer
            className={
                isContentEnabled() ? 'solutionCard' : 'solutionCard disabled'
            }
        >
            <CustomTypography
                id={makeCallText.id}
                text={makeCallText.text}
                type={TypographyType.notoSansSubHeading}
            />

            <CustomImg
                id={image.id}
                src={image.src}
                alt={image.alt}
                className={'imgSettings'}
            />
            {getCustomerServiceSupportLink()}
        </FlexContainer>
    );
}
