import { useTranslation } from 'react-i18next';
import { HeaderActionType } from '../../../SharedComponents/Header/HeaderActionType';
import {
    HeaderResource,
    ImageResource,
    TextResource,
} from '../../../Utils/PageUtils';
import smartRemotePageResource from './SmartRemotePageResource.json';

export type SmartRemoteSliderResource = {
    id?: string;
    label: TextResource;
};

export type SmartRemotePageResource = {
    header: HeaderResource;
    slider: SmartRemoteSliderResource;
    infoText: {
        id?: string;
        text?: string;
    };
    thumbComponent: ImageResource;
};

export default function useSmartRemotePageResource(): SmartRemotePageResource {
    const { t } = useTranslation();

    const header = {
        id: smartRemotePageResource.header.id,
        text: t(
            `smartRemote:SmartRemote_Feature:${smartRemotePageResource.header.text}`
        ),
        type: HeaderActionType.Menu,
    };

    const slider = {
        id: smartRemotePageResource.slider.id,
        label: {
            id: smartRemotePageResource.slider.label.id,
            text: t(
                `smartRemote:SmartRemote_Feature:${smartRemotePageResource.slider.label.text}`
            ),
        },
    };

    const infoText = {
        id: smartRemotePageResource.infoText.id,
    };

    const thumbComponent = {
        src: smartRemotePageResource.thumbComponent.src,
    };

    return {
        header,
        slider,
        infoText,
        thumbComponent,
    };
}
