import React, { useState } from 'react';
import { withTracking } from '../../../Services/Monitoring/ApplicationInsights';
import HIAssemblyBatteryPage from './Battery/HIAssemblyBatteryPage';
import HIAssemblyClickSleevePage from './ClickSleeveSelection/HIAssemblyClickSleevePage';
import { HIAssemblyContext, IHIAssemblyContext } from './HIAssemblyContext';
import { HIAssemblyState } from './HIAssemblyModel';

function HIAssemblyPage() {
    const [hiAssemblyState, setHIAssemblyState] = useState(
        HIAssemblyState.BatteryInsertion
    );

    const initialValue: IHIAssemblyContext = {
        currentHIAssemblyState: hiAssemblyState,
        setHIAssemblyState: setHIAssemblyState,
    };

    const renderSwitch = (hiAssemblyState: HIAssemblyState) => {
        window.scrollTo(0, 0);
        switch (hiAssemblyState) {
            case HIAssemblyState.BatteryInsertion:
                return <HIAssemblyBatteryPage></HIAssemblyBatteryPage>;
            case HIAssemblyState.ClickSleeveSelection:
                return <HIAssemblyClickSleevePage></HIAssemblyClickSleevePage>;
            default:
                return <></>;
        }
    };

    return (
        <HIAssemblyContext.Provider value={initialValue}>
            {renderSwitch(hiAssemblyState)}
        </HIAssemblyContext.Provider>
    );
}
export default withTracking(HIAssemblyPage);
