import { FineTuningInput } from './../Models/FineTuning/FineTuningInput';
import axios from 'axios';
import { ServiceConfigFactory } from './ServiceConfigFactory';
import LocalStorageService from './LocalStorageService';
import { InitialSettings } from '../Models/HearingProfile/InitialSettings';
import { BestClusterAppliedSettings } from '../Models/HearingProfile/BestClusterAppliedSettings';
import AuthService from './Authentication/AuthService';
import LoggingService from './LoggingService';
import easyFitStore from '../Redux/Store/EasyFitStore';
import { updateNotification } from '../Redux/Reducers/NotificationSlice';
import { t } from './LocalizationService';
import { NotificationCloseHandlerType } from '../Redux/Models/Notification/NotificationCloseHandlerType';
import { MainViewWorkflow } from '../Pages/MainComponents/MainView/MainViewModel';

const INVALID_USER = 'Invalid User';
export default class DeviceManagementService {
    public static generatePairingAddress(side: string): number {
        const existingPairingAddr =
            LocalStorageService.serviceInstance.getPairingAddress(
                side === 'right' ? 'left' : 'right'
            );
        return this.randomExcluded(1, 14, existingPairingAddr);
    }

    public static async resetPairingAddress(
        leftDeviceId: string,
        rightDeviceId: string
    ): Promise<undefined> {
        const res = await axios.put(
            process.env.REACT_APP_EASYFIT_API_URL +
                `/DeviceManagement/ResetPairingAddress?leftDeviceId=${leftDeviceId}&rightDeviceId=${rightDeviceId}`,
            null,
            ServiceConfigFactory.CreateAxiosConfigWithAuth(
                await AuthService.adB2cInstance.acquireAccessToken()
            )
        );
        if (res.status < 300) {
            return;
        }
        return Promise.reject(
            `Failed to reset device pairing address for deviceId: ${leftDeviceId} & ${rightDeviceId} with code: ${res.status}`
        );
    }

    public static async assignPairedDevice(
        deviceId: string,
        pairingAddress: number
    ): Promise<undefined> {
        const res = await axios.post(
            process.env.REACT_APP_EASYFIT_API_URL +
                `/DeviceManagement/AssignPairedDevice?deviceId=${deviceId}&pairingAddress=${pairingAddress}`,
            null,
            ServiceConfigFactory.CreateAxiosConfigWithAuth(
                await AuthService.adB2cInstance.acquireAccessToken()
            )
        );
        if (res.status < 300) {
            return;
        }
        return Promise.reject(
            `Failed to set pairing device for deviceId: ${deviceId} code: ${res.status}`
        );
    }

    private static randomExcluded(
        min: number,
        max: number,
        excluded: number
    ): number {
        let n = Math.floor(Math.random() * (max - min) + min);
        if (n >= excluded) n++;

        return n;
    }

    public static async adjustVolumeGain(
        patientId: string,
        deviceId: string,
        volumeGainId: number
    ): Promise<void> {
        const res = await axios.post(
            process.env.REACT_APP_EASYFIT_API_URL +
                `/DeviceManagement/VolumeToneIsAdjusted?deviceId=${deviceId}&volumeId=${volumeGainId}`,
            null,
            ServiceConfigFactory.CreateAxiosConfigWithAuth(
                await AuthService.adB2cInstance.acquireAccessToken()
            )
        );

        if (res.status < 300) {
            return;
        }

        return Promise.reject(
            'Patient: ' +
                patientId +
                ' failed to adjust volume gain ' +
                res.statusText
        );
    }

    public static async EasyFitControlsIsReset(
        patientId: string,
        deviceId: string
    ): Promise<void> {
        const res = await axios.post(
            process.env.REACT_APP_EASYFIT_API_URL +
                `/DeviceManagement/EasyFitControlsIsReset?deviceId=${deviceId}`,
            null,
            ServiceConfigFactory.CreateAxiosConfigWithAuth(
                await AuthService.adB2cInstance.acquireAccessToken()
            )
        );

        if (res.status < 300) {
            return;
        }

        return Promise.reject(
            'Patient: ' +
                patientId +
                ' failed to confirm EasyFit Controls Is Reset ' +
                res.statusText
        );
    }

    public static async confirmBestClustersAreApplied(
        initialSettings: BestClusterAppliedSettings
    ): Promise<undefined> {
        const res = await axios.post(
            process.env.REACT_APP_EASYFIT_API_URL +
                `/DeviceManagement/BestClusterAreApplied`,
            JSON.stringify(initialSettings),
            ServiceConfigFactory.CreateAxiosConfigWithAuth(
                await AuthService.adB2cInstance.acquireAccessToken()
            )
        );
        if (res.status < 300) {
            return;
        }
        return Promise.reject(
            'Failed to confirm best clusters are applied' + res.statusText
        );
    }

    public static async confirmInitialSettingsAreApplied(
        speechComfortTest: InitialSettings
    ): Promise<undefined> {
        const res = await axios.post(
            process.env.REACT_APP_EASYFIT_API_URL +
                `/DeviceManagement/InitialSettingsAreApplied`,
            JSON.stringify(speechComfortTest),
            ServiceConfigFactory.CreateAxiosConfigWithAuth(
                await AuthService.adB2cInstance.acquireAccessToken()
            )
        );
        if (res.status < 300) {
            return;
        }
        return Promise.reject(
            'Failed to confirm initial settings' + res.statusText
        );
    }

    public static async adjustMasterGain(
        patientId: string,
        deviceId: string,
        masterGainId: number
    ): Promise<void> {
        const res = await axios.post(
            process.env.REACT_APP_EASYFIT_API_URL +
                `/DeviceManagement/MasterGainIsAdjusted?deviceId=${deviceId}&masterGainId=${masterGainId}`,
            null,
            ServiceConfigFactory.CreateAxiosConfigWithAuth(
                await AuthService.adB2cInstance.acquireAccessToken()
            )
        );

        if (res.status < 300 && !(res.data === INVALID_USER)) {
            return;
        } else if (res.data === INVALID_USER) {
            LoggingService.warn({
                componentName: 'adjustMasterGain',
                args: [
                    `API returned status code 200 with text Invalid user for invalid user response`,
                ],
            });
            easyFitStore.dispatch(
                updateNotification({
                    ...easyFitStore.getState().notification,
                    alertMessage: {
                        title: t(
                            'common:Common_Feature:ErrorHandling:Invalid_User_Title'
                        ),
                        message: [
                            t(
                                'common:Common_Feature:ErrorHandling:Invalid_User_Text'
                            ),
                        ],
                        detailMessage: '',
                        userSelections: [
                            {
                                content: t('common:Common_Feature:ok'),
                                action: NotificationCloseHandlerType.UserAccountDeletedExit,
                            },
                        ],
                        isDisplayed: true,
                        workflow: MainViewWorkflow.AppFineTuning,
                    },
                })
            );

            return;
        }
        return Promise.reject(
            'Patient: ' +
                patientId +
                ' failed to adjust master gain ' +
                res.statusText
        );
    }

    public static async fineTuningApplied(
        type: number,
        patientId: string,
        deviceId: string,
        masterGainId: number,
        band1EqualizerGain: number,
        band2EqualizerGain: number,
        band3EqualizerGain: number,
        band4EqualizerGain: number
    ): Promise<void> {
        const res = await axios.post(
            process.env.REACT_APP_EASYFIT_API_URL +
                `/DeviceManagement/FineTuningApplied?type=${type}&deviceId=${deviceId}&masterGainId=${masterGainId}&band1EqualizerGain=${band1EqualizerGain}&band2EqualizerGain=${band2EqualizerGain}&band3EqualizerGain=${band3EqualizerGain}&band4EqualizerGain=${band4EqualizerGain}`,
            null,
            ServiceConfigFactory.CreateAxiosConfigWithAuth(
                await AuthService.adB2cInstance.acquireAccessToken()
            )
        );

        if (res.status < 300) {
            return;
        }

        return Promise.reject(
            'Patient: ' +
                patientId +
                'Failed to apply fine tuning ' +
                res.statusText
        );
    }

    public static async getDeviceMasterGain(deviceId: string): Promise<number> {
        const res = await axios.get<number>(
            process.env.REACT_APP_EASYFIT_API_URL +
                `/DeviceManagement/GetDeviceMasterGain?deviceId=${deviceId}`,
            ServiceConfigFactory.CreateAxiosConfigWithAuth(
                await AuthService.adB2cInstance.acquireAccessToken()
            )
        );

        if (res.status < 300) {
            return res.data;
        }
        return Promise.reject(
            'Fail to get device master gain ' + res.statusText
        );
    }

    public static async getDeviceVolumeGain(deviceId: string): Promise<number> {
        const res = await axios.get<number>(
            process.env.REACT_APP_EASYFIT_API_URL +
                `/DeviceManagement/GetDeviceVolumeGain?deviceId=${deviceId}`,
            ServiceConfigFactory.CreateAxiosConfigWithAuth(
                await AuthService.adB2cInstance.acquireAccessToken()
            )
        );

        if (res.status < 300) {
            return res.data;
        }
        return Promise.reject(
            'Fail to get device volume gain ' + res.statusText
        );
    }

    public static async getFineTuningInput(
        deviceId: string
    ): Promise<FineTuningInput> {
        const res = await axios.get<FineTuningInput>(
            process.env.REACT_APP_EASYFIT_API_URL +
                `/DeviceManagement/GetFineTuningInput?deviceId=${deviceId}`,
            ServiceConfigFactory.CreateAxiosConfigWithAuth(
                await AuthService.adB2cInstance.acquireAccessToken()
            )
        );

        if (res.status < 300) {
            return res.data;
        }
        return Promise.reject(
            'Fail to get fine tuning input ' + res.statusText
        );
    }

    public static async getClusterId(deviceId: string): Promise<number> {
        const res = await axios.get<number>(
            process.env.REACT_APP_EASYFIT_API_URL +
                `/DeviceManagement/GetClusterId?deviceId=${deviceId}`,
            ServiceConfigFactory.CreateAxiosConfigWithAuth(
                await AuthService.adB2cInstance.acquireAccessToken()
            )
        );

        if (res.status < 300) {
            return res.data;
        }
        return Promise.reject('Fail to get cluster id ' + res.statusText);
    }
}
