import { useTranslation } from 'react-i18next';
import LeaveAppResource from './LeaveAppDialogResource.json';
import { PartialDialogResource } from '../PartialDialog/PartialDialog';

function useLeaveAppDialogResource(): PartialDialogResource {
    const { t } = useTranslation();

    const { leaveAppTitleContent, leaveAppMessageContent, buttons } =
        LeaveAppResource;

    const dialogTitle = {
        id: leaveAppTitleContent.id,
        text: t(leaveAppTitleContent.translationKey),
    };

    const dialogMessage = {
        id: leaveAppMessageContent.id,
        text: t(leaveAppMessageContent.translationKey),
    };

    const buttonLabels = {
        primaryLabel: {
            id: buttons.primary.id,
            label: t(buttons.primary.translationKey),
        },
        secondaryLabel: {
            id: buttons.secondary.id,
            label: t(buttons.secondary.translationKey),
        },
    };

    return {
        dialogTitle,
        dialogMessage,
        buttonLabels,
    };
}

export default useLeaveAppDialogResource;
