import { useTranslation } from 'react-i18next';
import { HeaderActionType } from '../../../SharedComponents/Header/HeaderActionType';
import { HeaderResource } from '../../../Utils/PageUtils';

type HearingAssessmentStartResource = {
    header: HeaderResource;

    instructionTitle: {
        id: string;
        text: string;
    };

    instructionImage: {
        id: string;
        src: string;
        alt: string;
    };

    instructionGeneral: {
        id: string;
        text: string;
    };

    startButton: {
        id: string;
        text: string;
    };
};

export default function useHearingAssessmentStartResource(): HearingAssessmentStartResource {
    const { t } = useTranslation();

    const header = {
        id: 'header-hlaa-start',
        text: t('common:Common_Feature:HearingProfileCheck'),
        type: HeaderActionType.Menu,
    };

    const instructionTitle = {
        id: 'txt-title',
        text: t('hlaa:Hlaa_Feature:start:TitleText'),
    };

    const instructionImage = {
        id: 'img-assessment',
        src: '/resources/fittingWorkflow/illust_createhearingprofile.svg',
        alt: 'img-assessment',
    };

    const instructionGeneral = {
        id: 'txt-instruction-general',
        text: t('hlaa:Hlaa_Feature:start:Text'),
    };

    const startButton = {
        id: 'btn-start-assessment',
        text: t('common:Common_Feature:Continue'),
    };

    return {
        header,
        instructionTitle,
        instructionImage,
        instructionGeneral,
        startButton,
    };
}
