import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { getUserDeviceModel } from '../../../Services/ResourceService';
import { HeaderActionType } from '../../../SharedComponents/Header/HeaderActionType';
import { switchBasedOnDevice } from '../../../Utils/PageUtils';
import { BeepConfirmationResource } from '../../MainComponents/BeepConfirmation/BeepConfirmation';
import { PairingContext } from '../PairingContext';

export default function usePairingConfirmationResource(): BeepConfirmationResource {
    const {
        state: { context },
    } = useContext(PairingContext);
    const { t } = useTranslation();

    return {
        header: {
            id: 'header-pairing-confirmation',
            text: t(
                `common:Common_Feature:BeepConfirmation:Pairing:Header:${
                    context.activeSide === 'left' ? 'Left' : 'Right'
                }`
            ),
            type: HeaderActionType.Menu,
        },
        title: {
            id: 'txt-title',
            text: t(
                `pairing:Pairing_Feature:${
                    context.activeSide === 'left' ? 'Left' : 'Right'
                }Title`
            ),
        },
        instruction: {
            id: 'txt-instruction',
            text: t(`common:Common_Feature:ConfirmationBeepMessage`),
        },
        instructionImage: {
            id: 'img-instruction',
            src: switchBasedOnDevice(getUserDeviceModel(), {
                OrionAir: `/resources/OrionAir/beep-confirmation_${context.activeSide}_orionAir.svg`,
                OrionC20: `/resources/OrionC20/beep-confirmation_${context.activeSide}_orionC20.svg`,
                OrionGo: `/resources/OrionGo/beep-confirmation_${context.activeSide}_orionGo.svg`,
            }),
        },
        buttons: {
            primary: {
                id: 'btn-confirm-yes',
                label: t(`common:Common_Feature:Yes`),
            },
            secondary: {
                id: 'btn-confirm-no',
                label: t(`common:Common_Feature:No`),
            },
        },
    };
}
