export enum MenuDialogType {
    Instructions = 'instructions',
    LegalInformation = 'legalInformation',
    RedoEasyFit = 'redoEasyFit',
    RedoLoudness = 'redoLoudness',
    RedoHLAA = 'redoHLAA',
    CookiePolicy = 'cookiePolicy',
    LegalInfo = 'legalInfo',
    AppInfo = 'appInfo',
    AccountSettings = 'accountSettings',
    RevokeAnalyticConsent = 'revokeAnalyticConsent',
    RevokeOperationConsent = 'revokeOperationConsent',
    ReturnToTimeline = 'returnToTimeline',
}
