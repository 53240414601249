import { List, ListItem } from '@material-ui/core';
import React from 'react';
import CustomImg from '../CustomImage/CustomImg';
import CustomTypography, {
    TypographyType,
} from '../Typography/CustomTypography';
import './ListStyle.scss';
import { ListItemType } from './SubMenuList';

export interface IMenuItemListProps {
    menuItems: MenuItem[];
}

export type MenuItem = {
    id?: string;
    iconImgSrc: string;
    hasSubMenu?: boolean;
    displayText: string;
    disabled?: boolean;
    onClickHandler: (item: ListItemType) => void | Promise<void>;
};

function MenuItemList({ menuItems }: IMenuItemListProps): JSX.Element {
    return (
        <List>
            {menuItems.map((item, index) => {
                return (
                    <ListItem
                        className={'listItem itemLeftAlign'}
                        button
                        disabled={item.disabled}
                        onClick={() =>
                            item.onClickHandler(item as ListItemType)
                        }
                        divider={true}
                        key={index}
                        id={item.id ? item.id : `item-${index}`}
                    >
                        <CustomImg
                            src={item.iconImgSrc}
                            imgType="icon"
                            className={'menuItemIcon'}
                            alt="menu icon"
                        />
                        <CustomTypography
                            type={TypographyType.notoSansBody16px}
                            text={item.displayText}
                            textAlign="left"
                        />
                        <CustomImg
                            hidden={!item.hasSubMenu}
                            src={`/resources/sharedAcrossWorkflows/menu-right-arrow.svg`}
                            imgType="icon"
                            className={'menuItemArrowIcon'}
                            alt="subMenu arrow"
                        />
                    </ListItem>
                );
            })}
        </List>
    );
}

export default MenuItemList;
