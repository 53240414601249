import {
    ApplicationInsights,
    SeverityLevel,
} from '@microsoft/applicationinsights-web';

export default class AnonymousTelemetry {
    public static telemetryInstance: AnonymousTelemetry =
        new AnonymousTelemetry();
    private applicationinsights: ApplicationInsights | null;

    constructor() {
        this.applicationinsights = null;
    }

    static getInstance(): AnonymousTelemetry {
        if (!AnonymousTelemetry.telemetryInstance) {
            AnonymousTelemetry.telemetryInstance = new AnonymousTelemetry();
        }
        return AnonymousTelemetry.telemetryInstance;
    }

    public setApplicationInsights(
        applicationinsights: ApplicationInsights | null
    ): void {
        this.applicationinsights = applicationinsights;
    }

    public trackEvent(
        name: string,
        properties: { [name: string]: string } | null
    ): void {
        if (!this.applicationinsights) {
            return;
        }

        if (properties !== null) {
            this.applicationinsights.trackEvent({ name }, properties);
        } else this.applicationinsights.trackEvent({ name });
    }

    public trackPageView(
        name: string,
        url: string,
        properties: { [name: string]: string }
    ): void {
        if (this.applicationinsights)
            this.applicationinsights.trackPageView({
                name,
                uri: url,
                properties,
            });
    }

    public trackTrace(
        message: string,
        severityLevel: SeverityLevel = SeverityLevel.Information
    ): void {
        if (!this.applicationinsights) {
            return;
        }
        this.applicationinsights.trackTrace({
            message,
            severityLevel,
        });
    }
}
