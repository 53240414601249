import React from 'react';
import { ButtonType } from '../../../../../SharedComponents/Button/ButtonType';
import CustomButton from '../../../../../SharedComponents/Button/CustomButton';
import CustomImg from '../../../../../SharedComponents/CustomImage/CustomImg';
import useFineTuningSolutionPageHandler from '../../FineTuningSolutionPageHandlerHook';
import useFTFooterCustomerServicePageResource from './FTFooterCustomerServicePageResource';
import '../../FineTuningSolutionPage.scss';
import { useFineTuningSolutionContext } from '../../FineTuningSolutionContext';
import { FineTuningSolutionState } from '../../../../../Models/FineTuning/FineTuningSolutionState';

type FTFooterCustomerServicePageProps = {
    style?: React.CSSProperties;
};

export default function FTFooterCustomerServicePage(
    props: FTFooterCustomerServicePageProps
): React.ReactElement {
    const { fineTuningSolutionState } = useFineTuningSolutionContext();
    const { showWarningForHome, exitToOrionApp } =
        useFineTuningSolutionPageHandler();
    const { button, image } = useFTFooterCustomerServicePageResource();
    return (
        <CustomButton
            id={button.id}
            onClick={
                fineTuningSolutionState ===
                FineTuningSolutionState.ChangeSleeveCustomerService
                    ? exitToOrionApp
                    : showWarningForHome
            }
            buttonType={ButtonType.Primary}
            style={props.style}
        >
            <CustomImg
                id={image.id}
                className={'imgFooterHome'}
                src={image.src}
                alt={image.alt}
            />
            {button.text}
        </CustomButton>
    );
}
