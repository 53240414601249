import React, { HTMLAttributes } from 'react';
import { joinMultipleStyle } from '../../Utils/ScssUtils';
import './ContainerStyle.scss';
import FlexContainer from './FlexContainer';

function ButtonContainer({
    className,
    ...others
}: HTMLAttributes<HTMLDivElement>): React.ReactElement {
    return (
        <FlexContainer
            className={joinMultipleStyle('buttonContainer', className)}
            {...others}
        />
    );
}

export default ButtonContainer;
