import { useTranslation } from 'react-i18next';
import { HeaderActionType } from '../../../SharedComponents/Header/HeaderActionType';
import {
    ButtonResource,
    HeaderResource,
    ImageResource,
    TextResource,
} from '../../../Utils/PageUtils';

export type CompleteRecommendationResource = {
    header: HeaderResource;
    instructionsComplete: TextResource;
    finishImage: ImageResource;
    exitButton: ButtonResource;
    title: TextResource;
};

export default function useCompleteRecommendationResource(): CompleteRecommendationResource {
    const { t } = useTranslation();
    return {
        header: {
            id: 'complete-page-header',
            text: t(
                'completeAndLanding:CompleteAndLanding_Feature:CompleteRecommendation:Header'
            ),
            type: HeaderActionType.Menu,
        },
        instructionsComplete: {
            id: 'complete-instructions',
            text: t(
                'orionApp:OrionApp_Workflow:CompleteAndLanding_Feature:Text'
            ),
        },
        finishImage: {
            id: 'img-finish',
            src: '/resources/fittingWorkflow/finish_illustration.png',
            alt: 'finished illustration',
        },
        exitButton: {
            id: 'btn-exit-in-app-browser',
            label: t('common:Common_Feature:ReturnToOrionApp'),
        },
        title: {
            id: 'txt-title-complete-recommendation',
            text: t(
                'orionApp:OrionApp_Workflow:CompleteAndLanding_Feature:TitleText'
            ),
        },
    };
}
