import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceRepairingState } from '../../../../../Models/DeviceRepairingState';
import { MainViewContext } from '../../../../../Pages/MainComponents/MainView/MainViewContext';
import {
    MainViewWorkflow,
    PageState,
} from '../../../../../Pages/MainComponents/MainView/MainViewModel';
import LocalStorageService from '../../../../../Services/LocalStorageService';
import CompletedWorkflowService, {
    mandatoryWorkflow,
    Workflow,
} from '../../../../../Utils/CompletedWorkflow';
import { MenuListResource } from '../../../../../Utils/PageUtils';
import { HeaderActionType } from '../../../../Header/HeaderActionType';
import { createMenuItem } from '../../MenuUtils';
import { MainMenuHandler } from './MainMenuHandlerHook';
import { getPageStateParam } from '../../../../../Pages/MainComponents/MainView/TransitionHandlerUtils';
import { getUserDeviceModelName } from '../../../../../Services/ResourceService';
import ReturnToTimelineStateService, {
    ReturnToTimelineState,
} from '../../../../../Services/ReturnToTimelineService';

export default function useMainMenuResource(
    menuItemHandlers: MainMenuHandler
): MenuListResource {
    const { t } = useTranslation();
    const viewContext = useContext(MainViewContext);

    const translate = (id: string): string => {
        return 'menu:Menu_Feature:Main:' + id;
    };
    const menuLists = [
        createMenuItem(
            '/resources/sharedAcrossWorkflows/contact-support.svg',
            t(translate('Text7')),
            menuItemHandlers.contactCustomerService,
            'menuItem-contactCustomerService',
            false
        ),
        createMenuItem(
            '/resources/sharedAcrossWorkflows/faq.svg',
            t(translate('Text8')),
            menuItemHandlers.fAQ,
            'menuItem-faq',
            false
        ),
        createMenuItem(
            '/resources/sharedAcrossWorkflows/community.svg',
            t(translate('Text9')),
            menuItemHandlers.community,
            'menuItem-community',
            false
        ),
        createMenuItem(
            '/resources/sharedAcrossWorkflows/legal-information.svg',
            t(translate('Text4')),
            menuItemHandlers.legalInformation,
            'menuItem-legalinfo',
            true
        ),
    ];

    const completedWorkflow =
        LocalStorageService.serviceInstance.getCompletedWorkflow();

    const devicePairingState =
        LocalStorageService.serviceInstance.getDevicePairingRequired();

    if (
        CompletedWorkflowService.isCompleted(
            completedWorkflow,
            Workflow.Contraindication | Workflow.HIAssemble
        ) &&
        devicePairingState === DeviceRepairingState.NoAction.toString()
    ) {
        const returnToTimeline = createMenuItem(
            '/resources/sharedAcrossWorkflows/ic_return.svg',
            t(translate('Text2')),
            menuItemHandlers.returnToTimeline,
            'menuItem-returnToTimeline',
            false,
            ReturnToTimelineStateService.currentReturnToTimelineState ===
                ReturnToTimelineState.None ||
                ReturnToTimelineStateService.isOnDisabledPageState
        );
        (viewContext.workflowType === MainViewWorkflow.Web ||
            viewContext.workflowType === MainViewWorkflow.AppFitting) &&
            menuLists.unshift(returnToTimeline);
    }
    if (
        CompletedWorkflowService.isCompleted(
            completedWorkflow,
            mandatoryWorkflow
        ) &&
        devicePairingState === DeviceRepairingState.NoAction.toString()
    ) {
        const fineTuning = createMenuItem(
            '/resources/fineTuningWorkflow/fine-tune.svg',
            t('common:Common_Feature:FineTuning'),
            menuItemHandlers.fineTuning,
            'menuItem-fineTuning',
            false
        );

        viewContext.workflowType !== MainViewWorkflow.AppFitting &&
            getPageStateParam() != PageState.RedoLoudness &&
            menuLists.unshift(fineTuning);
    }

    return {
        header: {
            id: 'header-menu',
            text:
                t('menu:Menu_Feature:Main:Header') +
                '\n' +
                getUserDeviceModelName(),
            type: HeaderActionType.Close,
        },
        menuItems: menuLists,
    };
}
