export enum deviceModel {
    OrionAir = 'OrionAir',
    OrionC20 = 'OrionC20',
    OrionGo = 'OrionGo',
    OrionComplete = 'OrionComplete',
}

export enum deviceModelId {
    OrionAir = '6144007',
    OrionC20 = '72780372515670',
    OrionGo = '540938247',
    OrionComplete = '72024937728770',
}

export enum deviceModelName {
    OrionAir = 'CRE-C10',
    OrionC20 = 'CRE-C20',
    OrionGo = 'CRE-E10',
}

export enum deviceModelLabel {
    C10 = 'c10',
    E10 = 'e10',
    C20 = 'c20',
}

export enum platformType {
    D11 = 'D11',
}

export enum sleeveCoupling {
    Closed = 2,
    Vented = 1,
}
