import { useContext, useState } from 'react';
import PatientManagementService from '../../../Services/PatientManagementService';
import { Workflow } from '../../../Utils/CompletedWorkflow';
import { PairingCarouselHandler } from '../PairingCarouselPage';
import { PairingContext } from '../PairingContext';
import { getAvailableSide } from '../PairingStateMachineHandler';
import config from './InsertInstructionConfig.json';
import { getUserDeviceModel } from '../../../Services/ResourceService';
import { deviceModel } from '../../../Models/SupportedDeviceModel';

export default function useInsertInstructionHandler(): PairingCarouselHandler {
    const { send } = useContext(PairingContext);
    const [step, setStep] = useState(0);
    const [disabled, setDisabled] = useState(false);

    const stepPerSide =
        getUserDeviceModel() === deviceModel.OrionGo
            ? config.OrionGo.stepPerSide
            : config.OrionComplete.stepPerSide;

    const totalStep = getAvailableSide().length * stepPerSide;
    const handleChangeStep = (newStep: number) => {
        if (totalStep > stepPerSide) {
            if (newStep < stepPerSide && newStep >= 0) {
                send('switchSide', { data: 'left' });
            } else if (newStep >= stepPerSide && newStep < totalStep) {
                send('switchSide', { data: 'right' });
            }
        }
        setStep(newStep);
    };
    const buttonOnClick = async () => {
        setDisabled(true);
        await PatientManagementService.endWorkflow(
            Workflow.DevicePairingLeft | Workflow.DevicePairingRight
        );
        send('completeBLEParing');
        setDisabled(false);
    };

    return {
        step,
        setStep: handleChangeStep,
        buttonOnClick,
        disabled,
    };
}
