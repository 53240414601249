import React from 'react';
import CircularProgress, {
    CircularProgressProps,
} from '@material-ui/core/CircularProgress';

export default function ButtonSpinner({
    ...props
}: CircularProgressProps): JSX.Element {
    return <CircularProgress size={'2.05rem'} color={'secondary'} {...props} />;
}
