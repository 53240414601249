import { useTranslation } from 'react-i18next';
import { getUserDeviceModel } from '../../../../Services/ResourceService';
import { HeaderActionType } from '../../../../SharedComponents/Header/HeaderActionType';
import { isBluetoothDeviceByModel } from '../../../../Utils/BluetoothDeviceUtils';
import {
    ButtonResource,
    HeaderResource,
    ImageResource,
    TextResource,
} from '../../../../Utils/PageUtils';

type AudioRoutingPageResource = {
    header: HeaderResource;
    image: ImageResource;
    instructionText: TextResource;
    audioRoutingHelpLink: TextResource;
    button: ButtonResource;
};

export default function useAudioRoutingPageResource(): AudioRoutingPageResource {
    const { t } = useTranslation();

    const header = {
        id: 'header-audio-routing',
        text: t('pairing:Pairing_Feature:InstructionForPairingSetup:Header'),
        type: HeaderActionType.Menu,
    };

    const image = {
        id: 'img-audio-routing',
        src: '/resources/fineTuningWorkflow/redo-loudness-technical-setup.svg',
        alt: '',
    };

    const instructionText = {
        id: 'instruction-audio-routing',
        text: t(
            isBluetoothDeviceByModel(getUserDeviceModel())
                ? 'speechComfort:SpeechComfort_Feature:RedoLoudness_Feature:TechnicalSetupBluetooth:Text'
                : 'speechComfort:SpeechComfort_Feature:RedoLoudness_Feature:TechnicalSetupNonBluetooth:Text'
        ),
    };

    const audioRoutingHelpLink = {
        id: 'audioRouting_helplink',
        text: t(
            'orionGo:OrionGo_Device:Pairing_Feature:TechnicalSetup:TextHelp'
        ),
    };

    const button = {
        id: 'btn-audio-routing',
        label: t('common:Common_Feature:Continue'),
    };

    return {
        header,
        image,
        instructionText,
        audioRoutingHelpLink,
        button,
    };
}
