import AudioService from '../Services/AudioService';

export async function goToUrl(
    url: string,
    externalLink = false
): Promise<void> {
    // if the link address is an external link, close the audio context to avoid the audio to be interrupted.
    if (externalLink) await AudioService.serviceInstance.closeAudioContext();
    window.location.replace(url);
}
