import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { deviceModel } from '../../../Models/SupportedDeviceModel';
import { getUserDeviceModel } from '../../../Services/ResourceService';
import { HeaderActionType } from '../../../SharedComponents/Header/HeaderActionType';
import {
    ButtonResource,
    HeaderResource,
    ImageResource,
    switchBasedOnDevice,
    TextResource,
} from '../../../Utils/PageUtils';
import { PairingContext } from '../PairingContext';

export type PairingTroubleShootingResource = {
    header: HeaderResource;
    title: TextResource;
    subTitle: TextResource;
    instruction: TextResource;
    instructionNote: TextResource;
    instructionImage: ImageResource;
    button: ButtonResource;
    help: TextResource;
    additionalDetails: TextResource;
};

export default function usePairingTroubleShootingResource(): PairingTroubleShootingResource {
    const { t } = useTranslation();
    const {
        state: { context },
    } = useContext(PairingContext);

    const generalKey = switchBasedOnDevice(getUserDeviceModel(), {
        OrionAir: 'orionAir:OrionAir_Device:Pairing_Feature:Troubleshooting',
        OrionC20: 'orionC20:OrionC20_Device:Pairing_Feature:Troubleshooting',
    }) as string;

    const header = {
        text: t(`${generalKey}:Header`),
        type: HeaderActionType.Menu,
        id: 'header-pairing-troubleshooting',
    };
    const title = {
        id: 'txt-title',
        text: t(`${generalKey}:TitleText`),
    };
    const subTitle = {
        id: 'txt-sub-title',
        text:
            context.activeSide === 'left'
                ? t(`${generalKey}:SubTitleText1`)
                : t(`${generalKey}:SubTitleText2`),
    };
    const instruction = {
        id: 'txt-instruction',
        text: t(`${generalKey}:Text1`),
    };
    const instructionNote = {
        id: 'txt-instruction-note',
        text: t(`${generalKey}:Text3`),
    };
    const instructionImage = {
        id: 'img-instruction',
        src:
            getUserDeviceModel() === deviceModel.OrionAir
                ? `/resources/OrionAir/${context.activeSide}-troubleshoot.svg`
                : `/resources/OrionC20/${context.activeSide}-troubleshoot.svg`,
    };
    const button = {
        id: `btn-next`,
        label: t('common:Common_Feature:TryAgain'),
    };
    const help = {
        id: 'link-customer-support',
        text: t(`${generalKey}:Text2`),
    };
    const additionalDetails = {
        id: 'link-additional-details',
        text: t(`${generalKey}:Text4`),
    };

    return {
        header,
        title,
        subTitle,
        instruction,
        instructionNote,
        instructionImage,
        button,
        help,
        additionalDetails,
    };
}
