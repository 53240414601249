import { useTranslation } from 'react-i18next';
import MobileDeviceUnmutedCheckDialogResource from './MobileDeviceUnmutedCheckDialogResource.json';
import { TextResource } from '../../../Utils/PageUtils';

export type MobileDeviceUnmutedCheckDialogResourceType = {
    dialogTitle: TextResource;
};

function useMobileDeviceUnmutedCheckDialogResource(): MobileDeviceUnmutedCheckDialogResourceType {
    const { t } = useTranslation();

    const { MobileDeviceUnmutedCheckTitleContent } =
        MobileDeviceUnmutedCheckDialogResource;

    const dialogTitle = {
        id: MobileDeviceUnmutedCheckTitleContent.id,
        text: t(MobileDeviceUnmutedCheckTitleContent.translationKey),
    };

    return {
        dialogTitle,
    };
}

export default useMobileDeviceUnmutedCheckDialogResource;
