import { VoidReturn } from '../../../../Utils/PageUtils';
import { goToUrl } from '../../../../Utils/NavigationUtils';
import { CustomerServiceContactService } from '../../../../Services/Configuration/CustomerServiceContactService';
import { MobileDeviceUnmutedCheckContext } from '../MobileDeviceUnmutedCheckContext';
import { useContext } from 'react';

export type MobileDeviceUnmutedCheckTroubleshootingPageHandler = {
    primaryAction: () => VoidReturn;
    secondaryAction: () => void;
};

export default function useMobileDeviceUnmutedCheckTroubleshootingPageHandler(): MobileDeviceUnmutedCheckTroubleshootingPageHandler {
    const { onClose } = useContext(MobileDeviceUnmutedCheckContext);

    const primaryAction = () => {
        goToUrl(
            CustomerServiceContactService.getCountrySpecificOrionConnectionCheckAddress(
                false
            ),
            true
        );
    };

    const secondaryAction = () => {
        onClose();
    };

    return {
        primaryAction,
        secondaryAction,
    };
}
