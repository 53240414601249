import React, { useState } from 'react';
import { getUserDeviceModel } from '../../../Services/ResourceService';
import { ButtonType } from '../../../SharedComponents/Button/ButtonType';
import CustomButton from '../../../SharedComponents/Button/CustomButton';
import Carousel from '../../../SharedComponents/Carousel/Carousel';
import AppContainer from '../../../SharedComponents/Container/AppContainer';
import BodyContainer from '../../../SharedComponents/Container/BodyContainer';
import CustomImg from '../../../SharedComponents/CustomImage/CustomImg';
import Header from '../../../SharedComponents/Header/HeaderRefactor';
import CustomTypography, {
    TypographyType,
} from '../../../SharedComponents/Typography/CustomTypography';
import useTechnicalSetupHandler from './TechnicalSetupHandlerHook';
import useTechnicalSetupResource from './TechnicalSetupResourceHook';
import './TechnicalSetupStyle.scss';
import { isBluetoothDeviceByModel } from '../../../Utils/BluetoothDeviceUtils';

export default function TechnicalSetup(): JSX.Element {
    const [step, setStep] = useState(0);

    const { handleStartPairingAction, linkCallBacks } =
        useTechnicalSetupHandler();

    const {
        header,
        imgSrcList,
        instructionList,
        button,
        audioRoutingHelpLink,
    } = useTechnicalSetupResource();

    const carouselContents = imgSrcList.map((imgSrc, index) => {
        return (
            <CustomImg
                key={`carousel-img-${index}`}
                style={{ height: '21vh', maxHeight: '21vh' }}
                src={imgSrc}
                alt={`img-carousel-${step}`}
            />
        );
    });

    return (
        <AppContainer>
            <Header headerContent={header} />
            <BodyContainer flexStart noRowMargin>
                <Carousel
                    carouselContents={carouselContents}
                    instructionContents={instructionList}
                    step={step}
                    setStep={(newStep: number) => setStep(newStep)}
                    carouselContainerClassName={
                        'carouselContainerTechnicalSetup'
                    }
                    linkCallBacks={linkCallBacks}
                />
                {step === 1 &&
                isBluetoothDeviceByModel(getUserDeviceModel()) ? (
                    <CustomTypography
                        id={audioRoutingHelpLink.id}
                        type={TypographyType.notoSansBody16px}
                        text={audioRoutingHelpLink.text}
                        linkCallBacks={linkCallBacks}
                    />
                ) : (
                    <></>
                )}
            </BodyContainer>
            {step === carouselContents.length - 1 ? (
                <CustomButton
                    id={button.id}
                    buttonType={ButtonType.Primary}
                    onClick={handleStartPairingAction}
                >
                    {button.label}
                </CustomButton>
            ) : (
                <></>
            )}
        </AppContainer>
    );
}
