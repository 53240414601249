import { useTranslation } from 'react-i18next';
import { EnvironmentInfoService } from '../../../Services/EnvironmentInfoService';
import { HeaderResource, TextResource } from '../../../Utils/PageUtils';
import { HeaderActionType } from '../../Header/HeaderActionType';
import { LegalInfoType } from './LegalDialogModel';

export type LegalResource = {
    header: HeaderResource;
    legalContent: TextResource;
    terms: TextResource;
    warningContent: TextResource;
};

function useLegalResource(type: LegalInfoType): LegalResource {
    const { t } = useTranslation();

    let headerText = '';
    let term = '';
    let warningText = '';

    switch (type) {
        case LegalInfoType.CookiePolicy:
            headerText = 'common:Common_Feature:CookiePolicy';
            break;
        case LegalInfoType.LegalInfo:
            headerText = 'legal:Legal_Feature:LegalInfo:Header';
            break;
        case LegalInfoType.AppInfo:
            headerText = 'legal:Legal_Feature:AppInfo:Header';
            term = 'legal:Legal_Feature:AppInfo:Text1';
            warningText = 'legal:Legal_Feature:AppInfo:Text2';
            break;
    }

    const header = {
        id: 'header-legal-dialog',
        text: t(`${headerText}`),
        type: HeaderActionType.Return,
    };

    const legalContent = {
        id: 'txt-legal',
        text: t(`legal:Legal_Feature:${type}:Text`, {
            Version: EnvironmentInfoService.frontEndVersion(),
            UDI_PI: EnvironmentInfoService.frontEndVersion()
                .substring(1)
                .replace(/\./g, 'A'),
        }),
    };

    const terms = {
        id: 'txt-appinfo-terms',
        text: t(`${term}`),
    };

    const warningContent = {
        id: 'txt-appinfo-warning',
        text: t(`${warningText}`),
    };

    return {
        header,
        legalContent,
        terms,
        warningContent,
    };
}

export default useLegalResource;
