import { useContext, useState } from 'react';
import PatientManagementService from '../../../Services/PatientManagementService';
import LocalStorageService from '../../../Services/LocalStorageService';
import { VoidReturn } from '../../../Utils/PageUtils';
import { MainViewContext } from '../../MainComponents/MainView/MainViewContext';
import { TransitionAction } from '../../MainComponents/MainView/MainViewModel';

export type SevereHearingLossHandler = {
    getLoading: () => boolean | undefined;
    primaryAction: () => VoidReturn;
    secondaryAction: () => VoidReturn;
};

export default function useSevereHearingLossHandler(): SevereHearingLossHandler {
    const [loading, setLoading] = useState(false);
    const viewContext = useContext(MainViewContext);

    function getLoading(): boolean | undefined {
        return loading;
    }

    async function onGoBack() {
        setLoading(true);
        await redoHLAA();
        viewContext.doTransition(TransitionAction.InitialSettingsSevereLoss);
    }

    const redoHLAA = async () => {
        await PatientManagementService.redoHLAA();
        LocalStorageService.serviceInstance.clear(false);
        await PatientManagementService.getPatient();
    };

    async function primaryAction() {
        await onGoBack();
    }

    async function secondaryAction() {
        setLoading(true);
        await redoHLAA();
        viewContext.doTransition(TransitionAction.RedoHLAAViaJourney);
    }

    return {
        getLoading,
        primaryAction,
        secondaryAction,
    };
}
