import { ButtonProps } from '@material-ui/core';
import React from 'react';
import { withTracking } from '../../../../Services/Monitoring/ApplicationInsights';
import { ButtonType } from '../../../../SharedComponents/Button/ButtonType';
import CustomButton from '../../../../SharedComponents/Button/CustomButton';
import PairButton from '../../../../SharedComponents/Button/PairButton';
import AppContainer from '../../../../SharedComponents/Container/AppContainer';
import BodyContainer from '../../../../SharedComponents/Container/BodyContainer';
import Header from '../../../../SharedComponents/Header/HeaderRefactor';
import { StepContentType } from '../../../../SharedComponents/Stepper/StepperModel';
import VerticalStepper from '../../../../SharedComponents/Stepper/VerticalStepper';
import CustomTypography, {
    TypographyType,
} from '../../../../SharedComponents/Typography/CustomTypography';
import useUserJourneyHandler from '../UserJourneyPageHandlerHook';
import PartialDialog from '../../../../SharedComponents/Dialog/PartialDialog/PartialDialog';
import useUserJourneyReturnToTimelineResource from './UserJourneyPageReturnToTimelineResourceHook';
import ReturnToTimelineStateService from '../../../../Services/ReturnToTimelineService';

function UserJourneyReturnToTimelinePage() {
    const {
        useWarningDialogHandler,
        activeReturnToTimelineStep,
        showStartOver,
        onRedoStep,
        warningDisplayed,
    } = useUserJourneyHandler();

    const {
        useWarningDialogResource,
        header,
        stepContents,
        buttonLabels,
        informationText,
    } = useUserJourneyReturnToTimelineResource();

    const primButtonConfig: ButtonProps = {
        id: buttonLabels.primary.id,
        children: buttonLabels.primary.label,
        onClick: () => {
            ReturnToTimelineStateService.enableMenuOption();
            onRedoStep();
        },
    };

    return (
        <AppContainer>
            <PartialDialog
                open={warningDisplayed}
                useHandler={useWarningDialogHandler}
                useResource={useWarningDialogResource}
            />
            <Header headerContent={header} />
            <BodyContainer flexStart>
                <VerticalStepper
                    activeStep={activeReturnToTimelineStep}
                    stepContentType={StepContentType.IconStepper}
                    stepContents={stepContents}
                    showActiveStepText={true}
                    showCompletedStepIcon={false}
                />
                <CustomTypography
                    id={informationText.id}
                    text={informationText.text}
                    type={TypographyType.notoSansBody14px}
                    className="userJourneyInformationText"
                ></CustomTypography>
            </BodyContainer>
            {buttonLabels.secondary ? (
                <PairButton
                    withLoading
                    secondaryButton={{
                        id: buttonLabels.secondary.id,
                        onClick: () => {
                            ReturnToTimelineStateService.enableMenuOption();
                            showStartOver();
                        },
                        children: buttonLabels.secondary.label,
                    }}
                    primaryButton={primButtonConfig}
                />
            ) : (
                <CustomButton
                    buttonType={ButtonType.Primary}
                    {...primButtonConfig}
                />
            )}
        </AppContainer>
    );
}

export default withTracking(UserJourneyReturnToTimelinePage);
