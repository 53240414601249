import { useTranslation } from 'react-i18next';
import {
    ButtonResource,
    ImageResource,
    TextResource,
} from '../../../../Utils/PageUtils';

type MobileDeviceUnmutedCheckConfirmationPageResource = {
    infoText: TextResource;
    image: ImageResource;
    instruction: TextResource;
    buttons: {
        primary: ButtonResource;
        secondary: ButtonResource;
    };
};

export default function useMobileDeviceUnmutedCheckConfirmationPageResource(): MobileDeviceUnmutedCheckConfirmationPageResource {
    const { t } = useTranslation();

    const infoText = {
        id: 'txt-unmuted-check-confirmation-info',
        text: t(
            'common:Common_Feature:MobileDeviceUnmutedCheck:Confirmation:Text1'
        ),
    };

    const image = {
        id: 'img-melody-played',
        src: `/resources/sharedAcrossWorkflows/successful_tick-mark.svg`,
        alt: 'success tick',
    };

    const instruction = {
        id: 'txt-unmuted-check-instruction',
        text: t(
            'common:Common_Feature:MobileDeviceUnmutedCheck:Confirmation:Text2'
        ),
    };

    const buttons = {
        primary: {
            id: 'btn-confirmation-yes',
            label: t('common:Common_Feature:Yes'),
        },
        secondary: {
            id: 'btn-confirmation-no',
            label: t('common:Common_Feature:No'),
        },
    };

    return {
        infoText,
        image,
        instruction,
        buttons,
    };
}
