import customerServiceInfo from '../../CommonAssets/Configuration/customerServiceInfo.json';
import { CustomerServiceData } from '../../Models/CustomerServiceData/CustomerServiceData';
import { ContactDetails } from '../../Models/CustomerServiceData/ContactDetails';
import LocalStorageService from '../LocalStorageService';
import { SupportedCountries } from '../../Models/SupportedCountries';
import { getUserDeviceModelLabel } from '../ResourceService';

export class CustomerServiceContactService {
    public static CountrySpecificsService = new CustomerServiceContactService();
    public static countryData: CustomerServiceData | undefined;

    private static getCountrySpecificContactDetails():
        | CustomerServiceData
        | undefined {
        let countryData;

        const countryCode =
            LocalStorageService.serviceInstance.getPatientCountry() == ''
                ? SupportedCountries.US
                : LocalStorageService.serviceInstance.getPatientCountry();
        const { customerServiceCountryData } = customerServiceInfo;
        for (let i = 0; i < customerServiceCountryData.length; i++) {
            if (customerServiceCountryData[i].countryCode === countryCode) {
                const contactDetails = new Array<ContactDetails>();

                for (
                    let o = 0;
                    o < customerServiceCountryData[i].contacts.length;
                    o++
                ) {
                    contactDetails.push(
                        new ContactDetails(
                            customerServiceCountryData[i].contacts[o].type,
                            customerServiceCountryData[i].contacts[o].contact
                        )
                    );
                }

                this.countryData = new CustomerServiceData(
                    customerServiceCountryData[i].countryCode,
                    customerServiceCountryData[i].primaryContactType,
                    contactDetails
                );

                break;
            }
        }

        return countryData;
    }

    private static checkChangeCountryData() {
        if (
            this.countryData === undefined ||
            (this.countryData !== undefined &&
                this.countryData.countryCode !==
                    LocalStorageService.serviceInstance.getPatientCountry())
        ) {
            this.getCountrySpecificContactDetails();
        }
    }

    private static buildAddressString(
        contactType: string | undefined,
        display = true
    ): string {
        let contactDetails = '';

        if (this.countryData !== undefined) {
            for (const contact of this.countryData.contacts) {
                if (contact.type === contactType) {
                    contactDetails = display
                        ? this.buildMarkdownStyleContactDetails(
                              contact.contact,
                              contact.type
                          )
                        : contact.contact;
                }
            }
        }

        return contactDetails;
    }

    private static buildMarkdownStyleContactDetails(
        contactDetails: string,
        contactType: string
    ): string {
        let markDownStyleContactDetails = contactDetails;
        switch (contactType) {
            case 'phone':
                markDownStyleContactDetails = `[${contactDetails}](<tel:${contactDetails}>)`;
                break;
            case 'email':
                markDownStyleContactDetails = `[${contactDetails}](mailto:${contactDetails})`;
                break;
            case 'FAQ':
            case 'deviceInstruction':
            case 'contactCustomerService':
            case 'community':
                markDownStyleContactDetails = `[${contactDetails}](${contactDetails})`;
                break;
            default:
                break;
        }

        return markDownStyleContactDetails;
    }

    public static getCountrySpecificPrimaryContact(display = true): string {
        this.checkChangeCountryData();
        return this.buildAddressString(
            this.countryData?.primaryContactType,
            display
        );
    }

    public static getCountrySpecificPrimaryContactType(): string {
        this.checkChangeCountryData();

        return this.countryData?.primaryContactType ?? 'contactCustomerService';
    }

    public static getCountrySpecificPhoneContact(display = true): string {
        this.checkChangeCountryData();

        return this.buildAddressString('phone', display);
    }

    public static getCountrySpecificEmailContact(display = true): string {
        this.checkChangeCountryData();

        return this.buildAddressString('email', display);
    }

    public static getCountrySpecificOrionDeviceInstructionAddress(
        display = true
    ): string {
        this.checkChangeCountryData();

        return this.buildAddressString('deviceInstruction', display);
    }

    public static getCountrySpecificOrionConnectionCheckAddress(
        display = true
    ): string {
        this.checkChangeCountryData();

        return display
            ? `[https://www.sony.net/hearing-${getUserDeviceModelLabel()}-phone-settings/](https://www.sony.net/hearing-${getUserDeviceModelLabel()}-phone-settings/)`
            : `https://www.sony.net/hearing-${getUserDeviceModelLabel()}-phone-settings/`;
    }

    public static getCountrySpecificOrionFAQLink(display = true): string {
        this.checkChangeCountryData();

        return this.buildAddressString('FAQ', display);
    }

    public static getCountrySpecificOrionCustomerServiceContact(
        display = true
    ): string {
        this.checkChangeCountryData();

        return this.buildAddressString('contactCustomerService', display);
    }

    public static getCountrySpecificOrionCommunityLink(display = true): string {
        this.checkChangeCountryData();

        return this.buildAddressString('community', display);
    }

    public static getAllCountrySpecificCustomerServiceData(
        _Ignore = true
    ): CustomerServiceData | undefined {
        this.checkChangeCountryData();

        return this.countryData;
    }
}
