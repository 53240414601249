import React from 'react';
import { withTracking } from '../../../Services/Monitoring/ApplicationInsights';
import { ButtonType } from '../../../SharedComponents/Button/ButtonType';
import CustomButton from '../../../SharedComponents/Button/CustomButton';
import ActionContainer from '../../../SharedComponents/Container/ActionContainer';
import AppContainer from '../../../SharedComponents/Container/AppContainer';
import CustomImg from '../../../SharedComponents/CustomImage/CustomImg';
import CustomTypography, {
    TypographyType,
} from '../../../SharedComponents/Typography/CustomTypography';
import { WelcomeView } from '../Welcome/WelcomeView';
import DownloadAppDialog from './DownloadDialog/DownloadAppDialog';
import useLandingHandler from './LandingHandlerHook';
import './LandingPageStyle.scss';
import useLandingResource from './LandingResourceHook';
import useDownloadDialogResource from './DownloadDialog/DownloadAppDialogResourceHook';

function LandingPage(): React.ReactElement {
    const {
        header,
        landingImage,
        instruction,
        buttonFineTuning,
        orionIcon,
        instructionsAppDownload,
    } = useLandingResource();

    const {
        displayDownloadAppDialog,
        closeDownloadAppDialog,
        openDowndloadAppDialog,
        goToFineTuning,
    } = useLandingHandler();

    return (
        <>
            <AppContainer>
                <DownloadAppDialog
                    displayed={displayDownloadAppDialog}
                    onClose={closeDownloadAppDialog}
                    onContinue={closeDownloadAppDialog}
                    useResource={useDownloadDialogResource}
                ></DownloadAppDialog>
                <WelcomeView
                    header={header}
                    illustrationImage={landingImage}
                    subtitle={instruction}
                />
                <CustomButton
                    buttonType={ButtonType.Primary}
                    id={buttonFineTuning.id}
                    onClick={goToFineTuning}
                >
                    {buttonFineTuning.label}
                </CustomButton>
                <ActionContainer className={'landing-app-download-container'}>
                    <CustomImg
                        imgType="iconLarge"
                        id={orionIcon.id}
                        src={orionIcon.src}
                        alt={orionIcon.alt}
                    />
                    <CustomTypography
                        className={'landing-app-download-textarea'}
                        type={TypographyType.notoSansBody16px}
                        textAlign="left"
                        id={instructionsAppDownload.id}
                        text={instructionsAppDownload.text}
                        linkCallBacks={{ openDowndloadAppDialog }}
                    />
                </ActionContainer>
            </AppContainer>
        </>
    );
}

export default withTracking(LandingPage);
