import React from 'react';
import { withTracking } from '../../../Services/Monitoring/ApplicationInsights';
import { getUserDeviceModel } from '../../../Services/ResourceService';
import { ButtonType } from '../../../SharedComponents/Button/ButtonType';
import CustomButton from '../../../SharedComponents/Button/CustomButton';
import AppContainer from '../../../SharedComponents/Container/AppContainer';
import BodyContainer from '../../../SharedComponents/Container/BodyContainer';
import CustomImg from '../../../SharedComponents/CustomImage/CustomImg';
import Header from '../../../SharedComponents/Header/HeaderRefactor';
import CustomTypography, {
    TypographyType,
} from '../../../SharedComponents/Typography/CustomTypography';
import { isBluetoothDeviceByModel } from '../../../Utils/BluetoothDeviceUtils';
import useFineTuningCompletePageHandler from './FineTuningCompletePageHandlerHook';
import useFineTuningCompletePageResource from './FineTuningCompletePageResourceHook';
import './FineTuningCompletePageStyle.scss';

const FineTuningCompletePage = () => {
    const { onExit } = useFineTuningCompletePageHandler();
    const {
        header,
        illustrationImage,
        subtitle,
        instruction,
        instructionBle,
        buttons,
    } = useFineTuningCompletePageResource();
    return (
        <AppContainer>
            <Header headerContent={header} textStyle={'customHeader'} />
            <CustomImg
                imgType="fullwidth"
                id={illustrationImage.id}
                src={illustrationImage.src}
                alt={illustrationImage.alt}
            />
            <BodyContainer flexStart>
                <CustomTypography
                    type={TypographyType.notoSansSubHeading}
                    id={subtitle.id}
                    text={subtitle.text}
                    className={'finetuning-complete-subtitle'}
                />
                <CustomTypography
                    type={TypographyType.notoSansBody16px}
                    id={instruction.id}
                    text={instruction.text}
                    className={'finetuning-complete-instruction'}
                />
                {isBluetoothDeviceByModel(getUserDeviceModel()) && (
                    <CustomTypography
                        type={TypographyType.notoSansBody16px}
                        id={instructionBle.id}
                        text={instructionBle.text}
                        className={'finetuning-complete-instruction'}
                    />
                )}
            </BodyContainer>
            <CustomButton
                id={buttons.exit.id}
                buttonType={ButtonType.Primary}
                onClick={onExit}
            >
                {buttons.exit.label}
            </CustomButton>
        </AppContainer>
    );
};

export default withTracking(FineTuningCompletePage);
