import { useTranslation } from 'react-i18next';
import { VerticalStepperContent } from '../../../SharedComponents/Stepper/StepperModel';
import { useFineTuningContext } from '../FineTuningContext';
import './FineTuningStartPageStyle.scss';

export interface IFineTuningStartPageResourceProps {
    activeStep: number;
    onClick: (categorySelection: boolean) => void;
}

export type FineTuningStartPageResource = {
    stepContents: VerticalStepperContent[];
    headerText: string;
    instructionText: string;
    resetText: string;
    buttonText: string;
    resetImgSrc: string;
};

const useFineTuningStartPageResource = (
    props: IFineTuningStartPageResourceProps
): FineTuningStartPageResource => {
    const { t } = useTranslation();
    const { info } = useFineTuningContext();

    const selectedItems = [
        t(
            `fineTuning:FineTuning_Feature:Category:${info.current.selected.category}`
        ),
        t(
            `fineTuning:FineTuning_Feature:Problem:${info.current.selected.problem.replace(
                /_/g,
                ':'
            )}`
        ),
    ];

    const steps = [
        t('fineTuning:FineTuning_Feature:Text1'),
        t('fineTuning:FineTuning_Feature:Text2'),
    ];

    const goSelectionActions = [
        () => props.onClick(true),
        () => props.onClick(false),
    ];

    const stepContents = steps.map((step, index) => {
        return {
            index: index,
            stepIcon: `/resources/sharedAcrossWorkflows/process0${
                index + 1
            }-completed.svg`,
            stepLabel: step[index],
            stepLabelContent: step,
            stepTextContent: selectedItems[index],
            disabled: props.activeStep < index,
            id: `btn-select-${index === 0 ? 'category' : 'problem'}`,
            className: 'finetuning-selection',
            onClickHandler: goSelectionActions[index],
        };
    });

    const headerText = t('common:Common_Feature:FineTuning');

    const instructionText = t('fineTuning:FineTuning_Feature:Start:Text3');

    const resetText = t('fineTuning:FineTuning_Feature:Start:Text1');

    const buttonText = t('common:Common_Feature:Continue');

    const resetImgSrc = info.current.isResetDisabled
        ? `/resources/fineTuningWorkflow/reset-inactive.svg`
        : `/resources/fineTuningWorkflow/reset-active.svg`;

    return {
        stepContents,
        headerText,
        instructionText,
        resetText,
        buttonText,
        resetImgSrc,
    };
};

export default useFineTuningStartPageResource;
