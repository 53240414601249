import React from 'react';
import { ButtonType } from '../../../SharedComponents/Button/ButtonType';
import CustomButton from '../../../SharedComponents/Button/CustomButton';
import AppContainer from '../../../SharedComponents/Container/AppContainer';
import BodyContainer from '../../../SharedComponents/Container/BodyContainer';
import CustomTypography, {
    TypographyType,
} from '../../../SharedComponents/Typography/CustomTypography';
import LegalDialog from './../../../SharedComponents/Dialog/Legal/LegalDialog';
import FlexContainer from '../../../SharedComponents/Container/FlexContainer';
import useSplashScreenHandler from './SplashScreenHandlerHook';
import useSplashScreenResource from './SplashScreenResourceHook';
import './SplashScreenPageStyle.scss';
import { LegalInfoType } from '../../../SharedComponents/Dialog/Legal/LegalDialogModel';
import CustomDialog from '../../../SharedComponents/Dialog/CustomDialog';

const SplashScreen = (): JSX.Element => {
    const { nextButton, welcomeHeader, welcomeMessage, cookiePolicy } =
        useSplashScreenResource();

    const {
        showLegalInfo,
        gotoLoginPage,
        closeCookiePolicy,
        openCookiePolicy,
        spinner,
        loading,
    } = useSplashScreenHandler();

    const renderPage = () => {
        return (
            <>
                <CustomDialog open={showLegalInfo}>
                    {showLegalInfo && (
                        <LegalDialog
                            onClose={closeCookiePolicy}
                            type={LegalInfoType.CookiePolicy}
                        />
                    )}
                </CustomDialog>

                <BodyContainer>
                    <CustomTypography
                        type={TypographyType.notoSansSubHeading}
                        textAlign="center"
                        id={welcomeHeader.id}
                        text={welcomeHeader.text}
                        className={'splash-screen-welcome-header'}
                    />
                    <CustomTypography
                        type={TypographyType.notoSansBody16px}
                        textAlign="center"
                        id={welcomeMessage.id}
                        text={welcomeMessage.text}
                        className={'splash-screen-welcome-message'}
                    />
                </BodyContainer>
                <CustomButton
                    buttonType={ButtonType.Primary}
                    id={nextButton.id}
                    onClick={gotoLoginPage}
                    className={'splash-screen-button'}
                >
                    {nextButton.label}
                </CustomButton>
                <FlexContainer className={'splash-screen-cookie-policy'}>
                    <CustomTypography
                        type={TypographyType.notoSansBody16px}
                        textAlign="center"
                        id={cookiePolicy.id}
                        text={cookiePolicy.text}
                        linkCallBacks={{
                            cookiePolicyHandler: openCookiePolicy,
                        }}
                    />
                </FlexContainer>
            </>
        );
    };

    return (
        <>
            <AppContainer>{loading ? spinner : renderPage()}</AppContainer>
        </>
    );
};

export default SplashScreen;
