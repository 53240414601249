import { useState } from 'react';
import HLAAService from '../../Services/HLAAService';
import LocalStorageService from '../../Services/LocalStorageService';
import LoggingService from '../../Services/LoggingService';
import TelemetryService from '../../Services/Monitoring/TelemetryService';
import { getUserDeviceModel } from '../../Services/ResourceService';
import { VoidReturn } from '../../Utils/PageUtils';
import { ScoringCheckPageState } from './AudiogramScoringCheckModels';
import AudiogramScoringCheckPage from './AudiogramScoringCheckPage';

export type AudiogramScoringCheckHandle = {
    initialize: () => VoidReturn;
    leftRightDifference: boolean;
    audiogramResultSevere: boolean;
    pageState: ScoringCheckPageState;
};

export default function useAudiogramScoringCheckHandler(): AudiogramScoringCheckHandle {
    const [pageState, setPageState] = useState(ScoringCheckPageState.Loading);
    const [leftRightDifference, setLeftRightDifference] = useState(false);
    const [audiogramResultSevere, setAudiogramResultSevere] = useState(false);
    const userDeviceModel = getUserDeviceModel();

    const sendTrackingAudiogramEventForUserIssue = (
        value: string,
        issue: string
    ) => {
        TelemetryService.serviceInstance.TrackAnonymous('Orion-UserIssue', {
            Model: userDeviceModel,
            Issue: issue,
            FreqAndDb: value,
        });
    };

    const sendTrackingAudiogramEvent = (value: string, side: string) => {
        TelemetryService.serviceInstance.TrackAnonymous(
            `Orion-Audiogram-${side}`,
            {
                Model: userDeviceModel,
                FreqAndDb: value,
            }
        );
    };

    const initialize = async () => {
        const hlaaTestId =
            LocalStorageService.serviceInstance.getLastCompletedHlaaTestId();

        const audiogramHlaaResult = await HLAAService.getResult(hlaaTestId);
        if (audiogramHlaaResult !== undefined) {
            setLeftRightDifference(audiogramHlaaResult.leftRightMismatch);
            setAudiogramResultSevere(audiogramHlaaResult.severe);
            audiogramHlaaResult.severe
                ? setPageState(ScoringCheckPageState.Alert)
                : setPageState(ScoringCheckPageState.Results);

            LoggingService.log({
                componentName: AudiogramScoringCheckPage.name,
                args: [
                    `hlaa results: left=${audiogramHlaaResult.left}, right=${audiogramHlaaResult.right}`,
                ],
            });

            LoggingService.info({
                componentName: AudiogramScoringCheckPage.name,
                args: [
                    `Audiogram mismatch for left and right: ${audiogramHlaaResult.leftRightMismatch}`,
                ],
            });

            LoggingService.log({
                componentName: AudiogramScoringCheckPage.name,
                args: [
                    `hlaa audiogram index: left=${JSON.stringify(
                        audiogramHlaaResult.leftAudiogramIndex
                    )}, right=${JSON.stringify(
                        audiogramHlaaResult.rightAudiogramIndex
                    )}`,
                ],
            });

            for (const value of Object.values(
                audiogramHlaaResult.leftAudiogramIndex
            )) {
                sendTrackingAudiogramEvent(value, 'Left');
            }

            for (const value of Object.values(
                audiogramHlaaResult.rightAudiogramIndex
            )) {
                sendTrackingAudiogramEvent(value, 'Right');
            }

            if (audiogramHlaaResult.leftRightMismatch) {
                for (const value of Object.values(
                    audiogramHlaaResult.leftAudiogramIndex
                )) {
                    sendTrackingAudiogramEventForUserIssue(
                        value,
                        'HearingProfileLeftAndRightIsVeryDifferent'
                    );
                }
                for (const value of Object.values(
                    audiogramHlaaResult.rightAudiogramIndex
                )) {
                    sendTrackingAudiogramEventForUserIssue(
                        value,
                        'HearingProfileLeftAndRightIsVeryDifferent'
                    );
                }
            }

            if (audiogramHlaaResult.severe) {
                for (const value of Object.values(
                    audiogramHlaaResult.leftAudiogramIndex
                )) {
                    sendTrackingAudiogramEventForUserIssue(
                        value,
                        'HIDoesNotMatchHearingProfile'
                    );
                }
                for (const value of Object.values(
                    audiogramHlaaResult.rightAudiogramIndex
                )) {
                    sendTrackingAudiogramEventForUserIssue(
                        value,
                        'HIDoesNotMatchHearingProfile'
                    );
                }
            }
        }
    };

    return {
        initialize: initialize,
        leftRightDifference: leftRightDifference,
        audiogramResultSevere: audiogramResultSevere,
        pageState: pageState,
    };
}
