import React from 'react';
import { withTracking } from '../../../Services/Monitoring/ApplicationInsights';
import { VoidReturn } from '../../../Utils/PageUtils';
import CustomDialog from '../CustomDialog';
import WarningDialog from '../Warning/WarningDialog';
import useDeviceInfoCheckDialogHandler from './DeviceInfoCheckDialogHandlerHook';
import useDeviceInfoCheckDialogResource from './DeviceInfoCheckDialogResourceHook';

export interface IDeviceInfoCheckDialogProps {
    display: boolean;
    onClose: () => VoidReturn;
}

function DeviceInfoCheckDialog({
    display,
    onClose,
}: IDeviceInfoCheckDialogProps) {
    const useHandler = () =>
        useDeviceInfoCheckDialogHandler({
            onClose,
        });
    const useResource = () => useDeviceInfoCheckDialogResource();

    return (
        <CustomDialog fullScreen open={display}>
            <WarningDialog useHandler={useHandler} useResource={useResource} />
        </CustomDialog>
    );
}

export default withTracking(DeviceInfoCheckDialog);
