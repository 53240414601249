/* eslint-disable */
import React from 'react';
import {
    createTheme,
    makeStyles,
    responsiveFontSizes,
} from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';

export const theme = responsiveFontSizes(
    createTheme({
        palette: {
            primary: {
                // main blue
                light: '#c2ced4',
                main: '#658494',
                dark: '#44677a',
            },
            secondary: {
                main: '#00B4BC',
            },
            error: {
                main: '#ff3b30',
            },
            success: {
                main: '#adaf17',
            },
            info: {
                main: '#008bd2',
            },
            warning: {
                // orange accent
                main: '#ec6628',
            },
            text: {
                primary: '#44677a',
            },
            action: {
                disabledBackground: 'rgb(224, 224, 224, 1)',
                disabled: 'rgb(224, 224, 224, 1)',
            },
        },
        typography: {
            fontFamily: 'MetropolisRegular',
            // declare h3 as header styling
            h3: {
                width: '60%',
                margin: '5vh 2vw 2vh 6vw',
                fontFamily: 'MetropolisSemiBold',
                color: '#44677a',
            },
            h4: {
                margin: '5vh 2vw 2vh 6vw',
                fontFamily: 'MetropolisSemiBold',
                color: '#44677a',
            },
            h5: {
                fontFamily: 'MetropolisSemiBold',
                color: '#44677a',
            },
            h6: {
                fontFamily: 'MetropolisMedium',
                color: '#44677a',
            },
            button: {
                textTransform: 'none',
                textDecoration: 'none',
            },
            body1: {
                fontSize: '1rem',
                fontFamily: 'MetropolisMedium',
                color: '#44677a',
            },
            body2: {
                fontSize: '0.8rem',
                fontFamily: 'MetropolisMedium',
                color: '#44677a',
            },
        },
        shape: {
            borderRadius: 8,
        },
        props: {
            MuiTab: {
                disableRipple: true,
            },
        },
        mixins: {
            toolbar: {
                minHeight: 48,
            },
        },
        colors: {
            menu: {
                background: '#FFFF',
                label: '#2C2C2C',
            },
            defaultBackgroundColor: '#FFFF',
            right: '#E84249',
            left: '#2DAFE6',
            topProgressBarTrail: '#C5D3DB',
            notificationBarBackground: '#EDEDED',
            sliderTrack: '#E0E6EA',
            mainGreen: '#00B4BC',
            title: '#2C2C2C',
            link: '#00B4BC',
            orange: '#FF9E1B',
            unconfirmed: '#5D7D84',
            body: '#2C2C2C',
        },
        fontFamilies: {
            ComfortaaRegular: 'ComfortaaRegular',
            NotoSansRegular: 'NotoSansRegular',
        },
    })
);

const useStyles = makeStyles({
    root: {
        display: 'flex',
        height: '100vh',
    },

    app: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    main: {
        flex: 1,
        height: '90vh',
        padding: theme.spacing(6, 2, 0),
    },

    listItem: {
        paddingTop: 1,
        paddingBottom: 1,
    },

    listItemActiveItem: {
        color: theme.palette.secondary.dark,
    },
    listItemPrimary: {
        fontSize: 'inherit',
    },
    listItemNested: {
        paddingLeft: theme.spacing(6),
    },
    listItemIcon: {
        minWidth: 'auto',
        marginRight: theme.spacing(-2),
    },

    layout: {
        width: 'auto',
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 600,
            marginRight: 'auto',
            marginLeft: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    buttons: {
        display: 'flex',
        alignSelf: 'flex-end',
        justifyContent: 'flex-end',
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
    mainContainer: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
    },
    table: {
        minWidth: 550,
    },
    input: {
        display: 'none',
    },
    wrapper: {
        position: 'relative',
        margin: theme.spacing(1),
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },

    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
        color: green[500],
    },

    thumbNail: {
        width: 36,
        height: 36,
    },
});

const ThemeContext = React.createContext({
    theme,
    useStyles,
});

export function ThemeProvider(props) {
    return (
        <ThemeContext.Provider
            value={{
                theme,
                useStyles,
            }}
        >
            {props.children}
        </ThemeContext.Provider>
    );
}

export default ThemeContext;
