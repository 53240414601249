import { useContext } from 'react';
import { VoidReturn } from '../../../../Utils/PageUtils';
import { MainViewContext } from '../../../MainComponents/MainView/MainViewContext';
import { TransitionAction } from '../../../MainComponents/MainView/MainViewModel';

export type CustomerServiceHintPageHandler = {
    onExit: () => VoidReturn;
};

export default function useCustomerServiceHintPageHandler(): CustomerServiceHintPageHandler {
    const viewContext = useContext(MainViewContext);

    async function onExit() {
        viewContext.doTransition(TransitionAction.LoopConditionExit);
    }

    return {
        onExit,
    };
}
