import React from 'react';
import { HLAAState } from '../../Models/HLAA/HLAAState';
import { VoidReturn } from '../../Utils/PageUtils';

export type infoType = {
    activeSide: string;
    hlaaState: HLAAState;
    startKeepAlive: boolean;
    totalPageCount: number;
    totalPagesForProgress: number;
    freqStartTime: number;
    repeatDeviceSetup: boolean;
    left: {
        hlaaDone: boolean;
    };
    right: {
        hlaaDone: boolean;
    };
    hlaaTestId: string;
    loading: boolean;
    retryBeepConfirmationCount: number;
    retryBeepConfirmationCountThreshold: number;
};

export enum HearingAssessmentState {
    Start,
    Instruction,
    SetUp,
    BeepConfirmation,
    AssessmentPlay,
    AssessmentOneSide,
    Confirmation,
    OneSideDone,
    Done,
    Result,
    Reinstruct,
    AbortMaxPresentedToneReach,
    Constant0ToneHeard,
    TroubleShooting,
    RedoHLAA,
}

export interface IHearingAssessmentContext {
    info: React.MutableRefObject<infoType>;
    hearingAssessmentState: HearingAssessmentState;
    setHearingAssessmentState: React.Dispatch<HearingAssessmentState>;
    exitHearingAssessment: () => VoidReturn;
}

export const HLAAContext = React.createContext({} as IHearingAssessmentContext);
