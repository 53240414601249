import { createContext, MutableRefObject, useContext } from 'react';
import { DeviceState } from '../../Models/FineTuning/DeviceState';
import { FineTuningSolution } from '../../Models/FineTuning/FineTuningSolution';
import { SupportedCategory } from '../../Models/FineTuning/SupportedCategory';
import { FineTuningSend, FineTuningStateType } from './FineTuningModels';

export interface IFineTuningInfo {
    selected: { category: string; problem: string };
    fineTuningSolution: FineTuningSolution | undefined;
    deviceState: { [deviceId: string]: DeviceState };
    defaultMasterGain: { left: number; right: number };
    supportedCategoryList: SupportedCategory[];
    isResetDisabled: boolean;
    isCategory: boolean;
    deviceStateFinal: { [deviceId: string]: DeviceState };
}
export interface IFineTuningContext {
    info: MutableRefObject<IFineTuningInfo>;
    state: FineTuningStateType;
    send: FineTuningSend;
    handleStartFineTuning: () => Promise<void>;
}

export const FineTuningContext = createContext({} as IFineTuningContext);
export const useFineTuningContext = (): IFineTuningContext =>
    useContext(FineTuningContext);
