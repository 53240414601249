import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { getUserDeviceModel } from '../../../../Services/ResourceService';
import { HeaderActionType } from '../../../../SharedComponents/Header/HeaderActionType';
import { switchBasedOnDevice } from '../../../../Utils/PageUtils';
import { BeepConfirmationResource } from '../../../MainComponents/BeepConfirmation/BeepConfirmation';
import { InitialSettingContext } from '../InitialSettingContext';

export default function useInitialSettingBeepConfirmationResource(): BeepConfirmationResource {
    const { t } = useTranslation();
    const { info } = useContext(InitialSettingContext);
    return {
        header: {
            id: 'initial-setting-header',
            text: t('common:Common_Feature:ApplySettings'),
            type: HeaderActionType.Menu,
        },
        title: {
            id: 'initial-setting-title',
            text: t(
                `pairing:Pairing_Feature:${
                    info.activeSide.toLowerCase() === 'left' ? 'Left' : 'Right'
                }Title`
            ),
        },
        instruction: {
            text: t(`common:Common_Feature:ConfirmationBeepMessage`),
        },
        instructionImage: {
            src: switchBasedOnDevice(getUserDeviceModel(), {
                OrionAir: `/resources/OrionAir/beep-confirmation_${info.activeSide.toLowerCase()}_orionAir.svg`,
                OrionC20: `/resources/OrionC20/beep-confirmation_${info.activeSide.toLowerCase()}_orionC20.svg`,
                OrionGo: `/resources/OrionGo/beep-confirmation_${info.activeSide.toLowerCase()}_orionGo.svg`,
            }),
        },
        buttons: {
            primary: {
                id: 'btn-confirm-yes',
                label: t(`common:Common_Feature:Yes`),
            },
            secondary: {
                id: 'btn-confirm-no',
                label: t(`common:Common_Feature:No`),
            },
        },
    };
}
