import React, { useEffect, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { PrivacyPolicyAndTermsAndConditionsLink } from '../../../CommonAssets/Configuration/PrivacyPolicyAndTermsAndConditions';
import AuthService from '../../../Services/Authentication/AuthService';
import LocalStorageService from '../../../Services/LocalStorageService';
import TelemetryService from '../../../Services/Monitoring/TelemetryService';
import { getUserDeviceModel } from '../../../Services/ResourceService';
import { goToUrl } from '../../../Utils/NavigationUtils';
import { VoidReturn } from '../../../Utils/PageUtils';
import { MainViewContext } from '../MainView/MainViewContext';
import { TransitionAction } from '../MainView/MainViewModel';
import { setPatientInfo } from '../../../Utils/PatientUtils';

export type ConsentPageHandler = {
    showStartButton: boolean;
    handleOperationDataCheckboxChange: (
        event: React.ChangeEvent<HTMLInputElement>
    ) => VoidReturn;
    onContinue: () => VoidReturn;
    linkCallBacks: {
        privacyPolicyHandler: () => void;
    };
};

function useConsentPageHandler(
    setLoading: React.Dispatch<boolean>
): ConsentPageHandler {
    const history = useHistory();
    const viewContext = useContext(MainViewContext);
    const [showStartButton, setShowStartButton] = React.useState(false);

    const loading = useCallback(async () => {
        setLoading(true);
        const patientId = LocalStorageService.serviceInstance.getPatientId();
        if (patientId === '') {
            const refreshCookies = async () => {
                await AuthService.adB2cInstance.refreshCookies();
            };
            refreshCookies();
        }
        setLoading(false);
    }, [setLoading]);

    useEffect(() => {
        loading();
    }, [history, loading]);

    const handleOperationDataCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setShowStartButton(event.target.checked);
    };

    const onContinue = async () => {
        setShowStartButton(false);

        TelemetryService.serviceInstance.TrackAnonymous('Orion-FeatureUsage', {
            Feature: 'OperationConsented',
            Model: getUserDeviceModel(),
        });

        //skip asking user whether consent on analytic data collection
        setPatientInfo(false);
        viewContext.doTransition(TransitionAction.AnalyticsConsentContinue);
    };

    const openPrivacyPolicyLink = () => {
        goToUrl(PrivacyPolicyAndTermsAndConditionsLink);
    };

    const linkCallBacks = {
        privacyPolicyHandler: openPrivacyPolicyLink,
    };

    return {
        showStartButton,
        handleOperationDataCheckboxChange,
        onContinue,
        linkCallBacks,
    };
}

export default useConsentPageHandler;
