import React from 'react';
import { withTracking } from '../../../Services/Monitoring/ApplicationInsights';
import { VoidReturn } from '../../../Utils/PageUtils';
import AppContainer from '../../Container/AppContainer';
import BodyContainer from '../../Container/BodyContainer';
import CustomImg from '../../CustomImage/CustomImg';
import Header from '../../Header/HeaderRefactor';
import CustomTypography, {
    TypographyType,
} from '../../Typography/CustomTypography';
import CustomDialog from '../CustomDialog';
import useMaintenanceDialogResource from './MaintenanceDialogResourceHook';
import './MaintenanceDialog.scss';
import useMaintenanceDialogHandler from './MaintenanceDialogHandlerHook';

export interface IMaintenanceDialogProps {
    displayed: boolean;
    maintenancePeriod: number;
    onClose: () => VoidReturn;
}

function MaintenanceDialog(props: IMaintenanceDialogProps) {
    const {
        header,
        image,
        dialogTitle,
        dialogMessage,
        dialogHour,
        dialogMinute,
        dialogSecond,
    } = useMaintenanceDialogResource();

    const { getTimerText } = useMaintenanceDialogHandler({
        maintenanceStart: props.maintenancePeriod,
    });

    const timerCaptions = [dialogHour, dialogMinute, dialogSecond];

    const { hourTimer, minuteTimer } = getTimerText();

    const renderTime = (timeComponent: string, keyValue: number) => {
        return (
            <span className={'maintenanceTimerArea'} key={keyValue}>
                {timeComponent
                    .split(/(?!$)/u)
                    .filter(Boolean)
                    .map(function (char, i) {
                        return (
                            <CustomTypography
                                id={char}
                                key={i}
                                type={TypographyType.notoSansSubHeading}
                                text={`${char}`}
                                className={
                                    /^\d+$/.test(char)
                                        ? 'maintenanceTimerTextDigit'
                                        : 'maintenanceTimerTextSeperator'
                                }
                            />
                        );
                    })}
                <CustomTypography
                    id={timerCaptions[keyValue].id}
                    type={TypographyType.notoSansBody16px}
                    text={timerCaptions[keyValue].text}
                    className={'maintenanceTimerCaption'}
                />
            </span>
        );
    };

    return (
        <CustomDialog fullScreen open={props.displayed}>
            <AppContainer>
                <Header headerContent={header} onClick={props.onClose} />
                <BodyContainer>
                    <CustomImg
                        imgType={image.size ? image.size : 'small'}
                        id={image.id}
                        src={image.src}
                        alt={image.alt}
                        className={'maintenanceImageArea'}
                    />
                    <CustomTypography
                        id={dialogTitle.id}
                        type={TypographyType.notoSansBody18px}
                        text={dialogTitle.text}
                        className={'maintenanceTextArea'}
                    />
                    <CustomTypography
                        id={dialogMessage.id}
                        type={TypographyType.notoSansBody16px}
                        text={dialogMessage.text}
                        className={'maintenanceTextArea'}
                    />
                    <span className={'maintenanceTimerArea'}>
                        {[hourTimer, minuteTimer].map(function (
                            item,
                            keyValue
                        ) {
                            return (
                                <span key={keyValue}>
                                    {renderTime(item, keyValue)}
                                </span>
                            );
                        })}
                    </span>
                </BodyContainer>
            </AppContainer>
        </CustomDialog>
    );
}

export default withTracking(MaintenanceDialog);
