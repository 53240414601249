import { useContext } from 'react';
import { MainViewContext } from '../../../../Pages/MainComponents/MainView/MainViewContext';
import { TransitionAction } from '../../../../Pages/MainComponents/MainView/MainViewModel';
import { WarningDialogHandler } from '../../Warning/WarningDialog';
import { RedoEasyFitContext, RedoEasyFitState } from '../RedoEasyFitContext';
import AudioService from '../../../../Services/AudioService';
import PatientManagementService from '../../../../Services/PatientManagementService';
import LocalStorageService from '../../../../Services/LocalStorageService';
import { isBluetoothDeviceByModel } from '../../../../Utils/BluetoothDeviceUtils';
import { getUserDeviceModel } from '../../../../Services/ResourceService';
import ArcToneService from '../../../../Services/ArcToneService';

function useRedoEasyFitDialogHandler(): WarningDialogHandler {
    const viewContext = useContext(MainViewContext);
    const redoEasyFitContext = useContext(RedoEasyFitContext);

    const reset = async () => {
        await PatientManagementService.reSetupApp();
        LocalStorageService.serviceInstance.clear(false);
        await PatientManagementService.getPatient();
        await AudioService.serviceInstance.initAudioContext();
    };
    const redoEasyFitForAir = async () => {
        const leftBrandId =
            LocalStorageService.serviceInstance.getDeviceBrandId('left');
        const leftPairingAddress =
            LocalStorageService.serviceInstance.getPairingAddress('left');
        const rightBrandId =
            LocalStorageService.serviceInstance.getDeviceBrandId('right');
        const rightPairingAddress =
            LocalStorageService.serviceInstance.getPairingAddress('right');

        await AudioService.serviceInstance.prepAudio(
            ArcToneService.resetEasyFitControls(
                leftBrandId,
                leftPairingAddress
            ),
            'left side reset factory'
        );
        await AudioService.serviceInstance.prepAudio(
            ArcToneService.resetEasyFitControls(
                rightBrandId,
                rightPairingAddress
            ),
            'right side reset factory'
        );
    };

    const primaryAction = async () => {
        await reset();
        if (isBluetoothDeviceByModel(getUserDeviceModel())) {
            redoEasyFitContext.setRedoEasyFitState(
                RedoEasyFitState.OneSideSetUp
            );
        } else {
            await redoEasyFitForAir();
            viewContext.doTransition(TransitionAction.RedoEasyFitContinue);
        }
    };

    const secondaryAction = () => {
        viewContext.doTransition(TransitionAction.SkipRedoEasyFit);
    };

    return {
        primaryAction,
        secondaryAction,
    };
}

export default useRedoEasyFitDialogHandler;
