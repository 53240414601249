import { Grow, Icon } from '@material-ui/core';
import React from 'react';
import { usePlayToneHandler } from './PlayToneBubbleHandlerHook';

export interface IBubblePlayToneIcon {
    displayDuration: number; // Duration the bubble appears
    waitingDuration: number; // Duration the bubble disappear
    size: string;
    rotation: number;
}

export default function PlayToneBubble(
    props: IBubblePlayToneIcon
): React.ReactElement {
    const { displayed, elementRef } = usePlayToneHandler(props);

    return (
        <div>
            <Grow
                ref={elementRef}
                in={displayed}
                style={{ transformOrigin: '0 0 0' }}
                {...(displayed ? { timeout: props.displayDuration } : {})}
            >
                <Icon className={'icon'}>
                    <img
                        className={'image'}
                        alt=""
                        src={`/resources/fittingWorkflow/tone-icon.svg`}
                        style={{
                            transform: `rotate(${props.rotation}deg)`,
                            height: props.size,
                        }}
                    />
                </Icon>
            </Grow>
        </div>
    );
}
