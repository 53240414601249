import { useTranslation } from 'react-i18next';
import { HeaderActionType } from '../../../SharedComponents/Header/HeaderActionType';
import {
    ButtonResource,
    HeaderResource,
    ImageResource,
    TextResource,
} from '../../../Utils/PageUtils';

export type FineTuningCompletePageResource = {
    header: HeaderResource;
    illustrationImage: ImageResource;
    subtitle: TextResource;
    instruction: TextResource;
    instructionBle: TextResource;
    buttons: {
        exit: ButtonResource;
    };
};

export default function useFineTuningCompletePageResource(): FineTuningCompletePageResource {
    const { t } = useTranslation();
    const header = {
        id: 'header-finetuning-complete',
        type: HeaderActionType.Menu,
        text: t('orionApp:OrionApp_Workflow:FineTuning_Feature:Welcome:Header'),
    };

    const illustrationImage = {
        id: 'img-easyfitillustration',
        src: '/resources/sharedAcrossWorkflows/easyfit-illustration.png',
        alt: t('welcome:header'),
    };

    const subtitle = {
        id: 'txt-subtitle',
        text: t('orionApp:OrionApp_Workflow:FineTuning_Feature:Exit:Text'),
    };

    const instruction = {
        id: 'txt-instruction',
        text: t(
            'orionApp:OrionApp_Workflow:FineTuning_Feature:Exit:TextInstruction'
        ),
    };

    const instructionBle = {
        id: 'txt-instruction-ble',
        text: t(
            'orionApp:OrionApp_Workflow:FineTuning_Feature:Exit:TextInstructionBle'
        ),
    };

    const exitButton = {
        id: 'btn-exit',
        label: t('common:Common_Feature:Leave'),
    };

    const buttons = {
        exit: exitButton,
    };

    return {
        header,
        illustrationImage,
        subtitle,
        instruction,
        instructionBle,
        buttons,
    };
}
