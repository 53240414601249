import React from 'react';
import Header from '../../../../SharedComponents/Header/HeaderRefactor';
import useSpeechComfortTestStartPageHandler from './SpeechComfortTestStartPageHandlerHook';
import AppContainer from '../../../../SharedComponents/Container/AppContainer';
import CustomButton from '../../../../SharedComponents/Button/CustomButton';
import { ButtonType } from '../../../../SharedComponents/Button/ButtonType';
import BodyContainer from '../../../../SharedComponents/Container/BodyContainer';
import CustomImg from '../../../../SharedComponents/CustomImage/CustomImg';
import CustomTypography, {
    TypographyType,
} from '../../../../SharedComponents/Typography/CustomTypography';
import useSpeechComfortTestStartPageResource from './SpeechComfortTestStartPageResourceHook';
import './SpeechComfortTestStartPage.scss';

export default function SpeechComfortTestStartPage(): React.ReactElement {
    const { goToDetailPage } = useSpeechComfortTestStartPageHandler();
    const { header, instructionImage, instructionText, button } =
        useSpeechComfortTestStartPageResource();

    return (
        <AppContainer>
            <Header headerContent={header} />
            <CustomImg
                imgType="fullwidth"
                id={instructionImage.id}
                src={instructionImage.src}
                alt={instructionImage.alt}
                className={'finishedImageSpeechComfortStart'}
            />
            <BodyContainer>
                <CustomTypography
                    type={TypographyType.notoSansBody16px}
                    id={instructionText.id}
                    text={instructionText.text}
                />
            </BodyContainer>
            <CustomButton
                buttonType={ButtonType.PrimaryLoading}
                id={button.id}
                onClick={async () => {
                    await goToDetailPage();
                }}
            >
                {button.label}
            </CustomButton>
        </AppContainer>
    );
}
