import { FineTuningSolutionState } from '../../../Models/FineTuning/FineTuningSolutionState';
import FineTuningService from '../../../Services/FineTuningService';
import LocalStorageService from '../../../Services/LocalStorageService';
import LoggingService from '../../../Services/LoggingService';
import TelemetryService from '../../../Services/Monitoring/TelemetryService';
import { linkCallBacksResource } from '../../../Utils/PageUtils';
import { getCouplingOrDefault } from '../../../Utils/CouplingUtils';
import { useFineTuningContext } from '../FineTuningContext';
import { useFineTuningSolutionContext } from '../Solution/FineTuningSolutionContext';
import { isChangeSleeveSolution } from '../../../Utils/ChangeSleeveUtils';
import { getUserDeviceModel } from '../../../Services/ResourceService';

export type FineTuningStartPageHandler = {
    infoCallback: linkCallBacksResource;
    activeStep: number;
    solutionButtonDisabled: boolean;
    goHome: () => void;
    goSelection: (categorySelection: boolean) => void;
    goSolution: () => Promise<void>;
    goReset: () => void;
};

const useFineTuningStartPageHandler = (): FineTuningStartPageHandler => {
    const { info, send } = useFineTuningContext();
    const { setFineTuningSolutionState } = useFineTuningSolutionContext();
    const infoCallback = {
        fineTuningInfo: () => {
            send('goToInfo');
        },
    };

    const activeStep =
        info.current.selected.category === '-1'
            ? 0
            : info.current.selected.problem === '-1'
            ? 1
            : 2;

    const solutionButtonDisabled =
        info.current.selected.category === '-1' ||
        info.current.selected.problem === '-1';

    const goHome = () => {
        send('goToLanding');
    };

    const goSelection = (categorySelection: boolean) => {
        info.current.isCategory = categorySelection;
        send('goToSelection');
    };

    const goSolution = async () => {
        TelemetryService.serviceInstance.TrackAnonymous('Orion-FeatureUsage', {
            Feature: 'FineTuning',
            Model: getUserDeviceModel(),
        });
        info.current.fineTuningSolution =
            await FineTuningService.getFineTuningSolution(
                LocalStorageService.serviceInstance.getActiveHearingProfileId(),
                LocalStorageService.serviceInstance.getDeviceModelId('left'),
                info.current.selected.category,
                info.current.selected.problem,
                1,
                getCouplingOrDefault('left') | 0
            );
        if (info.current.fineTuningSolution !== undefined) {
            if (isChangeSleeveSolution(info.current.fineTuningSolution.name)) {
                setFineTuningSolutionState(
                    FineTuningSolutionState.ChangeSleeveRecommend
                );
            }
            send('goToSolution');
        } else {
            LoggingService.warn({
                componentName: goSelection.name,
                args: [
                    `Error: cannot find solution for category ${info.current.selected.category} 
                    and problem ${info.current.selected.problem}`,
                ],
            });
        }
    };

    const goReset = () => {
        send('goToReset');
    };

    return {
        infoCallback,
        activeStep,
        solutionButtonDisabled,
        goHome,
        goSelection,
        goSolution,
        goReset,
    };
};

export default useFineTuningStartPageHandler;
