import { useContext } from 'react';
import ArcToneService from '../../../Services/ArcToneService';
import AudioService from '../../../Services/AudioService';
import LocalStorageService from '../../../Services/LocalStorageService';
import { VoidReturn } from '../../../Utils/PageUtils';
import { ProgressBarCalculateCurrentProgress } from '../../../Utils/ProgressBarService';
import { sleep } from '../../../Utils/ResourcesUtils';
import { RunningState, useRunning } from '../../../Utils/UseRunning';
import { HearingAssessmentState, HLAAContext } from '../HLAAContext';

export type HearingAssessmentOneSideHandler = {
    onPlayTone: () => VoidReturn;
    getTopProgress: () => number;
};

export default function useHearingAssessmentOneSideHandler(): HearingAssessmentOneSideHandler {
    const { info, setHearingAssessmentState } = useContext(HLAAContext);
    const [_Ignore, _ignore, setRunning] = useRunning();

    const getTopProgress = (): number => {
        return ProgressBarCalculateCurrentProgress(
            info.current.totalPageCount,
            info.current.totalPagesForProgress
        );
    };

    const onPlayTone = async () => {
        try {
            info.current.startKeepAlive = false;
            setRunning(RunningState.Reset);
            const brandId =
                LocalStorageService.serviceInstance.getDeviceBrandId(
                    info.current.activeSide
                );
            const pairingAddress =
                LocalStorageService.serviceInstance.getPairingAddress(
                    info.current.activeSide
                );
            await AudioService.serviceInstance.prepAudio(
                ArcToneService.setFrequency(
                    brandId,
                    pairingAddress,
                    info.current.hlaaState.freqId
                ),
                'SetFrequency'
            );
            await sleep(500);
            await AudioService.serviceInstance.prepAudio(
                ArcToneService.getPlayToneSequence(
                    brandId,
                    pairingAddress,
                    info.current.hlaaState.seqId
                ),
                `SeqId ${info.current.hlaaState.seqId}, freqId ${info.current.hlaaState.freqId}`
            );

            //To let the user hear the tone from hearing aid
            await sleep(9000);

            info.current.startKeepAlive = true;
            setHearingAssessmentState(HearingAssessmentState.Confirmation);
        } catch (error) {
            setRunning(
                RunningState.Error,
                `Error when playing tone: ${error}.`
            );
            throw error;
        }
    };

    return {
        onPlayTone,
        getTopProgress,
    };
}
