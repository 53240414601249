import React, { useEffect, useRef, useState } from 'react';
import Header from '../../SharedComponents/Header/HeaderRefactor';
import { withTracking } from '../../Services/Monitoring/ApplicationInsights';
import InitialSettingApplicationPage from './InitialSetting/InitialSettingApplicationPage';
import SevereHearingLossPage from './SevereHearingLoss/SevereHearingLossPage';
import useAudiogramScoringCheckResource from './AudiogramScoringCheckPageResourceHook';
import AppContainer from '../../SharedComponents/Container/AppContainer';
import {
    IAudiogramInfo,
    IAudiogramContext,
    AudiogramContext,
} from './AudiogramScoringContext';
import useAudiogramScoringCheckHandler from './AudiogramScoringCheckPageHandlerHook';

export enum ScoringCheckPageState {
    Loading = 'loading',
    Alert = 'alert',
    Results = 'results',
}

function AudiogramScoringCheckPage() {
    const { header } = useAudiogramScoringCheckResource();
    const { initialize, pageState, leftRightDifference } =
        useAudiogramScoringCheckHandler();
    const isComponentMounted = useRef(true);

    const [info, setInfo] = useState<IAudiogramInfo>({
        leftScore: 0,
        rightScore: 0,
        leftRightDifference: leftRightDifference,
        scorinPageState: ScoringCheckPageState.Loading,
    });

    const initialValue: IAudiogramContext = {
        infoAudiogram: info,
        setAudiogramInfo: setInfo,
    };

    useEffect(() => {
        (async () => {
            if (isComponentMounted.current) {
                await initialize();
            }
        })();
        return () => {
            isComponentMounted.current = false;
        };
    }, [initialize]);

    const renderSwitch = (state: ScoringCheckPageState) => {
        window.scrollTo(0, 0);
        switch (state) {
            case ScoringCheckPageState.Results: {
                return (
                    <InitialSettingApplicationPage
                        isLeftRightDifference={leftRightDifference}
                    />
                );
            }
            case ScoringCheckPageState.Alert: {
                return <SevereHearingLossPage />;
            }
            default: {
                return (
                    <AppContainer>
                        <Header headerContent={header} />
                    </AppContainer>
                );
            }
        }
    };

    return (
        <AudiogramContext.Provider value={initialValue}>
            {renderSwitch(pageState)}
        </AudiogramContext.Provider>
    );
}
export default withTracking(AudiogramScoringCheckPage);
