import React from 'react';
import { MobileDeviceUnmutedCheckState } from './MobileDeviceUnmutedCheckModel';

export interface IMobileDeviceUnmutedCheckContext {
    currentMobileDeviceUnmutedCheckState: MobileDeviceUnmutedCheckState;
    setCurrentMobileDeviceUnmutedCheckState: React.Dispatch<MobileDeviceUnmutedCheckState>;
    onClose: () => void;
}

export const MobileDeviceUnmutedCheckContext = React.createContext(
    {} as IMobileDeviceUnmutedCheckContext
);
