export interface IVerticalStepper extends VerticalStepperProps {
    stepContentType: StepContentType;
}

export interface VerticalStepperProps {
    activeStep: number;
    showActiveStepText: boolean;
    showCompletedStepIcon: boolean;
    stepContents: VerticalStepperContent[];
}

export type VerticalStepperContent = {
    index: number;
    stepIcon?: string;
    stepIconCompleted?: string;
    stepLabel: string;
    stepLabelContent: string;
    stepTextContent?: string;
    currentStepIndicatorText?: string;
    className?: string;
    disabled?: boolean;
    id?: string;
    onClickHandler?: () => void;
};

export enum StepContentType {
    IconStepper,
    ButtonStepper,
}
