import { useContext } from 'react';
import DeviceManagementService from '../../../Services/DeviceManagementService';
import LocalStorageService from '../../../Services/LocalStorageService';
import TelemetryService from '../../../Services/Monitoring/TelemetryService';
import { getUserDeviceModel } from '../../../Services/ResourceService';
import { MainViewContext } from '../../MainComponents/MainView/MainViewContext';
import { TransitionAction } from '../../MainComponents/MainView/MainViewModel';
import { ISpeechComfortTestInfo } from './SpeechComfortTestContext';
import { getMasterGainIdFromObject } from './SpeechComfortTestUtils';

export type RedoLoudnessPageHandler = {
    exitSpeechComfort: (value: ISpeechComfortTestInfo) => Promise<void>;
};

export default function useRedoLoudnessPageHandler(): RedoLoudnessPageHandler {
    const viewContext = useContext(MainViewContext);

    const exitSpeechComfort = async (info: ISpeechComfortTestInfo) => {
        const leftDeviceId =
            LocalStorageService.serviceInstance.getDeviceId('Left');
        const rightDeviceId =
            LocalStorageService.serviceInstance.getDeviceId('Right');
        const leftCluster = await DeviceManagementService.getClusterId(
            leftDeviceId
        );
        const rightCluster = await DeviceManagementService.getClusterId(
            rightDeviceId
        );

        const userDeviceModel = getUserDeviceModel();

        TelemetryService.serviceInstance.TrackAnonymous(
            'Orion-RedoLoudness-MasterGain',
            {
                Model: userDeviceModel,
                LeftCluster: leftCluster.toString(),
                PreviousLeftMasterGain: getMasterGainIdFromObject(
                    info.masterGain.uiToValueMap,
                    'left',
                    info.previousLeftValue
                ).toString(),
                CurrentLeftMasterGain: getMasterGainIdFromObject(
                    info.masterGain.uiToValueMap,
                    'left',
                    info.latestLeftValue
                ).toString(),
            }
        );

        TelemetryService.serviceInstance.TrackAnonymous(
            'Orion-RedoLoudness-MasterGain',
            {
                Model: userDeviceModel,
                RightCluster: rightCluster.toString(),
                PreviousRightMasterGain: getMasterGainIdFromObject(
                    info.masterGain.uiToValueMap,
                    'right',
                    info.previousRightValue
                ).toString(),
                CurrentRightMasterGain: getMasterGainIdFromObject(
                    info.masterGain.uiToValueMap,
                    'right',
                    info.latestRightValue
                ).toString(),
            }
        );
        viewContext.doTransition(TransitionAction.RedoLoudnessContinue);
    };

    return {
        exitSpeechComfort,
    };
}
