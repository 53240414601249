import React from 'react';
import { VoidReturn } from '../../../Utils/PageUtils';
import { MenuDialogType } from './MenuDialogType';

export interface IMenuContext {
    pushMenu: (menuName: MenuDialogType) => void;
    popMenu: () => void;
    onClose?: () => VoidReturn;
}

export const MenuContext = React.createContext({} as IMenuContext);
