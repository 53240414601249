import { useTranslation } from 'react-i18next';
import { ButtonResource, TextResource } from '../../../Utils/PageUtils';
import { createGetPathFunction } from '../../../Utils/ResourcesUtils';

type SplashScreenResource = {
    nextButton: ButtonResource;
    welcomeHeader: TextResource;
    welcomeMessage: TextResource;
    cookiePolicy: TextResource;
};

function useSplashScreenResource(): SplashScreenResource {
    const getTrans = createGetPathFunction('splashScreen', ':');
    const { t } = useTranslation();

    const nextButton = {
        id: 'btn-next',
        label: t(getTrans('SplashScreen_Feature.NextButton')),
    };

    const welcomeHeader = {
        id: 'splashscreen-header',
        text: t(getTrans('SplashScreen_Feature.TitleText')),
    };

    const welcomeMessage = {
        id: 'splashscreen-message',
        text: t(getTrans('SplashScreen_Feature.Text1')),
    };

    const cookiePolicy = {
        id: 'txt-cookie-policy',
        text: t(getTrans('SplashScreen_Feature.Text2')),
    };

    return {
        nextButton,
        welcomeHeader,
        welcomeMessage,
        cookiePolicy,
    };
}

export default useSplashScreenResource;
