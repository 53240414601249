import { useContext } from 'react';
import { useTranslation } from 'react-i18next/';
import { BeepConfirmationResource } from '../../../../Pages/MainComponents/BeepConfirmation/BeepConfirmation';
import { HeaderActionType } from '../../../Header/HeaderActionType';
import { RedoEasyFitContext } from '../RedoEasyFitContext';

export const useRedoEasyFitConfirmationResource =
    (): BeepConfirmationResource => {
        const { info } = useContext(RedoEasyFitContext);
        const { t } = useTranslation();

        const side =
            info.current.activeSide.toLowerCase() === 'left' ? 'Left' : 'Right';
        return {
            header: {
                id: 'redo-easyFit-header',
                text: t(
                    'orionApp:OrionApp_Workflow:RedoAssessment_Feature:OneSideSetup:Header'
                ),
                type: HeaderActionType.Menu,
            },
            title: {
                id: 'redo-easyFit-title',
                text: t(`pairing:Pairing_Feature:${side}Title`),
            },
            instruction: {
                id: 'text-instruction',
                text: t(`common:Common_Feature:ConfirmationBeepMessage`),
            },
            instructionImage: {
                id: 'img-instruction',
                src: `/resources/OrionGo/beep-confirmation_${info.current.activeSide.toLowerCase()}_orionGo.svg`,
            },
            buttons: {
                primary: {
                    id: 'btn-confirm-yes',
                    label: t(`common:Common_Feature:Yes`),
                },
                secondary: {
                    id: 'btn-confirm-no',
                    label: t(`common:Common_Feature:No`),
                },
            },
        };
    };
