import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import { SupportedCountries } from '../../../Models/SupportedCountries';
import AuthService from '../../../Services/Authentication/AuthService';
import LocalStorageService from '../../../Services/LocalStorageService';
import { VoidReturn } from '../../../Utils/PageUtils';
import { MainViewContext } from '../MainView/MainViewContext';
import { TransitionAction } from '../MainView/MainViewModel';

export type WelcomeHandler = {
    onContinue: () => VoidReturn;
};

function useWelcomeHandler(): WelcomeHandler {
    const history = useHistory();
    const viewContext = useContext(MainViewContext);

    const loading = async () => {
        const patientId = LocalStorageService.serviceInstance.getPatientId();
        if (patientId === '') {
            const refreshCookies = async () => {
                await AuthService.adB2cInstance.refreshCookies();
            };
            refreshCookies();
        }
    };

    useEffect(() => {
        loading();
    }, [history]);

    const onContinue = async () => {
        LocalStorageService.serviceInstance.setSelectedCountry(
            SupportedCountries.US
        );
        LocalStorageService.serviceInstance.setSwitchDevice(false);
        await viewContext.doTransition(TransitionAction.WelcomeContinue);
    };

    return {
        onContinue,
    };
}

export default useWelcomeHandler;
