import { Dialog, DialogProps } from '@material-ui/core';
import React from 'react';

export default function CustomDialog(props: DialogProps): React.ReactElement {
    return (
        <Dialog
            fullScreen
            PaperProps={{ style: { alignItems: 'center' } }}
            {...props}
        />
    );
}
