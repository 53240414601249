import React, { useContext, useEffect } from 'react';

import Header from '../../../SharedComponents/Header/HeaderRefactor';
import TopProgressBar from '../../../SharedComponents/TopProgressBar/TopProgressBar';
import TelemetryService from '../../../Services/Monitoring/TelemetryService';
import { ButtonType } from '../../../SharedComponents/Button/ButtonType';
import CustomButton from '../../../SharedComponents/Button/CustomButton';
import AppContainer from '../../../SharedComponents/Container/AppContainer';
import useHearingAssessmentOneSideDoneResource from './HearingAssessmentOneSideDoneResource';
import CustomTypography, {
    TypographyType,
} from '../../../SharedComponents/Typography/CustomTypography';
import './HearingAssessmentOneSideDoneStyle.scss';
import '../HearingAssessmentStyle.scss';
import CustomImg from '../../../SharedComponents/CustomImage/CustomImg';
import BodyContainer from '../../../SharedComponents/Container/BodyContainer';
import useHearingAssessmentOneSideDoneHandler from './HearingAssessmentOneSideDoneHandler';
import { HLAAContext } from '../HLAAContext';
import { getUserDeviceModel } from '../../../Services/ResourceService';
import { CircularProgress } from '@material-ui/core';

export default function HearingAssessmentOneSideDone(): React.ReactElement {
    const {
        onDoneAssessment,
        onSwitchHLAASide,
        onCompletedBothSideHLAA,
        getTopProgress,
        error,
        loading,
        progressSetUp,
    } = useHearingAssessmentOneSideDoneHandler();
    const {
        header,
        image,
        instruction,
        errorText,
        button,
        infoText,
        lodingImage,
        progress,
    } = useHearingAssessmentOneSideDoneResource();

    const { info } = useContext(HLAAContext);

    useEffect(() => {
        const doAction = async () => {
            await onDoneAssessment();
        };
        doAction();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AppContainer>
            <TopProgressBar progress={getTopProgress()} />
            <Header headerContent={header} />
            {loading ? (
                <BodyContainer>
                    <div className="OneSideSetUp-runningArea">
                        <CustomImg
                            id={lodingImage.id}
                            className={'OneSideSetUp-image'}
                            src={lodingImage.src}
                            alt={lodingImage.alt}
                        />
                        <CircularProgress
                            id={progress.id}
                            className={'OneSideSetUp-circularProgress'}
                            size="5.3em"
                            thickness={2}
                            variant="determinate"
                            value={progressSetUp}
                        />
                    </div>
                    <CustomTypography
                        type={TypographyType.notoSansBody18px}
                        text={infoText.text}
                        id={infoText.id}
                        noMargin
                    />
                </BodyContainer>
            ) : (
                <>
                    <BodyContainer>
                        <CustomImg
                            id={image.id}
                            src={image.src}
                            alt={image.alt}
                            imgType="iconLarge"
                            className={'itemArea'}
                        />

                        <CustomTypography
                            type={TypographyType.notoSansBody18px}
                            noMargin
                            id={instruction.id1}
                            text={instruction.text1}
                        />

                        {error !== '' ? (
                            <CustomTypography
                                type={TypographyType.notoSansBody16px}
                                noMargin
                                text={error}
                                id={errorText.id}
                            />
                        ) : (
                            <></>
                        )}
                    </BodyContainer>

                    <CustomButton
                        id={button.id}
                        buttonType={ButtonType.PrimaryLoading}
                        onClick={() => {
                            TelemetryService.serviceInstance.TrackAnonymous(
                                'Orion-CompletedWorkflow',
                                {
                                    Workflow:
                                        info.current.activeSide.toLowerCase() ===
                                        'left'
                                            ? 'HLAALeft'
                                            : 'HLAARight',
                                    Model: getUserDeviceModel(),
                                }
                            );
                            info.current.activeSide.toLocaleLowerCase() ===
                            'left'
                                ? onSwitchHLAASide()
                                : onCompletedBothSideHLAA();
                        }}
                    >
                        {button.text}
                    </CustomButton>
                </>
            )}
        </AppContainer>
    );
}
