import React from 'react';
import { ScoringCheckPageState } from './AudiogramScoringCheckModels';

export interface IAudiogramInfo {
    leftScore: number;
    rightScore: number;
    leftRightDifference: boolean;
    scorinPageState: ScoringCheckPageState;
}

export interface IAudiogramContext {
    infoAudiogram: IAudiogramInfo;
    setAudiogramInfo: (value: IAudiogramInfo) => void;
}

export const AudiogramContext = React.createContext({} as IAudiogramContext);
