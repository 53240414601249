import React from 'react';
import { withTracking } from '../../../../Services/Monitoring/ApplicationInsights';
import CustomerServiceHintPage from '../../../MainComponents/TroubleShooting/CustomerServiceHint/CustomerServiceHintPage';
import useInitialSettingCustomerServiceHandler from './InitialSettingCustomerServiceHandlerHook';

function InitialSettingCustomerService() {
    const { retryInitialSetting } = useInitialSettingCustomerServiceHandler();
    return (
        <CustomerServiceHintPage
            tryAgainAction={async () => retryInitialSetting()}
        />
    );
}

export default withTracking(InitialSettingCustomerService);
