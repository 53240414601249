import React from 'react';
import BeepConfirmation from '../../../../Pages/MainComponents/BeepConfirmation/BeepConfirmation';
import TopProgressBar from '../../../TopProgressBar/TopProgressBar';
import useRedoEasyFitBeepConfirmationHandler from './RedoEasyFitBeepConfirmationHandler';
import { useRedoEasyFitConfirmationResource } from './RedoEasyFitBeepConfirmationResource';

export default function RedoEasyFitBeepConfirmation(): React.ReactElement {
    const {
        onBeepConfirmationYesAction,
        onBeepConfirmationNoAction,
        getTopProgress,
    } = useRedoEasyFitBeepConfirmationHandler();
    const useRender = () => {
        return {
            renderTopProgressBar: (): JSX.Element => {
                return <TopProgressBar progress={getTopProgress()} />;
            },
        };
    };

    return (
        <BeepConfirmation
            useHandler={() => {
                return {
                    primaryAction: onBeepConfirmationYesAction,
                    secondaryAction: onBeepConfirmationNoAction,
                };
            }}
            useResource={useRedoEasyFitConfirmationResource}
            useRender={useRender}
        />
    );
}
