import { useContext } from 'react';
import { GainUIMapping } from '../../../../Models/HearingProfile/GainUIMapping';
import { MasterGain } from '../../../../Models/HearingProfile/MasterGain';
import AudioService from '../../../../Services/AudioService';
import DeviceManagementService from '../../../../Services/DeviceManagementService';
import HearingProfileService from '../../../../Services/HearingProfileService';
import LocalStorageService from '../../../../Services/LocalStorageService';
import { VoidReturn, linkCallBacksResource } from '../../../../Utils/PageUtils';
import { SpeechComfortTestContext } from '../SpeechComfortTestContext';
import { SpeechComfortTestState } from '../SpeechComfortTestModels';
import { getMasterGainIdFromObject } from '../SpeechComfortTestUtils';
import { goToUrl } from '../../../../Utils/NavigationUtils';
import { CustomerServiceContactService } from '../../../../Services/Configuration/CustomerServiceContactService';
import { MainViewContext } from '../../../MainComponents/MainView/MainViewContext';
import { TransitionAction } from '../../../MainComponents/MainView/MainViewModel';

export type SpeechComfortTestStartPageHandler = {
    goToDetailPage: () => VoidReturn;
    linkCallBacks: linkCallBacksResource;
};

function useSpeechComfortTestStartPageHandler(): SpeechComfortTestStartPageHandler {
    const { info, setSpeechComfortTestInfo } = useContext(
        SpeechComfortTestContext
    );

    const viewContext = useContext(MainViewContext);

    const getDefaultSideValueFromObject = (
        side: string,
        masterGains: MasterGain[]
    ) => {
        let masterGainId = 0;

        const leftDefaultValueObject = masterGains.find(
            (x: { side: string }) => x.side === side
        );

        if (leftDefaultValueObject !== undefined) {
            masterGainId = getMasterGainIdFromObject(
                info.masterGain.valueToUiMap,
                side,
                leftDefaultValueObject.masterGainId
            );
        }

        return masterGainId;
    };

    const goToDetailPage = async () => {
        let hasError = false;
        setSpeechComfortTestInfo({
            ...info,
            loading: true,
        });
        const masterGains: MasterGain[] = [];
        if (
            LocalStorageService.serviceInstance.getActiveHearingProfileId() ===
            ''
        ) {
            hasError = true;
        } else {
            const bestClusterInfo =
                await HearingProfileService.getBestClusterInfo(
                    LocalStorageService.serviceInstance.getActiveHearingProfileId()
                );
            if (bestClusterInfo !== undefined) {
                const leftDeviceId =
                    LocalStorageService.serviceInstance.getDeviceId('left');
                const rightDeviceId =
                    LocalStorageService.serviceInstance.getDeviceId('right');
                const leftDeviceMasterGain =
                    await DeviceManagementService.getDeviceMasterGain(
                        leftDeviceId
                    );
                const rightDeviceMasterGain =
                    await DeviceManagementService.getDeviceMasterGain(
                        rightDeviceId
                    );
                const leftBestClusterInfo =
                    bestClusterInfo.deviceBestClusterDtos[leftDeviceId];
                const rightBestClusterInfo =
                    bestClusterInfo.deviceBestClusterDtos[rightDeviceId];

                if (
                    leftBestClusterInfo !== undefined &&
                    rightBestClusterInfo !== undefined
                ) {
                    masterGains.push(
                        new MasterGain(
                            'left',
                            leftDeviceMasterGain,
                            leftBestClusterInfo.masterGainUiMinId,
                            leftBestClusterInfo.masterGainUiMaxId
                        )
                    );
                    masterGains.push(
                        new MasterGain(
                            'right',
                            rightDeviceMasterGain,
                            rightBestClusterInfo.masterGainUiMinId,
                            rightBestClusterInfo.masterGainUiMaxId
                        )
                    );
                    const leftDefaultValue = getDefaultSideValueFromObject(
                        'left',
                        masterGains
                    );
                    const rightDefaultValue = getDefaultSideValueFromObject(
                        'right',
                        masterGains
                    );

                    await AudioService.serviceInstance.initAudioContext();
                    setSpeechComfortTestInfo({
                        ...info,
                        latestLeftValue: leftDefaultValue,
                        latestRightValue: rightDefaultValue,
                        previousLeftValue: leftDefaultValue,
                        previousRightValue: rightDefaultValue,
                        masterGain: new GainUIMapping(masterGains),
                        loading: false,
                        speechComfortTestState: SpeechComfortTestState.Detail,
                    });
                } else {
                    hasError = true;
                }
            } else {
                hasError = true;
            }
        }

        if (hasError === true) {
            setSpeechComfortTestInfo({
                ...info,
                loading: false,
            });
        }
    };

    const openHyperLink = () => {
        goToUrl(
            CustomerServiceContactService.getCountrySpecificOrionConnectionCheckAddress(
                false
            ),
            true
        );
    };

    const startMobileDeviceUnmutedCheck = () => {
        viewContext.doTransition(
            TransitionAction.DoMobileDeviceUnmutedCheckDialogOpen
        );
    };

    const linkCallBacks = {
        customerSupport: openHyperLink,
        startMobileDeviceUnmutedCheck: startMobileDeviceUnmutedCheck,
    };

    return {
        goToDetailPage,
        linkCallBacks,
    };
}

export default useSpeechComfortTestStartPageHandler;
