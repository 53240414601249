import React from 'react';
import Header from '../../../SharedComponents/Header/HeaderRefactor';
import AppContainer from '../../../SharedComponents/Container/AppContainer';
import BodyContainer from '../../../SharedComponents/Container/BodyContainer';
import SubMenuList from '../../../SharedComponents/List/SubMenuList';
import useFineTuningSelectionPageHandler from './FineTuningSelectionPageHandlerHook';
import useFineTuningSelectionPageResource from './FineTuningSelectionPageResourceHook';
import { HeaderActionType } from '../../../SharedComponents/Header/HeaderActionType';

const FineTuningSelectionPage = (): JSX.Element => {
    const { goStart, getSelectedItems, itemClickHandler } =
        useFineTuningSelectionPageHandler();
    const { menuItems, headerText } = useFineTuningSelectionPageResource({
        itemClickHandler,
    });

    return (
        <AppContainer>
            <Header
                headerContent={{
                    id: 'header-fine-tuning',
                    text: headerText,
                    type: HeaderActionType.Return,
                }}
                onClick={goStart}
            />
            <BodyContainer flexStart>
                <SubMenuList
                    getSelectedItems={getSelectedItems}
                    menuItems={menuItems}
                />
            </BodyContainer>
        </AppContainer>
    );
};

export default FineTuningSelectionPage;
