import { VoidReturn } from '../../../Utils/PageUtils';
import { HearingAssessmentState, HLAAContext } from '../HLAAContext';
import { useContext } from 'react';
import AudioService from '../../../Services/AudioService';
import { ProgressBarCalculateCurrentProgress } from '../../../Utils/ProgressBarService';
import { HLAAState } from '../../../Models/HLAA/HLAAState';

export type HearingAssessmentStartHandler = {
    startHLAA: () => VoidReturn;
    getTopProgress: () => number;
};

export default function useHearingAssessmentStartHandler(): HearingAssessmentStartHandler {
    const { info, setHearingAssessmentState, hearingAssessmentState } =
        useContext(HLAAContext);

    const getTopProgress = (): number => {
        // HLAA start page should start progress from 0%
        info.current.totalPageCount = 0;
        return ProgressBarCalculateCurrentProgress(
            info.current.totalPageCount,
            info.current.totalPagesForProgress
        );
    };

    const startHLAA = async () => {
        //if user request redohlaa from within assessment must reset the state
        if (hearingAssessmentState === HearingAssessmentState.RedoHLAA) {
            info.current.totalPageCount = 0;
            info.current.activeSide = 'left';
            info.current.left = {
                hlaaDone: false,
            };
            info.current.right = {
                hlaaDone: false,
            };
            info.current.hlaaState = new HLAAState('', '', -1, -1);
            info.current.startKeepAlive = false;
            info.current.repeatDeviceSetup = false;
            info.current.hlaaTestId = '';
            info.current.retryBeepConfirmationCount = 0;
        }

        await AudioService.serviceInstance.initAudioContext();

        info.current.freqStartTime = new Date().getTime();
        info.current.startKeepAlive = false;
        setHearingAssessmentState(HearingAssessmentState.Instruction);
    };

    return {
        startHLAA,
        getTopProgress,
    };
}
