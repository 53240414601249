import React from 'react';
import { withTracking } from '../../../../Services/Monitoring/ApplicationInsights';
import { IDialogContentProps } from '../../DialogUtils';
import useRedoHLAADialogResource from './RedoHLAADialogResourceHook';
import WarningDialog from '../../Warning/WarningDialog';
import useRedoHLAADialogHandler from './RedoHLAADialogHandlerHook';

function RedoHLAADialog({ onClose, onPrimaryAction }: IDialogContentProps) {
    const useHandler = () => useRedoHLAADialogHandler(onClose, onPrimaryAction);

    return (
        <WarningDialog
            onClose={onClose}
            useHandler={useHandler}
            useResource={useRedoHLAADialogResource}
        />
    );
}

export default withTracking(RedoHLAADialog);
