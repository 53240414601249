import { PageState } from '../Pages/MainComponents/MainView/MainViewModel';
import { Workflow } from '../Utils/CompletedWorkflow';
import LoggingService from './LoggingService';
import PatientManagementService from './PatientManagementService';

export enum ReturnToTimelineState {
    Pairing = 'Pairing',
    HLAA = 'HLAA',
    ApplyInitialSettings = 'ApplyInitialSettings',
    SpeechComfort = 'SpeechComfort',
    None = 'None',
}

const ReturnToTimelineStateOrder = [
    ReturnToTimelineState.Pairing,
    ReturnToTimelineState.HLAA,
    ReturnToTimelineState.ApplyInitialSettings,
    ReturnToTimelineState.SpeechComfort,
];

export default class ReturnToTimelineStateService {
    static currentReturnToTimelineState: ReturnToTimelineState =
        ReturnToTimelineState.None;
    static isOnDisabledPageState = false;

    static getExpectedPageFromReturnToTimelineRedo = (): PageState => {
        switch (ReturnToTimelineStateService.currentReturnToTimelineState) {
            case ReturnToTimelineState.Pairing:
                return PageState.Pairing;
            case ReturnToTimelineState.HLAA:
                return PageState.Assessment;
            case ReturnToTimelineState.ApplyInitialSettings:
                return PageState.InitialSettings;
            case ReturnToTimelineState.SpeechComfort:
                return PageState.SpeechComfort;
            default:
                LoggingService.error({
                    componentName: ReturnToTimelineStateService.name,
                    args: ['Unexpected state in return to timeline service'],
                });
                return PageState.None;
        }
    };

    static resetCompletedWorkflowState = async (): Promise<void> => {
        switch (ReturnToTimelineStateService.currentReturnToTimelineState) {
            case ReturnToTimelineState.Pairing:
                await PatientManagementService.resetWorkflow(
                    Workflow.DevicePairingLeft | Workflow.DevicePairingRight
                );
                break;
            case ReturnToTimelineState.HLAA:
                await PatientManagementService.redoHLAA();
                break;
            case ReturnToTimelineState.ApplyInitialSettings:
                await PatientManagementService.resetWorkflow(
                    Workflow.Recommendation
                );
                break;
            case ReturnToTimelineState.SpeechComfort:
                await PatientManagementService.resetWorkflow(
                    Workflow.SpeechComfortTest
                );
                break;
            default:
        }
    };

    static setCurrentReturnToTimelineState = (
        state: ReturnToTimelineState
    ): void => {
        ReturnToTimelineStateService.currentReturnToTimelineState = state;
    };

    static getCurrentStepNumberOfState = (): number => {
        return ReturnToTimelineStateOrder.findIndex(
            (state) =>
                state ===
                ReturnToTimelineStateService.currentReturnToTimelineState
        );
    };

    static resetState = (): void => {
        ReturnToTimelineStateService.currentReturnToTimelineState =
            ReturnToTimelineStateOrder[0];
    };

    static disableMenuOption = (): void => {
        ReturnToTimelineStateService.isOnDisabledPageState = true;
    };

    static enableMenuOption = (): void => {
        ReturnToTimelineStateService.isOnDisabledPageState = false;
    };
}
