import axios from 'axios';
import { AudioContext } from 'standardized-audio-context';
import { NotificationCloseHandlerType } from '../Redux/Models/Notification/NotificationCloseHandlerType';
import { updateNotification } from '../Redux/Reducers/NotificationSlice';
import easyFitStore from '../Redux/Store/EasyFitStore';
import { t } from './LocalizationService';
import LoggingService from './LoggingService';

export default class AudioService {
    public static serviceInstance: AudioService = new AudioService();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private audio: any | undefined;
    private isPaused: Boolean;

    constructor() {
        this.isPaused = true;
    }

    // This needs to be called from all user gesture handlers that will start playing an audio
    // to ensure that audio context is running.
    // Function will block until AudioContext resumption completes
    // Can be called multiple times safely.
    public async initAudioContext(): Promise<void> {
        if (this.audio) {
            // standardized audio-context has possible states 'closed | suspended | running'
            if (this.audio.state === 'closed') {
                this.audio = new AudioContext();
            } else if (this.audio.state === 'suspended') {
                await this.closeAudioContext();
                this.audio = new AudioContext();
            } else {
                // if already running, do nothing
            }
        } else {
            this.audio = new AudioContext();
            await this.audio.resume();
        }
    }

    public async suspendAduioContext(): Promise<void> {
        if (this.audio && this.audio.state == 'running')
            await this.audio.suspend();
    }

    public async checkAndResumeAudioContext(): Promise<void> {
        if (this.audio && this.audio.state === 'suspended')
            await this.audio.resume();
    }

    // Close the current audio context if there is one
    public async closeAudioContext(): Promise<void> {
        if (this.audio && this.audio.state != 'closed') {
            await this.audio.close();
        }
    }

    // Replace current audio context with a new one
    public async createNewAudioContext(): Promise<void> {
        this.audio = new AudioContext();
        await this.audio.resume();
    }

    public async prepAudio(
        url: string,
        description: string,
        waitTime = 30000,
        delayBeforeAudio = 200
    ): Promise<void> {
        // Check if audio context is closed due to external link, if so, initialize a new one.
        await this.initAudioContext();

        const sleep = (milliseconds: number) => {
            return new Promise((resolve) => setTimeout(resolve, milliseconds));
        };
        const checkForPrepAudio = async (retryCount: number) => {
            let isPaused = this.isPaused;

            if (this.isPaused) {
                this.isPaused = false;
                const audioCtx = this.audio;
                const source = audioCtx.createBufferSource();
                let count: ReturnType<typeof setTimeout>;

                const timeout = new Promise((resolve, reject) => {
                    count = setTimeout(() => {
                        reject(`Timeout while retrieving/playing tones.`);
                    }, waitTime);
                });

                const promise = new Promise<void>(function (
                    resolve: () => void,
                    reject: (error: string | DOMException | Error) => void
                ) {
                    axios
                        .get<ArrayBuffer>(url, {
                            validateStatus: (_Ignore) => true,
                            timeout: 10000,
                            responseType: 'arraybuffer',
                            headers: {
                                'Cache-Control': 'no-cache',
                                Pragma: 'no-cache',
                                Expires: '0',
                            },
                        })
                        .then((response) => {
                            const audioData = response.data as ArrayBuffer;
                            if (
                                200 <= response.status &&
                                response.status <= 299
                            ) {
                                audioCtx
                                    .decodeAudioData(
                                        audioData,
                                        function (buffer: AudioBuffer) {
                                            source.connect(
                                                audioCtx.destination
                                            );
                                            source.buffer = buffer;

                                            isPaused = false;

                                            source.start(0);
                                        }
                                    )
                                    .catch((e: DOMException | Error) => {
                                        isPaused = true;
                                        reject(e);
                                    });
                            } else {
                                reject(
                                    `Request responded with status ${response.status}: ${response.statusText} - ${response.data}`
                                );
                            }
                        })
                        .catch((err) => {
                            isPaused = true;
                            LoggingService.error({
                                componentName: prepAudio.name,
                                args: [`Error on request - ${err}`],
                            });
                            reject(`Error on request - ${err}`);
                        });
                    source.onended = function () {
                        source.stop(0);
                        isPaused = true;
                        LoggingService.log({
                            componentName: prepAudio.name,
                            args: [`${description} audio played!`],
                        });
                        resolve();
                    };
                });

                return Promise.race([promise, timeout])
                    .then((result) => {
                        return result;
                    })
                    .catch((error) => {
                        this.isPaused = true;
                        LoggingService.error({
                            componentName: AudioService.name,
                            args: [
                                `Could not play audio for ${description} - ${error}, attempt: ${
                                    retryCount + 1
                                }`,
                            ],
                        });
                        if (typeof error == 'string') {
                            throw new Error(`${error}`);
                        } else {
                            throw error;
                        }
                    })
                    .finally(() => {
                        clearTimeout(count);
                        this.isPaused = isPaused;
                        source.disconnect();
                    });
            } else {
                setTimeout(checkForPrepAudio, 500);
            }
        };

        const maxRetry = Number(process.env.REACT_APP_MaxRetryCount);

        async function retry<T>(
            fn: (retryCount: number) => Promise<T>,
            n = Number(process.env.REACT_APP_MaxRetryCount),
            retryDelay = 500
        ): Promise<T> {
            let lastError;
            for (let retryCount = 0; retryCount < n; retryCount++) {
                try {
                    return await fn(retryCount);
                } catch (err) {
                    lastError = err;
                    await sleep(retryDelay);
                }
            }
            throw lastError;
        }

        await sleep(delayBeforeAudio);
        try {
            if (this.audio.state !== 'running') {
                easyFitStore.dispatch(
                    updateNotification({
                        ...easyFitStore.getState().notification,
                        alertMessage: {
                            title: t(
                                'common:Common_Feature:ErrorHandling:AudioContextErrorTitle'
                            ),
                            message: [
                                t(
                                    'common:Common_Feature:ErrorHandling:AudioContextErrorMessage'
                                ),
                            ],
                            detailMessage: t(
                                'common:Common_Feature:ErrorHandling:AudioContextErrorDetail'
                            ),
                            userSelections: [
                                {
                                    content: t('common:Common_Feature:ok'),
                                    action: NotificationCloseHandlerType.Reload,
                                },
                            ],
                            isDisplayed: true,
                        },
                    })
                );
                throw new Error(
                    `Failed to start audio context, context state was ${this.audio.state}`
                );
            }
            await retry(prepAudio, maxRetry, 500);
        } catch (err) {
            LoggingService.error({
                componentName: prepAudio.name,
                args: [`Audio Service errors occured - ${err}`],
            });
            throw err;
        }

        async function prepAudio(retryCount: number) {
            await checkForPrepAudio(retryCount).then(() => {
                return;
            });
        }
    }
}
