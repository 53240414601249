import React from 'react';
import { withTracking } from '../../../../Services/Monitoring/ApplicationInsights';
import PairButton from '../../../../SharedComponents/Button/PairButton';
import AppContainer from '../../../../SharedComponents/Container/AppContainer';
import BodyContainer from '../../../../SharedComponents/Container/BodyContainer';
import CustomImg from '../../../../SharedComponents/CustomImage/CustomImg';
import Header from '../../../../SharedComponents/Header/HeaderRefactor';
import CustomTypography, {
    TypographyType,
} from '../../../../SharedComponents/Typography/CustomTypography';
import './CustomerServiceHintPage.scss';
import useCustomerServiceHintPageHandler from './CustomerServiceHintPageHandlerHook';
import useCustomerServiceHintPageResource from './CustomerServiceHintPageResourceHook';

export interface ICustomerServiceHintPageProps {
    tryAgainAction: () => Promise<void>;
}

const CustomerServiceHintPage = ({
    tryAgainAction,
}: ICustomerServiceHintPageProps): JSX.Element => {
    const {
        header,
        customerServiceImage,
        customerServiceLink,
        instructionHeader,
        instruction1,
        instruction2,
        tryAgainButton,
        exitButton,
        linkCallBacks,
    } = useCustomerServiceHintPageResource();

    const { onExit } = useCustomerServiceHintPageHandler();

    return (
        <AppContainer>
            <Header headerContent={header} />
            <BodyContainer>
                <CustomTypography
                    type={TypographyType.notoSansBody16px}
                    text={instruction1.text}
                    id={instruction1.id}
                    className={'customerServiceHintInstruction'}
                />
                <div
                    className={'solutionCardCustomerServiceHint'}
                    id="customer-service-card"
                >
                    <CustomTypography
                        type={TypographyType.notoSansSubHeading}
                        text={instructionHeader.text}
                        id={instructionHeader.id}
                        className={'solutionCardTitle'}
                    />
                    <CustomImg
                        id={customerServiceImage.id}
                        imgType={'icon'}
                        src={customerServiceImage.src}
                        alt={customerServiceImage.alt}
                        className={'customerServiceHintImage'}
                    />
                    <CustomTypography
                        type={TypographyType.notoSansBody16px}
                        id={customerServiceLink.id}
                        text={customerServiceLink.text}
                        linkCallBacks={linkCallBacks}
                        className={
                            'solutionCardLink customerServiceHintSupportLink'
                        }
                    />
                </div>
                <CustomTypography
                    type={TypographyType.notoSansBody16px}
                    text={instruction2.text}
                    id={instruction2.id}
                    className={'customerServiceHintInstruction'}
                />
            </BodyContainer>
            <PairButton
                primaryButton={{
                    id: exitButton.id,
                    onClick: async () => {
                        await onExit();
                    },
                    children: exitButton.label,
                }}
                secondaryButton={{
                    id: tryAgainButton.id,
                    onClick: async () => {
                        await tryAgainAction();
                    },
                    children: tryAgainButton.label,
                }}
            />
        </AppContainer>
    );
};

export default withTracking(CustomerServiceHintPage);
