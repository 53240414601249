import { useContext } from 'react';
import { createMachine } from 'xstate';
import { MainViewContext } from '../MainComponents/MainView/MainViewContext';
import { TransitionAction } from '../MainComponents/MainView/MainViewModel';
import { FineTuningMachineType } from './FineTuningModels';

export default function FineTuningStateMachine(): FineTuningMachineType {
    const viewContext = useContext(MainViewContext);
    const completeFineTuningWorkflow = () => {
        viewContext.doTransition(TransitionAction.FineTuningComplete);
    };
    const goBackToLandingPage = () => {
        viewContext.doTransition(TransitionAction.GoToFineTuningLandingPage);
    };
    const completeChangeSleeve = () => {
        viewContext.doTransition(
            TransitionAction.ChangeSleeveCustomerServiceContinue
        );
    };

    const fineTuningMachine = createMachine({
        id: 'finetuning-state-machine',
        initial: 'Boot',
        states: {
            Boot: {
                always: [{ target: 'Start' }],
            },
            Start: {
                on: {
                    goToSelection: 'Selection',
                    goToReset: 'BeforeReset',
                    goToSolution: 'Solution',
                    goToInfo: 'Info',
                    goToLanding: {
                        actions: goBackToLandingPage,
                    },
                },
            },
            Selection: {
                on: {
                    goToStart: 'Start',
                },
            },
            BeforeReset: {
                on: {
                    goToStart: 'Start',
                    startReset: 'DuringReset',
                },
            },
            DuringReset: {
                on: {
                    finishReset: 'AfterReset',
                },
            },
            AfterReset: {
                on: {
                    goToStart: 'Start',
                },
            },
            Solution: {
                on: {
                    goToStart: 'Start',
                    completeFineTuning: {
                        actions: completeFineTuningWorkflow,
                    },
                    goToLanding: {
                        actions: goBackToLandingPage,
                    },
                    completeChangeSleeve: {
                        actions: completeChangeSleeve,
                    },
                },
            },
            Info: {
                on: {
                    goToStart: 'Start',
                },
            },
        },
    });

    return fineTuningMachine;
}
