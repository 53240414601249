import React from 'react';
import useRedoHLAAOneSideSetUpResource from './RedoHLAAOneSideSetUpResourceHook';
import useRedoHLAAOneSideSetUpHandler from './RedoHLAAOneSideSetUpHandlerHook';
import OneSideSetUp from '../../../../Pages/MainComponents/OneSideSetUp/OneSideSetUp';

export default function RedoHLAAOneSideSetUp(): React.ReactElement {
    return (
        <OneSideSetUp
            useHandler={useRedoHLAAOneSideSetUpHandler}
            useResource={useRedoHLAAOneSideSetUpResource}
        />
    );
}
