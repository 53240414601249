import { Patient } from '../Models/Patient/Patient';
import { SupportedHi } from '../Models/Patient/SupportedHi';

enum StorageParams {
    CountrySelection = 'CountrySelection',
    PatientCountry = 'PatientCountry',
    PatientId = 'PatientId',
    PatientLocalId = 'PatientLocalId',
    LastCompletedHlaaTestId = 'LastCompletedHlaaTestId',
    ActiveHlaaTestId = 'ActiveHlaaTestId',
    ActiveHearingProfileId = 'ActiveHearingProfileId',
    DevicePairingRequired = 'DevicePairingRequired',
    DataAnalyticConsent = 'DataAnalyticConsent',
    CompletedWorkflow = 'CompletedWorkflow',
    RightPairing = 'RightPairing',
    LeftPairing = 'LeftPairing',
    RightDevice = 'RightDevice',
    LeftDevice = 'LeftDevice',
    LanguageCode = 'i18nextLng',
    OperationConsent = 'OperationConsent',
    QueryParams = 'QueryParams',
    SupportedHis = 'SupportedHis',
    MandatoryWorkflowCompletedOnce = 'MandatoryWorkflowCompletedOnce',
    IsRedoFit = 'IsRedoFit',
    AccessToken = 'accessToken',
    IsDataPrivacyAndSafetyInfoAccepted = 'isDataPrivacyAndSafetyInfoAccepted',
    MobileAppVersionNumber = 'MobileAppVersionNumber',
    SwitchDevice = 'SwitchDevice',
}

export default class LocalStorageService {
    public static serviceInstance: LocalStorageService =
        new LocalStorageService();
    private localStorage: Storage;

    constructor() {
        this.localStorage = window.localStorage;
    }

    public setQueryParams(params: string): void {
        this.localStorage.setItem(StorageParams.QueryParams, params);
    }

    public getQueryParams(): string {
        const result = this.localStorage.getItem(StorageParams.QueryParams);
        return result ? result : '';
    }

    public clearQueryParams(): void {
        this.localStorage.removeItem(StorageParams.QueryParams);
    }

    public getSelectedCountry(): string {
        const result = this.localStorage.getItem(
            StorageParams.CountrySelection
        );
        return result ? result : '';
    }

    public setSelectedCountry(country: string | null): void {
        if (country == null) {
            this.localStorage.removeItem(StorageParams.CountrySelection);
        } else {
            this.localStorage.setItem(StorageParams.CountrySelection, country);
        }
    }

    public getPatientCountry(): string {
        const result = this.localStorage.getItem(StorageParams.PatientCountry);
        return result ? result : '';
    }

    public getPatientId(): string {
        const result = this.localStorage.getItem(StorageParams.PatientId);
        return result ? result : '';
    }

    public setPatientId(patientId: string | null): void {
        if (patientId == null) {
            this.localStorage.removeItem(StorageParams.PatientId);
        } else {
            this.localStorage.setItem(StorageParams.PatientId, patientId);
        }
    }

    public getPatientLocalId(): string {
        const result = this.localStorage.getItem(StorageParams.PatientLocalId);
        return result ? result : '';
    }

    public setPatientLocalId(patientId: string | null): void {
        if (patientId == null) {
            this.localStorage.removeItem(StorageParams.PatientLocalId);
        } else {
            this.localStorage.setItem(StorageParams.PatientLocalId, patientId);
        }
    }

    private updateStorage(key: string, value: string) {
        if (value) {
            this.localStorage.setItem(key, value);
        } else {
            this.localStorage.removeItem(key);
        }
    }

    public async setPatient(patient: Patient): Promise<void> {
        this.updateStorage(
            StorageParams.LastCompletedHlaaTestId,
            patient.lastCompletedHlaaTestId
        );

        this.updateStorage(
            StorageParams.OperationConsent,
            patient.isOperationDataConsented ? 'true' : 'false'
        );

        this.updateStorage(
            StorageParams.DataAnalyticConsent,
            patient.dataAnalyticConsentValue
        );

        this.updateStorage(
            StorageParams.ActiveHlaaTestId,
            patient.activeHlaaTestId
        );

        this.updateStorage(
            StorageParams.ActiveHearingProfileId,
            patient.activeHearingProfileId
        );

        if (
            patient &&
            patient.devicePairingRequired !== undefined &&
            patient.devicePairingRequired !== null
        ) {
            this.updateStorage(
                StorageParams.DevicePairingRequired,
                patient.devicePairingRequired.toString()
            );
        }

        this.updateStorage(
            StorageParams.PatientCountry,
            patient.residingCountry
        );

        this.updateStorage(
            StorageParams.CompletedWorkflow,
            Number(patient.easyFitWorkflows).toString()
        );

        this.updateStorage(
            StorageParams.MandatoryWorkflowCompletedOnce,
            patient.isMandatoryWorkflowCompletedOnce ? 'true' : 'false'
        );

        if (patient && Array.isArray(patient.devices)) {
            patient.devices.forEach((d) => {
                this.localStorage.setItem(
                    this.firstCharToUpper(d.side) + 'Device',
                    `${d.id}&${d.brandId}&${d.modelId}&${d.couplingId}`
                );
                this.localStorage.setItem(
                    this.firstCharToUpper(d.side) + 'Pairing',
                    d.pairingAddress.toString()
                );
            });
        }
    }

    public async clearPatientData(): Promise<void> {
        this.updateStorage(StorageParams.LastCompletedHlaaTestId, '');

        this.updateStorage(StorageParams.OperationConsent, '');

        this.updateStorage(StorageParams.DataAnalyticConsent, '');

        this.updateStorage(StorageParams.ActiveHlaaTestId, '');

        this.updateStorage(StorageParams.ActiveHearingProfileId, '');

        this.updateStorage(StorageParams.DevicePairingRequired, '');

        this.updateStorage(StorageParams.PatientCountry, '');

        this.updateStorage(StorageParams.CompletedWorkflow, '');

        this.updateStorage(StorageParams.LeftDevice, '');

        this.updateStorage(StorageParams.RightDevice, '');

        this.updateStorage(StorageParams.MandatoryWorkflowCompletedOnce, '');
    }

    public getDeviceId(side: string): string {
        const result = this.localStorage.getItem(
            this.firstCharToUpper(side) + 'Device'
        );
        return result ? result.split('&')[0].trim() : '';
    }

    public getDeviceBrandId(side: string): number {
        const result = this.localStorage.getItem(
            this.firstCharToUpper(side) + 'Device'
        );
        return result ? Number(result.split('&')[1].trim()) : -1;
    }

    public getDeviceModelId(side: string): number {
        const result = this.localStorage.getItem(
            this.firstCharToUpper(side) + 'Device'
        );
        return result ? Number(result.split('&')[2].trim()) : -1;
    }

    public getDeviceCouplingId(side: string): number {
        const result = this.localStorage.getItem(
            this.firstCharToUpper(side) + 'Device'
        );
        return result ? Number(result.split('&')[3].trim()) : -1;
    }

    public setSupportedHis(supportedHis: SupportedHi[]): void {
        this.localStorage.setItem(
            StorageParams.SupportedHis,
            JSON.stringify(supportedHis)
        );
    }

    public getSupportedHis(): SupportedHi[] {
        const value = this.localStorage.getItem(StorageParams.SupportedHis);
        let result = undefined;
        if (value !== null) {
            result = JSON.parse(value);
        }
        return result;
    }

    public getPairingAddress(side: string): number {
        const result = this.localStorage.getItem(
            this.firstCharToUpper(side) + 'Pairing'
        );
        return result ? Number(result) : -1;
    }

    public doesPairingExist(side: string): boolean {
        const result = this.localStorage.getItem(
            this.firstCharToUpper(side) + 'Pairing'
        );
        return !(result === undefined || result === '-1');
    }

    public getLastCompletedHlaaTestId(): string {
        const result = this.localStorage.getItem(
            StorageParams.LastCompletedHlaaTestId
        );
        return result ? result : '';
    }
    public getActiveHlaaTestId(): string {
        const result = this.localStorage.getItem(
            StorageParams.ActiveHlaaTestId
        );
        return result ? result : '';
    }

    public setLastCompletedHlaaTestId(lastCompletedHlaaTestId: string): void {
        this.localStorage.setItem(
            StorageParams.LastCompletedHlaaTestId,
            lastCompletedHlaaTestId
        );
    }

    public setPairingAddress(side: string, pairingAddress: number): void {
        this.localStorage.setItem(
            this.firstCharToUpper(side) + 'Pairing',
            pairingAddress.toString()
        );
    }

    public clearPairingKey(): void {
        this.clearKey(StorageParams.LeftPairing);
        this.clearKey(StorageParams.RightPairing);
    }

    private clearKey(key: string) {
        if (this.localStorage.getItem(key) !== undefined)
            this.localStorage.removeItem(key);
    }

    public clear(includeUserProfile = true): void {
        const notToDelete = [
            StorageParams.LanguageCode,
            StorageParams.SupportedHis,
        ];

        let allCookies = Object.values(StorageParams).filter(
            (value) => !notToDelete.includes(value)
        );

        const patientAndDevice = [
            StorageParams.PatientId,
            StorageParams.PatientLocalId,
            StorageParams.IsRedoFit,
            StorageParams.RightDevice,
            StorageParams.LeftDevice,
            StorageParams.AccessToken,
        ];
        if (!includeUserProfile) {
            allCookies = allCookies.filter(
                (value) => !patientAndDevice.includes(value)
            );
        }

        allCookies.forEach((value) => {
            this.clearKey(value);
        });
    }

    private firstCharToUpper(text: string): string {
        return text.toLowerCase().charAt(0).toUpperCase() + text.slice(1);
    }

    public setActiveHlaaTestId(id: string): void {
        this.localStorage.setItem(StorageParams.ActiveHlaaTestId, id);
    }

    public setActiveHearingProfileId(id: string): void {
        this.localStorage.setItem(StorageParams.ActiveHearingProfileId, id);
    }

    public getActiveHearingProfileId(): string {
        const result = this.localStorage.getItem(
            StorageParams.ActiveHearingProfileId
        );
        return result ? result : '';
    }

    public IsHearingProfileIdAvailable(): boolean {
        return (
            this.localStorage.getItem(StorageParams.ActiveHearingProfileId) !==
            undefined
        );
    }

    public getLanguageCode(): string {
        const result = this.localStorage.getItem(StorageParams.LanguageCode);
        return result ? result : '';
    }

    public setDevicePairingRequired(value: string): void {
        this.localStorage.setItem(StorageParams.DevicePairingRequired, value);
    }

    public getDevicePairingRequired(): string {
        const result = this.localStorage.getItem(
            StorageParams.DevicePairingRequired
        );
        return result ? result : '';
    }

    public getCompletedWorkflow(): number {
        const result = this.localStorage.getItem(
            StorageParams.CompletedWorkflow
        );
        return result ? Number(result) : NaN;
    }

    public setCompletedWorkflow(completedWorkflow: number): void {
        this.localStorage.setItem(
            StorageParams.CompletedWorkflow,
            completedWorkflow.toString()
        );
    }

    public getDataAnalyticConsentState(): string {
        const result = this.localStorage.getItem(
            StorageParams.DataAnalyticConsent
        );
        return result ? result : '';
    }

    public setDataAnalyticConsentState(consentState: string): void {
        this.localStorage.setItem(
            StorageParams.DataAnalyticConsent,
            consentState
        );
    }

    public getOperationConsentState(acceptNullVal = false): boolean {
        const result = this.localStorage.getItem(
            StorageParams.OperationConsent
        );

        return result === 'true' || (result === null && acceptNullVal);
    }

    public setOperationConsentState(consentValue: boolean): void {
        this.localStorage.setItem(
            StorageParams.OperationConsent,
            consentValue.toString()
        );
    }

    public getMandatoryWorkflowCompletedOnce(): boolean {
        const result = this.localStorage.getItem(
            StorageParams.MandatoryWorkflowCompletedOnce
        );
        return result === 'true';
    }

    public getAccessToken(): string {
        const result = this.localStorage.getItem(StorageParams.AccessToken);
        return result ? result : '';
    }

    public setAccessToken(token: string): void {
        this.localStorage.setItem(StorageParams.AccessToken, token);
    }

    public getIsDataPrivacyAndSafetyInfoAccepted(): boolean {
        const result = this.localStorage.getItem(
            StorageParams.IsDataPrivacyAndSafetyInfoAccepted
        );
        return result === null ? false : result.toLowerCase() === 'true';
    }

    public setMobileAppVersionNumber(params: string): void {
        this.localStorage.setItem(StorageParams.MobileAppVersionNumber, params);
    }

    public getMobileAppVersionNumber(): string {
        const result = this.localStorage.getItem(
            StorageParams.MobileAppVersionNumber
        );
        return result ? result : '';
    }

    public getIsRedoFitState(acceptNullVal = false): boolean {
        const result = this.localStorage.getItem(StorageParams.IsRedoFit);

        return result === 'true' || (result === null && acceptNullVal);
    }

    public setIsRedoFitState(isFirstFitValue: boolean): void {
        this.localStorage.setItem(
            StorageParams.IsRedoFit,
            isFirstFitValue.toString()
        );
    }

    public getSwitchDevice(): boolean {
        const result = this.localStorage.getItem(StorageParams.SwitchDevice);

        return result === null ? false : result.toLowerCase() === 'true';
    }

    public setSwitchDevice(isDeviceSwitched: boolean): void {
        this.localStorage.setItem(
            StorageParams.SwitchDevice,
            isDeviceSwitched.toString()
        );
    }
}
