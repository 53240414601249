import React from 'react';
import FlexContainer from '../Container/FlexContainer';
import BulletList from '../List/BulletList';
import CustomTypography, {
    LinkCallBacks,
    TypographyType,
} from '../Typography/CustomTypography';

export interface ICarouselFooterProps {
    step: number;
    contents: CarouselFooterContent[];
    linkCallBacks?: LinkCallBacks;
}

export type CarouselFooterContent = {
    instructionType?: InstructionType;
    instructionContent: string;
};

export enum InstructionType {
    BulletList,
    TextLeftAlign,
}

function CarouselFooter(props: ICarouselFooterProps): JSX.Element {
    return (
        <FlexContainer>
            {props.contents[props.step].instructionType ===
            InstructionType.BulletList ? (
                <BulletList
                    listId={'carousel-instruction'}
                    bulletItems={props.contents[props.step].instructionContent
                        .split('\n')
                        .filter(Boolean)}
                />
            ) : (
                props.contents[props.step].instructionContent
                    .split(/(?=&&&)/)
                    .map((instructionContent, index) => (
                        <CustomTypography
                            key={index}
                            id={'carousel-instruction'}
                            type={TypographyType.notoSansBody16px}
                            text={instructionContent.replace('&&&', '')}
                            linkCallBacks={props.linkCallBacks}
                            className={
                                !instructionContent.includes('&&&') &&
                                index === 0
                                    ? props.contents[props.step]
                                          .instructionType ===
                                      InstructionType.TextLeftAlign
                                        ? 'instructionTextLeftAlign'
                                        : 'instructionText'
                                    : ''
                            }
                        />
                    ))
            )}
        </FlexContainer>
    );
}

export default CarouselFooter;
