import { deviceModel } from '../Models/SupportedDeviceModel';
import { PageState } from '../Pages/MainComponents/MainView/MainViewModel';
import LocalStorageService from '../Services/LocalStorageService';
import LoggingService from '../Services/LoggingService';
import { getUserDeviceModel } from '../Services/ResourceService';

export enum Workflow {
    Contraindication = 1 << 3, //8
    HIAssemble = 1 << 5, //32
    DevicePairingLeft = 1 << 9, //512
    DevicePairingRight = 1 << 10, //1024
    HLAATest = 1 << 12, //4096
    Recommendation = 1 << 16, //65536
    SpeechComfortTest = 1 << 18, //262144
    FineTuningAssistant = 1 << 24, //16777216
}

export const mandatoryWorkflow =
    Workflow.Contraindication |
    Workflow.HIAssemble |
    Workflow.DevicePairingLeft |
    Workflow.DevicePairingRight |
    Workflow.HLAATest |
    Workflow.Recommendation |
    Workflow.SpeechComfortTest;

export default class CompletedWorkflowService {
    static getFirstSetBitPos = (n: number): number => {
        return Math.log2(n & -n) + 1;
    };

    // Handles mandatory workflow only
    static getNextMandatoryWorkflow = (
        completedWorkflows: Workflow,
        startFromUserJourney = false
    ): PageState => {
        let nextPage = PageState.None;
        const missingWorkflow =
            CompletedWorkflowService.getFirstMissingWorkflow(
                completedWorkflows
            );
        switch (missingWorkflow) {
            case Workflow.Contraindication:
                nextPage = PageState.SplashScreen;
                break;
            case Workflow.HIAssemble:
                nextPage = LocalStorageService.serviceInstance.getSwitchDevice()
                    ? PageState.SplashScreen
                    : PageState.HiAssemble;
                break;
            case Workflow.DevicePairingLeft:
            case Workflow.DevicePairingRight:
                nextPage = startFromUserJourney
                    ? PageState.Journey
                    : PageState.Pairing;
                break;
            case Workflow.HLAATest:
                nextPage = startFromUserJourney
                    ? PageState.Journey
                    : CompletedWorkflowService.showSleeveSelection()
                    ? PageState.SleeveSelection
                    : PageState.Assessment;
                break;
            case Workflow.Recommendation:
                nextPage = startFromUserJourney
                    ? PageState.Journey
                    : PageState.InitialSettings;
                break;
            case Workflow.SpeechComfortTest:
                nextPage = startFromUserJourney
                    ? PageState.Journey
                    : PageState.SpeechComfort;
                break;
            default:
                LoggingService.error({
                    componentName:
                        'CompletedWorkflowService.getNextMandatoryWorkflow',
                    args: [`No mandatory workflow missing.`],
                });
                break;
        }
        return nextPage;
    };

    static getFirstMissingWorkflow = (
        completedWorkflows: Workflow
    ): Workflow => {
        const missingworkflow =
            1 <<
            (CompletedWorkflowService.getFirstSetBitPos(
                (completedWorkflows & mandatoryWorkflow) ^ mandatoryWorkflow
            ) -
                1);

        return missingworkflow;
    };

    static isCompletedMandatoryWorkflow = (
        completedWorkflows: Workflow
    ): boolean => {
        return completedWorkflows >= mandatoryWorkflow;
    };

    static isCompleted = (
        completedWorkflows: Workflow | undefined,
        workflowToCheck: Workflow
    ): boolean => {
        if (!completedWorkflows) return false;
        return (completedWorkflows & workflowToCheck) === workflowToCheck;
    };

    static showSleeveSelection = (): boolean => {
        const isMandatoryWorkflowCompletedOnce =
            LocalStorageService.serviceInstance.getMandatoryWorkflowCompletedOnce();
        return (
            isMandatoryWorkflowCompletedOnce &&
            getUserDeviceModel() === deviceModel.OrionComplete
        );
    };

    static isUnderHLAAWorkflow = (): boolean => {
        return (
            CompletedWorkflowService.getFirstMissingWorkflow(
                LocalStorageService.serviceInstance.getCompletedWorkflow()
            ) == Workflow.HLAATest
        );
    };
}
