export class PatientConsentData {
    public patientId: string;
    public state: ConsentValue;
    public name: string;
    public languageCode: string;
    public version: string;
    public givenAtUtc: Date;

    constructor(
        patientId: string,
        state: ConsentValue,
        name: string,
        languageCode: string,
        version: string,
        givenAtUtc: Date
    ) {
        this.patientId = patientId;
        this.state = state;
        this.name = name;
        this.languageCode = languageCode;
        this.version = version;
        this.givenAtUtc = givenAtUtc;
    }
}

export enum ConsentValue {
    Pending = 'Pending',
    Agreed = 'Agreed',
    Declined = 'Declined',
}
