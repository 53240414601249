import { ButtonProps } from '@material-ui/core';
import React, { useState } from 'react';
import ButtonSpinner from '../../Spinner/ButtonSpinner';

import '../ButtonStyle.scss';
import PrimaryButton from './PrimaryButton';

function PrimaryButtonWithLoading({
    onClick,
    disabled,
    ...others
}: ButtonProps): React.ReactElement {
    const loadingSpinner = <ButtonSpinner color="inherit" size="2.05rem" />;
    const [loading, setLoading] = useState(false);

    return (
        <PrimaryButton
            disabled={loading || disabled}
            onClick={async (event) => {
                if (onClick) {
                    setLoading(true);
                    await onClick(event);
                    setLoading(false);
                }
            }}
            {...others}
        >
            {loading ? loadingSpinner : <div>{others.children}</div>}
        </PrimaryButton>
    );
}

export default PrimaryButtonWithLoading;
