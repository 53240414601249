import React from 'react';
import { getMasterGainStepSize } from '../../../Services/ResourceService';
import { IVolumeSlider } from '../SliderFactory';
import VolumeSlider from './SliderWithControlButton';

function SliderWithControlButtonSideRight(
    others: IVolumeSlider
): React.ReactElement {
    return (
        <VolumeSlider
            thumbImgSrc={`/resources/sharedAcrossWorkflows/slider-right.svg`}
            stepAdjustValue={getMasterGainStepSize()}
            {...others}
        />
    );
}

export default SliderWithControlButtonSideRight;
