import { useContext } from 'react';
import { assign, AssignAction, EventObject } from 'xstate';
import { Side } from '../../Models/Side';
import { VoidReturn } from '../../Utils/PageUtils';
import { MainViewContext } from '../MainComponents/MainView/MainViewContext';
import { TransitionAction } from '../MainComponents/MainView/MainViewModel';
import { PairingStateContext } from './PairingModels';

export type PairingStateMachineHandler = {
    exitWorkflowHandler: () => VoidReturn;
    updateRetryBeepConfirmationCount: AssignAction<
        PairingStateContext,
        EventObject
    >;
    switchSide: (context: PairingStateContext) => Side | undefined;
    updatePairingContext: AssignAction<PairingStateContext, EventObject>;
};

export function getAvailableSide(): Side[] {
    return ['left' as Side, 'right' as Side];
}

export default function usePairingStateMachineHandler(): PairingStateMachineHandler {
    const viewContext = useContext(MainViewContext);

    function switchSide(context: PairingStateContext): Side | undefined {
        let newSide = undefined;
        const avalableSide = getAvailableSide();
        avalableSide.forEach((side) => {
            if (side !== context.activeSide && context[side] === false)
                newSide = side;
        });
        return newSide;
    }

    async function exitWorkflowHandler() {
        viewContext.doTransition(TransitionAction.PairingContinue);
    }

    const updateRetryBeepConfirmationCount = assign(
        (context: PairingStateContext) => {
            const count =
                context.retryBeepConfirmationCount <
                context.retryBeepConfirmationCountThreshold
                    ? context.retryBeepConfirmationCount + 1
                    : context.retryBeepConfirmationCount;
            return {
                retryBeepConfirmationCount: count,
            };
        }
    );

    const updatePairingContext = assign((context: PairingStateContext) => {
        const newSide = switchSide(context);
        return {
            activeSide: newSide,
            [context.activeSide]: true,
            retryBeepConfirmationCount: 0,
        };
    });

    return {
        exitWorkflowHandler,
        updateRetryBeepConfirmationCount,
        updatePairingContext,
        switchSide,
    };
}
