export enum HearingLossAssessmentState {
    NotStarted = 'NotStarted',
    Continue = 'Continue',
    Reinstruct = 'Reinstruct',
    LeftEndRightNext = 'LeftEndRightNext',
    RightEndLeftNext = 'RightEndLeftNext',
    Complete = 'Complete',
    AbortMaxPresentedToneReach = 'AbortMaxPresentedToneReach',
    Constant0ToneHeard = 'Constant0ToneHeard',
}
