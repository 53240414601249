import React from 'react';
import { withTracking } from '../../../../Services/Monitoring/ApplicationInsights';
import CustomTypography, {
    TypographyType,
} from '../../../Typography/CustomTypography';
import useMobileDeviceUnmutedCheckConfirmationPageResource from './MobileDeviceUnmutedCheckConfirmationPageResourceHook';
import useMobileDeviceUnmutedCheckConfirmationPageHandler from './MobileDeviceUnmutedCheckConfirmationPageHandlerHook';
import PairButton from '../../../Button/PairButton';
import BodyContainer from '../../../Container/BodyContainer';
import CustomImg from '../../../CustomImage/CustomImg';
import './MobileDeviceUnmutedCheckConfirmationPage.scss';

function MobileDeviceUnmutedCheckConfirmationPage() {
    const { infoText, image, instruction, buttons } =
        useMobileDeviceUnmutedCheckConfirmationPageResource();

    const { primaryAction, secondaryAction } =
        useMobileDeviceUnmutedCheckConfirmationPageHandler();

    return (
        <>
            <BodyContainer>
                <CustomTypography
                    id={infoText.id}
                    type={TypographyType.notoSansBody16px}
                    text={infoText.text}
                />
                <CustomImg
                    id={image.id}
                    src={image.src}
                    alt={image.alt}
                    imgType="iconLarge"
                    className={
                        'MobileDeviceUnmutedCheckConfirmationPageItemArea'
                    }
                />

                <CustomTypography
                    type={TypographyType.notoSansBody16px}
                    noMargin
                    id={instruction.id}
                    text={instruction.text}
                />
            </BodyContainer>
            <PairButton
                primaryButton={{
                    children: buttons.primary.label,
                    id: buttons.primary.id,
                    onClick: primaryAction,
                    className:
                        'MobileDeviceUnmutedCheckConfirmationPagePairButton',
                }}
                secondaryButton={{
                    children: buttons.secondary.label,
                    id: buttons.secondary.id,
                    onClick: secondaryAction,
                    className:
                        'MobileDeviceUnmutedCheckConfirmationPagePairButton',
                }}
            />
        </>
    );
}

export default withTracking(MobileDeviceUnmutedCheckConfirmationPage);
